import { Label, Modal } from 'flowbite-react';
import { 
    HiPlusSm,
    HiOutlineChevronLeft,
    HiOutlineChevronDown,
    HiOutlinePlus,
    HiOutlineUpload
} from "react-icons/hi";
import { Link } from "react-router-dom";
import { useState } from 'react';

const Aiarticlewizard =()=>{
    const [openAddkeyword, setAddkeyword] = useState(false);
    const [showAdvancedOptions, setAdvancedOptions] = useState(false);  

    return(
        <>
            <div className='p-5 md:flex md:justify-between bg-white rounded-md my-2'>
                <div>
                    <div className='mb-1 text-slate-400'>
                        <Link to="/" className="flex items-center">
                                <HiOutlineChevronLeft className="me-1" /> Back to dashboard
                        </Link>
                    </div>
                    <h2 className='text-4xl font-bold'>AI Article Wizard</h2>
                    <p className='text-slate-400 mb-3 md:mb-0'>Create stunning images in seconds.</p>
                </div>
                <div className='md:flex items-center'>
                    <div className="px-6 py-4 me-4 bg-[#EEEEEE] rounded-md">
                        <div className="flex items-center gap-x-1 mb-1">
                            <div className="h-2 w-2 rounded-full bg-blue-600"></div>
                            <h3 className='text-slate-500'>Remaining Credits 2,995,966</h3>
                        </div>
                        <div className="flex items-center gap-x-4">
                            <div className="w-full">
                                <div className="bg-blue-200 rounded-full">
                                    <div className="w-2/3 h-1 bg-blue-600 rounded-full"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='flex items-center gap-3 mt-2 md:mt-0'>
                        <button className='bg-white hover:bg-gray-50/50 rounded-md py-2 px-4 flex font-medium hover:-translate-y-2 duration-300 border hover:shadow-lg'>
                            <HiOutlineUpload className="mr-2 h-5 w-5" /> My Documents
                        </button>
                        <button className='bg-blue-600 hover:bg-blue-500 rounded-md py-2 px-4 text-white flex font-medium hover:-translate-y-2 duration-300 hover:drop-shadow-lg'>
                            <HiPlusSm className="mr-2 h-5 w-5" /> New
                        </button>
                    </div>
                </div>
            </div>
            <div className="grid md:grid-cols-1 lg:grid-cols-2 gap-0 mt-5 rounded-md">
                <div className="pt-1 pb-10 px-5">
                     <div className='md:grid grid-cols-4 mb-4'>
                        <div>
                            <button className='flex items-center justify-center py-2 rounded-md bg-slate-200 hover:bg-slate-200 w-24'>
                                <div className='bg-blue-700 text-white p-1 w-6 h-6 rounded-md me-2 flex justify-center items-center'>1</div> Topic
                            </button>
                        </div>
                        <div>
                            <button className='flex items-center justify-center py-2 rounded-md hover:bg-slate-200 w-24'>
                                2
                            </button>
                        </div>
                        <div>
                            <button className='flex items-center justify-center py-2 rounded-md hover:bg-slate-200 w-24'>
                                3
                            </button>
                        </div>
                        <div>
                            <button className='flex items-center justify-center py-2 rounded-md hover:bg-slate-200 w-24'>
                                4
                            </button>
                        </div>
                    </div>
                    <div className="flex items-center gap-x-4">
                        <div className="w-full">
                            <div className="bg-blue-200 rounded-full">
                                <div className="w-24 h-2 bg-blue-600 rounded-full"></div>
                            </div>
                        </div>
                    </div>
                    <div className='mt-4'>
                        <div className="mb-4">
                            <div className="mb-2 block">
                                <Label value="Topic" />
                            </div>
                            <textarea placeholder="What is this article about?" rows={6} className="bg-[#DEF9FF] border-[#cbe8ef] w-full rounded-md"></textarea>
                        </div>
                        <div className="mb-4">
                            <div className="mb-2 block">
                                <Label value="Number of Keywords" />
                            </div>
                            <input type="number" placeholder='5' min={5} max={50} className="bg-[#DEF9FF] border-[#cbe8ef] w-full rounded-md" />
                        </div>
                        <div className='mb-4'>
                            <div className='sm:grid grid-cols-3 mt-5'>
                                <div className='flex items-center pe-2'>
                                    <div className='border-t w-full hidden sm:block'></div>
                                </div>
                                <div className=''>
                                    <span onClick={()=> setAdvancedOptions (!showAdvancedOptions)} className='flex items-center justify-center cursor-pointer'>
                                    Advanced Options <HiOutlineChevronDown className='ms-2' />
                                    </span>
                                </div>
                                <div className='flex items-center ps-2'>
                                    <div className='border-t w-full hidden sm:block'></div>
                                </div>
                            </div>
                            <div className='mt-2'>
                                {
                                    showAdvancedOptions && 
                                    <div className='py-4'>
                                        <div className="mb-4">
                                            <div className="mb-2 block">
                                                <Label value="Language" />
                                            </div>
                                            <select className="bg-[#DEF9FF] border-[#cbe8ef] w-full rounded-md">
                                                <option>Select</option>
                                            </select>
                                        </div>
                                        <div className="mb-4">
                                            <div className="mb-2 block">
                                                <Label value="Blog Post Length" />
                                            </div>
                                            <input type='text' placeholder='800' className="bg-[#DEF9FF] border-[#cbe8ef] w-full rounded-md" />
                                        </div>
                                        <div className="mb-4">
                                            <div className="mb-2 block">
                                                <Label value="Creativity" />
                                            </div>
                                            <select className="bg-[#DEF9FF] border-[#cbe8ef] w-full rounded-md">
                                                <option>Select</option>
                                            </select>
                                        </div>
                                    </div>
                                }
                            </div>

                        </div>
                        <div className="py-4 flex justify-center">
                            <button className="bg-blue-600 hover:bg-blue-500 rounded-full py-2 px-4 text-white flex justify-center font-medium hover:-translate-y-2 duration-300 hover:drop-shadow-lg w-full">
                                Generate Keywords
                            </button>
                        </div>
                    </div>
                </div>
                <div className="lg:border-s border-h pt-1 pb-10 px-5">
                    <div className='rounded-md py-2 px-4 bg-[#FFDEDE]'>
                        <div className='flex items-center justify-between'>
                            <div>
                                <div className='flex items-center justify-center py-2 rounded-md'>
                                    <span className='text-xl font-semibold'>1. Select Keywords</span>
                                </div>
                            </div>
                            <div>
                                <button onClick={() => setAddkeyword(true)} className='font-semibold text-purple-950 flex items-center'>
                                    Add <HiOutlinePlus className='ms-1 text-xs' />
                                </button>
                                <Modal show={openAddkeyword} size="md" onClose={() => setAddkeyword(false)}>
                                    <Modal.Header>Add Keyword</Modal.Header>
                                    <Modal.Body>
                                        <div className='space-y-6'>
                                            <div className="mb-4">
                                                <input type='text' placeholder='New Keyword' className='w-full bg-[#DEF9FF] border-[#cbe8ef] rounded-md' />
                                            </div>
                                            <div className="py-4 flex justify-center">
                                                <button className="bg-blue-600 hover:bg-blue-500 rounded-full py-2 px-4 text-white flex justify-center font-medium hover:-translate-y-2 duration-300 hover:drop-shadow-lg w-full">
                                                    Add
                                                </button>
                                            </div>
                                        </div>  
                                    </Modal.Body>
                                </Modal>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Aiarticlewizard;