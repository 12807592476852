import { 
    HiPlusSm,
    HiOutlineChevronLeft,
} from "react-icons/hi";
import { Link } from "react-router-dom";
import { Table } from 'flowbite-react';
const BrandVoice =()=>{   
    return(
        <>
            <div className='p-5 md:flex md:justify-between bg-white rounded-md my-2'>
                <div>
                    <div className='mb-1 text-gray-400 font-medium'>
                        <Link to="/" className="flex items-center">
                                <HiOutlineChevronLeft className="me-1" /> Back to dashboard
                        </Link>
                    </div>
                    <h2 className='text-4xl font-bold'>Brand Voice</h2>
                    <p className='text-slate-400 mb-3 md:mb-0'>
                        Generate AI content exclusive to your brand and eliminate the need for repetitive introductions of your company.
                    </p> 
                </div>
                <div className='flex items-center gap-3'>
                    <Link to="/user/brandvoice/add-or-update">
                        <button class='bg-blue-600 hover:bg-blue-500 rounded-md py-2 px-4 text-white flex font-medium hover:-translate-y-2 duration-300 hover:drop-shadow-lg'>
                            <HiPlusSm className="mr-1 h-5 w-5" /> New Company
                        </button>
                    </Link>
                </div>
            </div>
            <div className="bg-white rounded-md border">
                <div className="">
                    <div className="overflow-x-auto">
                        <Table className="">
                            <Table.Head>
                                <Table.HeadCell className="text-slate-500 font-medium bg-white">
                                    Name
                                </Table.HeadCell>
                                <Table.HeadCell className="text-slate-500 font-medium bg-white">
                                    Actions
                                </Table.HeadCell>
                            </Table.Head>
                            <Table.Body className="divide-y">
                                <Table.Row>
                                    <Table.Cell className="w-full">Name</Table.Cell>
                                    <Table.Cell>Actions</Table.Cell>
                                </Table.Row>
                            </Table.Body>
                        </Table>
                    </div>
                </div>
            </div>
        </>
    )
}
export default BrandVoice;