import { Link } from "react-router-dom";
import { 
    HiOutlineChevronLeft,
} from "react-icons/hi";
import { Label, TextInput, Textarea, FileInput } from 'flowbite-react';
const CreateUpdate =()=>{
    
    return(
        <>
            <div className='p-5 md:flex md:justify-between bg-white rounded-md my-2'>
                <div>
                    <div className='mb-1 text-slate-400'>
                        <Link to="/admin/dashboard" className="flex items-center">
                                <HiOutlineChevronLeft className="me-1" /> Back to dashboard
                        </Link>
                    </div>
                    <h2 className='text-4xl font-bold'>Generators List</h2>
                </div>
            </div>
            <div className='p-5 bg-white rounded-md'>
                <div className='grid md:grid-cols-12'>
                    <div className='md:col-span-6 md:col-start-4'>
                        <div class=''>
                            <div>
                                <form className="flex flex-col">
                                    <div className='mb-4'>
                                        <div className="mb-2 block">
                                            <Label value="Menu Title" className="text-slate-500" />
                                        </div>
                                        <TextInput type="text" />
                                    </div>
                                    <div className='mb-4'>
                                        <div className="mb-2 block">
                                            <Label value="Subtitle One" className="text-slate-500" />
                                        </div>
                                        <TextInput type="text" />
                                    </div>
                                    <div className='mb-4'>
                                        <div className="mb-2 block">
                                            <Label value="Subtitle Two" className="text-slate-500" />
                                        </div>
                                        <TextInput type="text"  />
                                    </div>
                                    <div className='mb-4'>
                                        <div className="mb-2 block">
                                            <Label value="Title" className="text-slate-500" />
                                        </div>
                                        <TextInput type="text"  />
                                    </div>
                                    <div className='mb-4'>
                                        <div className="mb-2 block">
                                            <Label value="Text" className="text-slate-500" />
                                        </div>
                                        <Textarea rows={4}  />
                                    </div>
                                    <div className='mb-4'>
                                        <div className="mb-2 block">
                                            <Label value="Image" className="text-slate-500" />
                                        </div>
                                        <FileInput />
                                    </div>
                                    <div className='mb-4'>
                                        <div className="mb-2 block">
                                            <Label value="Image Title" className="text-slate-500" />
                                        </div>
                                        <TextInput type="text"  />
                                    </div>
                                    <div className='mb-4'>
                                        <div className="mb-2 block">
                                            <Label value="Image Subtitle" className="text-slate-500" />
                                        </div>
                                        <TextInput type="text"  />
                                    </div>
                                    <div className='mb-4'>
                                        <div className="mb-2 block">
                                            <Label value="Color Code (Please enter like code. For example: #FFFFFF)" className="text-slate-500" />
                                        </div>
                                        <TextInput type="text"  />
                                    </div>                                   
                                    <div className="py-4 flex justify-center">
                                        <button className="bg-blue-600 hover:bg-blue-500 rounded-full py-2 px-4 text-white flex justify-center font-medium hover:-translate-y-2 duration-300 hover:drop-shadow-lg w-full">
                                            Save
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default CreateUpdate;