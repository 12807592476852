import { Label, Select, TextInput, Textarea } from 'flowbite-react';
import {
    HiOutlineDocumentDuplicate, 
    HiOutlineMinusCircle,
    HiOutlineChevronLeft,
    HiOutlineDownload
} from "react-icons/hi";
import { LuUndo2, LuRedo2 } from "react-icons/lu";
import { Link } from 'react-router-dom';
const PostGenerator =()=>{
    return(
        <>
            <div className='p-5 md:flex md:justify-between bg-white rounded-md my-2'>
                <div>
                    <div className='mb-1 text-slate-400'>
                        <Link to="/" className="flex items-center">
                                <HiOutlineChevronLeft className="me-1" /> Back to dashboard 
                        </Link>
                    </div>
                    <h2 className='text-4xl font-bold'>Post Title Generator</h2>
                    <p className='text-slate-400'>
                        Get captivating post titles instantly with our title generator. Boost engagement and save time.
                    </p>
                </div> 
            </div>
            <div className="grid md:grid-cols-1 lg:grid-cols-2 gap-0 md:p-5 bg-white rounded-md my-2">
                <div className="pt-1 pb-10 px-6">
                    <div className="border rounded-lg px-2 py-4 mb-4">
                        <h3>Remaining Credits</h3>
                        <div className='w-full mt-2'>
                            <ul className='sm:flex'>
                                <li className='flex items-center h-8 border-t-8 mb-3 border-purple-700 sm:w-1/2 pt-2'>
                                    <div className="h-2 w-2 rounded-full bg-purple-800 me-2"></div> 
                                    <div className="pe-4"><span className='text-slate-500'>Words</span> 2,999,119</div>
                                </li>
                                <li className='flex items-center h-8 border-t-8 mb-3 border-slate-300 sm:w-1/2 pt-2'>
                                    <div className="h-2 w-2 rounded-full bg-slate-300 me-2"></div> 
                                    <div><span className='text-slate-500'>Images</span> 2,999,996</div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div>
                        <div className="mb-4">
                            <div className="mb-2 block">
                                <Label htmlFor="comment" value="Description" />
                            </div>
                            <Textarea id="comment" placeholder="Describe What Kind of Code You Need" rows={10} />
                        </div>
                        <div className="mb-4">
                            <div className="mb-2 block">
                                <Label htmlFor="code" value="Language" />
                            </div>
                            <Select>
                                <option>Select</option>
                            </Select>
                        </div>

                        <div className='grid md:grid-cols-2 gap-4'>
                            <div className="mb-4">
                                <div className="mb-2 block">
                                    <Label htmlFor="code" value="Language" />
                                </div>
                                <TextInput id="code" type="text" placeholder="Coding Language (Java, PHP etc.)" />
                            </div>
                            <div className="mb-4">
                                <div className="mb-2 block">
                                    <Label htmlFor="code" value="Language" />
                                </div>
                                <TextInput id="code" type="text" placeholder="Coding Language (Java, PHP etc.)" />
                            </div>
                            <div className="mb-4">
                                <div className="mb-2 block">
                                    <Label htmlFor="code" value="Language" />
                                </div>
                                <Select>
                                    <option>Select</option>
                                </Select>
                            </div>
                            <div className="mb-4">
                                <div className="mb-2 block">
                                    <Label htmlFor="code" value="Language" />
                                </div>
                                <Select>
                                    <option>Select</option>
                                </Select>
                            </div>
                        </div>
                        <div className="py-4 flex justify-center">
                            <button className="flex items-center justify-center text-white bg-[#BC87F6] px-6 py-2 rounded-full hover:scale-105 duration-300 hover:shadow-xl w-full">
                            <span className="font-medium">Generate</span>   
                            </button>
                        </div>
                    </div>
                </div>
                <div className="lg:border-s border-h pt-1 pb-10 px-6">
                    <div className="py-2 border-b flex gap-1">
                        <div className='p-1 hover:bg-gray-300 rounded-sm cursor-pointer'>
                            <LuUndo2 className='text-xl' title='Copy to clipboard' />
                        </div>
                        <div className='p-1 hover:bg-gray-300 rounded-sm cursor-pointer'>
                            <LuRedo2 className='text-xl' title='Copy to clipboard' />
                        </div>
                        <div className='p-1 hover:bg-gray-300 rounded-sm cursor-pointer'>
                            <HiOutlineDocumentDuplicate className='text-xl' title='Copy to clipboard' />
                        </div>
                        <div className='p-1 hover:bg-gray-300 rounded-sm cursor-pointer'>
                            <HiOutlineDownload className='text-xl' title='Copy to clipboard' />
                        </div>
                        <div className='p-1 hover:bg-gray-300 rounded-sm cursor-pointer'>
                            <HiOutlineMinusCircle className='text-xl text-red-700' title='Delete' />
                        </div>
                    </div>
                    <div className='mt-6 p-4rounded-xl'>
                        Untitled Document...
                    </div>                    
                </div>
            </div>
        </>
    )
}
export default PostGenerator;