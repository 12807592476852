import { Link } from "react-router-dom";
import { 
    HiOutlineChevronLeft,
    HiOutlinePencil,
    HiOutlineX,
} from "react-icons/hi";
import { Table } from 'flowbite-react';
const AIcategories =()=>{
    return(
        <>
            <div className='p-5 md:flex md:justify-between bg-white rounded-md my-2'>
                <div>
                    <div className='mb-1 text-slate-400'>
                        <Link to="/admin/dashboard" className="flex items-center">
                                <HiOutlineChevronLeft className="me-1" /> Back to dashboard
                        </Link>
                    </div>
                    <h2 className='text-4xl font-bold'>AI Writer Categories</h2>
                    <p className='text-slate-400 mb-3 md:mb-0'>Manage AI Writer Categories</p>
                </div>
            </div>
            <div className="p-5 mt-4 bg-white rounded-md">
                <div className="p-2 flex items-center mb-5">
                    <div>
                        <div className="flex justify-center">
                            <Link to="/admin/ai-writer-categories/add-or-update">
                                <button  className="bg-blue-600 hover:bg-blue-500 rounded-full py-2 px-4 text-white flex font-medium hover:-translate-y-2 duration-300 hover:drop-shadow-lg w-full">
                                    Add Category
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="border rounded-md">
                    <div className="">
                        <div className="overflow-x-auto">
                            <Table className="bg-transparent">
                                <Table.Head>
                                    <Table.HeadCell className="border-b text-slate-500 font-medium">
                                        Name
                                    </Table.HeadCell>
                                    <Table.HeadCell className="border-b text-slate-500 font-medium">
                                        Actions
                                    </Table.HeadCell>
                                </Table.Head>
                                <Table.Body className="divide-y">
                                    <Table.Row>
                                        <Table.Cell className="w-full">
                                            advertisement
                                        </Table.Cell>
                                        <Table.Cell>
                                            <div className='flex gap-2'>
                                                <div className='h-10 w-10 flex justify-center items-center bg-white  hover:bg-purple-800 rounded-full shadow-md hover:-translate-y-1 duration-300 hover:shadow-xl cursor-pointer hover:text-white' title="Edit">
                                                    <HiOutlinePencil className='text-lg' />
                                                </div>
                                                <div className='h-10 w-10 flex justify-center items-center bg-white  hover:bg-red-700 rounded-full shadow-md hover:-translate-y-1 duration-300 hover:shadow-xl cursor-pointer hover:text-white' title="Delete">
                                                    <HiOutlineX className='text-lg' />
                                                </div>
                                            </div>
                                        </Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell className="w-full">
                                            advertisement
                                        </Table.Cell>
                                        <Table.Cell>
                                            <div className='flex gap-2'>
                                                <div className='h-10 w-10 flex justify-center items-center bg-white  hover:bg-purple-800 rounded-full shadow-md hover:-translate-y-1 duration-300 hover:shadow-xl cursor-pointer hover:text-white' title="Edit">
                                                    <HiOutlinePencil className='text-lg' />
                                                </div>
                                                <div className='h-10 w-10 flex justify-center items-center bg-white  hover:bg-red-700 rounded-full shadow-md hover:-translate-y-1 duration-300 hover:shadow-xl cursor-pointer hover:text-white' title="Delete">
                                                    <HiOutlineX className='text-lg' />
                                                </div>
                                            </div>
                                        </Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell className="w-full">
                                            advertisement
                                        </Table.Cell>
                                        <Table.Cell>
                                            <div className='flex gap-2'>
                                                <div className='h-10 w-10 flex justify-center items-center bg-white  hover:bg-purple-800 rounded-full shadow-md hover:-translate-y-1 duration-300 hover:shadow-xl cursor-pointer hover:text-white' title="Edit">
                                                    <HiOutlinePencil className='text-lg' />
                                                </div>
                                                <div className='h-10 w-10 flex justify-center items-center bg-white  hover:bg-red-700 rounded-full shadow-md hover:-translate-y-1 duration-300 hover:shadow-xl cursor-pointer hover:text-white' title="Delete">
                                                    <HiOutlineX className='text-lg' />
                                                </div>
                                            </div>
                                        </Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell className="w-full">
                                            advertisement
                                        </Table.Cell>
                                        <Table.Cell>
                                            <div className='flex gap-2'>
                                                <div className='h-10 w-10 flex justify-center items-center bg-white  hover:bg-purple-800 rounded-full shadow-md hover:-translate-y-1 duration-300 hover:shadow-xl cursor-pointer hover:text-white' title="Edit">
                                                    <HiOutlinePencil className='text-lg' />
                                                </div>
                                                <div className='h-10 w-10 flex justify-center items-center bg-white  hover:bg-red-700 rounded-full shadow-md hover:-translate-y-1 duration-300 hover:shadow-xl cursor-pointer hover:text-white' title="Delete">
                                                    <HiOutlineX className='text-lg' />
                                                </div>
                                            </div>
                                        </Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell className="w-full">
                                            advertisement
                                        </Table.Cell>
                                        <Table.Cell>
                                            <div className='flex gap-2'>
                                                <div className='h-10 w-10 flex justify-center items-center bg-white  hover:bg-purple-800 rounded-full shadow-md hover:-translate-y-1 duration-300 hover:shadow-xl cursor-pointer hover:text-white' title="Edit">
                                                    <HiOutlinePencil className='text-lg' />
                                                </div>
                                                <div className='h-10 w-10 flex justify-center items-center bg-white  hover:bg-red-700 rounded-full shadow-md hover:-translate-y-1 duration-300 hover:shadow-xl cursor-pointer hover:text-white' title="Delete">
                                                    <HiOutlineX className='text-lg' />
                                                </div>
                                            </div>
                                        </Table.Cell>
                                    </Table.Row>
                                </Table.Body>
                            </Table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default AIcategories;