import { 
    HiPlusSm,
    HiPhotograph,
    HiOutlineUpload,
} from "react-icons/hi";
import { MdTitle } from "react-icons/md";
import { Table } from 'flowbite-react';
import { Link } from "react-router-dom";

const Dashboard = () =>{
    return(
        <>
            <div className='p-5 md:flex md:justify-between bg-white rounded-md my-2'>
                <div>
                    <div className='mb-1 text-gray-400 font-medium'>User Dashboard</div>
                    <h2 className='text-4xl font-bold mb-3 md:mb-0'>Welcome, Admin.</h2>
                </div>
                <div className='flex items-center gap-4'>
                    <button class='bg-white hover:bg-gray-50/50 rounded-md py-2 px-4 flex font-medium hover:-translate-y-2 duration-300 border hover:shadow-lg'>
                        <HiOutlineUpload className="mr-2 h-5 w-5" /> My Documents
                    </button>
                    <button class='bg-blue-600 hover:bg-blue-500 rounded-md py-2 px-4 text-white flex font-medium hover:-translate-y-2 duration-300 hover:drop-shadow-lg'>
                        <HiPlusSm className="mr-2 h-5 w-5" /> New
                    </button>
                </div>
            </div>
            <div className='p-5 bg-white rounded-md my-2'>
                <div class="grid sm:grid-cols-2 lg:grid-cols-2 gap-2 xl:gap-6">
                    <div className='text-slate-500'>
                        <h3 className='text-2xl font-medium mb-4 text-slate-700'>Upgrade</h3>
                        <p className='mb-4'>
                            You have no subscription at the moment. Please select a subscription plan or a token pack.
                        </p>
                        <p className='mb-4'>
                            Total <span className='font-medium text-slate-700'>2,994,431</span> word and <span className='font-medium text-slate-700'>2,999,992</span> image tokens left.
                        </p>
                        <div className='mt-5 mb-2'>
                            <Link to="user/payment">
                                <button className='flex items-center bg-gray-100 hover:bg-green-500 hover:text-white rounded-full py-2 px-4 shadow-md hover:-translate-y-1 duration-300 font-semibold'>
                                    <HiPlusSm className=" text-2xl" /> Select a Plan
                                </button>
                            </Link>
                        </div>
                    </div>
                    <div className='text-slate-500 flex items-center justify-center flex-col'>
                       Chart
                    </div>
                </div>
            </div>
            <div className='p-5 bg-white rounded-md my-2'>
                <div className=''>
                    <h3 className='text-2xl font-medium mb-4 text-slate-700'>Overview</h3>
                    <div class="grid sm:grid-cols-1 lg:grid-cols-6 gap-2 ">
                        <div className='flex items-center px-4 py-2 bg-[#FFE2E5] rounded-md'>
                            <div>
                                <div className='text-gray-600 font-semibold mb-1'>Words Left</div>
                                <div className='text-xl font-bold flex items-center'>
                                    <h3 className='text-2xl'>2,994,431</h3>
                                </div>
                            </div>
                        </div>
                        <div className='flex items-center px-4 py-2 bg-[#FFF4DE] rounded-md'>
                            <div>
                                <div className='text-gray-600 font-semibold mb-1'>Images Left</div>
                                <div className='text-xl font-bold flex items-center'>
                                    <h3 className='text-2xl'>2,999,992</h3>
                                </div>
                            </div>
                        </div>
                        <div className='flex items-center px-4 py-2 bg-[#DCFCE7] rounded-md'>
                            <div>
                                <div className='text-gray-600 font-semibold mb-1'>Hours Saved</div>
                                <div className='text-xl font-bold flex items-center'>
                                    <h3 className='text-2xl'>25</h3>
                                </div>
                            </div>
                        </div>
                        <div className='lg:col-span-3 flex items-center px-4 py-2 bg-gray-100 rounded-md'>
                            <div className='w-full'>
                                <div className='text-gray-600 font-semibold'>Your Documents</div>
                                <div className="flex items-center gap-x-4">
                                    <div className="w-3/4">
                                        <div className="bg-blue-200 rounded-full">
                                            <div className="w-2/3 h-1 bg-blue-600 rounded-full"></div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="bg-blue-50 px-8 border border-blue-500 rounded-lg font-medium text-blue-800">
                                            45%
                                        </div>
                                    </div>
                                </div>
                                <div className=' flex items-center gap-3'>
                                    <div className='w-3 h-3 bg-blue-600 rounded-full'></div>
                                    <div>Text</div>
                                    <div className='w-3 h-3 bg-green-400 rounded-full'></div>
                                    <div>Image</div>
                                    <div className='w-3 h-3 bg-green-400 rounded-full'></div>
                                    <div>Total</div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='px-5 py-4 bg-white rounded-md my-2'>
                <div className='grid lg:grid-cols-2 gap-6'> 
                    <div className='sm:min-h-64'>
                        <div className='px-3 pb-2 text-xl text-slate-600 font-semibold'>Documents</div>
                        <div className="overflow-x-auto">
                            <Table>
                                <Table.Body className="divide-y-8 divide-white">
                                    <Table.Row className="bg-gray-100">
                                        <Table.Cell className='p-0 ps-3 rounded-tl-xl rounded-bl-xl w-16'>
                                            <div className='w-12 h-12 bg-red-200 text-red-400 rounded-full flex justify-center items-center'>
                                                <HiPhotograph className='text-2xl' />
                                            </div>
                                        </Table.Cell>
                                        <Table.Cell className='p-0 ps-3' >
                                            <h3 className='text-slate-800 font-semibold text-lg'>AI Image Generator</h3>
                                            <h4 className="max-w-60 truncate text-wrap md:text-nowrap text-slate-400 font-medium text-md italic ">
                                                Create stunning images in seconds.
                                            </h4>
                                        </Table.Cell>
                                        <Table.Cell className="rounded-tr-xl rounded-br-xl">
                                            <div className='float-end'>
                                                <h3 className='text-slate-800 font-semibold text-lg'>in Workbook</h3>
                                                <h4 className='text-slate-400 font-medium text-md italic'>
                                                    Jan 18, 2024
                                                </h4>
                                            </div>
                                        </Table.Cell>
                                    </Table.Row>
                                    <Table.Row className="bg-gray-100">
                                        <Table.Cell className='p-0 ps-3 rounded-tl-xl rounded-bl-xl'>
                                            <div className='w-12 h-12 bg-red-200 text-red-400 rounded-full flex justify-center items-center'>
                                                <HiPhotograph className='text-2xl' />
                                            </div>
                                        </Table.Cell>
                                        <Table.Cell className='p-0 ps-3' >
                                            <h3 className='text-slate-800 font-semibold text-lg'>Custom Generation</h3>
                                            <h4 className="max-w-60 truncate text-wrap md:text-nowrap text-slate-400 font-medium text-md italic ">
                                                Create your own custom generator with AI! Our app allows you to quickly and easily generate unique content in any language.
                                            </h4>
                                        </Table.Cell>
                                        <Table.Cell className="rounded-tr-xl rounded-br-xl">
                                            <div className='float-end'>
                                                <h3 className='text-slate-800 font-semibold text-lg'>in Workbook</h3>
                                                <h4 className='text-slate-400 font-medium text-md italic'>
                                                    Jan 18, 2024
                                                </h4>
                                            </div>
                                        </Table.Cell>
                                    </Table.Row>
                                    <Table.Row className="bg-gray-100">
                                        <Table.Cell className='p-0 ps-3 rounded-tl-xl rounded-bl-xl'>
                                            <div className='w-12 h-12 bg-red-200 text-red-400 rounded-full flex justify-center items-center'>
                                                <HiPhotograph className='text-2xl' />
                                            </div>
                                        </Table.Cell>
                                        <Table.Cell className='p-0 ps-3' >
                                            <h3 className='text-slate-800 font-semibold text-lg'>Blog Section</h3>
                                            <h4 className="max-w-60 truncate text-wrap md:text-nowrap text-slate-400 font-medium text-md italic ">
                                                Effortlessly create blog sections with AI. Get unique, engaging content and save time.
                                            </h4>
                                        </Table.Cell>
                                        <Table.Cell className="rounded-tr-xl rounded-br-xl">
                                            <div className='float-end'>
                                                <h3 className='text-slate-800 font-semibold text-lg'>in Workbook</h3>
                                                <h4 className='text-slate-400 font-medium text-md italic'>
                                                    Jan 18, 2024
                                                </h4>
                                            </div>
                                        </Table.Cell>
                                    </Table.Row>
                                    <Table.Row className="bg-gray-100">
                                        <Table.Cell className='p-0 ps-3 rounded-tl-xl rounded-bl-xl'>
                                            <div className='w-12 h-12 bg-red-200 text-red-400 rounded-full flex justify-center items-center'>
                                                <HiPhotograph className='text-2xl' />
                                            </div>
                                        </Table.Cell>
                                        <Table.Cell className='p-0 ps-3' >
                                            <h3 className='text-slate-800 font-semibold text-lg'>Summarize Text</h3>
                                            <h4 className="max-w-60 truncate text-wrap md:text-nowrap text-slate-400 font-medium text-md italic ">
                                                Effortlessly condense large text into shorter summaries. Save time and increase productivity.
                                            </h4>
                                        </Table.Cell>
                                        <Table.Cell className="">
                                            <div className='float-end'>
                                                <h3 className='text-slate-800 font-semibold text-lg'>in Workbook</h3>
                                                <h4 className='text-slate-400 font-medium text-md italic'>
                                                    Jan 18, 2024
                                                </h4>
                                            </div>
                                        </Table.Cell>
                                    </Table.Row>
                                </Table.Body>
                            </Table>
                        </div>
                    </div>
                    <div className='sm:min-h-64'>
                        <div className='px-3 pb-2 text-xl text-slate-600 font-semibold'>Favorite Templates</div>
                        <div className="overflow-x-auto">
                            <Table>
                                <Table.Body className="divide-y-8 divide-white">
                                    <Table.Row className="bg-gray-100">
                                        <Table.Cell className='p-0 ps-3 rounded-tl-xl rounded-bl-xl w-16'>
                                            <div className='h-12 w-12 rounded-full flex items-center justify-center  cursor-pointer relative group-hover:drop-shadow-lg ease-in group-hover:-translate-y-1 duration-300 bg-red-200 text-red-400'>
                                                <MdTitle className='text-slate-800 text-2xl' />
                                                <div className='h-4 w-4 bg-red-600 rounded-full absolute bottom-0 right-0 border-2 border-white'></div>
                                            </div>
                                        </Table.Cell>
                                        <Table.Cell className='p-0 ps-3' >
                                            <h3 className='text-slate-800 font-semibold text-lg'>Post Title Generator</h3>
                                            <h4 className="max-w-60 truncate text-wrap md:text-nowrap text-slate-400 font-medium text-md italic ">
                                                Get captivating post titles instantly with our title generator. Boost engagement and save time.
                                            </h4>
                                        </Table.Cell>
                                        <Table.Cell className="rounded-tr-xl rounded-br-xl">
                                            <div className='float-end'>
                                                <h3 className='text-slate-800 font-semibold text-lg'>in Workbook</h3>
                                                <h4 className='text-slate-400 font-medium text-md italic'>
                                                    Jan 18, 2024
                                                </h4>
                                            </div>
                                        </Table.Cell>
                                    </Table.Row>
                                    <Table.Row className="bg-gray-100">
                                        <Table.Cell className='p-0 ps-3 rounded-tl-xl rounded-bl-xl'>
                                            <div className='h-12 w-12 bg-red-200 text-red-400 rounded-full flex items-center justify-center  cursor-pointer relative group-hover:drop-shadow-lg ease-in group-hover:-translate-y-1 duration-300'>
                                                <MdTitle className='text-slate-800 text-2xl' />
                                                <div className='h-4 w-4 bg-red-600 rounded-full absolute bottom-0 right-0 border-2 border-white'></div>
                                            </div>
                                        </Table.Cell>
                                        <Table.Cell className='p-0 ps-3' >
                                            <h3 className='text-slate-800 font-semibold text-lg'>Post Title Generator</h3>
                                            <h4 className="max-w-60 truncate text-wrap md:text-nowrap text-slate-400 font-medium text-md italic ">
                                                Get captivating post titles instantly with our title generator. Boost engagement and save time.
                                            </h4>
                                        </Table.Cell>
                                        <Table.Cell className="rounded-tr-xl rounded-br-xl">
                                            <div className='float-end'>
                                                <h3 className='text-slate-800 font-semibold text-lg'>in Workbook</h3>
                                                <h4 className='text-slate-400 font-medium text-md italic'>
                                                    Jan 18, 2024
                                                </h4>
                                            </div>
                                        </Table.Cell>
                                    </Table.Row>
                                    <Table.Row className="bg-gray-100">
                                        <Table.Cell className='p-0 ps-3 rounded-tl-xl rounded-bl-xl'>
                                            <div className='h-12 w-12 bg-red-200 text-red-400 rounded-full flex items-center justify-center  cursor-pointer relative group-hover:drop-shadow-lg ease-in group-hover:-translate-y-1 duration-300'>
                                                <MdTitle className='text-slate-800 text-2xl' />
                                                <div className='h-4 w-4 bg-red-600 rounded-full absolute bottom-0 right-0 border-2 border-white'></div>
                                            </div>
                                        </Table.Cell>
                                        <Table.Cell className='p-0 ps-3' >
                                            <h3 className='text-slate-800 font-semibold text-lg'>Post Title Generator</h3>
                                            <h4 className="max-w-60 truncate text-wrap md:text-nowrap text-slate-400 font-medium text-md italic ">
                                                Get captivating post titles instantly with our title generator. Boost engagement and save time.
                                            </h4>
                                        </Table.Cell>
                                        <Table.Cell className="rounded-tr-xl rounded-br-xl">
                                            <div className='float-end'>
                                                <h3 className='text-slate-800 font-semibold text-lg'>in Workbook</h3>
                                                <h4 className='text-slate-400 font-medium text-md italic'>
                                                    Jan 18, 2024
                                                </h4>
                                            </div>
                                        </Table.Cell>
                                    </Table.Row>
                                    <Table.Row className="bg-gray-100">
                                        <Table.Cell className='p-0 ps-3 rounded-tl-xl rounded-bl-xl'>
                                            <div className='h-12 w-12 bg-red-200 text-red-400 rounded-full flex items-center justify-center  cursor-pointer relative group-hover:drop-shadow-lg ease-in group-hover:-translate-y-1 duration-300'>
                                                <MdTitle className='text-slate-800 text-2xl' />
                                                <div className='h-4 w-4 bg-red-600 rounded-full absolute bottom-0 right-0 border-2 border-white'></div>
                                            </div>
                                        </Table.Cell>
                                        <Table.Cell className='p-0 ps-3' >
                                            <h3 className='text-slate-800 font-semibold text-lg'>Post Title Generator</h3>
                                            <h4 className="max-w-60 truncate text-wrap md:text-nowrap text-slate-400 font-medium text-md italic ">
                                                Get captivating post titles instantly with our title generator. Boost engagement and save time.
                                            </h4>
                                        </Table.Cell>
                                        <Table.Cell className="rounded-tr-xl rounded-br-xl">
                                            <div className='float-end'>
                                                <h3 className='text-slate-800 font-semibold text-lg'>in Workbook</h3>
                                                <h4 className='text-slate-400 font-medium text-md italic'>
                                                    Jan 18, 2024
                                                </h4>
                                            </div>
                                        </Table.Cell>
                                    </Table.Row>
                                </Table.Body>
                            </Table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Dashboard;