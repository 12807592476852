import { HiPlusSm } from "react-icons/hi";
const PlusNavbar =()=>{
    return(
        <>
            <div className='h-14 w-14 bg-blue-600 text-white rounded-full flex items-center justify-center fixed bottom-20 md:bottom-12 right-10 cursor-pointer group z-50'>
                <span><HiPlusSm className='text-4xl group-hover:rotate-180 duration-300 ' /></span>
                <div className="text-slate-600 font-semibold bg-white  border absolute w-32 bottom-14 -right-4 invisible group-hover:visible grid grid-cols-1 grid-flow-row divide-y divide-solid">
                    <div className="py-2 px-4 text-slate-400 cursor-default">Item</div>
                    <div className="py-2 px-4 hover:bg-slate-50">Document</div>
                    <div className="py-2 px-4 hover:bg-slate-50">Image</div>
                    <div className="py-2 px-4 hover:bg-slate-50">Chat</div>
                    <div className="py-2 px-4 hover:bg-slate-50">Code</div>
                    <div className="py-2 px-4 hover:bg-slate-50">Transcribe</div>
                </div>
            </div>
        </>
    )
}
export default PlusNavbar;