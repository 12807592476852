import { Link } from "react-router-dom";
import { 
    HiOutlineChevronLeft,
    HiOutlineInformationCircle
} from "react-icons/hi";
import { Label, TextInput, Select, FileInput } from 'flowbite-react';
const Settings =()=>{
    return(
        <>
            <div className='p-5 md:flex md:justify-between bg-white rounded-md my-2'>
                <div>
                    <div className='mb-1 text-slate-400'>
                        <Link to="/admin/dashboard" className="flex items-center">
                                <HiOutlineChevronLeft className="me-1" /> Back to dashboard
                        </Link>
                    </div>
                    <h2 className='text-4xl font-bold mb-1'>Settings</h2>
                </div>
            </div>
            <div className='p-5  bg-white rounded-md mb-2'>
                <div className='grid md:grid-cols-12'>
                    <div className='md:col-span-6 md:col-start-4'>
                        <div class=''>
                            <div>
                                <form className="flex flex-col">
                                    <div className='mb-4'>
                                        <div className="mb-2 block">
                                            <Label value="Avatar" className="text-slate-500" />
                                        </div>
                                        <FileInput id="file-upload" />
                                    </div>
                                    <div className='mb-4'>
                                        <div className="mb-2 block">
                                            <Label value="Name" className="text-slate-500" />
                                        </div>
                                        <TextInput type="text"  />
                                    </div>
                                    <div className='mb-4'>
                                        <div className="mb-2 block">
                                            <Label value="Surname" className="text-slate-500" />
                                        </div>
                                        <TextInput type="url" />
                                    </div>
                                    <div className='mb-4'>
                                        <div className="mb-2 block">
                                            <Label value="Phone" className="text-slate-500" />
                                        </div>
                                        <TextInput type="email"  />
                                    </div>
                                    <div className='mb-4'>
                                        <div className="mb-2 block">
                                            <Label value="Email" className="text-slate-500" />
                                        </div>
                                        <TextInput type="email"  />
                                    </div>
                                    <div className='mb-4'>
                                        <div className="mb-2 block">
                                            <Label value="Country" className="text-slate-500" />
                                        </div>
                                        <Select>
                                            <option>Active</option>
                                            <option>Passive</option>
                                        </Select>
                                    </div>
                                    <h3 className='text-2xl font-medium text-slate-700 my-5'>
                                        Change Password
                                    </h3>
                                    <div className='mb-4'>
                                        <div className="bg-blue-100 text-blue-600 rounded-xl p-2 flex mt-2 ">
                                            <span className='me-1'><HiOutlineInformationCircle className='text-2xl' /> </span>   Please leave empty if you don’t want to change your password.
                                        </div>
                                    </div>
                                    <div className='mb-4'>
                                        <div className="mb-2 block">
                                            <Label value="Old Password" className="text-slate-500" />
                                        </div>
                                        <TextInput type="text"  />
                                    </div>
                                    <div className='mb-4'>
                                        <div className="mb-2 block">
                                            <Label value="New Password" className="text-slate-500" />
                                        </div>
                                        <TextInput type="text"  />
                                    </div>
                                    <div className='mb-4'>
                                        <div className="mb-2 block">
                                            <Label value="Confirm Your New Password" className="text-slate-500" />
                                        </div>
                                        <TextInput type="text"  />
                                    </div>                            
                                    <div className="py-4 flex justify-center">
                                        <button className="bg-blue-600 hover:bg-blue-500 rounded-full py-2 px-4 text-white flex justify-center font-medium hover:-translate-y-2 duration-300 hover:drop-shadow-lg w-full">
                                            Save
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Settings;