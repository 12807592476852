import { Link } from "react-router-dom";
import { 
    HiOutlineChevronLeft,
} from "react-icons/hi";
import { Table, Select } from 'flowbite-react';
const BuiltTemplates =()=>{
    return(
        <>
            <div className='p-5 md:flex md:justify-between bg-white rounded-md my-2'>
                <div>
                    <div className='mb-1 text-slate-400'>
                        <Link to="/admin/dashboard" className="flex items-center">
                                <HiOutlineChevronLeft className="me-1" /> Back to dashboard
                        </Link>
                    </div>
                    <h2 className='text-4xl font-bold mb-1'>Built-in Templates</h2>
                    <p className='text-slate-400 mb-3 md:mb-0'>Manage Built-in Prompts and Templates</p>
                </div>
            </div>
            <div className="p-5 mt-4 bg-white rounded-md">
                <div className="border rounded-md">
                    <div className="">
                        <div className="overflow-x-auto">
                            <Table className="bg-transparent">
                                <Table.Head>
                                    <Table.HeadCell className="bg-transparent border-b text-slate-500 font-medium whitespace-nowrap">
                                        Status
                                    </Table.HeadCell>
                                    <Table.HeadCell className="bg-transparent border-b text-slate-500 font-medium whitespace-nowrap">
                                        Template Name
                                    </Table.HeadCell>
                                    <Table.HeadCell className="bg-transparent border-b text-slate-500 font-medium whitespace-nowrap">
                                        Template Description
                                    </Table.HeadCell>
                                    <Table.HeadCell className="bg-transparent border-b text-slate-500 font-medium whitespace-nowrap">
                                        Package
                                    </Table.HeadCell>
                                    <Table.HeadCell className="bg-transparent border-b text-slate-500 font-medium whitespace-nowrap">
                                        Updated At
                                    </Table.HeadCell>
                                    <Table.HeadCell className="bg-transparent border-b text-slate-500 font-medium whitespace-nowrap">
                                        Actions
                                    </Table.HeadCell>
                                </Table.Head>
                                <Table.Body className="divide-y">
                                    <Table.Row>
                                        <Table.Cell className="whitespace-nowrap">
                                            <span className="bg-green-100 px-4 py-1 rounded-full font-semibold text-green-600">
                                                Active
                                            </span>
                                        </Table.Cell>
                                        <Table.Cell>
                                            AI Article Wizard Generator
                                        </Table.Cell>
                                        <Table.Cell>
                                            Create custom article instantly with our article wizard generator. Boost engagement and save time.
                                        </Table.Cell>
                                        <Table.Cell className="">
                                            <div className="w-24">
                                                <Select className="p-0">
                                                    <option>Regular</option>
                                                    <option>Premium</option>
                                                </Select>
                                            </div>
                                        </Table.Cell>
                                        <Table.Cell className="min-w-32">
                                            <div>20.9.2023</div> 
                                            <div className='text-slate-400'>05:26:49</div>
                                        </Table.Cell>
                                        <Table.Cell>
                                            <button  className="bg-green-500 text-white px-5 py-1 flex items-center justify-center rounded-full cursor-pointer hover:-translate-y-1 duration-300 hover:shadow-xl font-semibold">
                                                Active
                                            </button>
                                        </Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell className="whitespace-nowrap">
                                            <span className="bg-green-100 px-4 py-1 rounded-full font-semibold text-green-600">
                                                Active
                                            </span>
                                        </Table.Cell>
                                        <Table.Cell>
                                            AI Article Wizard Generator
                                        </Table.Cell>
                                        <Table.Cell>
                                            Create custom article instantly with our article wizard generator. Boost engagement and save time.
                                        </Table.Cell>
                                        <Table.Cell className="">
                                            <div className="w-24">
                                                <Select className="p-0">
                                                    <option>Regular</option>
                                                    <option>Premium</option>
                                                </Select>
                                            </div>
                                        </Table.Cell>
                                        <Table.Cell className="min-w-32">
                                            <div>20.9.2023</div> 
                                            <div className='text-slate-400'>05:26:49</div>
                                        </Table.Cell>
                                        <Table.Cell>
                                            <button  className="bg-green-500 text-white px-5 py-1 flex items-center justify-center rounded-full cursor-pointer hover:-translate-y-1 duration-300 hover:shadow-xl font-semibold">
                                                Active
                                            </button>
                                        </Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell className="whitespace-nowrap">
                                            <span className="bg-green-100 px-4 py-1 rounded-full font-semibold text-green-600">
                                                Active
                                            </span>
                                        </Table.Cell>
                                        <Table.Cell>
                                            AI Article Wizard Generator
                                        </Table.Cell>
                                        <Table.Cell>
                                            Create custom article instantly with our article wizard generator. Boost engagement and save time.
                                        </Table.Cell>
                                        <Table.Cell className="">
                                            <div className="w-24">
                                                <Select className="p-0">
                                                    <option>Regular</option>
                                                    <option>Premium</option>
                                                </Select>
                                            </div>
                                        </Table.Cell>
                                        <Table.Cell className="min-w-32">
                                            <div>20.9.2023</div> 
                                            <div className='text-slate-400'>05:26:49</div>
                                        </Table.Cell>
                                        <Table.Cell>
                                            <button  className="bg-green-500 text-white px-5 py-1 flex items-center justify-center rounded-full cursor-pointer hover:-translate-y-1 duration-300 hover:shadow-xl font-semibold">
                                                Active
                                            </button>
                                        </Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell className="whitespace-nowrap">
                                            <span className="bg-green-100 px-4 py-1 rounded-full font-semibold text-green-600">
                                                Active
                                            </span>
                                        </Table.Cell>
                                        <Table.Cell>
                                            AI Article Wizard Generator
                                        </Table.Cell>
                                        <Table.Cell>
                                            Create custom article instantly with our article wizard generator. Boost engagement and save time.
                                        </Table.Cell>
                                        <Table.Cell className="">
                                            <div className="w-24">
                                                <Select className="p-0">
                                                    <option>Regular</option>
                                                    <option>Premium</option>
                                                </Select>
                                            </div>
                                        </Table.Cell>
                                        <Table.Cell className="min-w-32">
                                            <div>20.9.2023</div> 
                                            <div className='text-slate-400'>05:26:49</div>
                                        </Table.Cell>
                                        <Table.Cell>
                                            <button  className="bg-green-500 text-white px-5 py-1 flex items-center justify-center rounded-full cursor-pointer hover:-translate-y-1 duration-300 hover:shadow-xl font-semibold">
                                                Active
                                            </button>
                                        </Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell className="whitespace-nowrap">
                                            <span className="bg-green-100 px-4 py-1 rounded-full font-semibold text-green-600">
                                                Active
                                            </span>
                                        </Table.Cell>
                                        <Table.Cell>
                                            AI Article Wizard Generator
                                        </Table.Cell>
                                        <Table.Cell>
                                            Create custom article instantly with our article wizard generator. Boost engagement and save time.
                                        </Table.Cell>
                                        <Table.Cell className="">
                                            <div className="w-24">
                                                <Select className="p-0">
                                                    <option>Regular</option>
                                                    <option>Premium</option>
                                                </Select>
                                            </div>
                                        </Table.Cell>
                                        <Table.Cell className="min-w-32">
                                            <div>20.9.2023</div> 
                                            <div className='text-slate-400'>05:26:49</div>
                                        </Table.Cell>
                                        <Table.Cell>
                                            <button  className="bg-green-500 text-white px-5 py-1 flex items-center justify-center rounded-full cursor-pointer hover:-translate-y-1 duration-300 hover:shadow-xl font-semibold">
                                                Active
                                            </button>
                                        </Table.Cell>
                                    </Table.Row>
                                </Table.Body>
                            </Table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default BuiltTemplates;