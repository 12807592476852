import { Link } from "react-router-dom";
import { 
    HiOutlineChevronLeft,
    HiOutlineSwitchHorizontal,
    HiOutlineMinusCircle,
    HiOutlineDocumentDuplicate,
    HiDownload,
} from "react-icons/hi";
import { MdAttachFile, MdSend } from "react-icons/md";
import { BiUndo, BiRedo } from "react-icons/bi";
import { Label,Textarea,FileInput } from 'flowbite-react';
const AIReWriter =()=>{
    return(
        <>
            <div className='p-5 md:flex md:justify-between bg-white rounded-md my-2'>
                <div>
                    <div className='mb-1 text-slate-400'>
                        <Link to="/" className="flex items-center">
                                <HiOutlineChevronLeft className="me-1" /> Back to dashboard
                        </Link>
                    </div>
                    <h2 className='text-4xl font-bold'>AI ReWriter</h2>
                    <p className='text-slate-400'>
                        Effortlessly reshape and elevate your pre-existing content with a single click.
                    </p>
                </div>
            </div>
            <div className="mt-4">   
                <div className="lg:grid grid-cols-5 gap-4">
                    <div className="mb-2 rounded-md px-2 col-span-2">
                        <div className="">
                            <div className="border rounded-lg px-4 py-4 mb-4 bg-white">
                                <div className='text-gray-600 font-medium mb-2'>
                                    Remaining Credits <span className="font-bold">2,999,595</span>
                                </div>
                                <div className="flex items-center gap-x-4">
                                    <div className="w-full">
                                        <div className="bg-blue-200 rounded-full">
                                            <div className="w-2/3 h-1 bg-blue-600 rounded-full"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="bg-[#DCFCE7] mb-2 rounded-md px-4 pt-2 pb-5">
                                <div className="mb-4">
                                    <div className="mb-2 block">
                                        <Label value="Description" />
                                    </div>
                                    <textarea rows="10" className="bg-white w-full border-1 border-slate-300 rounded-md" ></textarea>
                                </div>
                                <div className="mb-4">
                                    <div className="mb-2 block">
                                        <Label value="Mode" />
                                    </div>
                                    <select className="bg-white w-full border-1 border-slate-300 rounded-md">
                                        <option>Select</option>
                                    </select>
                                </div>
                                <div className="mb-4">
                                    <div className="mb-2 block">
                                        <Label value="Output Language" />
                                    </div>
                                    <select className="bg-white w-full border-1 border-slate-300 rounded-md">
                                        <option>Select</option>
                                    </select>
                                </div>
                                <div className="px-1 flex justify-start lg:justify-center">
                                    <div className="bg-blue-600 text-white px-5 py-2 flex items-center justify-center rounded-full cursor-pointer hover:-translate-y-1 duration-300 hover:shadow-xl w-full mt-2">Generate</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mb-2 rounded-md px-2 col-span-3">
                    <div className="sticky top-20">
                        <div className="py-2 px-2 flex justify-between items-center gap-1 bg-[#DCFCE7] rounded-md">
                            <div className='p-1 cursor-pointer flex items-center gap-2 hover:-translate-y-1 duration-300'>
                                <HiOutlineSwitchHorizontal /> Regenerate
                            </div>
                            <div className="flex items-center gap-1">
                                <div className='p-1 hover:bg-gray-300 rounded-sm cursor-pointer '>
                                    <BiUndo className='text-xl' title='Undo' />
                                </div>
                                <div className='p-1 hover:bg-gray-300 rounded-sm cursor-pointer '>
                                    <BiRedo className='text-xl' title='Redo' />
                                </div>
                                <div className='p-1 hover:bg-gray-300 rounded-sm cursor-pointer '>
                                    <HiOutlineDocumentDuplicate className='text-xl' title='Copy to clipboard' />
                                </div>
                                <div className='p-1 hover:bg-gray-300 rounded-sm cursor-pointer '>
                                    <HiDownload className='text-xl' title='Download' />
                                </div>
                                <div className='p-1 hover:bg-gray-300 rounded-sm cursor-pointer '>
                                    <HiOutlineMinusCircle className='text-xl text-red-700' title='Delete' />
                                </div>
                            </div>
                        </div>
                        <div className="py-4">
                            <h3 className="text-2xl px-4">Untitled Document</h3>
                        </div>
                        <div className="bg-[#DCFCE7] mb-2 rounded-md px-4 py-2">
                            React Text Editor
                            <p class="mt-2 mb-2 text-justify">
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                            </p>
                        </div>
                        <div className="mt-5 md:min-h-10"></div>
                        <div className="w-full flex items-center">
                            <div className="relative w-full">
                                <div className="flex items-center justify-center absolute inset-y-0 left-2">
                                    <Label
                                        htmlFor="dropzone-file"
                                        className="dark:hover:bg-bray-800 flex h-7 w-7 lg:h-9 lg:w-9 cursor-pointer flex-col items-center justify-center rounded-full bg-blue-600 text-white"
                                    >
                                        <div className="flex flex-col items-center justify-center">
                                            <MdAttachFile className="lg:text-2xl text-lg" />
                                        </div>
                                        <FileInput id="dropzone-file" className="hidden" />
                                    </Label>
                                </div>
                                <Textarea 
                                    placeholder="Type a message or upload an image" className="w-full rounded-full shadow-sm border-0 pl-10 lg:pl-14 lg:pr-12 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm placeholder:pt-2"
                                />
                            </div>
                            <div className="lg:px-2 pt-3 lg:pt-0">
                                <div className="flex h-9 w-9 lg:h-12 lg:w-12 cursor-pointer flex-col items-center justify-center rounded-full bg-blue-600 text-white hover:-translate-y-1 duration-300 hover:shadow-xl">
                                    <div className="flex flex-col items-center justify-center">
                                        <MdSend className="text-xl lg:text-2xl" />
                                    </div>
                                </div>
                            </div>
                        </div>  
                        </div>   
                    </div>
                </div>
            </div>
        </>
    )
}
export default AIReWriter;