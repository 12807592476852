import { Link } from "react-router-dom";
import { 
    HiOutlineChevronLeft,
    HiOutlineInformationCircle
} from "react-icons/hi";
import { Label, TextInput, Select, Textarea } from 'flowbite-react';
const FrontendSettings =()=>{
    return(
        <>
            <div className='p-5 md:flex md:justify-between bg-white rounded-md my-2'>
                <div>
                    <div className='mb-1 text-slate-400'>
                        <Link to="/admin/dashboard" className="flex items-center">
                                <HiOutlineChevronLeft className="me-1" /> Back to dashboard
                        </Link>
                    </div>
                    <h2 className='text-4xl font-bold mb-1'>Frontend Settings</h2>
                    <p className='text-slate-400 mb-3 md:mb-0'>Manage Custom Prompts and Templates</p>
                </div>
            </div>
            <div className='p-5  bg-white rounded-md mb-2'>
                <div className='grid md:grid-cols-12'>
                    <div className='md:col-span-6 md:col-start-4'>
                        <div class='mb-8'>
                            <div>
                                <div className="p-3 rounded-md shadow-sm border border-l-8 border-l-blue-600 text-blue-600/80">
                                    <p className="flex">
                                        <span className='me-2'><HiOutlineInformationCircle className='text-2xl' /> </span> Some of the inputs were deprecated. Show them and edit.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class=''>
                            <h3 className='text-2xl font-medium text-slate-700 mb-5'>General Settings</h3>
                            <div>
                                <form className="flex flex-col">
                                    <div className='mb-4'>
                                        <div className="mb-2 block">
                                            <Label value="Site Name" className="text-slate-500" />
                                        </div>
                                        <TextInput type="text" placeholder="Hamari AI" />
                                    </div>
                                    <div className='mb-4'>
                                        <div className="mb-2 block">
                                            <Label value="Site URL" className="text-slate-500" />
                                        </div>
                                        <TextInput type="url" placeholder="https://liquid-themes.com" />
                                    </div>
                                    <div className='mb-4'>
                                        <div className="mb-2 block">
                                            <Label value="Site Email" className="text-slate-500" />
                                        </div>
                                        <TextInput type="email"  />
                                    </div>
                                    <div className='mb-4'>
                                        <div className="mb-2 block">
                                            <Label value="Registration Active" className="text-slate-500" />
                                        </div>
                                        <Select>
                                            <option>Active</option>
                                            <option>Passive</option>
                                        </Select>
                                    </div>
                                    <h3 className='text-2xl font-medium text-slate-700 my-5'>Frontend Settings</h3>
                                    <div className='mb-4'>
                                        <div className="mb-2 block">
                                            <Label value="PreHeader Section" className="text-slate-500" />
                                        </div>
                                        <Select>
                                            <option>Active</option>
                                            <option>Passive</option>
                                        </Select>
                                    </div>
                                    <div className='mb-4'>
                                        <div className="mb-2 block">
                                            <Label value="PreHeader Title" className="text-slate-500" />
                                        </div>
                                        <TextInput type="text" placeholder="Limited Offer" />
                                    </div>
                                    <div className='mb-4'>
                                        <div className="mb-2 block">
                                            <Label value="PreHeader Text" className="text-slate-500" />
                                        </div>
                                        <TextInput type="text" placeholder="Sign up and receive 20% bonus discount on checkout." />
                                    </div>
                                    <div className='mb-4'>
                                        <div className="mb-2 block">
                                            <Label value="Sign In Text" className="text-slate-500" />
                                        </div>
                                        <TextInput type="text" placeholder="Sign In" />
                                    </div>
                                    <div className='mb-4'>
                                        <div className="mb-2 block">
                                            <Label value="Sign Up Text" className="text-slate-500" />
                                        </div>
                                        <TextInput type="text" placeholder="Join Hub" />
                                    </div>
                                    <div className='mb-4'>
                                        <div className="mb-2 block">
                                            <Label value="Hero Subtitle" className="text-slate-500" />
                                        </div>
                                        <TextInput type="text" placeholder="Unleash the Power of AI" />
                                    </div>
                                    <div className='mb-4'>
                                        <div className="mb-2 block">
                                            <Label value="Hero Title" className="text-slate-500" />
                                        </div>
                                        <TextInput type="text" placeholder="Ultimate AI" />
                                    </div>
                                    <div className='mb-4'>
                                        <div className="mb-2 block">
                                            <Label value="Hero Title Text Rotator" className="text-slate-500" />
                                        </div>
                                        <TextInput type="text" placeholder="Generator,Chatbot,Assistant" />
                                        <div className="bg-blue-100 text-blue-600 rounded-xl p-2 flex mt-2 text-sm">
                                            <span className='me-1'><HiOutlineInformationCircle className='text-2xl' /> </span>  Please use comma seperated like; Generator,Chatbot,Assistant
                                        </div>
                                    </div>
                                    <div className='mb-4'>
                                        <div className="mb-2 block">
                                            <Label value="Hero Description" className="text-slate-500" />
                                        </div>
                                        <TextInput type="text" placeholder="All-in-one platform to generate AI content and start making money in minutes." />
                                    </div>
                                    <div className='mb-4'>
                                        <div className="mb-2 block">
                                            <Label value="Hero Scroll Text" className="text-slate-500" />
                                        </div>
                                        <TextInput type="text" placeholder="Discover HamariAI" />
                                    </div>
                                    <div className='mb-4'>
                                        <div className="mb-2 block">
                                            <Label value="Hero Button" className="text-slate-500" />
                                        </div>
                                        <TextInput type="text" placeholder="Start Making Money" />
                                    </div>
                                    <div className='mb-4'>
                                        <div className="mb-2 flex items-center relative">
                                            <Label value="Hero Button Type" className="text-slate-500" />
                                            <span className='cursor-pointer group'>
                                                <HiOutlineInformationCircle className='ms-1 text-slate-500' />
                                                <span className='absolute bottom-2 left-0 bg-slate-200 text-slate-600 p-2 rounded-md border text-xs invisible group-hover:visible group-hover:-translate-y-6 translate-y-4 duration-300 opacity-0 group-hover:opacity-100'>
                                                    This will affect the button style
                                                </span>
                                            </span>
                                        </div>
                                        <Select>
                                            <option>Website</option>
                                            <option>Video</option>
                                        </Select>
                                    </div>
                                    <div className='mb-4'>
                                        <div className="mb-2 block">
                                            <Label value="Hero Button URL" className="text-slate-500" />
                                        </div>
                                        <TextInput type="text" />
                                    </div>
                                    <div className='mb-4'>
                                        <div className="mb-2 block">
                                            <Label value="Footer Header" className="text-slate-500" />
                                        </div>
                                        <TextInput type="text" placeholder="Start your free trial." />
                                    </div>
                                    <div className='mb-4'>
                                        <div className="mb-2 block">
                                            <Label value="Footer Header Small Text" className="text-slate-500" />
                                        </div>
                                        <TextInput type="text" placeholder="Pay once, own forever." />
                                    </div>
                                    <div className='mb-4'>
                                        <div className="mb-2 block">
                                            <Label value="Footer Text" className="text-slate-500" />
                                        </div>
                                        <TextInput type="text" placeholder="Unlock your business potential by letting the AI work and generate money for you." />
                                    </div>
                                    <div className='mb-4'>
                                        <div className="mb-2 block">
                                            <Label value="Footer Button Text" className="text-slate-500" />
                                        </div>
                                        <TextInput type="text" placeholder="Join our community" />
                                    </div>
                                    <div className='mb-4'>
                                        <div className="mb-2 block">
                                            <Label value="Footer Button URL (Please enter full url)" className="text-slate-500" />
                                        </div>
                                        <TextInput type="url" placeholder="https://codecanyon.net/item/magicai-openai-content-text-image-chat-code-generator-as-saas/45408109" />
                                    </div>
                                    <div className='mb-4'>
                                        <div className="mb-2 block">
                                            <Label value="Footer Copyright" className="text-slate-500" />
                                        </div>
                                        <TextInput type="text" placeholder="2023 MagicAI. All images are for demo purposes." />
                                    </div>
                                    <div className='mb-4'>
                                        <div className="mb-2 block">
                                            <Label value="Pricing Section" className="text-slate-500" />
                                        </div>
                                        <Select>
                                            <option>Active</option>
                                            <option>Passive</option>
                                        </Select>
                                    </div>
                                    <div className='mb-4'>
                                        <div className="mb-2 block">
                                            <Label value="Custom Templates Section" className="text-slate-500" />
                                        </div>
                                        <Select>
                                            <option>Active</option>
                                            <option>Passive</option>
                                        </Select>
                                    </div>
                                    <h3 className='text-2xl font-medium text-slate-700 my-5'>Floating Button</h3>
                                    <div className='mb-4'>
                                        <Select>
                                            <option>Active</option>
                                            <option selected>Passive</option>
                                        </Select>
                                    </div>
                                    <h3 className='text-2xl font-medium text-slate-700 my-5'>
                                        Footer Social Media Settings
                                    </h3>
                                    <div className='mb-4'>
                                        <div className="mb-2 block">
                                            <Label value="Facebook Address" className="text-slate-500" />
                                        </div>
                                        <TextInput type="text" />
                                    </div>
                                    <div className='mb-4'>
                                        <div className="mb-2 block">
                                            <Label value="Twitter Address" className="text-slate-500" />
                                        </div>
                                        <TextInput type="text" />
                                    </div>
                                    <div className='mb-4'>
                                        <div className="mb-2 block">
                                            <Label value="Instagram Address" className="text-slate-500" />
                                        </div>
                                        <TextInput type="text" />
                                    </div>
                                    <h3 className='text-2xl font-medium text-slate-700 my-5'>
                                        Advanced Settings
                                    </h3>
                                    <div className='mb-4'>
                                        <div className="mb-2 block">
                                            <Label value="Custom Landing Page URL" className="text-slate-500" />
                                        </div>
                                        <TextInput type="text" />
                                        <div className="bg-blue-100 text-blue-600 rounded-xl p-2 flex mt-2 text-sm">
                                            <span className='me-1'><HiOutlineInformationCircle className='text-2xl' /> </span>  Please provide full URL with http:// or https://
                                        </div>
                                    </div>
                                    <div className='mb-4'>
                                        <div className="mb-2 block">
                                            <Label value="Custom CSS URL" className="text-slate-500" />
                                        </div>
                                        <TextInput type="text" />
                                        <div className="bg-blue-100 text-blue-600 rounded-xl p-2 flex mt-2 text-sm">
                                            <span className='me-1'><HiOutlineInformationCircle className='text-2xl' /> </span>  Please provide full URL with http:// or https://
                                        </div>
                                    </div>
                                    <div className='mb-4'>
                                        <div className="mb-2 block">
                                            <Label value="Custom JS URL" className="text-slate-500" />
                                        </div>
                                        <TextInput type="text" />
                                        <div className="bg-blue-100 text-blue-600 rounded-xl p-2 flex mt-2 text-sm">
                                            <span className='me-1'><HiOutlineInformationCircle className='text-2xl' /> </span>  Please provide full URL with http:// or https://
                                        </div>
                                    </div>
                                    <div className='mb-4'>
                                        <div className="mb-2 flex items-center relative">
                                            <Label value="Code before </head>" className='text-slate-500' />
                                            <span className='cursor-pointer group'>
                                                <HiOutlineInformationCircle className='ms-1 text-slate-500' />
                                                <span className='absolute bottom-2 left-0 bg-slate-200 text-slate-600 p-2 rounded-md border text-xs invisible group-hover:visible group-hover:-translate-y-6 translate-y-4 duration-300 opacity-0 group-hover:opacity-100'>
                                                    Only accepts javascript code wrapped with &lt;script&gt; tags and HTML markup that is valid inside the &lt;/head&gt; tag.
                                                </span>
                                            </span>
                                        </div>
                                        <Textarea rows={4} />
                                    </div>
                                    <div className='mb-4'>
                                        <div className="mb-2 flex items-center relative">
                                            <Label value="Code before </body>" className='text-slate-500' />
                                            <span className='cursor-pointer group'>
                                                <HiOutlineInformationCircle className='ms-1 text-slate-500' />
                                                <span className='absolute bottom-2 left-0 bg-slate-200 text-slate-600 p-2 rounded-md border text-xs invisible group-hover:visible group-hover:-translate-y-6 translate-y-4 duration-300 opacity-0 group-hover:opacity-100'>
                                                    Only accepts javascript code wrapped with &lt;script&gt; tags and HTML markup that is valid inside the &lt;/body&gt; tag.
                                                </span>
                                            </span>
                                        </div>
                                        <Textarea rows={4} />
                                    </div>                                    
                                    <div className="py-4 flex justify-center">
                                        <button className="bg-blue-600 hover:bg-blue-500 rounded-full py-2 px-4 text-white flex justify-center font-medium hover:-translate-y-2 duration-300 hover:drop-shadow-lg w-full">
                                            Save
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default FrontendSettings;