import { Link } from "react-router-dom";
import { 
    HiOutlineChevronLeft,
} from "react-icons/hi";
import { FiAlertTriangle } from "react-icons/fi";
import { Label, TextInput } from 'flowbite-react';
const Serperapi =()=>{
    return(
        <>
            <div className='p-5 md:flex md:justify-between bg-white rounded-md my-2'>
                <div>
                    <div className='mb-1 text-slate-400'>
                        <Link to="/admin/dashboard" className="flex items-center">
                                <HiOutlineChevronLeft className="me-1" /> Back to dashboard
                        </Link>
                    </div>
                    <h2 className='text-4xl font-bold'>Serper API Settings</h2>
                </div> 
            </div>
            <div className='p-5 bg-white rounded-md mb-2'>
                <div className='grid md:grid-cols-12'>
                    <div className='md:col-span-6 md:col-start-4'>
                        <div class=''>
                            <h3 className='text-2xl font-medium text-slate-700 mb-5'>Serper API Settings</h3>
                            <div>
                                <form className="flex flex-col">
                                    <div className='mb-4'>
                                        <div className="mb-2 block">
                                            <Label value="Unsplash API Key" className="text-slate-500" />
                                        </div>
                                        <TextInput type="text" />
                                        <div className="bg-orange-100 text-orange-600 rounded-xl p-3 flex mt-2 text-sm">
                                            <span className='me-1'><FiAlertTriangle className='text-2xl' /> </span>  Please ensure that your Serper api key is fully functional and billing defined on your Serper account.
                                        </div>
                                        <div className='mt-1'>
                                            <span className='text-blue-700 text-sm hover:underline'>
                                                <Link>Get Serper Api Key</Link>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="mb-4 flex justify-center">
                                        <button className="bg-blue-600 hover:bg-blue-500 rounded-full py-2 px-4 text-white flex justify-center font-medium hover:-translate-y-2 duration-300 hover:drop-shadow-lg w-full">
                                            After Saving Setting, Click Here to Test Your api key
                                        </button>
                                    </div>
                                    <div className="mb-4 flex justify-center">
                                        <button className="bg-blue-600 hover:bg-blue-500 rounded-full py-2 px-4 text-white flex justify-center font-medium hover:-translate-y-2 duration-300 hover:drop-shadow-lg w-full">
                                            Save
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Serperapi;