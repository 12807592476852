import { Link } from "react-router-dom";
import { 
    HiOutlineChevronLeft, 
    HiOutlineSearch, 
    HiOutlineChat,
    HiOutlinePencil,
    HiOutlineX,
    HiPlus,
    HiOutlineClipboard,
    HiOutlineDocumentText,
    HiOutlineMicrophone,
    HiOutlineChevronDown,
    HiOutlineCloudUpload,
    HiOutlinePlus,
    HiOutlineStar
} from "react-icons/hi";
import { MdAttachFile, MdSend } from "react-icons/md";
import { Textarea, FileInput, Label } from 'flowbite-react';
import avatar from '../../../images/avatar.jpg';
import { Modal } from "flowbite-react";
import { useState } from "react";
const Aivision =()=>{
    const [openModal, setOpenModal] = useState(false);
    const [openModalAdd, setOpenModalAdd] = useState(false);
    return(
        <>
            <div className='p-5 md:flex md:justify-between bg-white rounded-md my-2'>
                <div>
                    <div className='mb-1 text-slate-400'>
                        <Link to="/" className="flex items-center">
                                <HiOutlineChevronLeft className="me-1" /> Back to dashboard
                        </Link>
                    </div>
                    <h2 className='text-4xl font-bold'>Vision AI</h2>
                    <p className='text-slate-400'>
                        Seamlessly upload any image you want to explore and get insightful conversations.
                    </p>
                </div>
            </div>
            <div className="">   
                <div className="lg:grid grid-cols-4 gap-4">
                    {/* <div className="bg-[#FFDFDF] mb-2 rounded-md px-2 py-4"> */}
                    <div className="">
                        <div className="bg-[#FFDFDF] mb-2 rounded-md px-2 py-4">
                            <div className="relative">
                                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                    <span className="text-gray-500 sm:text-sm"><HiOutlineSearch /></span>
                                </div>
                                <input
                                type="text"
                                className="w-full rounded-full shadow-sm border-0  pl-8 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                placeholder="Search..."
                                />
                            </div>
                        </div>
                        <div className="bg-[#FFDFDF] mb-2 rounded-md px-2 pt-2 pb-5">
                            <div className="h-96 overflow-y-auto bg-white mt-5 rounded-md mb-4">
                                <div className="grid grid-cols-1 grid-flow-row divide-y divide-solid mt-2">
                                    <div className="py-4 px-2 group flex cursor-pointer">
                                        <div className="flex ">
                                            <div><HiOutlineChat className="text-2xl me-2" /></div>
                                            <div className="pe-4">
                                                <div>VisionAI Chat</div>
                                                <div className="text-xs text-slate-500">5 days ago</div>
                                            </div>
                                        </div>
                                        <div className="flex items-center justify-end pe-3 invisible group-hover:visible">
                                            <div className="h-8 w-8 bg-white rounded-full flex justify-center items-center drop-shadow-md me-2 hover:-translate-y-1 duration-300">
                                                <HiOutlinePencil />
                                            </div>
                                            <div className="h-8 w-8 bg-pink-800 text-white rounded-full flex justify-center items-center hover:-translate-y-1 duration-300">
                                                <HiOutlineX />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="py-4 px-2 group flex cursor-pointer">
                                        <div className="flex ">
                                            <div><HiOutlineChat className="text-2xl me-2" /></div>
                                            <div className="pe-4">
                                                <div>VisionAI Chat</div>
                                                <div className="text-xs text-slate-500">5 days ago</div>
                                            </div>
                                        </div>
                                        <div className="flex items-center justify-end pe-3 invisible group-hover:visible">
                                            <div className="h-8 w-8 bg-white rounded-full flex justify-center items-center drop-shadow-md me-2 hover:-translate-y-1 duration-300">
                                                <HiOutlinePencil />
                                            </div>
                                            <div className="h-8 w-8 bg-pink-800 text-white rounded-full flex justify-center items-center hover:-translate-y-1 duration-300">
                                                <HiOutlineX />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="py-4 px-2 group flex cursor-pointer">
                                        <div className="flex ">
                                            <div><HiOutlineChat className="text-2xl me-2" /></div>
                                            <div className="pe-4">
                                                <div>VisionAI Chat</div>
                                                <div className="text-xs text-slate-500">5 days ago</div>
                                            </div>
                                        </div>
                                        <div className="flex items-center justify-end pe-3 invisible group-hover:visible">
                                            <div className="h-8 w-8 bg-white rounded-full flex justify-center items-center drop-shadow-md me-2 hover:-translate-y-1 duration-300">
                                                <HiOutlinePencil />
                                            </div>
                                            <div className="h-8 w-8 bg-pink-800 text-white rounded-full flex justify-center items-center hover:-translate-y-1 duration-300">
                                                <HiOutlineX />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="py-4 px-2 group flex cursor-pointer">
                                        <div className="flex ">
                                            <div><HiOutlineChat className="text-2xl me-2" /></div>
                                            <div className="pe-4">
                                                <div>VisionAI Chat</div>
                                                <div className="text-xs text-slate-500">5 days ago</div>
                                            </div>
                                        </div>
                                        <div className="flex items-center justify-end pe-3 invisible group-hover:visible">
                                            <div className="h-8 w-8 bg-white rounded-full flex justify-center items-center drop-shadow-md me-2 hover:-translate-y-1 duration-300">
                                                <HiOutlinePencil />
                                            </div>
                                            <div className="h-8 w-8 bg-pink-800 text-white rounded-full flex justify-center items-center hover:-translate-y-1 duration-300">
                                                <HiOutlineX />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="py-4 px-2 group flex cursor-pointer">
                                        <div className="flex ">
                                            <div><HiOutlineChat className="text-2xl me-2" /></div>
                                            <div className="pe-4">
                                                <div>VisionAI Chat</div>
                                                <div className="text-xs text-slate-500">5 days ago</div>
                                            </div>
                                        </div>
                                        <div className="flex items-center justify-end pe-3 invisible group-hover:visible">
                                            <div className="h-8 w-8 bg-white rounded-full flex justify-center items-center drop-shadow-md me-2 hover:-translate-y-1 duration-300">
                                                <HiOutlinePencil />
                                            </div>
                                            <div className="h-8 w-8 bg-pink-800 text-white rounded-full flex justify-center items-center hover:-translate-y-1 duration-300">
                                                <HiOutlineX />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="py-4 px-2 group flex cursor-pointer">
                                        <div className="flex ">
                                            <div><HiOutlineChat className="text-2xl me-2" /></div>
                                            <div className="pe-4">
                                                <div>VisionAI Chat</div>
                                                <div className="text-xs text-slate-500">5 days ago</div>
                                            </div>
                                        </div>
                                        <div className="flex items-center justify-end pe-3 invisible group-hover:visible">
                                            <div className="h-8 w-8 bg-white rounded-full flex justify-center items-center drop-shadow-md me-2 hover:-translate-y-1 duration-300">
                                                <HiOutlinePencil />
                                            </div>
                                            <div className="h-8 w-8 bg-pink-800 text-white rounded-full flex justify-center items-center hover:-translate-y-1 duration-300">
                                                <HiOutlineX />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="py-4 px-2 group flex cursor-pointer">
                                        <div className="flex ">
                                            <div><HiOutlineChat className="text-2xl me-2" /></div>
                                            <div className="pe-4">
                                                <div>VisionAI Chat</div>
                                                <div className="text-xs text-slate-500">5 days ago</div>
                                            </div>
                                        </div>
                                        <div className="flex items-center justify-end pe-3 invisible group-hover:visible">
                                            <div className="h-8 w-8 bg-white rounded-full flex justify-center items-center drop-shadow-md me-2 hover:-translate-y-1 duration-300">
                                                <HiOutlinePencil />
                                            </div>
                                            <div className="h-8 w-8 bg-pink-800 text-white rounded-full flex justify-center items-center hover:-translate-y-1 duration-300">
                                                <HiOutlineX />
                                            </div>
                                        </div>
                                    </div>
                                </div> 
                            </div>
                        <div className=" px-1 flex justify-start lg:justify-center">
                            <div className="bg-blue-600 text-white px-5 py-2 flex items-center rounded-full cursor-pointer hover:-translate-y-1 duration-300 hover:shadow-xl w-full">
                                <HiPlus className="me-2 text-white" />  New Conversation
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="bg-[#FFDFDF] mb-2 rounded-md px-2 py-4 col-span-3">
                        <div className="bg-white rounded-md">
                            <div className="flex items-center py-2 border-t lg:border-t-0 border-b px-2">
                                <div>
                                    <div className="flex justify-center">
                                        <div className="h-12 w-12 bg-slate-200 border-4 border-white rounded-full flex items-center justify-center shadow-md group-hover:shadow-xl duration-300">
                                            <h2 className="text-sm font-medium text-slate-600 uppercase">acb</h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="ps-2">
                                    <h3 className='font-medium '>Vision AI</h3>
                                    <div className='text-slate-500 text-sm'>Image and PDF Expert</div>
                                </div>
                                <div className="ms-auto flex pe-10 gap-4">
                                    <div>
                                        <label class="inline-flex items-center cursor-pointer mt-1">
                                            <input type="checkbox" value="" class="sr-only peer" />
                                            <div class="relative w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                                            <span class="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">Use Real-Time Data</span>
                                        </label>
                                    </div>
                                    <div className="relative group">
                                        <div className="absolute -top-6 -right-10 min-w-28 bg-blue-900  group-hover:-translate-y-2 duration-300 rounded-md grid grid-cols-3 divide-x divide-solid divide-slate-100 invisible group-hover:visible text-slate-100 cursor-pointer">
                                            <div className="text-xs p-1">PDF</div>
                                            <div className="text-xs p-1"> Word </div>
                                            <div className="text-xs p-1"> Text</div>
                                        </div>
                                        <HiOutlineClipboard className="text-2xl cursor-pointer" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col items-center mb-10 mt-5 h-80 pb-5 overflow-y-auto">
                            <div className="flex justify-start mb-4">
                                <div>
                                    <div className='h-8 w-8'>
                                        <img src={avatar} className="rounded-full"  alt="" />
                                    </div>
                                </div>
                                <div className="relative group">
                                    <div className='bg-slate-50 ms-3 me-6 p-2 rounded-md'>
                                        Hi! I am VisionAI, and I'm Image Expert. I can assist you with PDF or Images-related information or questions                                      
                                    </div>
                                    <div className='h-8 w-8 bg-white rounded-full shadow-md absolute -bottom-2 right-1 flex items-center justify-center invisible group-hover:visible'>
                                        <HiOutlineClipboard className="text-xl cursor-pointer" title="Copy to clipboard" />
                                    </div>
                                </div>
                            </div>
                            <div className="flex items-center mb-5 bg-slate-50 px-6 py-2 rounded-full">
                                Upload an image and ask me anything 
                                <HiOutlineChevronDown className='ms-2 text-xs' />
                            </div>
                            <div className='mb-5 bg-slate-50 px-6 py-2 rounded-full'>
                                Explain an Image
                            </div>
                            <div className='mb-5 bg-slate-50 px-6 py-2 rounded-full'>
                                Summarize a book for Research
                            </div>
                            <div className='mb-5 bg-slate-50 px-6 py-2 rounded-full'>
                                Translate a book
                            </div>
                            <div className="mb-4 px-4 flex w-full items-center justify-center">
                                <Label
                                    htmlFor="dropzone-file"
                                    className="dark:hover:bg-bray-800 flex w-full cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-dashed border-gray-300 bg-white hover:bg-gray-50 dark:border-gray-600 dark:bg-gray-700 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                                >
                                    <div className="flex flex-col items-center justify-center pb-6 pt-5">
                                        <HiOutlineCloudUpload className='text-4xl mb-2 text-slate-400 font-normal' />
                                        <p className='text-slate-500 mb-2'>Drop your image here or browse</p>
                                        <p className='text-slate-400 text-sm font-normal mb-2'>
                                            (Only jpg, png, webp will be accepted)
                                        </p>
                                    </div>
                                    <FileInput id="dropzone-file" className="hidden" />
                                </Label>
                            </div>
                        </div>
                        <div className="w-full flex items-center">
                            <div className="relative w-full">
                                <div className="flex items-center justify-center absolute inset-y-0 left-2">
                                    <Label
                                        htmlFor="dropzone-file"
                                        className="dark:hover:bg-bray-800 flex h-7 w-7 lg:h-9 lg:w-9 cursor-pointer flex-col items-center justify-center rounded-full bg-blue-600 text-white"
                                    >
                                        <div className="flex flex-col items-center justify-center">
                                            <MdAttachFile className="lg:text-2xl text-lg" />
                                        </div>
                                        <FileInput id="dropzone-file" className="hidden" />
                                    </Label>
                                </div>
                                <Textarea 
                                    placeholder="Type a message or upload an image" className="w-full rounded-full shadow-sm border-0 pl-10 lg:pl-14 lg:pr-28 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm placeholder:pt-2"
                                />
                                <div className="flex items-center justify-center absolute inset-y-0 right-2">
                                    <div className="flex h-6 w-6 lg:h-9 lg:w-9 cursor-pointer flex-col items-center justify-center rounded-full hover:bg-slate-300">
                                        <div className="flex flex-col items-center justify-center">
                                            <HiOutlineMicrophone className="lg:text-2xl text-lg" />
                                        </div>
                                    </div>
                                </div>
                                <div className="flex items-center justify-center absolute inset-y-0 right-9 lg:right-12">
                                    <div className="flex h-7 w-7 lg:h-9 lg:w-9 cursor-pointer flex-col items-center justify-center rounded-full hover:bg-slate-300">
                                        <div className="flex flex-col items-center justify-center" onClick={() => setOpenModal(true)}>
                                            <HiOutlineDocumentText className="lg:text-2xl text-lg" />
                                        </div>

                                        <Modal dismissible size="4xl" show={openModal} onClose={() => setOpenModal(false)}>                                
                                            <Modal.Body>
                                                <div className="flex items-center justify-between border rounded-md px-4 py-2 mb-4">
                                                    <div>Prompt Library</div>
                                                    <div>
                                                        <div onClick={() => setOpenModalAdd(true)} className="flex items-center cursor-pointer">
                                                            <div className="mt-1">Add</div>
                                                            <div>
                                                                <HiOutlinePlus className="ms-1 text-[12px]" />
                                                            </div>
                                                        </div>
                                                        <Modal show={openModalAdd} size="md" onClose={() => setOpenModalAdd(false)} popup >
                                                        <Modal.Body>
                                                            <div className="pt-6">
                                                                <div className='mb-4'>
                                                                    <input type="text" class="bg-white w-full border-1 border-slate-300 rounded-md" placeholder="Add Title" />
                                                                </div>
                                                                <div className='mb-4'>
                                                                    <textarea class="bg-white w-full border-1 border-slate-300 rounded-md" placeholder="Add custom prompt" rows={5} />
                                                                </div>
                                                                <div className="flex gap-4">
                                                                    <button onClick={() => setOpenModalAdd(false)} className="bg-white border border-slate-300 rounded-full py-1  px-8 flex justify-center items-center font-medium hover:-translate-y-2 duration-300 hover:drop-shadow-lg">
                                                                        Cancel
                                                                    </button>

                                                                    <button onClick={() => setOpenModalAdd(false)} className="bg-blue-600 hover:bg-blue-500 text-white rounded-full px-8 flex py-1 justify-center items-center font-medium hover:-translate-y-2 duration-300 hover:drop-shadow-lg">
                                                                        Save
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </Modal.Body>
                                                        </Modal>
                                                    </div>
                                                </div>
                                                <div className="sm:flex items-center justify-between rounded-md py-1 mb-4">
                                                    <div className="flex items-center">
                                                        <ul className="flex gap-x-3 mb-2 md:mb-0">
                                                            <li className="px-4 py-1 bg-gray-200 hover:bg-gray-200 rounded-md cursor-pointer mb-1 md:mb-0">
                                                                All
                                                            </li>
                                                            <li className="px-4 py-1 hover:bg-gray-200 rounded-md cursor-pointer mb-1 md:mb-0">
                                                                Favorite
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="md:min-w-64">
                                                        <div className="relative w-full">
                                                            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                                                <span className="text-gray-500 sm:text-sm"><HiOutlineSearch /></span>
                                                            </div>
                                                            <input
                                                            type="text"
                                                            className="block w-full rounded-full border-0  pl-8 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 bg-slate-50"
                                                            placeholder="Search..."
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 mb-4">
                                                    <div className="rounded-md shadow-md border border-slate-100 p-4 mb-3 cursor-pointer hover:-translate-y-1 duration-300 hover:shadow-lg ease-in">
                                                        <div className="flex">
                                                            <div>
                                                                <h3 className="font-semibold text-lg mb-3">Lorem Ipsum is simply dummy.</h3>
                                                                <p>
                                                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                                                </p>
                                                            </div>
                                                            <div className="ps-3">
                                                                <div className='h-8 w-8 bg-white rounded-full flex items-center justify-center cursor-pointer drop-shadow-md'>
                                                                    <HiOutlineStar className='text-slate-500'  />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Modal.Body>
                                        </Modal>
                                    </div>
                                </div>
                            </div>
                            <div className="lg:px-2 pt-3 lg:pt-0">
                                <div className="flex h-9 w-9 lg:h-12 lg:w-12 cursor-pointer flex-col items-center justify-center rounded-full bg-blue-600 text-white hover:-translate-y-1 duration-300 hover:shadow-xl">
                                    <div className="flex flex-col items-center justify-center">
                                        <MdSend className="text-xl lg:text-2xl" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Aivision;