import { Link, Navigate, Outlet } from "react-router-dom";
import Topnavbar from "../component/layout/topnavbar/Topnavbar";
import PlusNavbar from "../component/layout/bottomplusnavbar/PlusNavbar";
import logo from "../images/hamariAI-white-logo.svg";
import logoSmall from "../images/hamariAI-white-logo-small.svg";
import React, { useState } from "react";
import {
    HiChevronRight,
    HiOutlineViewGrid,
    HiOutlineDocument,
    HiOutlineDocumentText,
    HiOutlinePhotograph,
    HiOutlineEye,
    HiOutlineChevronDown,
    HiOutlineDesktopComputer,
    HiOutlineDocumentDuplicate,
    HiOutlinePencil,
    HiOutlineMail,
    HiOutlineAdjustments,
    HiOutlineChatAlt2,
    HiOutlineVideoCamera,
    HiRss,
} from 'react-icons/hi';
import { LuLandmark, LuFileEdit } from "react-icons/lu";
import { AiOutlineYoutube } from "react-icons/ai";
import { SiGoogleadsense } from "react-icons/si";
import { TbTemplate, TbBrandTrello } from "react-icons/tb";
import Footer from "../component/layout/footer/Footer";

const Privateroutes = () =>{

    const [open, setOpen] = useState(true);
    const [template, setTemplate] = useState(false);
    const [chat, setchat] = useState(false);
    const [frontend, setFrontend] = useState(false); 
    const [setting, setSetting] = useState(false);

    const loggedIn = true;
    if(loggedIn)
    {
         return   (
            <>
                <div className='flex'>
                    <div className={`${open ? "md:w-64" : "md:w-20"} h-full fixed duration-300 bg-white hidden md:block z-50 pb-24`}>
                        <div className="bg-primary pt-3 pb-5 ps-5">
                            <div className={`h-8 ${ !open && "hidden" }`}>
                                <Link to="/"><img src={logo} alt="Hamari AI" /></Link>
                            </div>
                            <div className={`h-8 ${ open && "hidden" }`}>
                                <Link to="/"><img src={logoSmall} alt="Hamari AI" /></Link>
                            </div>
                        </div>
                        <div className={`absolute cursor-pointer -right-3 top-5 w-7 h-7 bg-white flex items-center justify-center rounded-full  ${!open && "rotate-180"}`} onClick={() => setOpen(!open)}>
                            <span><HiChevronRight /></span>
                        </div>
                        <div className="h-full overflow-y-auto overflow-x-hidden p-2 pb-10">
                            <div className='text-lg p-1 ps-5 mb-2 uppercase font-semibold text-slate-500'>
                                User
                            </div>  
                            <ul>
                                <li title="Dashboard">
                                
                                    <div className={`mb-1 font-medium text-slate-500 hover:bg-red-50 border-s-4 border-transparent hover:border-s-4 rounded-none hover:border-red-600 cursor-pointer ps-2 py-1`}>
                                        <Link to="/" className={`flex items-center gap-3.5 group`}>
                                            <div className={`w-8 h-8 rounded-full bg-red-200 flex justify-center items-center p-2`}>
                                                <HiOutlineViewGrid className={`text-lg text-red-500`}/>
                                            </div>
                                            <div className={`whitespace-pre duration-500 ${!open && "opacity-0 translate-x-28 overflow-hidden"
                                            }`}>
                                                Dashboard
                                            </div>
                                            {/* <div className={`${open && "hidden"} absolute left-48 bg-white font-medium whitespace-pre text-slate-500 rounded-md drop-shadow-lg px-0 py-0 w-0 overflow-hidden group-hover:px-2 group-hover:py-1 group-hover:left-16 group-hover:duration-300 group-hover:w-fit`}>
                                                Dashboard
                                            </div> */}
                                        </Link>
                                    </div>
                                    
                                </li>
                                <li title="Document">
                                    <div className={`mb-1 group font-medium text-slate-500 hover:bg-purple-100 border-s-4 border-transparent hover:border-s-4 rounded-none hover:border-purple-600 cursor-pointer ps-2 py-1`}>
                                        <Link to="user/documents" className={`flex items-center gap-3.5`}>
                                            <div className={`w-8 h-8 rounded-full bg-purple-200 flex justify-center items-center p-2`}>
                                                <HiOutlineDocument className={`text-lg text-purple-600`}/>
                                            </div>
                                            <div className={`whitespace-pre duration-500 ${!open && "opacity-0 translate-x-28 overflow-hidden"
                                            }`}>
                                                Document
                                            </div>
                                            {/* <div className={`${open && "hidden"} absolute left-48 bg-white font-medium whitespace-pre text-slate-500 rounded-md drop-shadow-lg px-0 py-0 w-0 overflow-hidden group-hover:px-2 group-hover:py-1 group-hover:left-16 group-hover:duration-300 group-hover:w-fit  `}>
                                                Document
                                            </div> */}
                                        </Link>
                                    </div>
                                </li>
                                <li title="Writer">
                                    <div className={`mb-1 group font-medium text-slate-500 hover:bg-red-50 border-s-4 border-transparent hover:border-s-4 rounded-none hover:border-red-600 cursor-pointer ps-2 py-1`}>
                                        <Link to="user/ai-writer" className={`flex items-center gap-3.5`}>
                                            <div className={`w-8 h-8 rounded-full bg-red-200 flex justify-center items-center p-2`}>
                                                <HiOutlineDocumentText className={`text-lg text-red-500`}/>
                                            </div>
                                            <div className={`whitespace-pre duration-500 ${!open && "opacity-0 translate-x-28 overflow-hidden"
                                            }`}>
                                                Writer
                                            </div>
                                            {/* <div className={`${open && "hidden"} absolute left-48 bg-white font-medium whitespace-pre text-slate-500 rounded-md drop-shadow-lg px-0 py-0 w-0 overflow-hidden group-hover:px-2 group-hover:py-1 group-hover:left-16 group-hover:duration-300 group-hover:w-fit  `}>
                                                AI Writer
                                            </div> */}
                                        </Link>
                                    </div>
                                </li>
                                <li title="Image">
                                    <div className={`mb-1 group font-medium text-slate-500 hover:bg-yellow-100/50 border-s-4 border-transparent hover:border-s-4 rounded-none hover:border-yellow-400 cursor-pointer ps-2 py-1`}>
                                        <Link to="user/ai-image" className={`flex items-center gap-3.5`}>
                                            <div className={`w-8 h-8 rounded-full bg-yellow-200/50 flex justify-center items-center p-2`}>
                                                <HiOutlinePhotograph className={`text-lg text-yellow-500`}/>
                                            </div>
                                            <div className={`whitespace-pre duration-500 ${!open && "opacity-0 translate-x-28 overflow-hidden"
                                            }`}>
                                                Image
                                            </div>
                                            {/* <div className={`${open && "hidden"} absolute left-48 bg-white font-medium whitespace-pre text-slate-500 rounded-md drop-shadow-lg px-0 py-0 w-0 overflow-hidden group-hover:px-2 group-hover:py-1 group-hover:left-16 group-hover:duration-300 group-hover:w-fit  `}>
                                                AI Image
                                            </div> */}
                                        </Link>
                                    </div>
                                </li>
                                <li title="Video">
                                    <div className={`mb-1 group font-medium text-slate-500 hover:bg-purple-100/50 border-s-4 border-transparent hover:border-s-4 rounded-none hover:border-purple-400 cursor-pointer ps-2 py-1`}>
                                        <Link to="user/ai-video" className={`flex items-center gap-3.5`}>
                                            <div className={`w-8 h-8 rounded-full bg-purple-200 flex justify-center items-center p-2`}>
                                                <HiOutlineVideoCamera className={`text-lg text-purple-500`}/>
                                            </div>
                                            <div className={`whitespace-pre duration-500 ${!open && "opacity-0 translate-x-28 overflow-hidden"
                                            }`}>
                                                Video
                                            </div>
                                            {/* <div className={`${open && "hidden"} absolute left-48 bg-white font-medium whitespace-pre text-slate-500 rounded-md drop-shadow-lg px-0 py-0 w-0 overflow-hidden group-hover:px-2 group-hover:py-1 group-hover:left-16 group-hover:duration-300 group-hover:w-fit  `}>
                                                AI Image
                                            </div> */}
                                        </Link>
                                    </div>
                                </li>
                                <li title="Article Wizard">
                                    <div className={`mb-1 group font-medium text-slate-500 hover:bg-lime-100/50 border-s-4 border-transparent hover:border-s-4 rounded-none hover:border-lime-400 cursor-pointer ps-2 py-1`}>
                                        <Link to="user/ai-article-wizard" className={`flex items-center gap-3.5`}>
                                            <div className={`w-8 h-8 rounded-full bg-lime-200/50 flex justify-center items-center p-2`}>
                                                <HiOutlineDocumentText className={`text-lg text-lime-600`}/>
                                            </div>
                                            <div className={`whitespace-pre duration-500 ${!open && "opacity-0 translate-x-28 overflow-hidden"
                                            }`}>
                                                Article Wizard
                                            </div>
                                            {/* <div className={`${open && "hidden"} absolute left-48 bg-white font-medium whitespace-pre text-slate-500 rounded-md drop-shadow-lg px-0 py-0 w-0 overflow-hidden group-hover:px-2 group-hover:py-1 group-hover:left-16 group-hover:duration-300 group-hover:w-fit  `}>
                                                AI Article Wizard
                                            </div> */}
                                        </Link>
                                    </div>
                                </li>
                                <li title="File Chat">
                                    <div className={`mb-1 group font-medium text-slate-500 hover:bg-emerald-100/50 border-s-4 border-transparent hover:border-s-4 rounded-none hover:border-emerald-500 cursor-pointer ps-2 py-1`}>
                                        <Link to="user/ai-pdf" className={`flex items-center gap-3.5`}>
                                            <div className={`w-8 h-8 rounded-full bg-emerald-100 flex justify-center items-center p-2`}>
                                                <LuFileEdit className={`text-lg text-emerald-600`}/>
                                            </div>
                                            <div className={`whitespace-pre duration-500 ${!open && "opacity-0 translate-x-28 overflow-hidden"
                                            }`}>
                                                File Chat 
                                            </div>
                                            {/* <div className={`${open && "hidden"} absolute left-48 bg-white font-medium whitespace-pre text-slate-500 rounded-md drop-shadow-lg px-0 py-0 w-0 overflow-hidden group-hover:px-2 group-hover:py-1 group-hover:left-16 group-hover:duration-300 group-hover:w-fit  `}>
                                                AI Chat PDF
                                            </div> */}
                                        </Link>
                                    </div>
                                </li>
                                <li title="Vision">
                                    <div className={`mb-1 group font-medium text-slate-500 hover:bg-purple-100 border-s-4 border-transparent hover:border-s-4 rounded-none hover:border-purple-600 cursor-pointer ps-2 py-1`}>
                                        <Link to="user/ai-vision" className={`flex items-center gap-3.5`}>
                                            <div className={`w-8 h-8 rounded-full bg-purple-200 flex justify-center items-center p-2`}>
                                                <HiOutlineEye className={`text-lg text-purple-600`}/>
                                            </div>
                                            <div className={`whitespace-pre duration-500 ${!open && "opacity-0 translate-x-28 overflow-hidden"
                                            }`}>
                                                Vision
                                            </div>
                                            {/* <div className={`${open && "hidden"} absolute left-48 bg-white font-medium whitespace-pre text-slate-500 rounded-md drop-shadow-lg px-0 py-0 w-0 overflow-hidden group-hover:px-2 group-hover:py-1 group-hover:left-16 group-hover:duration-300 group-hover:w-fit  `}>
                                                AI Vision
                                            </div> */}
                                        </Link>
                                    </div>
                                </li>
                                <li title="AI ReWriter">
                                    <div className={`mb-1 group font-medium text-slate-500 hover:bg-[#F7C9FF]/25 border-s-4 border-transparent hover:border-s-4 rounded-none hover:border-[#A376AB] cursor-pointer ps-2 py-1`}>
                                        <Link to="user/ai-rewriter" className={`flex items-center gap-3.5`}>
                                            <div className={`w-8 h-8 rounded-full bg-[#F7C9FF] flex justify-center items-center p-2`}>
                                                <HiOutlinePencil className={`text-lg text-[#A376AB]`}/>
                                            </div>
                                            <div className={`whitespace-pre duration-500 ${!open && "opacity-0 translate-x-28 overflow-hidden"
                                            }`}>
                                                AI ReWriter
                                            </div>
                                            {/* <div className={`${open && "hidden"} absolute left-48 bg-white font-medium whitespace-pre text-slate-500 rounded-md drop-shadow-lg px-0 py-0 w-0 overflow-hidden group-hover:px-2 group-hover:py-1 group-hover:left-16 group-hover:duration-300 group-hover:w-fit  `}>
                                                AI Writer
                                            </div> */}
                                        </Link>
                                    </div>
                                </li>
                                <li title="Chat Image">
                                    <div className={`mb-1 group font-medium text-slate-500 hover:bg-red-50 border-s-4 border-transparent hover:border-s-4 rounded-none hover:border-red-600 cursor-pointer ps-2 py-1`}>
                                        <Link to="user/chat-image" className={`flex items-center gap-3.5`}>
                                            <div className={`w-8 h-8 rounded-full bg-red-200 flex justify-center items-center p-2`}>
                                                <HiOutlinePhotograph className={`text-lg text-red-500`}/>
                                            </div>
                                            <div className={`whitespace-pre duration-500 ${!open && "opacity-0 translate-x-28 overflow-hidden"
                                            }`}>
                                                Chat Image
                                            </div>
                                            {/* <div className={`${open && "hidden"} absolute left-48 bg-white font-medium whitespace-pre text-slate-500 rounded-md drop-shadow-lg px-0 py-0 w-0 overflow-hidden group-hover:px-2 group-hover:py-1 group-hover:left-16 group-hover:duration-300 group-hover:w-fit  `}>
                                                AI Writer
                                            </div> */}
                                        </Link>
                                    </div>
                                </li>
                                <li title="Chat">
                                    <div className={`mb-1 group font-medium text-slate-500 hover:bg-lime-100/50 border-s-4 border-transparent hover:border-s-4 rounded-none hover:border-lime-400 cursor-pointer ps-2 py-1`}>
                                        <Link to="user/ai-chat" className={`flex items-center gap-3.5`}>
                                            <div className={`w-8 h-8 rounded-full bg-lime-200/50 flex justify-center items-center p-2`}>
                                                <HiOutlineEye className={`text-lg text-lime-600`}/>
                                            </div>
                                            <div className={`whitespace-pre duration-500 ${!open && "opacity-0 translate-x-28 overflow-hidden"
                                            }`}>
                                                Chat
                                            </div>
                                            {/* <div className={`${open && "hidden"} absolute left-48 bg-white font-medium whitespace-pre text-slate-500 rounded-md drop-shadow-lg px-0 py-0 w-0 overflow-hidden group-hover:px-2 group-hover:py-1 group-hover:left-16 group-hover:duration-300 group-hover:w-fit  `}>
                                                AI Chat
                                            </div> */}
                                        </Link>
                                    </div>
                                </li>
                                <li title="Code">
                                    <div className={`mb-1 group font-medium text-slate-500 hover:bg-purple-100 border-s-4 border-transparent hover:border-s-4 rounded-none hover:border-purple-600 cursor-pointer ps-2 py-1`}>
                                        <Link to="user/ai-code" className={`flex items-center gap-3.5`}>
                                            <div className={`w-8 h-8 rounded-full bg-purple-200 flex justify-center items-center p-2`}>
                                                <HiOutlineEye className={`text-lg text-purple-600`}/>
                                            </div>
                                            <div className={`whitespace-pre duration-500 ${!open && "opacity-0 translate-x-28 overflow-hidden"
                                            }`}>
                                                Code
                                            </div>
                                            {/* <div className={`${open && "hidden"} absolute left-48 bg-white font-medium whitespace-pre text-slate-500 rounded-md drop-shadow-lg px-0 py-0 w-0 overflow-hidden group-hover:px-2 group-hover:py-1 group-hover:left-16 group-hover:duration-300 group-hover:w-fit  `}>
                                                AI Code
                                            </div> */}
                                        </Link>
                                    </div>
                                </li>
                                <li title="YouTube">
                                    <div className={`mb-1 group font-medium text-slate-500 hover:bg-emerald-100/50 border-s-4 border-transparent hover:border-s-4 rounded-none hover:border-emerald-500 cursor-pointer ps-2 py-1`}>
                                        <Link to="user/ai-youtube" className={`flex items-center gap-3.5`}>
                                            <div className={`w-8 h-8 rounded-full bg-emerald-100 flex justify-center items-center p-2`}>
                                                <AiOutlineYoutube className={`text-lg text-emerald-600`}/>
                                            </div>
                                            <div className={`whitespace-pre duration-500 ${!open && "opacity-0 translate-x-28 overflow-hidden"
                                            }`}>
                                                YouTube
                                            </div>
                                        </Link>
                                    </div>
                                </li>
                                <li title="AI RSS">
                                    <div className={`mb-1 group font-medium text-slate-500 hover:bg-yellow-100/50 border-s-4 border-transparent hover:border-s-4 rounded-none hover:border-yellow-400 cursor-pointer ps-2 py-1`}>
                                        <Link to="user/ai-rss" className={`flex items-center gap-3.5`}>
                                            <div className={`w-8 h-8 rounded-full bg-yellow-200/50 flex justify-center items-center p-2`}>
                                                <HiRss className={`text-lg text-yellow-500`}/>
                                            </div>
                                            <div className={`whitespace-pre duration-500 ${!open && "opacity-0 translate-x-28 overflow-hidden"
                                            }`}>
                                                AI RSS
                                            </div>
                                        </Link>
                                    </div>
                                </li>
                                <li title="Speech to Text">
                                    <div className={`mb-1 group font-medium text-slate-500 hover:bg-red-50 border-s-4 border-transparent hover:border-s-4 rounded-none hover:border-red-600 cursor-pointer ps-2 py-1`}>
                                        <Link to="user/ai-speech-to-text" className={`flex items-center gap-3.5`}>
                                            <div className={`w-8 h-8 rounded-full bg-red-200 flex justify-center items-center p-2`}>
                                                <HiOutlineDocumentText className={`text-lg text-red-500`}/>
                                            </div>
                                            <div className={`whitespace-pre duration-500 ${!open && "opacity-0 translate-x-28 overflow-hidden"
                                            }`}>
                                                Speech to Text
                                            </div>
                                            {/* <div className={`${open && "hidden"} absolute left-48 bg-white font-medium whitespace-pre text-slate-500 rounded-md drop-shadow-lg px-0 py-0 w-0 overflow-hidden group-hover:px-2 group-hover:py-1 group-hover:left-16 group-hover:duration-300 group-hover:w-fit  `}>
                                                AI Speech to Text
                                            </div> */}
                                        </Link>
                                    </div>
                                </li>
                                <li title="Voiceover">
                                    <div className={`mb-1 group font-medium text-slate-500 hover:bg-yellow-100/50 border-s-4 border-transparent hover:border-s-4 rounded-none hover:border-yellow-400 cursor-pointer ps-2 py-1`}>
                                        <Link to="user/ai-voiceover" className={`flex items-center gap-3.5`}>
                                            <div className={`w-8 h-8 rounded-full bg-yellow-200/50 flex justify-center items-center p-2`}>
                                                <HiOutlinePhotograph className={`text-lg text-yellow-500`}/>
                                            </div>
                                            <div className={`whitespace-pre duration-500 ${!open && "opacity-0 translate-x-28 overflow-hidden"
                                            }`}>
                                                Voiceover
                                            </div>
                                            {/* <div className={`${open && "hidden"} absolute left-48 bg-white font-medium whitespace-pre text-slate-500 rounded-md drop-shadow-lg px-0 py-0 w-0 overflow-hidden group-hover:px-2 group-hover:py-1 group-hover:left-16 group-hover:duration-300 group-hover:w-fit  `}>
                                                AI Voiceover
                                            </div> */}
                                        </Link>
                                    </div>
                                </li>
                                <li title="Brand Voice">
                                    <div className={`mb-1 group font-medium text-slate-500 hover:bg-lime-100/50 border-s-4 border-transparent hover:border-s-4 rounded-none hover:border-lime-400 cursor-pointer ps-2 py-1`}>
                                        <Link to="user/brand-voice" className={`flex items-center gap-3.5`}>
                                            <div className={`w-8 h-8 rounded-full bg-lime-200/50 flex justify-center items-center p-2`}>
                                                <TbBrandTrello className={`text-lg text-lime-600`}/>
                                            </div>
                                            <div className={`whitespace-pre duration-500 ${!open && "opacity-0 translate-x-28 overflow-hidden"
                                            }`}>
                                                Brand Voice
                                            </div>
                                            {/* <div className={`${open && "hidden"} absolute left-48 bg-white font-medium whitespace-pre text-slate-500 rounded-md drop-shadow-lg px-0 py-0 w-0 overflow-hidden group-hover:px-2 group-hover:py-1 group-hover:left-16 group-hover:duration-300 group-hover:w-fit  `}>
                                                AI Chat
                                            </div> */}
                                        </Link>
                                    </div>
                                </li>
                            </ul>
                            <div className="h-[2px] bg-gray-100 my-5"></div>
                            <div className='text-lg p-1 ps-5 mb-2 uppercase font-semibold text-slate-500'>
                                Admin
                            </div>
                            <ul>
                                <li title="Dashboard">
                                    <div className={`mb-1 font-medium text-slate-500 hover:bg-red-50 border-s-4 border-transparent hover:border-s-4 rounded-none hover:border-red-600 cursor-pointer ps-2 py-1`}>
                                        <Link to='admin/dashboard' className={`flex items-center gap-3.5 group`}>
                                            <div className={`w-8 h-8 rounded-full bg-red-200 flex justify-center items-center p-2`}>
                                                <HiOutlineViewGrid className={`text-lg text-red-500`}/>
                                            </div>
                                            <div className={`whitespace-pre duration-500 ${!open && "opacity-0 translate-x-28 overflow-hidden"
                                            }`}>
                                                Dashboard
                                            </div>
                                            {/* <div className={`${open && "hidden"} absolute left-48 bg-white font-medium whitespace-pre text-slate-500 rounded-md drop-shadow-lg px-0 py-0 w-0 overflow-hidden group-hover:px-2 group-hover:py-1 group-hover:left-16 group-hover:duration-300 group-hover:w-fit`}>
                                                Dashboard
                                            </div> */}
                                        </Link>
                                    </div>
                                </li>
                                <li title="Marketplace">
                                    <div className={`mb-1 group font-medium text-slate-500 hover:bg-emerald-100/50 border-s-4 border-transparent hover:border-s-4 rounded-none hover:border-emerald-500 cursor-pointer ps-2 py-1`}>
                                        <Link to='admin/marketplace' className={`flex items-center gap-3.5`}>
                                            <div className={`w-8 h-8 rounded-full bg-emerald-100 flex justify-center items-center p-2`}>
                                                <LuLandmark className={`text-lg text-emerald-600`}/>
                                            </div>
                                            <div className={`whitespace-pre duration-500 ${!open && "opacity-0 translate-x-28 overflow-hidden"
                                            }`}>
                                                Marketplace
                                            </div>
                                            {/* <div className={`${open && "hidden"} absolute left-48 bg-white font-medium whitespace-pre text-slate-500 rounded-md drop-shadow-lg px-0 py-0 w-0 overflow-hidden group-hover:px-2 group-hover:py-1 group-hover:left-16 group-hover:duration-300 group-hover:w-fit  `}>
                                                Blog
                                            </div> */}
                                        </Link>
                                    </div>
                                </li>
                                <li title="User Management">
                                    <div className={`mb-1 group font-medium text-slate-500 hover:bg-purple-100 border-s-4 border-transparent hover:border-s-4 rounded-none hover:border-purple-600 cursor-pointer ps-2 py-1`}>
                                        <Link to='admin/user-management' className={`flex items-center gap-3.5`}>
                                            <div className={`w-8 h-8 rounded-full bg-purple-200 flex justify-center items-center p-2`}>
                                                <HiOutlineDocument className={`text-lg text-purple-600`}/>
                                            </div>
                                            <div className={`whitespace-pre duration-500 ${!open && "opacity-0 translate-x-28 overflow-hidden"
                                            }`}>
                                                User Management
                                            </div>
                                            {/* <div className={`${open && "hidden"} absolute left-48 bg-white font-medium whitespace-pre text-slate-500 rounded-md drop-shadow-lg px-0 py-0 w-0 overflow-hidden group-hover:px-2 group-hover:py-1 group-hover:left-16 group-hover:duration-300 group-hover:w-fit  `}>
                                                User Management
                                            </div> */}
                                        </Link>
                                    </div>
                                </li>
                                <li title="Google Adsense">
                                    <div className={`mb-1 group font-medium text-slate-500 hover:bg-lime-100/50 border-s-4 border-transparent hover:border-s-4 rounded-none hover:border-lime-400 cursor-pointer ps-2 py-1`}>
                                        <Link to="admin/googleAdsense/google-adsense" className={`flex items-center gap-3.5`}>
                                            <div className={`w-8 h-8 rounded-full bg-lime-200/50 flex justify-center items-center p-2`}>
                                                <SiGoogleadsense className={`text-lg text-lime-600`}/>
                                            </div>
                                            <div className={`whitespace-pre duration-500 ${!open && "opacity-0 translate-x-28 overflow-hidden"
                                            }`}>
                                                Google Adsense
                                            </div>
                                        </Link>
                                    </div>
                                </li>
                                <li title="Templates">
                                    <div className={`mb-1 font-medium text-slate-500 hover:bg-red-50 border-s-4 border-transparent hover:border-s-4 rounded-none hover:border-red-600 cursor-pointer ps-2 py-1 duration-300 transition-all flex items-center justify-between`} onClick={() => setTemplate(!template)}>
                                        <Link className={`flex items-center gap-3.5 group`}>
                                            <div className={`w-8 h-8 rounded-full bg-red-200 flex justify-center items-center p-2`}>
                                                <TbTemplate className={`text-lg text-red-500`}/>
                                            </div>
                                            <div className={`whitespace-pre duration-500 ${!open && "opacity-0 translate-x-28 overflow-hidden"
                                            }`}>
                                                Templates
                                            </div>
                                            {/* <div className={`${open && "hidden"} absolute left-48 bg-white font-medium whitespace-pre text-slate-500 rounded-md drop-shadow-lg px-0 py-0 w-0 overflow-hidden group-hover:px-2 group-hover:py-1 group-hover:left-16 group-hover:duration-300 group-hover:w-fit`}>
                                                Templates
                                            </div> */}
                                        </Link>
                                        <div className="mr-1">
                                            <HiOutlineChevronDown />
                                        </div>
                                    </div>
                                    <div>
                                        {template && 
                                            <div className="bg-slate-50 rounded-md p-3"> 
                                                <ul>
                                                    <li className="mb-1 py-1 px-2 border-s-4 border-transparent hover:bg-slate-100 hover:border-s-4 hover:border-slate-400">
                                                        <Link to="admin/built-in-templates">
                                                            Built-in Templates
                                                        </Link>
                                                    </li>
                                                    <li className="mb-1 py-1 px-2 border-s-4 border-transparent hover:bg-slate-100 hover:border-s-4 hover:border-slate-400">
                                                        <Link to="admin/custom-templates">
                                                            Custom Templates
                                                        </Link>
                                                    </li>
                                                    <li className="mb-1 py-1 px-2 border-s-4 border-transparent hover:bg-slate-100 hover:border-s-4 hover:border-slate-400">
                                                        <Link to="admin/ai-writer-categories">
                                                            Writer Categories
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        }
                                    </div>
                                </li>
                                <li title="Chat Settings">
                                    <div className={`mb-1 font-medium text-slate-500 hover:bg-slate-50 border-s-4 border-transparent hover:border-s-4 rounded-none hover:border-slate-600 cursor-pointer ps-2 py-1 duration-300 transition-all flex items-center justify-between`} onClick={() => setchat(!chat)}>
                                        <Link className={`flex items-center gap-3.5 group`}>
                                            <div className={`w-8 h-8 rounded-full bg-slate-200 flex justify-center items-center p-2`}>
                                                <HiOutlineChatAlt2 className={`text-lg text-slate-500`}/>
                                            </div>
                                            <div className={`whitespace-pre duration-500 ${!open && "opacity-0 translate-x-28 overflow-hidden"
                                            }`}>
                                                Chat Settings
                                            </div>
                                            {/* <div className={`${open && "hidden"} absolute left-48 bg-white font-medium whitespace-pre text-slate-500 rounded-md drop-shadow-lg px-0 py-0 w-0 overflow-hidden group-hover:px-2 group-hover:py-1 group-hover:left-16 group-hover:duration-300 group-hover:w-fit`}>
                                                Templates
                                            </div> */}
                                        </Link>
                                        <div className="mr-1">
                                            <HiOutlineChevronDown />
                                        </div>
                                    </div>
                                    <div>
                                        {chat && 
                                            <div className="bg-slate-50 rounded-md p-3"> 
                                                <ul>
                                                    <li className="mb-1 py-1 px-2 border-s-4 border-transparent hover:bg-slate-100 hover:border-s-4 hover:border-slate-400">
                                                        <Link to="admin/chat-categories">
                                                            Chat Categories
                                                        </Link>
                                                    </li>
                                                    <li className="mb-1 py-1 px-2 border-s-4 border-transparent hover:bg-slate-100 hover:border-s-4 hover:border-slate-400">
                                                        <Link to="admin/chat-templates">
                                                            Chat Templates
                                                        </Link>
                                                    </li>
                                                    <li className="mb-1 py-1 px-2 border-s-4 border-transparent hover:bg-slate-100 hover:border-s-4 hover:border-slate-400">
                                                        <Link to="admin/chatbot">
                                                            Chatbot Training
                                                        </Link>
                                                    </li>
                                                    <li className="mb-1 py-1 px-2 border-s-4 border-transparent hover:bg-slate-100 hover:border-s-4 hover:border-slate-400">
                                                        <Link to="admin/floatingchatsettings/floating-chat-settings">
                                                            Floating Chat Settings
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        }
                                    </div>
                                </li>
                                <li title="Frontend">
                                    <div className={`mb-1 group font-medium text-slate-500 hover:bg-yellow-100/50 border-s-4 border-transparent hover:border-s-4 rounded-none hover:border-yellow-400 cursor-pointer ps-2 py-1 duration-300 transition-all flex items-center justify-between`} onClick={() => setFrontend(!frontend)}>
                                        <Link className={`flex items-center gap-3.5 group`}>
                                            <div className={`w-8 h-8 rounded-full bg-yellow-200/50 flex justify-center items-center p-2`}>
                                                <HiOutlineDesktopComputer className={`text-lg text-yellow-500`}/>
                                            </div>
                                            <div className={`whitespace-pre duration-500 ${!open && "opacity-0 translate-x-28 overflow-hidden"
                                            }`}>
                                                Frontend
                                            </div>
                                            {/* <div className={`${open && "hidden"} absolute left-48 bg-white font-medium whitespace-pre text-slate-500 rounded-md drop-shadow-lg px-0 py-0 w-0 overflow-hidden group-hover:px-2 group-hover:py-1 group-hover:left-16 group-hover:duration-300 group-hover:w-fit`}>
                                                Frontend
                                            </div> */}
                                        </Link>
                                        <div className="mr-1">
                                            <HiOutlineChevronDown />
                                        </div>
                                    </div>
                                    <div>
                                        {frontend && 
                                            <div className="bg-slate-50 rounded-md p-3"> 
                                                <ul>
                                                    <li className="mb-1 py-1 px-2 border-s-4 border-transparent hover:bg-slate-100 hover:border-s-4 hover:border-slate-400">
                                                        <Link to='admin/frontend-settings'>
                                                            Frontend Settings
                                                        </Link>
                                                    </li>
                                                    <li className="mb-1 py-1 px-2 border-s-4 border-transparent hover:bg-slate-100 hover:border-s-4 hover:border-slate-400">
                                                        <Link to='admin/frontend-section-settings'>
                                                            Frontend Section Settings
                                                        </Link>
                                                    </li>
                                                    <li className="mb-1 py-1 px-2 border-s-4 border-transparent hover:bg-slate-100 hover:border-s-4 hover:border-slate-400">
                                                        <Link to='admin/menu'>
                                                            Menu
                                                        </Link>
                                                    </li>
                                                    <li className="mb-1 py-1 px-2 border-s-4 border-transparent hover:bg-slate-100 hover:border-s-4 hover:border-slate-400">
                                                        <Link to='admin/faq'>
                                                            F.A.Q
                                                        </Link>
                                                    </li>
                                                    <li className="mb-1 py-1 px-2 border-s-4 border-transparent hover:bg-slate-100 hover:border-s-4 hover:border-slate-400">
                                                        <Link to='admin/tools'>
                                                            Tools Section
                                                        </Link>
                                                    </li>
                                                    <li className="mb-1 py-1 px-2 border-s-4 border-transparent hover:bg-slate-100 hover:border-s-4 hover:border-slate-400">
                                                        <Link to='admin/features'>
                                                            Features Section
                                                        </Link>
                                                    </li>
                                                    <li className="mb-1 py-1 px-2 border-s-4 border-transparent hover:bg-slate-100 hover:border-s-4 hover:border-slate-400">
                                                        <Link to='admin/testimonials'>
                                                            Testimonials Section
                                                        </Link>
                                                    </li>
                                                    <li className="mb-1 py-1 px-2 border-s-4 border-transparent hover:bg-slate-100 hover:border-s-4 hover:border-slate-400">
                                                        <Link to='admin/clients'>
                                                            Clients Section
                                                        </Link>
                                                    </li>
                                                    <li className="mb-1 py-1 px-2 border-s-4 border-transparent hover:bg-slate-100 hover:border-s-4 hover:border-slate-400">
                                                        <Link to='admin/how-it-works'>
                                                            How it Works Section
                                                        </Link>
                                                    </li>
                                                    <li className="mb-1 py-1 px-2 border-s-4 border-transparent hover:bg-slate-100 hover:border-s-4 hover:border-slate-400">
                                                        <Link to='admin/who-can-use-section'>
                                                            Who Can Use Section
                                                        </Link>
                                                    </li>
                                                    <li className="mb-1 py-1 px-2 border-s-4 border-transparent hover:bg-slate-100 hover:border-s-4 hover:border-slate-400">
                                                        <Link to='admin/generators-list'>
                                                            Generators List Section
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        }
                                    </div>
                                </li>
                                <li title="Pages">
                                    <div className={`mb-1 group font-medium text-slate-500 hover:bg-lime-100/50 border-s-4 border-transparent hover:border-s-4 rounded-none hover:border-lime-400 cursor-pointer ps-2 py-1`}>
                                        <Link to='admin/pages' className={`flex items-center gap-3.5`}>
                                            <div className={`w-8 h-8 rounded-full bg-lime-200/50 flex justify-center items-center p-2`}>
                                                <HiOutlineDocumentDuplicate className={`text-lg text-lime-600`}/>
                                            </div>
                                            <div className={`whitespace-pre duration-500 ${!open && "opacity-0 translate-x-28 overflow-hidden"
                                            }`}>
                                                Pages
                                            </div>
                                            {/* <div className={`${open && "hidden"} absolute left-48 bg-white font-medium whitespace-pre text-slate-500 rounded-md drop-shadow-lg px-0 py-0 w-0 overflow-hidden group-hover:px-2 group-hover:py-1 group-hover:left-16 group-hover:duration-300 group-hover:w-fit  `}>
                                                Pages
                                            </div> */}
                                        </Link>
                                    </div>
                                </li>
                                <li title="Blog">
                                    <div className={`mb-1 group font-medium text-slate-500 hover:bg-emerald-100/50 border-s-4 border-transparent hover:border-s-4 rounded-none hover:border-emerald-500 cursor-pointer ps-2 py-1`}>
                                        <Link to='admin/blog-posts' className={`flex items-center gap-3.5`}>
                                            <div className={`w-8 h-8 rounded-full bg-emerald-100 flex justify-center items-center p-2`}>
                                                <HiOutlinePencil className={`text-lg text-emerald-600`}/>
                                            </div>
                                            <div className={`whitespace-pre duration-500 ${!open && "opacity-0 translate-x-28 overflow-hidden"
                                            }`}>
                                                Blog
                                            </div>
                                            {/* <div className={`${open && "hidden"} absolute left-48 bg-white font-medium whitespace-pre text-slate-500 rounded-md drop-shadow-lg px-0 py-0 w-0 overflow-hidden group-hover:px-2 group-hover:py-1 group-hover:left-16 group-hover:duration-300 group-hover:w-fit  `}>
                                                Blog
                                            </div> */}
                                        </Link>
                                    </div>
                                </li>
                                <li title="Email Templates">
                                    <div className={`mb-1 group font-medium text-slate-500 hover:bg-purple-100 border-s-4 border-transparent hover:border-s-4 rounded-none hover:border-purple-600 cursor-pointer ps-2 py-1`}>
                                        <Link to='admin/email-templates' className={`flex items-center gap-3.5`}>
                                            <div className={`w-8 h-8 rounded-full bg-purple-200 flex justify-center items-center p-2`}>
                                                <HiOutlineMail className={`text-lg text-purple-600`}/>
                                            </div>
                                            <div className={`whitespace-pre duration-500 ${!open && "opacity-0 translate-x-28 overflow-hidden"
                                            }`}>
                                                Email Templates
                                            </div>
                                            {/* <div className={`${open && "hidden"} absolute left-48 bg-white font-medium whitespace-pre text-slate-500 rounded-md drop-shadow-lg px-0 py-0 w-0 overflow-hidden group-hover:px-2 group-hover:py-1 group-hover:left-16 group-hover:duration-300 group-hover:w-fit  `}>
                                                Email Templates
                                            </div> */}
                                        </Link>
                                    </div>
                                </li>
                                <li title="Setting">
                                    <div className={`mb-1 group font-medium text-slate-500 hover:bg-lime-100/50 border-s-4 border-transparent hover:border-s-4 rounded-none hover:border-lime-400 cursor-pointer ps-2 py-1 flex items-center justify-between`} onClick={() => setSetting(!setting)}>
                                        <Link className={`flex items-center gap-3.5`}>
                                            <div className={`w-8 h-8 rounded-full bg-lime-200/50 flex justify-center items-center p-2`}>
                                                <HiOutlineAdjustments className={`text-lg text-lime-600`}/>
                                            </div>
                                            <div className={`whitespace-pre duration-500 ${!open && "opacity-0 translate-x-28 overflow-hidden"
                                            }`}>
                                                Setting
                                            </div>
                                            {/* <div className={`${open && "hidden"} absolute left-48 bg-white font-medium whitespace-pre text-slate-500 rounded-md drop-shadow-lg px-0 py-0 w-0 overflow-hidden group-hover:px-2 group-hover:py-1 group-hover:left-16 group-hover:duration-300 group-hover:w-fit  `}>
                                                Setting
                                            </div> */}
                                        </Link>
                                        <div className="mr-1">
                                            <HiOutlineChevronDown />
                                        </div>
                                    </div>
                                    <div>
                                        {setting && 
                                            <div className="bg-slate-50 rounded-md p-3"> 
                                                <ul>
                                                    <li className="mb-1 py-1 px-2 border-s-4 border-transparent hover:bg-slate-100 hover:border-s-4 hover:border-slate-400">
                                                        <Link to='admin/general'>
                                                            General
                                                        </Link>
                                                    </li>
                                                    <li className="mb-1 py-1 px-2 border-s-4 border-transparent hover:bg-slate-100 hover:border-s-4 hover:border-slate-400">
                                                        <Link to='admin/invoice'>
                                                            Invoice
                                                        </Link>
                                                    </li>
                                                    <li className="mb-1 py-1 px-2 border-s-4 border-transparent hover:bg-slate-100 hover:border-s-4 hover:border-slate-400">
                                                        <Link to='admin/openai'>
                                                            OpenAI
                                                        </Link>
                                                    </li>
                                                    <li className="mb-1 py-1 px-2 border-s-4 border-transparent hover:bg-slate-100 hover:border-s-4 hover:border-slate-400">
                                                        <Link to='admin/stablediffusion'>
                                                            StableDiffusion
                                                        </Link>
                                                    </li>
                                                    <li className="mb-1 py-1 px-2 border-s-4 border-transparent hover:bg-slate-100 hover:border-s-4 hover:border-slate-400">
                                                        <Link to='admin/unsplashapi'>
                                                            Unsplash API
                                                        </Link>
                                                    </li>
                                                    <li className="mb-1 py-1 px-2 border-s-4 border-transparent hover:bg-slate-100 hover:border-s-4 hover:border-slate-400">
                                                        <Link to='admin/serperapi'>
                                                            Serper API
                                                        </Link>
                                                    </li>
                                                    <li className="mb-1 py-1 px-2 border-s-4 border-transparent hover:bg-slate-100 hover:border-s-4 hover:border-slate-400">
                                                        <Link to='admin/tts'>
                                                            TTS
                                                        </Link>
                                                    </li>
                                                    <li className="mb-1 py-1 px-2 border-s-4 border-transparent hover:bg-slate-100 hover:border-s-4 hover:border-slate-400">
                                                        <Link to='admin/affiliate'>
                                                            Affiliate
                                                        </Link>
                                                    </li>
                                                    <li className="mb-1 py-1 px-2 border-s-4 border-transparent hover:bg-slate-100 hover:border-s-4 hover:border-slate-400">
                                                        <Link to='admin/smtp'>
                                                            SMTP
                                                        </Link>
                                                    </li>
                                                    <li className="mb-1 py-1 px-2 border-s-4 border-transparent hover:bg-slate-100 hover:border-s-4 hover:border-slate-400">
                                                        <Link to='admin/gdpr'>
                                                            GDPR
                                                        </Link>
                                                    </li>
                                                    <li className="mb-1 py-1 px-2 border-s-4 border-transparent hover:bg-slate-100 hover:border-s-4 hover:border-slate-400">
                                                        <Link to='admin/privacy'>
                                                            Privacy Policy and Terms
                                                        </Link>
                                                    </li>
                                                    <li className="mb-1 py-1 px-2 border-s-4 border-transparent hover:bg-slate-100 hover:border-s-4 hover:border-slate-400">
                                                        <Link to='admin/languages'>
                                                            Languages
                                                        </Link>
                                                    </li>
                                                    <li className="mb-1 py-1 px-2 border-s-4 border-transparent hover:bg-slate-100 hover:border-s-4 hover:border-slate-400">
                                                        <Link to='admin/storage'>
                                                            Storage
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        }
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className={`${open ? "md:ms-64" : "md:ms-20"} min-h-screen w-screen bg-[#F9F9F9] duration-300 pt-[200px] md:pt-[68px] pb-20`}>
                        <div><Topnavbar/></div>
                        <div className="mx-2">
                            <Outlet/>
                            <PlusNavbar/>
                        </div>
                    </div>
                    <Footer/>
                </div>
            </>
         )
    }
    else
    {
        return <Navigate to='/login' />
    }
}
export default Privateroutes;