import { Link } from "react-router-dom";
import { 
    HiOutlineChevronLeft,
    HiOutlinePencil,
    HiOutlineX
} from "react-icons/hi";
import { Table, Label, TextInput, Textarea, Modal, ToggleSwitch } from 'flowbite-react';
import { useState } from 'react';
const HowitWorks =()=>{
    const [openTools, setTools] = useState(false);
    const [switch1, setSwitch1] = useState(true);
    return(
        <>
            <div className='p-5 md:flex md:justify-between bg-white rounded-md my-2'>
                <div>
                    <div className='mb-1 text-slate-400'>
                        <Link to="/admin/dashboard" className="flex items-center">
                                <HiOutlineChevronLeft className="me-1" /> Back to dashboard
                        </Link>
                    </div>
                    <h2 className='text-4xl font-bold'>Manage How it Works</h2>
                </div>
            </div>
            <div className="p-5 bg-white rounded-md mb-2">
                <div className="border rounded-md">
                    <div className="p-2 flex justify-between items-center border-b">
                        {/* <div><h3 className="text-lg font-medium">Tools Section</h3></div> */}
                        <div>
                            <div className="flex justify-center">
                                <button onClick={() => setTools(true)} className="bg-blue-600 hover:bg-blue-500 rounded-full py-2 px-4 text-white flex justify-center font-medium hover:-translate-y-2 duration-300 hover:drop-shadow-lg w-full">
                                    Create New Step
                                </button>
                                <Modal show={openTools} size="md" onClose={() => setTools(false)}>
                                    {/* <Modal.Header>Tools Section</Modal.Header> */}
                                    <Modal.Body>
                                        <div className=''>
                                            <div className="mb-3">
                                                <div className='mb-2 block'>
                                                    <Label value="Order" />
                                                </div>
                                                <TextInput type='text' />
                                            </div>
                                            <div className="mb-3">
                                                <div className='mb-2 block'>
                                                    <Label value="Title" />
                                                </div>
                                                <Textarea rows={4} />
                                            </div>
                                            <div className="pt-2 flex justify-end gap-4">
                                                <button  onClick={() => setTools(false)} className="border border-purple-300 text-purple-700 px-5 py-2 flex items-center justify-center rounded-md cursor-pointer hover:-translate-y-1 duration-300 hover:shadow-lg">
                                                    Cancel
                                                </button>
                                                <button className="bg-blue-600 hover:bg-blue-500 rounded-md py-2 px-4 text-white flex justify-center font-medium hover:-translate-y-2 duration-300 hover:drop-shadow-lg">
                                                    Save
                                                </button>
                                            </div>
                                        </div>  
                                    </Modal.Body>
                                </Modal>
                            </div>
                        </div>
                    </div>
                    <div className="">
                        <div className="overflow-x-auto">
                            <Table className="bg-transparent">
                                <Table.Head>
                                    <Table.HeadCell className="bg-transparent border-b text-slate-500 font-medium">
                                        Order
                                    </Table.HeadCell>
                                    <Table.HeadCell className="bg-transparent border-b text-slate-500 font-medium whitespace-nowrap">
                                        Title
                                    </Table.HeadCell>
                                    <Table.HeadCell className="bg-transparent border-b text-slate-500 font-medium whitespace-nowrap">
                                        Updated At
                                    </Table.HeadCell>
                                    <Table.HeadCell className="bg-transparent border-b text-slate-500 font-medium whitespace-nowrap">
                                        Actions
                                    </Table.HeadCell>
                                </Table.Head>
                                <Table.Body className="divide-y">
                                    <Table.Row>
                                        <Table.Cell>
                                            1
                                        </Table.Cell>
                                        <Table.Cell>
                                            Simply explain what your content is about and adjust settings according to your needs.
                                        </Table.Cell>
                                        <Table.Cell className="whitespace-nowrap">
                                            <div>2.6.2023</div> 
                                            <div className='text-slate-400'>05:41:26</div>
                                        </Table.Cell>
                                        <Table.Cell>
                                        <div className='flex gap-2'>
                                            <div className='h-10 w-10 flex justify-center items-center bg-white  hover:bg-blue-600 rounded-full shadow-md hover:-translate-y-1 duration-300 hover:shadow-xl cursor-pointer hover:text-white' title="Edit">
                                                <HiOutlinePencil className='text-lg' />
                                            </div>
                                            <div className='h-10 w-10 flex justify-center items-center bg-white  hover:bg-red-700 rounded-full shadow-md hover:-translate-y-1 duration-300 hover:shadow-xl cursor-pointer hover:text-white' title="Delete">
                                                <HiOutlineX className='text-lg' />
                                            </div>
                                        </div>
                                        </Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>
                                            2
                                        </Table.Cell>
                                        <Table.Cell>
                                            Simply input some basic information or keywords about your brand or product, and let our AI algorithms do the rest.
                                        </Table.Cell>
                                        <Table.Cell className="whitespace-nowrap">
                                            <div>2.6.2023</div> 
                                            <div className='text-slate-400'>05:41:26</div>
                                        </Table.Cell>
                                        <Table.Cell>
                                        <div className='flex gap-2'>
                                            <div className='h-10 w-10 flex justify-center items-center bg-white  hover:bg-blue-600 rounded-full shadow-md hover:-translate-y-1 duration-300 hover:shadow-xl cursor-pointer hover:text-white' title="Edit">
                                                <HiOutlinePencil className='text-lg' />
                                            </div>
                                            <div className='h-10 w-10 flex justify-center items-center bg-white  hover:bg-red-700 rounded-full shadow-md hover:-translate-y-1 duration-300 hover:shadow-xl cursor-pointer hover:text-white' title="Delete">
                                                <HiOutlineX className='text-lg' />
                                            </div>
                                        </div>
                                        </Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>
                                            3
                                        </Table.Cell>
                                        <Table.Cell>
                                            View, edit or export your result with a few clicks. And you’re done!
                                        </Table.Cell>
                                        <Table.Cell className="whitespace-nowrap">
                                            <div>2.6.2023</div> 
                                            <div className='text-slate-400'>05:41:26</div>
                                        </Table.Cell>
                                        <Table.Cell>
                                        <div className='flex gap-2'>
                                            <div className='h-10 w-10 flex justify-center items-center bg-white  hover:bg-blue-600 rounded-full shadow-md hover:-translate-y-1 duration-300 hover:shadow-xl cursor-pointer hover:text-white' title="Edit">
                                                <HiOutlinePencil className='text-lg' />
                                            </div>
                                            <div className='h-10 w-10 flex justify-center items-center bg-white  hover:bg-red-700 rounded-full shadow-md hover:-translate-y-1 duration-300 hover:shadow-xl cursor-pointer hover:text-white' title="Delete">
                                                <HiOutlineX className='text-lg' />
                                            </div>
                                        </div>
                                        </Table.Cell>
                                    </Table.Row>
                                </Table.Body>
                            </Table>
                        </div>
                    </div>
                </div>
                <div className="mt-6">
                    <h3 className='text-xl font-medium text-slate-700 mb-5'>Bottom Line Settings</h3>
                    <div className="mb-4">
                        <ToggleSwitch checked={switch1} label="Display Bottom Line" onChange={setSwitch1} />
                    </div>
                    <div className="mb-3">
                        <Textarea rows={4} placeholder="Want to see?" />
                    </div>
                    <div className="pt-2 mb-4 flex gap-4">           
                        <button className="bg-blue-600 hover:bg-blue-500 rounded-md py-2 px-4 text-white flex justify-center font-medium hover:-translate-y-2 duration-300 hover:drop-shadow-lg">
                            Save
                        </button>
                    </div>
                    <div className="mb-6">
                        Preview : Want to see? <span className="text-blue-500 hover:underline hover:cursor-pointer">Join Magic</span>
                    </div>
                    <div className="mb-6">
                        *You can use HTML in Step Title and Bottom Line.
                    </div>
                </div>
            </div>
        </>
    )
}
export default HowitWorks;