import { Label } from 'flowbite-react';
import { HiOutlineDocumentDuplicate, HiOutlineMinusCircle, HiOutlineChevronLeft } from "react-icons/hi";
import { Link } from 'react-router-dom';
const Aicode =()=>{
    return(
        <>
            <div className='p-5 md:flex md:justify-between bg-white rounded-md my-2'>
                <div>
                    <div className='mb-1 text-gray-400 font-medium'>
                        <Link to="/" className="flex items-center">
                                <HiOutlineChevronLeft className="me-1" /> Back to dashboard
                        </Link>
                    </div>
                    <h2 className='text-4xl font-bold'>AI Code Generator</h2>
                    <p className='text-slate-400'>
                        Generate high quality code in seconds.
                    </p>
                </div>
            </div>
            <div className="grid md:grid-cols-1 lg:grid-cols-2 gap-0 pt-5 md:p-5 bg-white rounded">
                <div className="pt-1 pb-10 px-6">
                    <div className="shadow-md rounded-lg px-2 py-2 mb-4">
                        <h3>Remaining Credits</h3>
                        <div className='w-full mt-1'>
                            <ul className='sm:flex'>
                                <li className="w-auto md:w-1/2 mb-2 md:mb-0">
                                    <div className="h-2 bg-blue-600 md:rounded-s-full rounded-full md:rounded-none mb-1"></div>
                                    <div className="flex items-center">
                                        <div className="h-2 w-2 rounded-full bg-blue-600 me-2"></div> 
                                        <div className="pe-4">
                                            <span className='text-slate-500'>Words</span> 2,999,119
                                        </div>
                                    </div>
                                </li>
                                <li className="w-auto md:w-1/2">
                                    <div className="h-2 bg-blue-200 md:rounded-e-full rounded-full md:rounded-none mb-1"></div>
                                    <div className="flex items-center">
                                        <div className="h-2 w-2 rounded-full bg-blue-200 me-2"></div> 
                                        <div><span className='text-slate-500'>Images</span> 2,999,996</div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="bg-[#F3E8FF] p-4 rounded-md">
                        <div>
                            <div className="mb-4">
                                <div className="mb-2 block">
                                    <Label value="Describe What Kind of Code You Need" />
                                </div>
                                <textarea placeholder="Describe What Kind of Code You Need" rows="10" className="bg-white w-full border-1 border-slate-300 rounded-md" ></textarea>
                            </div>
                            <div className="mb-4">
                                <div className="mb-2 block">
                                    <Label value="Coding Language (Java, PHP etc.)" />
                                </div>
                                <input  type="text" placeholder="Coding Language (Java, PHP etc.)" className="bg-white w-full border-1 border-slate-300 rounded-md" />
                            </div>
                            <div className="py-4 flex justify-center">
                                <button className="bg-blue-600 hover:bg-blue-500 text-white px-5 py-2 flex items-center justify-center rounded-full cursor-pointer hover:-translate-y-1 duration-300 hover:shadow-xl w-full">
                                    Generate
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="lg:border-s border-h pt-1 pb-10 px-6">
                    <div className="py-2 border-b flex gap-1">
                        <div className='p-1 hover:bg-gray-300 rounded-sm cursor-pointer'>
                            <HiOutlineDocumentDuplicate className='text-xl' title='Copy to clipboard' />
                        </div>
                        <div className='p-1 hover:bg-gray-300 rounded-sm cursor-pointer'>
                            <HiOutlineMinusCircle className='text-xl text-red-700' title='Delete' />
                        </div>
                    </div>
                    <div className='mt-6 p-4 bg-[#F3E8FF] rounded-xl'>
                        . . . . 
                    </div>
                </div>
            </div>
        </>
    )
}
export default Aicode;