import { 
    HiOutlineChevronLeft,
    HiOutlineStar,
} from "react-icons/hi";
import { Tabs } from 'flowbite-react';
import { Link } from "react-router-dom";

const Aichat =()=>{
    return(
        <>
            <div className='p-5 md:flex md:justify-between bg-white rounded-md my-2'>
                <div>
                    <div className='mb-1 text-slate-400'>
                        <Link to="/" className="flex items-center">
                                <HiOutlineChevronLeft className="me-1" /> Back to dashboard
                        </Link>
                    </div>
                    <h2 className='text-4xl font-bold mb-4'>AI Chat</h2>
                </div> 
            </div>

            <div className="bg-white rounded-md p-2">
                <Tabs aria-label="Tabs with icons" style="underline">
                    <Tabs.Item active title="All">
                        <div class='grid sm:grid-cols-2 xl:grid-cols-4 gap-0 p-0'>
                            <div className='group border-t border-b border-s border-e py-5'>
                                <div className="ms-4 mb-4">
                                    <div className='h-8 w-8 bg-white rounded-full flex items-center justify-center  shadow-md'>
                                        <HiOutlineStar className='text-slate-500'  />
                                    </div>
                                </div>
                                <Link to="/user/ai-chat-bot">
                                    <div className="flex justify-center">
                                        <div className="h-32 w-32 bg-slate-200 border-8 border-white rounded-full flex items-center justify-center shadow-md group-hover:shadow-xl duration-300">
                                            <h2 className="text-3xl font-bold text-slate-600 uppercase">dcb</h2>
                                        </div>
                                    </div>
                                    <div className="text-center mt-5">
                                        <h3 className='text-xl font-medium text-slate-700'>Default AI Chat Bot</h3>
                                        <p className='text-slate-500'>Default</p>
                                    </div>
                                </Link>
                            </div>
                            <div className='group border-t border-b border-e py-5'>
                                <div className="ms-4 mb-4">
                                    <div className='h-8 w-8 bg-white rounded-full flex items-center justify-center  shadow-md'>
                                        <HiOutlineStar className='text-slate-500'  />
                                    </div>
                                </div>
                                <div className="flex justify-center">
                                    <div className="h-32 w-32 bg-slate-200 border-8 border-white rounded-full flex items-center justify-center shadow-md group-hover:shadow-xl duration-300">
                                        <h2 className="text-3xl font-bold text-slate-600 uppercase">fe</h2>
                                    </div>
                                </div>
                                <div className="text-center mt-5">
                                    <h3 className='text-xl font-medium text-slate-700'>Finance Expert</h3>
                                    <p className='text-slate-500'>Personal Finance Expert</p>
                                </div>
                            </div>
                            <div className='group border-t border-b border-e py-5'>
                                <div className="ms-4 mb-4">
                                    <div className='h-8 w-8 bg-white rounded-full flex items-center justify-center  shadow-md'>
                                        <HiOutlineStar className='text-slate-500'  />
                                    </div>
                                </div>
                                <div className="flex justify-center">
                                    <div className="h-32 w-32 bg-slate-200 border-8 border-white rounded-full flex items-center justify-center shadow-md group-hover:shadow-xl duration-300">
                                        <h2 className="text-3xl font-bold text-slate-600 uppercase">n</h2>
                                    </div>
                                </div>
                                <div className="text-center mt-5">
                                    <h3 className='text-xl font-medium text-slate-700'>Nutritionist</h3>
                                    <p className='text-slate-500'>Personal Nutritionist</p>
                                </div>
                            </div>
                            <div className='group border-t border-b border-e py-5'>
                                <div className="ms-4 mb-4">
                                    <div className='h-8 w-8 bg-white rounded-full flex items-center justify-center  shadow-md'>
                                        <HiOutlineStar className='text-slate-500'  />
                                    </div>
                                </div>
                                <div className="flex justify-center">
                                    <div className="h-32 w-32 bg-slate-200 border-8 border-white rounded-full flex items-center justify-center shadow-md group-hover:shadow-xl duration-300">
                                        <h2 className="text-3xl font-bold text-slate-600 uppercase">cc</h2>
                                    </div>
                                </div>
                                <div className="text-center mt-5">
                                    <h3 className='text-xl font-medium text-slate-700'>Career Counselor</h3>
                                    <p className='text-slate-500'>Personal Career Counselor</p>
                                </div>
                            </div>
                            <div className='group border-t border-b border-s border-e py-5'>
                                <div className="ms-4 mb-4">
                                    <div className='h-8 w-8 bg-white rounded-full flex items-center justify-center  shadow-md'>
                                        <HiOutlineStar className='text-slate-500'  />
                                    </div>
                                </div>
                                <div className="flex justify-center">
                                    <div className="h-32 w-32 bg-slate-200 border-8 border-white rounded-full flex items-center justify-center shadow-md group-hover:shadow-xl duration-300">
                                        <h2 className="text-3xl font-bold text-slate-600 uppercase">tmc</h2>
                                    </div>
                                </div>
                                <div className="text-center mt-5">
                                    <h3 className='text-xl font-medium text-slate-700'>Time Management Consultant</h3>
                                    <p className='text-slate-500'>Personal Time Management Consultant</p>
                                </div>
                            </div>
                            <div className='group border-t border-b border-e py-5'>
                                <div className="ms-4 mb-4">
                                    <div className='h-8 w-8 bg-white rounded-full flex items-center justify-center  shadow-md'>
                                        <HiOutlineStar className='text-slate-500'  />
                                    </div>
                                </div>
                                <div className="flex justify-center">
                                    <div className="h-32 w-32 bg-slate-200 border-8 border-white rounded-full flex items-center justify-center shadow-md group-hover:shadow-xl duration-300">
                                        <h2 className="text-3xl font-bold text-slate-600 uppercase">lt</h2>
                                    </div>
                                </div>
                                <div className="text-center mt-5">
                                    <h3 className='text-xl font-medium text-slate-700'>Language Tutor</h3>
                                    <p className='text-slate-500'>Personal Language Tutor</p>
                                </div>
                            </div>
                            <div className='group border-t border-b border-e py-5'>
                                <div className="ms-4 mb-4">
                                    <div className='h-8 w-8 bg-white rounded-full flex items-center justify-center  shadow-md'>
                                        <HiOutlineStar className='text-slate-500'  />
                                    </div>
                                </div>
                                <div className="flex justify-center">
                                    <div className="h-32 w-32 bg-slate-200 border-8 border-white rounded-full flex items-center justify-center shadow-md group-hover:shadow-xl duration-300">
                                        <h2 className="text-3xl font-bold text-slate-600 uppercase">ce</h2>
                                    </div>
                                </div>
                                <div className="text-center mt-5">
                                    <h3 className='text-xl font-medium text-slate-700'>Cybersecurity Expert</h3>
                                    <p className='text-slate-500'>Cybersecurity Expert</p>
                                </div>
                            </div>
                            <div className='group border-t border-b border-e py-5'>
                                <div className="ms-4 mb-4">
                                    <div className='h-8 w-8 bg-white rounded-full flex items-center justify-center  shadow-md'>
                                        <HiOutlineStar className='text-slate-500'  />
                                    </div>
                                </div>
                                <div className="flex justify-center">
                                    <div className="h-32 w-32 bg-slate-200 border-8 border-white rounded-full flex items-center justify-center shadow-md group-hover:shadow-xl duration-300">
                                        <h2 className="text-3xl font-bold text-slate-600 uppercase">id</h2>
                                    </div>
                                </div>
                                <div className="text-center mt-5">
                                    <h3 className='text-xl font-medium text-slate-700'>Interior Designer</h3>
                                    <p className='text-slate-500'>Personal Interior Designer</p>
                                </div>
                            </div>
                        </div>
                    </Tabs.Item>
                    <Tabs.Item title="Favorites">
                        <h3 className="font-bold text-2xl p-5">Favorites</h3>
                    </Tabs.Item>
                </Tabs>
            </div>


        </>
    )
}
export default Aichat;