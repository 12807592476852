import { Link } from "react-router-dom";
import { 
    HiPlusSm,
    HiOutlineChevronLeft,
    HiOutlineInformationCircle,
} from "react-icons/hi";
import { Label } from 'flowbite-react';

const BlogAddUpdate =()=>{
    return(
        <>
            <div className='p-5 md:flex md:justify-between bg-white rounded-md my-2'>
                <div>
                    <div className='mb-1 text-gray-400 font-medium'>
                        <Link to="/" className="flex items-center">
                                <HiOutlineChevronLeft className="me-1" /> Back to dashboard
                        </Link>
                    </div>
                    <h2 className='text-4xl font-bold'>Add or Edit Post</h2>
                </div>
            </div>            
            <div className="p-5 mt-4 bg-white rounded-md mb-2">
                <div className="lg:grid grid-cols-5 gap-4">
                    <div className="mb-2 rounded-md pe-2 py-4 col-span-3">
                        <div className='mb-4'>
                            <div className="mb-2 flex items-center relative">
                                <Label value="Post Title" className="text-slate-500" />
                                <span className='cursor-pointer group text-slate-500'>
                                    <HiOutlineInformationCircle className='ms-1' />
                                    <span className='absolute bottom-2 left-0 bg-slate-200 text-slate-600 p-2 rounded-md border text-xs invisible group-hover:visible group-hover:-translate-y-6 translate-y-4 duration-300 opacity-0 group-hover:opacity-100'>
                                        Add a post title.
                                    </span>
                                </span>
                            </div>
                            <input type="text" class="bg-white w-full border-1 border-slate-300 rounded-md" />
                        </div>
                        <div className='mb-4'>
                            <div className="mb-2 flex items-center relative">
                                <Label value="Content" className="text-slate-500" />
                                <span className='cursor-pointer group text-slate-500'>
                                    <HiOutlineInformationCircle className='ms-1' />
                                    <span className='absolute bottom-2 left-0 bg-slate-200 text-slate-600 p-2 rounded-md border text-xs invisible group-hover:visible group-hover:-translate-y-6 translate-y-4 duration-300 opacity-0 group-hover:opacity-100'>
                                        A short description of what this chat template can help with.
                                    </span>
                                </span>
                            </div>
                            <div>React Text Editor</div>
                        </div>
                    </div>
                    <div className="col-span-2">
                        <div className="mb-2 rounded-md px-2 py-4">
                            <div className='mb-4'>
                                <div className="mb-2 flex items-center relative">
                                    <Label value="Post Image" className="text-slate-500" />
                                </div>
                                <input class="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:outline-none" type="file" />
                            </div>
                            <div className='mb-4'>
                                <div className="mb-2 flex items-center relative">
                                    <Label value="Post Status" className="text-slate-500" />
                                </div>
                                <select  class="bg-white w-full border-1 border-slate-300 rounded-md">
                                    <option>Select</option>
                                </select>
                            </div>
                            <div className='mb-4'>
                                <div className="mb-2 flex items-center relative">
                                    <Label value="Category" className="text-slate-500" />
                                    <span className='cursor-pointer group text-slate-500'>
                                        <HiOutlineInformationCircle className='ms-1' />
                                        <span className='absolute bottom-2 left-0 bg-slate-200 text-slate-600 p-2 rounded-md border text-xs invisible group-hover:visible group-hover:-translate-y-6 translate-y-4 duration-300 opacity-0 group-hover:opacity-100'>
                                            Categories of the post. Useful for filtering in the blog posts.
                                        </span>
                                    </span>
                                </div>
                                <select id="years" size="5" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                </select>
                                <div>
                                    <small>
                                        Hold cmd(on mac) or ctrl(on pc) to select multiple items.
                                    </small>
                                </div>
                            </div>
                            <div className='mb-4'>
                                <button class='bg-blue-600 hover:bg-blue-500 rounded-md py-2 ps-2 pe-4 text-white flex font-medium hover:-translate-y-2 duration-300 hover:drop-shadow-lg'>
                                    <HiPlusSm className="mr-1 h-5 w-5" /> Add New
                                </button>
                            </div>
                            <div className='mb-4'>
                                <div className="mb-2 flex items-center relative">
                                    <Label value="Tag" className="text-slate-500" />
                                    <span className='cursor-pointer group text-slate-500'>
                                        <HiOutlineInformationCircle className='ms-1' />
                                        <span className='absolute bottom-2 left-0 bg-slate-200 text-slate-600 p-2 rounded-md border text-xs invisible group-hover:visible group-hover:-translate-y-6 translate-y-4 duration-300 opacity-0 group-hover:opacity-100'>
                                            Tags of the post. Useful for filtering in the blog posts.
                                        </span>
                                    </span>
                                </div>
                                <select id="years" size="5" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                </select>
                                <div>
                                    <small>
                                        Hold cmd(on mac) or ctrl(on pc) to select multiple items.
                                    </small>
                                </div>
                            </div>
                            <div className='mb-4'>
                                <button class='bg-blue-600 hover:bg-blue-500 rounded-md py-2 ps-2 pe-4 text-white flex font-medium hover:-translate-y-2 duration-300 hover:drop-shadow-lg'>
                                    <HiPlusSm className="mr-1 h-5 w-5" /> Add New
                                </button>
                            </div>
                            <h3 className="text-3xl font-bold mt-8 mb-4">SEO</h3>
                            <div className='mb-4'>
                                <div className="mb-2 flex items-center relative">
                                    <Label value="SEO Title" className="text-slate-500" />
                                    <span className='cursor-pointer group text-slate-500'>
                                        <HiOutlineInformationCircle className='ms-1' />
                                        <span className='absolute bottom-2 left-0 bg-slate-200 text-slate-600 p-2 rounded-md border text-xs invisible group-hover:visible group-hover:-translate-y-6 translate-y-4 duration-300 opacity-0 group-hover:opacity-100'>
                                            If you will leave empty: using the post title for the SEO
                                        </span>
                                    </span>
                                </div>
                                <input type="text" class="bg-white w-full border-1 border-slate-300 rounded-md" />
                            </div>
                            <div className='mb-4'>
                                <div className="mb-2 flex items-center relative">
                                    <Label value="Slug" className="text-slate-500" />
                                    <span className='cursor-pointer group text-slate-500'>
                                        <HiOutlineInformationCircle className='ms-1' />
                                        <span className='absolute bottom-2 left-0 bg-slate-200 text-slate-600 p-2 rounded-md border text-xs invisible group-hover:visible group-hover:-translate-y-6 translate-y-4 duration-300 opacity-0 group-hover:opacity-100'>
                                            Add Slug for SEO. Example: my-post
                                        </span>
                                    </span>
                                </div>
                                <input type="text" class="bg-white w-full border-1 border-slate-300 rounded-md" />
                            </div>
                            <div className='mb-4'>
                                <div className="mb-2 flex items-center relative">
                                    <Label value="SEO Description" className="text-slate-500" />
                                    <span className='cursor-pointer group text-slate-500'>
                                        <HiOutlineInformationCircle className='ms-1' />
                                        <span className='absolute bottom-2 left-0 bg-slate-200 text-slate-600 p-2 rounded-md border text-xs invisible group-hover:visible group-hover:-translate-y-6 translate-y-4 duration-300 opacity-0 group-hover:opacity-100'>
                                            A short description of what this chat template can help with for SEO
                                        </span>
                                    </span>
                                </div>
                                <textarea rows={3} className="bg-white w-full border-1 border-slate-300 rounded-md"></textarea>
                            </div>
                            <div>
                                <button className="bg-blue-600 hover:bg-blue-500 rounded-full py-2 px-10 text-white flex justify-center font-medium hover:-translate-y-2 duration-300 hover:drop-shadow-lg">Save</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BlogAddUpdate;