const Footer =()=>{
    return(
        <>
            <div className='px-5 bg-primary fixed bottom-0 w-full z-50 text-white md:flex justify-between'>
                <div>Copyright © 2024 Hamari.AI. All rights reserved.</div>
                <div>Version: 5.1.1</div>
            </div>
        </>
    )
}
export default Footer;