import { Avatar, Dropdown, Navbar } from 'flowbite-react';
import logo from '../../../images/hamariAI-logo.svg';
import { 
    HiOutlineSearch, 
    HiOutlineChevronDown,
    HiOutlineViewGrid,
    HiOutlineDocument,
    HiOutlinePhotograph,
    HiOutlineDocumentText,
    HiOutlineEye,
    HiOutlineDesktopComputer,
    HiOutlineDocumentDuplicate,
    HiOutlinePencil,
    HiOutlineMail,
    HiOutlineAdjustments,
    HiOutlineChatAlt2,
    HiOutlineVideoCamera,
    HiRss,
} from "react-icons/hi";
import { LuLandmark, LuFileEdit } from "react-icons/lu";
import { AiOutlineYoutube } from "react-icons/ai";
import { SiGoogleadsense } from "react-icons/si";
import { TbTemplate } from "react-icons/tb";
import { Link } from 'react-router-dom';
import React, { useState } from "react";
const Topnavbar =()=>{
    const [template, setTemplate] = useState(false);
    const [chat, setchat] = useState(false);
    const [frontend, setFrontend] = useState(false); 
    const [setting, setSetting] = useState(false);
    return(
        <>
           <div className='px-1 pb-2 bg-white md:bg-primary fixed top-0 right-0 w-full z-10'>
                <div className="md:ms-64">
                    <Navbar className='bg-transparent py-0'>
                        <Navbar.Brand className='md:hidden'>
                            <Navbar.Toggle />
                            <Link to="/">
                                <img src={logo} className="ml-3 h-6" alt="Hamari AI" />
                            </Link>
                        </Navbar.Brand>
                        <Navbar.Collapse className='md:hidden relative w-full overflow-y-auto h-72 md:h-0'>
                            <div className='uppercase my-2 ps-3 py-2 bg-slate-100 font-medium'>User</div>
                            <ul>
                                <li>
                                    <div className={`mb-1 font-medium text-slate-500 hover:bg-red-50 border-s-4 border-transparent hover:border-s-4 rounded-none hover:border-red-600 cursor-pointer ps-2 py-1`}>
                                        <Link to="/" className={`flex items-center gap-3.5 group`}>
                                            <div className={`w-8 h-8 rounded-full bg-red-200 flex justify-center items-center p-2`}>
                                                <HiOutlineViewGrid className={`text-lg text-red-500`}/>
                                            </div>
                                            <div className={`whitespace-pre duration-500`}>
                                                Dashboard
                                            </div>
                                        </Link>
                                    </div>
                                </li>
                                <li>
                                    <div className={`mb-1 group font-medium text-slate-500 hover:bg-purple-100 border-s-4 border-transparent hover:border-s-4 rounded-none hover:border-purple-600 cursor-pointer ps-2 py-1`}>
                                        <Link to="user/documents" className={`flex items-center gap-3.5`}>
                                            <div className={`w-8 h-8 rounded-full bg-purple-200 flex justify-center items-center p-2`}>
                                                <HiOutlineDocument className={`text-lg text-purple-600`}/>
                                            </div>
                                            <div className={`whitespace-pre duration-500 `}>
                                                Document
                                            </div>
                                        </Link>
                                    </div>
                                </li>
                                <li>
                                    <div className={`mb-1 group font-medium text-slate-500 hover:bg-red-50 border-s-4 border-transparent hover:border-s-4 rounded-none hover:border-red-600 cursor-pointer ps-2 py-1`}>
                                        <Link to="user/ai-writer" className={`flex items-center gap-3.5`}>
                                            <div className={`w-8 h-8 rounded-full bg-red-200 flex justify-center items-center p-2`}>
                                                <HiOutlineDocumentText className={`text-lg text-red-500`}/>
                                            </div>
                                            <div className={`whitespace-pre duration-500`}>
                                                Writer
                                            </div>
                                        </Link>
                                    </div>
                                </li>
                                <li>
                                    <div className={`mb-1 group font-medium text-slate-500 hover:bg-yellow-100/50 border-s-4 border-transparent hover:border-s-4 rounded-none hover:border-yellow-400 cursor-pointer ps-2 py-1`}>
                                        <Link to="user/ai-image" className={`flex items-center gap-3.5`}>
                                            <div className={`w-8 h-8 rounded-full bg-yellow-200/50 flex justify-center items-center p-2`}>
                                                <HiOutlinePhotograph className={`text-lg text-yellow-500`}/>
                                            </div>
                                            <div className={`whitespace-pre duration-500`}>
                                                Image
                                            </div>
                                        </Link>
                                    </div>
                                </li>
                                <li>
                                    <div className={`mb-1 group font-medium text-slate-500 hover:bg-purple-100/50 border-s-4 border-transparent hover:border-s-4 rounded-none hover:border-purple-400 cursor-pointer ps-2 py-1`}>
                                        <Link to="user/ai-video" className={`flex items-center gap-3.5`}>
                                            <div className={`w-8 h-8 rounded-full bg-purple-200 flex justify-center items-center p-2`}>
                                                <HiOutlineVideoCamera className={`text-lg text-purple-500`}/>
                                            </div>
                                            <div className={`whitespace-pre duration-500`}>
                                                Video
                                            </div>
                                            {/* <div className={`${open && "hidden"} absolute left-48 bg-white font-medium whitespace-pre text-slate-500 rounded-md drop-shadow-lg px-0 py-0 w-0 overflow-hidden group-hover:px-2 group-hover:py-1 group-hover:left-16 group-hover:duration-300 group-hover:w-fit  `}>
                                                AI Image
                                            </div> */}
                                        </Link>
                                    </div>
                                </li>
                                <li>
                                    <div className={`mb-1 group font-medium text-slate-500 hover:bg-lime-100/50 border-s-4 border-transparent hover:border-s-4 rounded-none hover:border-lime-400 cursor-pointer ps-2 py-1`}>
                                        <Link to="user/ai-article-wizard" className={`flex items-center gap-3.5`}>
                                            <div className={`w-8 h-8 rounded-full bg-lime-200/50 flex justify-center items-center p-2`}>
                                                <HiOutlineDocumentText className={`text-lg text-lime-600`}/>
                                            </div>
                                            <div className={`whitespace-pre duration-500`}>
                                                Article Wizard
                                            </div>
                                        </Link>
                                    </div>
                                </li>
                                <li>
                                    <div className={`mb-1 group font-medium text-slate-500 hover:bg-emerald-100/50 border-s-4 border-transparent hover:border-s-4 rounded-none hover:border-emerald-500 cursor-pointer ps-2 py-1`}>
                                        <Link to="user/ai-pdf" className={`flex items-center gap-3.5`}>
                                            <div className={`w-8 h-8 rounded-full bg-emerald-100 flex justify-center items-center p-2`}>
                                                <LuFileEdit className={`text-lg text-emerald-600`}/>
                                            </div>
                                            <div className={`whitespace-pre duration-500`}>
                                                File Chat 
                                            </div>
                                        </Link>
                                    </div>
                                </li>
                                <li>
                                    <div className={`mb-1 group font-medium text-slate-500 hover:bg-purple-100 border-s-4 border-transparent hover:border-s-4 rounded-none hover:border-purple-600 cursor-pointer ps-2 py-1`}>
                                        <Link to="user/ai-vision" className={`flex items-center gap-3.5`}>
                                            <div className={`w-8 h-8 rounded-full bg-purple-200 flex justify-center items-center p-2`}>
                                                <HiOutlineEye className={`text-lg text-purple-600`}/>
                                            </div>
                                            <div className={`whitespace-pre duration-500`}>
                                                Vision
                                            </div>
                                        </Link>
                                    </div>
                                </li>
                                <li>
                                    <div className={`mb-1 group font-medium text-slate-500 hover:bg-[#F7C9FF]/25 border-s-4 border-transparent hover:border-s-4 rounded-none hover:border-[#A376AB] cursor-pointer ps-2 py-1`}>
                                        <Link to="user/ai-rewriter" className={`flex items-center gap-3.5`}>
                                            <div className={`w-8 h-8 rounded-full bg-[#F7C9FF] flex justify-center items-center p-2`}>
                                                <HiOutlinePencil className={`text-lg text-[#A376AB]`}/>
                                            </div>
                                            <div className={`whitespace-pre duration-500`}>
                                                AI ReWriter
                                            </div>
                                        </Link>
                                    </div>
                                </li>
                                <li>
                                    <div className={`mb-1 group font-medium text-slate-500 hover:bg-red-50 border-s-4 border-transparent hover:border-s-4 rounded-none hover:border-red-600 cursor-pointer ps-2 py-1`}>
                                        <Link to="user/chat-image" className={`flex items-center gap-3.5`}>
                                            <div className={`w-8 h-8 rounded-full bg-red-200 flex justify-center items-center p-2`}>
                                                <HiOutlinePhotograph className={`text-lg text-red-500`}/>
                                            </div>
                                            <div className={`whitespace-pre duration-500`}>
                                                Chat Image
                                            </div>
                                        </Link>
                                    </div>
                                </li>
                                <li>
                                    <div className={`mb-1 group font-medium text-slate-500 hover:bg-lime-100/50 border-s-4 border-transparent hover:border-s-4 rounded-none hover:border-lime-400 cursor-pointer ps-2 py-1`}>
                                        <Link to="user/ai-chat" className={`flex items-center gap-3.5`}>
                                            <div className={`w-8 h-8 rounded-full bg-lime-200/50 flex justify-center items-center p-2`}>
                                                <HiOutlineEye className={`text-lg text-lime-600`}/>
                                            </div>
                                            <div className={`whitespace-pre duration-500`}>
                                                Chat
                                            </div>
                                        </Link>
                                    </div>
                                </li>
                                <li>
                                    <div className={`mb-1 group font-medium text-slate-500 hover:bg-purple-100 border-s-4 border-transparent hover:border-s-4 rounded-none hover:border-purple-600 cursor-pointer ps-2 py-1`}>
                                        <Link to="user/ai-code" className={`flex items-center gap-3.5`}>
                                            <div className={`w-8 h-8 rounded-full bg-purple-200 flex justify-center items-center p-2`}>
                                                <HiOutlineEye className={`text-lg text-purple-600`}/>
                                            </div>
                                            <div className={`whitespace-pre duration-500`}>
                                                Code
                                            </div>
                                        </Link>
                                    </div>
                                </li>
                                <li>
                                    <div className={`mb-1 group font-medium text-slate-500 hover:bg-emerald-100/50 border-s-4 border-transparent hover:border-s-4 rounded-none hover:border-emerald-500 cursor-pointer ps-2 py-1`}>
                                        <Link to="user/ai-youtube" className={`flex items-center gap-3.5`}>
                                            <div className={`w-8 h-8 rounded-full bg-emerald-100 flex justify-center items-center p-2`}>
                                                <AiOutlineYoutube className={`text-lg text-emerald-600`}/>
                                            </div>
                                            <div className={`whitespace-pre duration-500`}>
                                                YouTube
                                            </div>
                                        </Link>
                                    </div>
                                </li>
                                <li>
                                    <div className={`mb-1 group font-medium text-slate-500 hover:bg-yellow-100/50 border-s-4 border-transparent hover:border-s-4 rounded-none hover:border-yellow-400 cursor-pointer ps-2 py-1`}>
                                        <Link to="user/ai-rss" className={`flex items-center gap-3.5`}>
                                            <div className={`w-8 h-8 rounded-full bg-yellow-200/50 flex justify-center items-center p-2`}>
                                                <HiRss className={`text-lg text-yellow-500`}/>
                                            </div>
                                            <div className={`whitespace-pre duration-500`}>
                                                AI RSS
                                            </div>
                                        </Link>
                                    </div>
                                </li>
                                <li>
                                    <div className={`mb-1 group font-medium text-slate-500 hover:bg-red-50 border-s-4 border-transparent hover:border-s-4 rounded-none hover:border-red-600 cursor-pointer ps-2 py-1`}>
                                        <Link to="user/ai-speech-to-text" className={`flex items-center gap-3.5`}>
                                            <div className={`w-8 h-8 rounded-full bg-red-200 flex justify-center items-center p-2`}>
                                                <HiOutlineDocumentText className={`text-lg text-red-500`}/>
                                            </div>
                                            <div className={`whitespace-pre duration-500`}>
                                                Speech to Text
                                            </div>
                                        </Link>
                                    </div>
                                </li>
                                <li>
                                    <div className={`mb-1 group font-medium text-slate-500 hover:bg-yellow-100/50 border-s-4 border-transparent hover:border-s-4 rounded-none hover:border-yellow-400 cursor-pointer ps-2 py-1`}>
                                        <Link to="user/ai-voiceover" className={`flex items-center gap-3.5`}>
                                            <div className={`w-8 h-8 rounded-full bg-yellow-200/50 flex justify-center items-center p-2`}>
                                                <HiOutlinePhotograph className={`text-lg text-yellow-500`}/>
                                            </div>
                                            <div className={`whitespace-pre duration-500`}>
                                                Voiceover
                                            </div>
                                        </Link>
                                    </div>
                                </li>
                                <li title="Brand Voice">
                                    <div className={`mb-1 group font-medium text-slate-500 hover:bg-lime-100/50 border-s-4 border-transparent hover:border-s-4 rounded-none hover:border-lime-400 cursor-pointer ps-2 py-1`}>
                                        <Link to="user/brand-voice" className={`flex items-center gap-3.5`}>
                                            <div className={`w-8 h-8 rounded-full bg-lime-200/50 flex justify-center items-center p-2`}>
                                                <HiOutlineEye className={`text-lg text-lime-600`}/>
                                            </div>
                                            <div className={`whitespace-pre duration-500`}>
                                                Brand Voice
                                            </div>
                                        </Link>
                                    </div>
                                </li>
                            </ul>
                            <div className="h-[2px] bg-gray-100 my-5"></div>
                            <div className='text-lg p-1 mb-2 uppercase font-semibold text-slate-500'>
                                Admin
                            </div>
                            <ul>
                                <li>
                                    <div className={`mb-1 font-medium text-slate-500 hover:bg-red-50 border-s-4 border-transparent hover:border-s-4 rounded-none hover:border-red-600 cursor-pointer ps-2 py-1`}>
                                        <Link to='admin/dashboard' className={`flex items-center gap-3.5 group`}>
                                            <div className={`w-8 h-8 rounded-full bg-red-200 flex justify-center items-center p-2`}>
                                                <HiOutlineViewGrid className={`text-lg text-red-500`}/>
                                            </div>
                                            <div className={`whitespace-pre duration-500`}>
                                                Dashboard
                                            </div>
                                        </Link>
                                    </div>
                                </li>
                                <li title="Marketplace">
                                    <div className={`mb-1 group font-medium text-slate-500 hover:bg-emerald-100/50 border-s-4 border-transparent hover:border-s-4 rounded-none hover:border-emerald-500 cursor-pointer ps-2 py-1`}>
                                        <Link to='admin/marketplace' className={`flex items-center gap-3.5`}>
                                            <div className={`w-8 h-8 rounded-full bg-emerald-100 flex justify-center items-center p-2`}>
                                                <LuLandmark className={`text-lg text-emerald-600`}/>
                                            </div>
                                            <div className={`whitespace-pre duration-500`}>
                                                Marketplace
                                            </div>
                                        </Link>
                                    </div>
                                </li>
                                <li>
                                    <div className={`mb-1 group font-medium text-slate-500 hover:bg-purple-100 border-s-4 border-transparent hover:border-s-4 rounded-none hover:border-purple-600 cursor-pointer ps-2 py-1`}>
                                        <Link to='admin/user-management' className={`flex items-center gap-3.5`}>
                                            <div className={`w-8 h-8 rounded-full bg-purple-200 flex justify-center items-center p-2`}>
                                                <HiOutlineDocument className={`text-lg text-purple-600`}/>
                                            </div>
                                            <div className={`whitespace-pre duration-500`}>
                                                User Management
                                            </div>
                                        </Link>
                                    </div>
                                </li>
                                <li title="Google Adsense">
                                    <div className={`mb-1 group font-medium text-slate-500 hover:bg-lime-100/50 border-s-4 border-transparent hover:border-s-4 rounded-none hover:border-lime-400 cursor-pointer ps-2 py-1`}>
                                        <Link to="admin/googleAdsense/google-adsense" className={`flex items-center gap-3.5`}>
                                            <div className={`w-8 h-8 rounded-full bg-lime-200/50 flex justify-center items-center p-2`}>
                                                <SiGoogleadsense className={`text-lg text-lime-600`}/>
                                            </div>
                                            <div className={`whitespace-pre duration-500`}>
                                                Google Adsense
                                            </div>
                                        </Link>
                                    </div>
                                </li>
                                <li>
                                    <div className={`mb-1 font-medium text-slate-500 hover:bg-red-50 border-s-4 border-transparent hover:border-s-4 rounded-none hover:border-red-600 cursor-pointer ps-2 py-1 duration-300 transition-all flex items-center justify-between`} onClick={() => setTemplate(!template)}>
                                        <Link className={`flex items-center gap-3.5 group`}>
                                            <div className={`w-8 h-8 rounded-full bg-red-200 flex justify-center items-center p-2`}>
                                                <TbTemplate className={`text-lg text-red-500`}/>
                                            </div>
                                            <div className={`whitespace-pre duration-500`}>
                                                Templates
                                            </div>
                                            {/* <div className={`${open && "hidden"} absolute left-48 bg-white font-medium whitespace-pre text-slate-500 rounded-md drop-shadow-lg px-0 py-0 w-0 overflow-hidden group-hover:px-2 group-hover:py-1 group-hover:left-16 group-hover:duration-300 group-hover:w-fit`}>
                                                Templates
                                            </div> */}
                                        </Link>
                                        <div className="mr-1">
                                            <HiOutlineChevronDown />
                                        </div>
                                    </div>
                                    <div>
                                        {template && 
                                            <div className="bg-slate-50 rounded-md p-3"> 
                                                <ul>
                                                    <li className="mb-1 py-1 px-2 border-s-4 border-transparent hover:bg-slate-100 hover:border-s-4 hover:border-slate-400">
                                                        <Link to="admin/built-in-templates">
                                                            Built-in Templates
                                                        </Link>
                                                    </li>
                                                    <li className="mb-1 py-1 px-2 border-s-4 border-transparent hover:bg-slate-100 hover:border-s-4 hover:border-slate-400">
                                                        <Link to="admin/custom-templates">
                                                            Custom Templates
                                                        </Link>
                                                    </li>
                                                    <li className="mb-1 py-1 px-2 border-s-4 border-transparent hover:bg-slate-100 hover:border-s-4 hover:border-slate-400">
                                                        <Link to="admin/ai-writer-categories">
                                                            Writer Categories
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        }
                                    </div>
                                </li>
                                <li>
                                    <div className={`mb-1 font-medium text-slate-500 hover:bg-slate-50 border-s-4 border-transparent hover:border-s-4 rounded-none hover:border-slate-600 cursor-pointer ps-2 py-1 duration-300 transition-all flex items-center justify-between`} onClick={() => setchat(!chat)}>
                                        <Link className={`flex items-center gap-3.5 group`}>
                                            <div className={`w-8 h-8 rounded-full bg-slate-200 flex justify-center items-center p-2`}>
                                                <HiOutlineChatAlt2 className={`text-lg text-slate-500`}/>
                                            </div>
                                            <div className={`whitespace-pre duration-500`}>
                                                Chat Settings
                                            </div>
                                            {/* <div className={`${open && "hidden"} absolute left-48 bg-white font-medium whitespace-pre text-slate-500 rounded-md drop-shadow-lg px-0 py-0 w-0 overflow-hidden group-hover:px-2 group-hover:py-1 group-hover:left-16 group-hover:duration-300 group-hover:w-fit`}>
                                                Templates
                                            </div> */}
                                        </Link>
                                        <div className="mr-1">
                                            <HiOutlineChevronDown />
                                        </div>
                                    </div>
                                    <div>
                                        {chat && 
                                            <div className="bg-slate-50 rounded-md p-3"> 
                                                <ul>
                                                    <li className="mb-1 py-1 px-2 border-s-4 border-transparent hover:bg-slate-100 hover:border-s-4 hover:border-slate-400">
                                                        <Link to="admin/chat-categories">
                                                            Chat Categories
                                                        </Link>
                                                    </li>
                                                    <li className="mb-1 py-1 px-2 border-s-4 border-transparent hover:bg-slate-100 hover:border-s-4 hover:border-slate-400">
                                                        <Link to="admin/chat-templates">
                                                            Chat Templates
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        }
                                    </div>
                                </li>
                                <li>
                                    <div className={`mb-1 group font-medium text-slate-500 hover:bg-yellow-100/50 border-s-4 border-transparent hover:border-s-4 rounded-none hover:border-yellow-400 cursor-pointer ps-2 py-1 duration-300 transition-all flex items-center justify-between`} onClick={() => setFrontend(!frontend)}>
                                        <Link className={`flex items-center gap-3.5 group`}>
                                            <div className={`w-8 h-8 rounded-full bg-yellow-200/50 flex justify-center items-center p-2`}>
                                                <HiOutlineDesktopComputer className={`text-lg text-yellow-500`}/>
                                            </div>
                                            <div className={`whitespace-pre duration-500`}>
                                                Frontend
                                            </div>
                                        </Link>
                                        <div className="mr-1">
                                            <HiOutlineChevronDown />
                                        </div>
                                    </div>
                                    <div>
                                        {frontend && 
                                            <div className="bg-slate-50 rounded-md p-3"> 
                                                <ul>
                                                    <li className="mb-1 py-1 px-2 border-s-4 border-transparent hover:bg-slate-100 hover:border-s-4 hover:border-slate-400">
                                                        <Link to='admin/frontend-settings'>
                                                            Frontend Settings
                                                        </Link>
                                                    </li>
                                                    <li className="mb-1 py-1 px-2 border-s-4 border-transparent hover:bg-slate-100 hover:border-s-4 hover:border-slate-400">
                                                        <Link to='admin/frontend-section-settings'>
                                                            Frontend Section Settings
                                                        </Link>
                                                    </li>
                                                    <li className="mb-1 py-1 px-2 border-s-4 border-transparent hover:bg-slate-100 hover:border-s-4 hover:border-slate-400">
                                                        <Link to='admin/menu'>
                                                            Menu
                                                        </Link>
                                                    </li>
                                                    <li className="mb-1 py-1 px-2 border-s-4 border-transparent hover:bg-slate-100 hover:border-s-4 hover:border-slate-400">
                                                        <Link to='admin/faq'>
                                                            F.A.Q
                                                        </Link>
                                                    </li>
                                                    <li className="mb-1 py-1 px-2 border-s-4 border-transparent hover:bg-slate-100 hover:border-s-4 hover:border-slate-400">
                                                        <Link to='admin/tools'>
                                                            Tools Section
                                                        </Link>
                                                    </li>
                                                    <li className="mb-1 py-1 px-2 border-s-4 border-transparent hover:bg-slate-100 hover:border-s-4 hover:border-slate-400">
                                                        <Link to='admin/features'>
                                                            Features Section
                                                        </Link>
                                                    </li>
                                                    <li className="mb-1 py-1 px-2 border-s-4 border-transparent hover:bg-slate-100 hover:border-s-4 hover:border-slate-400">
                                                        <Link to='admin/testimonials'>
                                                            Testimonials Section
                                                        </Link>
                                                    </li>
                                                    <li className="mb-1 py-1 px-2 border-s-4 border-transparent hover:bg-slate-100 hover:border-s-4 hover:border-slate-400">
                                                        <Link to='admin/clients'>
                                                            Clients Section
                                                        </Link>
                                                    </li>
                                                    <li className="mb-1 py-1 px-2 border-s-4 border-transparent hover:bg-slate-100 hover:border-s-4 hover:border-slate-400">
                                                        <Link to='admin/how-it-works'>
                                                            How it Works Section
                                                        </Link>
                                                    </li>
                                                    <li className="mb-1 py-1 px-2 border-s-4 border-transparent hover:bg-slate-100 hover:border-s-4 hover:border-slate-400">
                                                        <Link to='admin/who-can-use-section'>
                                                            Who Can Use Section
                                                        </Link>
                                                    </li>
                                                    <li className="mb-1 py-1 px-2 border-s-4 border-transparent hover:bg-slate-100 hover:border-s-4 hover:border-slate-400">
                                                        <Link to='admin/generators-list'>
                                                            Generators List Section
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        }
                                    </div>
                                </li>
                                <li>
                                    <div className={`mb-1 group font-medium text-slate-500 hover:bg-lime-100/50 border-s-4 border-transparent hover:border-s-4 rounded-none hover:border-lime-400 cursor-pointer ps-2 py-1`}>
                                        <Link to='admin/pages' className={`flex items-center gap-3.5`}>
                                            <div className={`w-8 h-8 rounded-full bg-lime-200/50 flex justify-center items-center p-2`}>
                                                <HiOutlineDocumentDuplicate className={`text-lg text-lime-600`}/>
                                            </div>
                                            <div className={`whitespace-pre duration-500`}>
                                                Pages
                                            </div>
                                        </Link>
                                    </div>
                                </li>
                                <li>
                                    <div className={`mb-1 group font-medium text-slate-500 hover:bg-emerald-100/50 border-s-4 border-transparent hover:border-s-4 rounded-none hover:border-emerald-500 cursor-pointer ps-2 py-1`}>
                                        <Link to='admin/blog-posts' className={`flex items-center gap-3.5`}>
                                            <div className={`w-8 h-8 rounded-full bg-emerald-100 flex justify-center items-center p-2`}>
                                                <HiOutlinePencil className={`text-lg text-emerald-600`}/>
                                            </div>
                                            <div className={`whitespace-pre duration-500`}>
                                                Blog
                                            </div>
                                        </Link>
                                    </div>
                                </li>
                                <li>
                                    <div className={`mb-1 group font-medium text-slate-500 hover:bg-purple-100 border-s-4 border-transparent hover:border-s-4 rounded-none hover:border-purple-600 cursor-pointer ps-2 py-1`}>
                                        <Link to='admin/email-templates' className={`flex items-center gap-3.5`}>
                                            <div className={`w-8 h-8 rounded-full bg-purple-200 flex justify-center items-center p-2`}>
                                                <HiOutlineMail className={`text-lg text-purple-600`}/>
                                            </div>
                                            <div className={`whitespace-pre duration-500`}>
                                                Email Templates
                                            </div>
                                        </Link>
                                    </div>
                                </li>
                                <li>
                                    <div className={`mb-1 group font-medium text-slate-500 hover:bg-lime-100/50 border-s-4 border-transparent hover:border-s-4 rounded-none hover:border-lime-400 cursor-pointer ps-2 py-1 flex items-center justify-between`} onClick={() => setSetting(!setting)}>
                                        <Link className={`flex items-center gap-3.5`}>
                                            <div className={`w-8 h-8 rounded-full bg-lime-200/50 flex justify-center items-center p-2`}>
                                                <HiOutlineAdjustments className={`text-lg text-lime-600`}/>
                                            </div>
                                            <div className={`whitespace-pre duration-500`}>
                                                Setting
                                            </div>
                                        </Link>
                                        <div className="mr-1">
                                            <HiOutlineChevronDown />
                                        </div>
                                    </div>
                                    <div>
                                        {setting && 
                                            <div className="bg-slate-50 rounded-md p-3"> 
                                                <ul>
                                                    <li className="mb-1 py-1 px-2 border-s-4 border-transparent hover:bg-slate-100 hover:border-s-4 hover:border-slate-400">
                                                        <Link to='admin/general'>
                                                            General
                                                        </Link>
                                                    </li>
                                                    <li className="mb-1 py-1 px-2 border-s-4 border-transparent hover:bg-slate-100 hover:border-s-4 hover:border-slate-400">
                                                        <Link to='admin/invoice'>
                                                            Invoice
                                                        </Link>
                                                    </li>
                                                    <li className="mb-1 py-1 px-2 border-s-4 border-transparent hover:bg-slate-100 hover:border-s-4 hover:border-slate-400">
                                                        <Link to='admin/openai'>
                                                            OpenAI
                                                        </Link>
                                                    </li>
                                                    <li className="mb-1 py-1 px-2 border-s-4 border-transparent hover:bg-slate-100 hover:border-s-4 hover:border-slate-400">
                                                        <Link to='admin/stablediffusion'>
                                                            StableDiffusion
                                                        </Link>
                                                    </li>
                                                    <li className="mb-1 py-1 px-2 border-s-4 border-transparent hover:bg-slate-100 hover:border-s-4 hover:border-slate-400">
                                                        <Link to='admin/unsplashapi'>
                                                            Unsplash API
                                                        </Link>
                                                    </li>
                                                    <li className="mb-1 py-1 px-2 border-s-4 border-transparent hover:bg-slate-100 hover:border-s-4 hover:border-slate-400">
                                                        <Link to='admin/serperapi'>
                                                            Serper API
                                                        </Link>
                                                    </li>
                                                    <li className="mb-1 py-1 px-2 border-s-4 border-transparent hover:bg-slate-100 hover:border-s-4 hover:border-slate-400">
                                                        <Link to='admin/tts'>
                                                            TTS
                                                        </Link>
                                                    </li>
                                                    <li className="mb-1 py-1 px-2 border-s-4 border-transparent hover:bg-slate-100 hover:border-s-4 hover:border-slate-400">
                                                        <Link to='admin/affiliate'>
                                                            Affiliate
                                                        </Link>
                                                    </li>
                                                    <li className="mb-1 py-1 px-2 border-s-4 border-transparent hover:bg-slate-100 hover:border-s-4 hover:border-slate-400">
                                                        <Link to='admin/smtp'>
                                                            SMTP
                                                        </Link>
                                                    </li>
                                                    <li className="mb-1 py-1 px-2 border-s-4 border-transparent hover:bg-slate-100 hover:border-s-4 hover:border-slate-400">
                                                        <Link to='admin/gdpr'>
                                                            GDPR
                                                        </Link>
                                                    </li>
                                                    <li className="mb-1 py-1 px-2 border-s-4 border-transparent hover:bg-slate-100 hover:border-s-4 hover:border-slate-400">
                                                        <Link to='admin/privacy'>
                                                            Privacy Policy and Terms
                                                        </Link>
                                                    </li>
                                                    <li className="mb-1 py-1 px-2 border-s-4 border-transparent hover:bg-slate-100 hover:border-s-4 hover:border-slate-400">
                                                        <Link to='admin/languages'>
                                                            Languages
                                                        </Link>
                                                    </li>
                                                    <li className="mb-1 py-1 px-2 border-s-4 border-transparent hover:bg-slate-100 hover:border-s-4 hover:border-slate-400">
                                                        <Link to='admin/storage'>
                                                            Storage
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        }
                                    </div>
                                </li>
                            </ul>
                        </Navbar.Collapse>
                        <div className="relative my-2 md:my-auto lg:mb-0 rounded-md shadow-sm bg-white w-full md:w-auto lg:w-2/4">
                            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                <span className="text-gray-500 sm:text-sm"><HiOutlineSearch /></span>
                            </div>
                            <input
                            type="text"
                            className="block w-full rounded-md border-0 py-1.5 pl-8 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 h-12"
                            placeholder="Search for templates and documents..."
                            />
                        </div>
                        <div className="flex items-center gap-x-6">
                            <div>
                                <label className="md:text-white font-semibold text-lg">AI Model</label>
                                <Dropdown label="" dismissOnClick={false} renderTrigger={() => 
                                    <span className='flex items-center gap-2 bg-red-100 text-red-600 cursor-pointer rounded-md py-1 px-2'> 
                                        <span>
                                            <span className="block text-sm font-bold">All GPT</span>
                                        </span> <HiOutlineChevronDown />
                                    </span>
                                    }>
                                    <Dropdown.Item>Chat GPT</Dropdown.Item>
                                    <Dropdown.Item>Gemini GPT</Dropdown.Item>
                                </Dropdown>
                            </div>
                            <div className="cursor-pointer">
                                <Dropdown label="" dismissOnClick={false} renderTrigger={() => 
                                <span className='flex items-center gap-x-3 md:text-white'>
                                    <div>
                                        <Avatar alt="User settings" img="https://flowbite.com/docs/images/people/profile-picture-5.jpg" className="border-2 border-white/50" />
                                    </div>
                                    <div>
                                        <span className="flex">
                                            <span className="block text-sm font-bold">User Name</span>
                                            <HiOutlineChevronDown /> 
                                        </span> 
                                        <div className="md:text-white font-medium">Admin</div>
                                    </div>
                                     
                                </span> 
                                }>
                                <Dropdown.Header>
                                    <span className="block text-sm">Admin Admin</span>
                                    <span className="block truncate text-sm font-medium">admin@gmail.com</span>
                                </Dropdown.Header>
                                    <Link to="user/payment">
                                        <Dropdown.Item>Plan</Dropdown.Item>
                                    </Link>
                                    <Link to="user/orders">
                                        <Dropdown.Item>Order</Dropdown.Item>
                                    </Link>
                                    <Link to="user/settings">
                                        <Dropdown.Item>Settings</Dropdown.Item>
                                    </Link>
                                    <Dropdown.Divider />
                                    <Dropdown.Item>Logout</Dropdown.Item>
                            </Dropdown>
                            </div>
                        </div>
                    </Navbar>
                </div>
            </div>              
        </>
    )
}
export default Topnavbar;