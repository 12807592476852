import { Link } from "react-router-dom";
import { 
    HiPlusSm,
    HiOutlineChevronLeft,
    HiOutlineUpload,
    HiOutlineInformationCircle,
} from "react-icons/hi";
import { Label } from 'flowbite-react';

const PageAddUpdate =()=>{
    return(
        <>
            <div className='p-5 md:flex md:justify-between bg-white rounded-md my-2'>
                <div>
                    <div className='mb-1 text-gray-400 font-medium'>
                        <Link to="/" className="flex items-center">
                                <HiOutlineChevronLeft className="me-1" /> Back to dashboard
                        </Link>
                    </div>
                    <h2 className='text-4xl font-bold'>Add or Edit Page</h2>
                </div>
                <div className='flex items-center gap-3'>
                    <Link to="">
                        <button class='bg-white hover:bg-gray-50/50 rounded-md py-2 px-4 flex font-medium hover:-translate-y-2 duration-300 border hover:shadow-lg'>
                            <HiOutlineUpload className="mr-2 h-5 w-5" /> My Documents
                        </button>
                    </Link>
                    <Link to="">
                        <button class='bg-blue-600 hover:bg-blue-500 rounded-md py-2 px-4 text-white flex font-medium hover:-translate-y-2 duration-300 hover:drop-shadow-lg'>
                            <HiPlusSm className="mr-1 h-5 w-5" /> New
                        </button>
                    </Link>
                </div>
            </div>
            <div className="p-5 mt-4 bg-white rounded-md mb-2">
                <div className="flex items-center relative mb-3">
                    <div>
                        <label class="inline-flex items-center cursor-pointer mt-1">
                            <input type="checkbox" value="" class="sr-only peer" />
                            <div class="relative w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                            <span class="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">
                                Page Status
                            </span>
                        </label>
                    </div>
                    <div>
                        <span className='cursor-pointer group'>
                            <HiOutlineInformationCircle className='ms-1 -mt-1 text-slate-500' />
                            <span className='absolute bottom-2 left-0 bg-slate-200 text-slate-600 p-2 rounded-md border text-xs invisible group-hover:visible group-hover:-translate-y-6 translate-y-4 duration-300 opacity-0 group-hover:opacity-100'>
                                You can disable or enable this page. When this option is disabled, the page cannot be accessible to users.
                            </span>
                        </span>
                    </div>
                </div>
                <div className="flex items-center relative mb-3">
                    <div>
                        <label class="inline-flex items-center cursor-pointer mt-1">
                            <input type="checkbox" value="" class="sr-only peer" />
                            <div class="relative w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                            <span class="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">
                                Titlebar Status
                            </span>
                        </label>
                    </div>
                </div>
                <div className='mb-4'>
                    <div className="mb-2 flex items-center relative">
                        <Label value="Page Title" className="text-slate-500" />
                        <span className='cursor-pointer group text-slate-500'>
                            <HiOutlineInformationCircle className='ms-1' />
                            <span className='absolute bottom-2 left-0 bg-slate-200 text-slate-600 p-2 rounded-md border text-xs invisible group-hover:visible group-hover:-translate-y-6 translate-y-4 duration-300 opacity-0 group-hover:opacity-100'>
                                Add a page title. Example: Privacy Policy.
                            </span>
                        </span>
                    </div>
                    <input type="text" class="bg-white w-full border-1 border-slate-300 rounded-md" />
                </div>
                <div className='mb-4'>
                    <div className="mb-2 flex items-center relative">
                        <Label value="Slug" className="text-slate-500" />
                        <span className='cursor-pointer group text-slate-500'>
                            <HiOutlineInformationCircle className='ms-1' />
                            <span className='absolute bottom-2 left-0 bg-slate-200 text-slate-600 p-2 rounded-md border text-xs invisible group-hover:visible group-hover:-translate-y-6 translate-y-4 duration-300 opacity-0 group-hover:opacity-100'>
                                Add Slug for SEO. Example: privaciy-policy
                            </span>
                        </span>
                    </div>
                    <input type="text" class="bg-white w-full border-1 border-slate-300 rounded-md" />
                </div>
                <div className='mb-4'>
                    <div className="mb-2 flex items-center relative">
                        <Label value="Content" className="text-slate-500" />
                        <span className='cursor-pointer group text-slate-500'>
                            <HiOutlineInformationCircle className='ms-1' />
                            <span className='absolute bottom-2 left-0 bg-slate-200 text-slate-600 p-2 rounded-md border text-xs invisible group-hover:visible group-hover:-translate-y-6 translate-y-4 duration-300 opacity-0 group-hover:opacity-100'>
                                A short description of what this chat template can help with.
                            </span>
                        </span>
                    </div>
                    <div>React Text Editor</div>
                </div>
                <div className="py-4 flex justify-start items-center">
                    <button className="bg-blue-600 hover:bg-blue-500 rounded-full py-2 px-10 text-white flex justify-center font-medium hover:-translate-y-2 duration-300 hover:drop-shadow-lg">
                        Save
                    </button>
                </div>
            </div>
        </>
    )
}

export default PageAddUpdate;