
import { 
    HiOutlineChevronLeft,
    HiOutlineInformationCircle,
} from "react-icons/hi";
import { Link } from "react-router-dom";
import { Label } from 'flowbite-react';

const FloatingChat =()=>{
    return(
        <>
            <div className='p-5 md:flex md:justify-between bg-white rounded-md my-2'>
                <div>
                    <div className='mb-1 text-gray-400 font-medium'>
                        <Link to="/" className="flex items-center">
                                <HiOutlineChevronLeft className="me-1" /> Back to dashboard
                        </Link>
                    </div>
                    <h2 className='text-4xl font-bold mb-3 md:mb-0'>Floating Chat Settings</h2>
                </div>
            </div>
            <div className='p-5 bg-white rounded-md mb-2'>
                <div className='grid md:grid-cols-12'>
                    <div className='md:col-span-6 md:col-start-4'>
                        <div class=''>
                            <form className="flex flex-col">
                                <div className='mb-4'>
                                    <div className="mb-2 block">
                                        <Label value="Show ChatBot Ballon on" className="text-slate-500" />
                                    </div>
                                    <select class="bg-white w-full border-1 border-slate-300 rounded-md">
                                        <option>Select</option>
                                    </select>
                                </div>
                                <div className='mb-4'>
                                    <div className="mb-2 block">
                                        <Label value="Template" className="text-slate-500" />
                                    </div>
                                    <select class="bg-white w-full border-1 border-slate-300 rounded-md">
                                        <option>Select</option>
                                    </select>
                                </div>
                                <div className='mb-4'>
                                    <div className="mb-2 block">
                                        <Label value="Position" className="text-slate-500" />
                                    </div>
                                    <select class="bg-white w-full border-1 border-slate-300 rounded-md">
                                        <option>Select</option>
                                    </select>
                                </div>
                                <div className='mb-4'>
                                    <div className="mb-2 block">
                                        <Label value="Message Limit per day" className="text-slate-500" />
                                    </div>
                                    <input type="number" class="bg-white w-full border-1 border-slate-300 rounded-md" min="2" max="50" placeholder="10" />
                                </div>
                                <div className='mb-4'>
                                    <div className="mb-2 block">
                                        <Label value="First Message" className="text-slate-500" />
                                    </div>
                                    <input type="text" class="bg-white w-full border-1 border-slate-300 rounded-md" />
                                </div>
                                <div className='mb-4'>
                                    <div className="mb-2 flex items-center relative">
                                        <Label value="Instructions" className="text-slate-500" />
                                        <span className='cursor-pointer group text-slate-500'>
                                            <HiOutlineInformationCircle className='ms-1' />
                                            <span className='absolute bottom-2 left-0 bg-slate-200 text-slate-600 p-2 rounded-md border text-xs invisible group-hover:visible group-hover:-translate-y-6 translate-y-4 duration-300 opacity-0 group-hover:opacity-100'>
                                                You can provide instructions to your GPT-3 model to ensure it aligns with your brand and tone.
                                            </span>
                                        </span>
                                    </div>
                                    <textarea class="bg-white w-full border-1 border-slate-300 rounded-md" rows={4} />
                                </div>
                                <div className='mb-1'>
                                    <label class="inline-flex items-center cursor-pointer mt-1">
                                        <input type="checkbox" value="" class="sr-only peer" />
                                        <div class="relative w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                                        <span class="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">Disable if user not logged in?</span>
                                    </label>
                                </div>
                                <div className='mb-3'>
                                    <label class="inline-flex items-center cursor-pointer mt-1">
                                        <input type="checkbox" value="" class="sr-only peer" />
                                        <div class="relative w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                                        <span class="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">Show timestamp?</span>
                                    </label>
                                </div>                                
                                <div className="py-4 flex justify-center items-center">
                                    <button className="bg-blue-600 hover:bg-blue-500 rounded-full  px-4 py-2 text-white flex justify-center font-medium hover:-translate-y-2 duration-300 hover:drop-shadow-lg w-full">
                                        Save
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default FloatingChat;