
import { Link } from "react-router-dom";
import { 
    HiOutlineChevronLeft,
    HiOutlineInformationCircle,
} from "react-icons/hi";
import { Label, TextInput, Textarea, ToggleSwitch } from 'flowbite-react';
import { useState } from 'react';
const Gdpr =()=>{
    const [switch1, setSwitch1] = useState(false);
    return(
        <>
            <div className='p-5 md:flex md:justify-between bg-white rounded-md my-2'>
                <div>
                    <div className='mb-1 text-slate-400'>
                        <Link to="/admin/dashboard" className="flex items-center">
                                <HiOutlineChevronLeft className="me-1" /> Back to dashboard
                        </Link>
                    </div>
                    <h2 className='text-4xl font-bold'>GDPR Settings</h2>
                </div> 
            </div>
            <div className='p-5 bg-white rounded-md mb-2'>
                <div className='grid md:grid-cols-12'>
                    <div className='md:col-span-6 md:col-start-4'>
                        <div class=''>
                            <div className="mb-3">
                                <ToggleSwitch checked={switch1} label="Enable GDPR Alert Box" onChange={setSwitch1} />
                            </div>
                            <div>
                                <form className="flex flex-col">
                                    <div className='mb-4'>
                                        <div className="mb-2 flex items-center relative">
                                            <Label value="Button" className="text-slate-500" />
                                            <span className='cursor-pointer group text-slate-500'>
                                                <HiOutlineInformationCircle className='ms-1' />
                                                <span className='absolute bottom-2 left-0 bg-slate-200 text-slate-600 p-2 rounded-md border text-xs invisible group-hover:visible group-hover:-translate-y-6 translate-y-4 duration-300 opacity-0 group-hover:opacity-100'>
                                                    Accept button text
                                                </span>
                                            </span>
                                        </div>
                                        <TextInput type="text" placeholder="Accept" />
                                    </div>
                                    <div className='mb-4'>
                                        <div className="mb-2 flex items-center relative">
                                            <Label value="Content" className="text-slate-500" />
                                            <span className='cursor-pointer group text-slate-500'>
                                                <HiOutlineInformationCircle className='ms-1' />
                                                <span className='absolute bottom-2 left-0 bg-slate-200 text-slate-600 p-2 rounded-md border text-xs invisible group-hover:visible group-hover:-translate-y-6 translate-y-4 duration-300 opacity-0 group-hover:opacity-100'>
                                                    GDPR alert text. You can use HTML tags.
                                                </span>
                                            </span>
                                        </div>
                                        <Textarea placeholder="This website uses cookies to improve your web experience." rows={4} />
                                    </div>
                                    <div className="mb-4 mt-4 flex justify-center">
                                        <button className="bg-blue-600 hover:bg-blue-500 rounded-full py-2 px-4 text-white flex justify-center font-medium hover:-translate-y-2 duration-300 hover:drop-shadow-lg w-full">
                                            Save
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Gdpr;