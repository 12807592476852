import { Link } from "react-router-dom";
import { 
    HiOutlineChevronLeft,
} from "react-icons/hi";
import { Label, TextInput } from 'flowbite-react';
const Smtp =()=>{
    return(
        <>
            <div className='p-5 md:flex md:justify-between bg-white rounded-md my-2'>
                <div>
                    <div className='mb-1 text-slate-400'>
                        <Link to="/admin/dashboard" className="flex items-center">
                                <HiOutlineChevronLeft className="me-1" /> Back to dashboard
                        </Link>
                    </div>
                    <h2 className='text-4xl font-bold'>SMTP Settings</h2>
                </div> 
            </div>
            <div className="p-5 bg-white rounded-md">
                <div className='grid md:grid-cols-2 gap-x-10 lg:gap-x-20'>
                    <div>
                        <h3 className='text-2xl font-medium text-slate-700 mb-5'>SMTP Settings</h3>
                        <div>
                            <form className="flex flex-col">
                                <div className='mb-4'>
                                    <div className="mb-2 block">
                                        <Label value="SMTP Host" className="text-slate-500" />
                                    </div>
                                    <TextInput type="text" />
                                </div>
                                <div className='mb-4'>
                                    <div className="mb-2 block">
                                        <Label value="SMTP Port" className="text-slate-500" />
                                    </div>
                                    <TextInput type="text" />
                                </div>
                                <div className='mb-4'>
                                    <div className="mb-2 block">
                                        <Label value="SMTP Username" className="text-slate-500" />
                                    </div>
                                    <TextInput type="text" />
                                </div>
                                <div className='mb-4'>
                                    <div className="mb-2 block">
                                        <Label value="SMTP Password" className="text-slate-500" />
                                    </div>
                                    <TextInput type="text" />
                                </div>
                                <div className='mb-4'>
                                    <div className="mb-2 block">
                                        <Label value="SMTP Sender Email" className="text-slate-500" />
                                    </div>
                                    <TextInput type="text" />
                                </div>
                                <div className='mb-4'>
                                    <div className="mb-2 block">
                                        <Label value="SMTP Sender Name" className="text-slate-500" />
                                    </div>
                                    <TextInput type="text" />
                                </div>
                                <div className='mb-4'>
                                    <div className="mb-2 block">
                                        <Label value="SMTP Encryption" className="text-slate-500" />
                                    </div>
                                    <TextInput type="text" />
                                </div>
                                <div className="mb-4 mt-4 flex justify-center">
                                    <button className="bg-blue-600 hover:bg-blue-500 rounded-full py-2 px-4 text-white flex justify-center font-medium hover:-translate-y-2 duration-300 hover:drop-shadow-lg w-full">
                                        Save
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div>
                        <h3 className='text-2xl font-medium text-slate-700 mb-5'>SMTP Test</h3>
                        <div>
                            <form className="flex flex-col">
                                <div className='mb-4'>
                                    <div className="mb-2 block">
                                        <Label value="Test Email" className="text-slate-500" />
                                    </div>
                                    <TextInput type="text" />
                                </div>
                                <div className="mb-4 mt-4 flex justify-center">
                                    <button className="bg-blue-600 hover:bg-blue-500 rounded-full py-2 px-4 text-white flex justify-center font-medium hover:-translate-y-2 duration-300 hover:drop-shadow-lg w-full">
                                        Save
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Smtp;