import { 
    Card,
    Tabs,
    TextInput,
    Button,
    Accordion,
    Select,
    Label,
    FileInput,
    Modal
} from 'flowbite-react';
import { 
    HiOutlineArrowSmRight,
    HiPlus,
    HiOutlineEye,
    HiOutlineDocumentDownload,
    HiOutlineTrash,
    HiOutlineChevronLeft,
    HiPlusSm
} from "react-icons/hi";
import cat from '../../../images/cat.png';
import { Link } from 'react-router-dom';
import { useState } from 'react';
const AiImage =()=>{
    const [openNewfolder, setNewfolder] = useState(false); 
    return(
        <>
            <div className='p-5 md:flex md:justify-between bg-white rounded-md my-2'>
                <div>
                    <div className='mb-1 text-gray-400 font-medium'>
                        <Link to="/" className="flex items-center">
                                <HiOutlineChevronLeft className="me-1" /> Back to dashboard
                        </Link>
                    </div>
                    <h2 className='text-4xl font-bold'>AI Image Generator</h2>
                    <p className='text-slate-400'>Create stunning images in seconds.</p>
                </div>
                {/* <div className='flex items-center gap-3'>
                <button class='bg-blue-600 hover:bg-blue-500 rounded-md py-2 px-4 text-white flex font-medium hover:-translate-y-2 duration-300 hover:drop-shadow-lg'>
                        <HiPlusSm className="mr-2 h-5 w-5" /> New Folder
                    </button>
                </div> */}
            </div>
            <div className="rounded-md">
                <Card className="bg-[#F3E8FF] shadow-none ">
                    <div className='flex flex-wrap justify-between relative'>
                        <div className='max-sm:-order-1 max-sm:mb-4 max-sm:w-full max-lg:mb-4  lg:absolute top-0 right-0'>
                            <ul className='sm:flex'>
                                <li className='flex items-center h-8 border-b-4 border-blue-600'>
                                    <div className="h-2 w-2 rounded-full bg-blue-600 me-2"></div> 
                                    <div className="pe-4"><span className='text-slate-500'>Words</span> 
                                        2,999,119
                                    </div>
                                </li>
                                <li className='flex items-center h-8 border-b-4 border-blue-200'>
                                    <div className="h-2 w-2 rounded-full bg-blue-200 me-2"></div> 
                                    <div><span className='text-slate-500'>Images</span> 2,999,996</div>
                                </li>
                            </ul>
                        </div>
                        <div className='flex flex-col flex-auto'>
                            <div className='flex gap-4 mb-4'>
                                <div className="bg-[#BC87F6] px-4 py-2 rounded-md text-white uppercase mb-2 font-semibold cursor-pointer">
                                    Dall-e
                                </div>
                                <div className="px-4 py-2 rounded-md uppercase mb-2 font-semibold cursor-pointer">
                                    Stable Diffusion
                                </div>
                            </div>
                            <div>
                                <h2 className="font-medium text-2xl">
                                    Explain your idea. | <span>Generate example prompt</span>
                                </h2>
                                <div className="mt-5 relative">
                                    <TextInput type="text" sizing="lg" placeholder='Painting of a flower vase on a kitchen table with a window in the backdrop.' />
                                    <button className="absolute right-2 top-2 flex items-center text-white bg-[#BC87F6] px-6 py-2 rounded-full hover:scale-105 duration-300 hover:shadow-xl">
                                    <span className="font-medium">Generate</span>      
                                        <span>
                                            <HiOutlineArrowSmRight className='text-xl ms-1 font-medium' />
                                        </span>
                                    </button>
                                </div>
                            </div>
                            <div className="mt-5">
                                <Accordion collapseAll className="border-none border-transparent">
                                    <Accordion.Panel className="border-none border-transparent">
                                        <Accordion.Title className="w-48 text-sm bg-transparent hover:bg-transparent focus:ring-0" >Advanced Settings</Accordion.Title>
                                        <Accordion.Content>
                                            <div className="sm:grid sm:grid-cols-3 xl:grid-cols-6 gap-2">
                                                <div>
                                                    <div className="max-w-md">
                                                        <div className="mb-2 block">
                                                            <Label value="Image resolution" />
                                                        </div>
                                                        <Select required>
                                                            <option>1024 x 1024</option>
                                                            <option>1024 x 1792</option>
                                                            <option>1792 x 1024</option>
                                                        </Select>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="max-w-md">
                                                        <div className="mb-2 block">
                                                            <Label value="Art Style" />
                                                        </div>
                                                        <Select required>
                                                            <option>None</option>
                                                        </Select>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="max-w-md">
                                                        <div className="mb-2 block">
                                                            <Label value="Lightning Style" />
                                                        </div>
                                                        <Select required>
                                                            <option>None</option>
                                                        </Select>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="max-w-md">
                                                        <div className="mb-2 block">
                                                            <Label value="Mood" />
                                                        </div>
                                                        <Select required>
                                                            <option>None</option>
                                                        </Select>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="max-w-md">
                                                        <div className="mb-2 block">
                                                            <Label value="Number of Images" />
                                                        </div>
                                                        <Select required>
                                                            <option>1</option>
                                                        </Select>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="max-w-md">
                                                        <div className="mb-2 block">
                                                            <Label value="Quality of Images" />
                                                        </div>
                                                        <Select required>
                                                            <option>Standard</option>
                                                            <option>HD</option>
                                                        </Select>
                                                    </div>
                                                </div>
                                            </div>
                                        </Accordion.Content>
                                    </Accordion.Panel>
                                </Accordion>
                            </div>

                            {/* STABLE DIFFUSION */}

                            {/* <div className="mt-5">
                                <div className='flex gap-4 mb-4 rounded-full bg-white'>
                                    <div className="bg-[#BC87F6] px-4 py-2 rounded-full text-white uppercase font-semibold cursor-pointer">
                                        Text-to-Image
                                    </div>
                                    <div className="px-4 py-2 rounded-md uppercase font-semibold cursor-pointer">
                                        Image-to-Image
                                    </div>
                                    <div className="px-4 py-2 rounded-md uppercase font-semibold cursor-pointer">
                                        Upscaling
                                    </div>
                                    <div className="px-4 py-2 rounded-md uppercase font-semibold cursor-pointer">
                                        Multi-Prompting
                                    </div>
                                </div>
                            </div> */}

                            {/* <Tabs aria-label="Pills" style="pills" className="mt-5">
                                <Tabs.Item active title="DALL-E" >
                                    <div>
                                        <h2 className="font-medium text-2xl">
                                            Explain your idea. | <span>Generate example prompt</span>
                                        </h2>
                                        <div className="mt-5 relative">
                                            <TextInput type="text" sizing="lg" placeholder='Painting of a flower vase on a kitchen table with a window in the backdrop.' />
                                            <button className="absolute right-2 top-2 flex items-center text-white bg-[#BC87F6] px-6 py-2 rounded-full hover:scale-105 duration-300 hover:shadow-xl">
                                            <span className="font-medium">Generate</span>      
                                                <span>
                                                    <HiOutlineArrowSmRight className='text-xl ms-1 font-medium' />
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="mt-5">
                                        <Accordion collapseAll className="border-none border-transparent">
                                            <Accordion.Panel className="border-none border-transparent">
                                                <Accordion.Title className="w-48 text-sm bg-transparent hover:bg-transparent focus:ring-0" >Advanced Settings</Accordion.Title>
                                                <Accordion.Content>
                                                    <div className="sm:grid sm:grid-cols-3 xl:grid-cols-6 gap-2">
                                                        <div>
                                                            <div className="max-w-md">
                                                                <div className="mb-2 block">
                                                                    <Label value="Image resolution" />
                                                                </div>
                                                                <Select required>
                                                                    <option>1024 x 1024</option>
                                                                    <option>1024 x 1792</option>
                                                                    <option>1792 x 1024</option>
                                                                </Select>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div className="max-w-md">
                                                                <div className="mb-2 block">
                                                                    <Label value="Art Style" />
                                                                </div>
                                                                <Select required>
                                                                    <option>None</option>
                                                                </Select>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div className="max-w-md">
                                                                <div className="mb-2 block">
                                                                    <Label value="Lightning Style" />
                                                                </div>
                                                                <Select required>
                                                                    <option>None</option>
                                                                </Select>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div className="max-w-md">
                                                                <div className="mb-2 block">
                                                                    <Label value="Mood" />
                                                                </div>
                                                                <Select required>
                                                                    <option>None</option>
                                                                </Select>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div className="max-w-md">
                                                                <div className="mb-2 block">
                                                                    <Label value="Number of Images" />
                                                                </div>
                                                                <Select required>
                                                                    <option>1</option>
                                                                </Select>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div className="max-w-md">
                                                                <div className="mb-2 block">
                                                                    <Label value="Quality of Images" />
                                                                </div>
                                                                <Select required>
                                                                    <option>Standard</option>
                                                                    <option>HD</option>
                                                                </Select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Accordion.Content>
                                            </Accordion.Panel>
                                        </Accordion>
                                    </div>
                                </Tabs.Item>
                                <Tabs.Item title="Stable Diffusion">
                                    <Tabs aria-label="Pills" style="pills">
                                        <Tabs.Item active title="Text-to-Image">
                                            <div className="mt-2">
                                                <Accordion collapseAll className="border-none border-transparent">
                                                    <Accordion.Panel className="border-none border-transparent">
                                                        <Accordion.Title className="w-48 text-sm bg-transparent hover:bg-transparent focus:ring-0" >Advanced Settings</Accordion.Title>
                                                        <Accordion.Content>
                                                            <div className="sm:grid sm:grid-cols-3 xl:grid-cols-6 gap-2">
                                                                <div>
                                                                    <div className="max-w-md">
                                                                        <div className="mb-2 block">
                                                                            <Label value="Image resolution" />
                                                                        </div>
                                                                        <Select required>
                                                                            <option>1024 x 1024</option>
                                                                            <option>1024 x 1792</option>
                                                                            <option>1792 x 1024</option>
                                                                        </Select>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <div className="max-w-md">
                                                                        <div className="mb-2 block">
                                                                            <Label value="Art Style" />
                                                                        </div>
                                                                        <Select required>
                                                                            <option>None</option>
                                                                        </Select>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <div className="max-w-md">
                                                                        <div className="mb-2 block">
                                                                            <Label value="Lightning Style" />
                                                                        </div>
                                                                        <Select required>
                                                                            <option>None</option>
                                                                        </Select>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <div className="max-w-md">
                                                                        <div className="mb-2 block">
                                                                            <Label value="Mood" />
                                                                        </div>
                                                                        <Select required>
                                                                            <option>None</option>
                                                                        </Select>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <div className="max-w-md">
                                                                        <div className="mb-2 block">
                                                                            <Label value="Number of Images" />
                                                                        </div>
                                                                        <Select required>
                                                                            <option>1</option>
                                                                        </Select>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <div className="max-w-md">
                                                                        <div className="mb-2 block">
                                                                            <Label value="Quality of Images" />
                                                                        </div>
                                                                        <Select required>
                                                                            <option>Standard</option>
                                                                            <option>HD</option>
                                                                        </Select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Accordion.Content>
                                                    </Accordion.Panel>
                                                </Accordion>
                                            </div>
                                            <div>
                                                <h2 className="font-medium text-2xl">
                                                    Explain your idea. | <span className="text-green-500 hover:underline cursor-pointer">Generate example prompt</span>
                                                </h2>
                                                <div className="mt-5 relative">
                                                    <TextInput type="text" sizing="lg" className="" placeholder='Painting of a flower vase on a kitchen table with a window in the backdrop.' />
                                                    <Button color="purple" className="absolute right-2 top-2 flex items-center text-white bg-purple-700 px-2 rounded-full hover:scale-105 duration-300 hover:shadow-xl">
                                                    <span className="font-medium">Generate</span>      
                                                        <span>
                                                            <HiOutlineArrowSmRight className='text-xl ms-1 font-medium' />
                                                        </span>
                                                    </Button>
                                                </div>
                                            </div>
                                        </Tabs.Item>
                                        <Tabs.Item title="Image-to-Image">
                                            <div className="mt-2">
                                                <Accordion collapseAll className="border-none border-transparent">
                                                    <Accordion.Panel className="border-none border-transparent">
                                                        <Accordion.Title className="w-48 text-sm bg-transparent hover:bg-transparent focus:ring-0" >Advanced Settings</Accordion.Title>
                                                        <Accordion.Content>
                                                            <div className="sm:grid sm:grid-cols-3 xl:grid-cols-6 gap-2">
                                                                <div>
                                                                    <div className="max-w-md">
                                                                        <div className="mb-2 block">
                                                                            <Label value="Image resolution" />
                                                                        </div>
                                                                        <Select required>
                                                                            <option>1024 x 1024</option>
                                                                            <option>1024 x 1792</option>
                                                                            <option>1792 x 1024</option>
                                                                        </Select>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <div className="max-w-md">
                                                                        <div className="mb-2 block">
                                                                            <Label value="Art Style" />
                                                                        </div>
                                                                        <Select required>
                                                                            <option>None</option>
                                                                        </Select>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <div className="max-w-md">
                                                                        <div className="mb-2 block">
                                                                            <Label value="Lightning Style" />
                                                                        </div>
                                                                        <Select required>
                                                                            <option>None</option>
                                                                        </Select>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <div className="max-w-md">
                                                                        <div className="mb-2 block">
                                                                            <Label value="Mood" />
                                                                        </div>
                                                                        <Select required>
                                                                            <option>None</option>
                                                                        </Select>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <div className="max-w-md">
                                                                        <div className="mb-2 block">
                                                                            <Label value="Number of Images" />
                                                                        </div>
                                                                        <Select required>
                                                                            <option>1</option>
                                                                        </Select>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <div className="max-w-md">
                                                                        <div className="mb-2 block">
                                                                            <Label value="Quality of Images" />
                                                                        </div>
                                                                        <Select required>
                                                                            <option>Standard</option>
                                                                            <option>HD</option>
                                                                        </Select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Accordion.Content>
                                                    </Accordion.Panel>
                                                </Accordion>
                                            </div>
                                            <div>
                                                <h2 className="font-medium text-2xl">
                                                    Explain your idea.
                                                </h2>
                                                <div className="mt-5">
                                                    <TextInput type="text" sizing="lg" className="" placeholder='Painting of a flower vase on a kitchen table with a window in the backdrop.' />
                                                    
                                                </div>
                                            </div>
                                            <div className="mt-5">
                                                <h3 className="text-2xl font-bold">Upload Image</h3>
                                                <div className="flex w-full items-center justify-center mt-4">
                                                    <Label
                                                        htmlFor="dropzone-file"
                                                        className="dark:hover:bg-bray-800 flex h-64 w-full cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-dashed border-gray-300 bg-gray-50 hover:bg-gray-100 dark:border-gray-600 dark:bg-gray-700 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                                                    >
                                                        <div className="flex flex-col items-center justify-center pb-6 pt-5">
                                                        <svg
                                                            className="mb-4 h-8 w-8 text-gray-500 dark:text-gray-400"
                                                            aria-hidden="true"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            fill="none"
                                                            viewBox="0 0 20 16"
                                                        >
                                                            <path
                                                            stroke="currentColor"
                                                            strokeLinecap="round"
                                                            strokeLineJoin="round"
                                                            strokeWidth="2"
                                                            d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                                                            />
                                                        </svg>
                                                        <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                                                            <span className="font-semibold">
                                                                Drop your image here or browse
                                                            </span> 
                                                        </p>
                                                        <p className="text-xs text-gray-500 dark:text-gray-400">
                                                            (Only jpg, png, webp will be accepted)
                                                        </p>
                                                        </div>
                                                        <FileInput id="dropzone-file" className="hidden" />
                                                    </Label>
                                                </div>
                                                <div className='mt-4'>
                                                    <button className="flex items-center text-white bg-[#BC87F6] px-6 py-2 rounded-full hover:scale-105 duration-300 hover:shadow-xl">
                                                    <span className="font-medium">Generate</span>      
                                                        <span>
                                                            <HiOutlineArrowSmRight className='text-xl ms-1 font-medium' />
                                                        </span>
                                                    </button>
                                                </div>
                                            </div>
                                        </Tabs.Item>
                                        <Tabs.Item title="Upscaling">
                                            <div className="mt-2">
                                                <Accordion collapseAll className="border-none border-transparent">
                                                    <Accordion.Panel className="border-none border-transparent">
                                                        <Accordion.Title className="w-48 text-sm bg-transparent hover:bg-transparent focus:ring-0" >Advanced Settings</Accordion.Title>
                                                        <Accordion.Content>
                                                            <div className="sm:grid sm:grid-cols-3 xl:grid-cols-6 gap-2">
                                                                <div>
                                                                    <div className="max-w-md">
                                                                        <div className="mb-2 block">
                                                                            <Label value="Image resolution" />
                                                                        </div>
                                                                        <Select required>
                                                                            <option>1024 x 1024</option>
                                                                            <option>1024 x 1792</option>
                                                                            <option>1792 x 1024</option>
                                                                        </Select>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <div className="max-w-md">
                                                                        <div className="mb-2 block">
                                                                            <Label value="Art Style" />
                                                                        </div>
                                                                        <Select required>
                                                                            <option>None</option>
                                                                        </Select>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <div className="max-w-md">
                                                                        <div className="mb-2 block">
                                                                            <Label value="Lightning Style" />
                                                                        </div>
                                                                        <Select required>
                                                                            <option>None</option>
                                                                        </Select>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <div className="max-w-md">
                                                                        <div className="mb-2 block">
                                                                            <Label value="Mood" />
                                                                        </div>
                                                                        <Select required>
                                                                            <option>None</option>
                                                                        </Select>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <div className="max-w-md">
                                                                        <div className="mb-2 block">
                                                                            <Label value="Number of Images" />
                                                                        </div>
                                                                        <Select required>
                                                                            <option>1</option>
                                                                        </Select>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <div className="max-w-md">
                                                                        <div className="mb-2 block">
                                                                            <Label value="Quality of Images" />
                                                                        </div>
                                                                        <Select required>
                                                                            <option>Standard</option>
                                                                            <option>HD</option>
                                                                        </Select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Accordion.Content>
                                                    </Accordion.Panel>
                                                </Accordion>
                                            </div>
                                            <div className="mt-5">
                                                <h3 className="text-2xl font-bold">Upload Image</h3>
                                                <div className="flex w-full items-center justify-center mt-4">
                                                    <Label
                                                        htmlFor="dropzone-file"
                                                        className="dark:hover:bg-bray-800 flex h-64 w-full cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-dashed border-gray-300 bg-gray-50 hover:bg-gray-100 dark:border-gray-600 dark:bg-gray-700 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                                                    >
                                                        <div className="flex flex-col items-center justify-center pb-6 pt-5">
                                                        <svg
                                                            className="mb-4 h-8 w-8 text-gray-500 dark:text-gray-400"
                                                            aria-hidden="true"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            fill="none"
                                                            viewBox="0 0 20 16"
                                                        >
                                                            <path
                                                            stroke="currentColor"
                                                            strokeLinecap="round"
                                                            strokeLineJoin="round"
                                                            strokeWidth="2"
                                                            d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                                                            />
                                                        </svg>
                                                        <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                                                            <span className="font-semibold">
                                                                Drop your image here or browse
                                                            </span>
                                                        </p>
                                                        <p className="text-xs text-gray-500 dark:text-gray-400">
                                                            (Only jpg, png, webp will be accepted)
                                                        </p>
                                                        </div>
                                                        <FileInput id="dropzone-file" className="hidden" />
                                                    </Label>
                                                </div>
                                                <div className='mt-4'>
                                                <button className="flex items-center text-white bg-[#BC87F6] px-6 py-2 rounded-full hover:scale-105 duration-300 hover:shadow-xl">
                                                    <span className="font-medium">Generate</span>      
                                                    <span>
                                                        <HiOutlineArrowSmRight className='text-xl ms-1 font-medium' />
                                                    </span>
                                                </button>
                                                </div>
                                            </div>
                                        </Tabs.Item>
                                        <Tabs.Item title="Multi-Prompting">
                                            <div className="mt-2">
                                                <Accordion collapseAll className="border-none border-transparent">
                                                    <Accordion.Panel className="border-none border-transparent">
                                                        <Accordion.Title className="w-48 text-sm bg-transparent hover:bg-transparent focus:ring-0" >Advanced Settings</Accordion.Title>
                                                        <Accordion.Content>
                                                            <div className="sm:grid sm:grid-cols-3 xl:grid-cols-6 gap-2">
                                                                <div>
                                                                    <div className="max-w-md">
                                                                        <div className="mb-2 block">
                                                                            <Label value="Image resolution" />
                                                                        </div>
                                                                        <Select required>
                                                                            <option>1024 x 1024</option>
                                                                            <option>1024 x 1792</option>
                                                                            <option>1792 x 1024</option>
                                                                        </Select>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <div className="max-w-md">
                                                                        <div className="mb-2 block">
                                                                            <Label value="Art Style" />
                                                                        </div>
                                                                        <Select required>
                                                                            <option>None</option>
                                                                        </Select>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <div className="max-w-md">
                                                                        <div className="mb-2 block">
                                                                            <Label value="Lightning Style" />
                                                                        </div>
                                                                        <Select required>
                                                                            <option>None</option>
                                                                        </Select>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <div className="max-w-md">
                                                                        <div className="mb-2 block">
                                                                            <Label value="Mood" />
                                                                        </div>
                                                                        <Select required>
                                                                            <option>None</option>
                                                                        </Select>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <div className="max-w-md">
                                                                        <div className="mb-2 block">
                                                                            <Label value="Number of Images" />
                                                                        </div>
                                                                        <Select required>
                                                                            <option>1</option>
                                                                        </Select>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <div className="max-w-md">
                                                                        <div className="mb-2 block">
                                                                            <Label value="Quality of Images" />
                                                                        </div>
                                                                        <Select required>
                                                                            <option>Standard</option>
                                                                            <option>HD</option>
                                                                        </Select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Accordion.Content>
                                                    </Accordion.Panel>
                                                </Accordion>
                                            </div>
                                            <div>
                                                <h2 className="font-medium text-2xl">
                                                    Explain your idea.
                                                </h2>
                                                <div className="mt-5">
                                                    <TextInput type="text" sizing="lg" className="" placeholder='Painting of a flower vase on a kitchen table with a window in the backdrop.' />
                                                    
                                                </div>
                                            </div>
                                            <div className="mt-5">
                                                <div className='mt-4'>
                                                    <div className="flex items-center bg-white max-w-36 py-2 px-4 rounded-full hover:scale-105 duration-300 hover:shadow-xl text-slate-600 drop-shadow-md hover:bg-white  cursor-pointer">
                                                        <span>
                                                            <HiPlus className='text-xl font-medium' /> 
                                                        </span>
                                                        <span className="font-medium ms-2">Add More</span>
                                                    </div>
                                                </div>
                                                <div className='mt-6'>
                                                <button className="flex items-center text-white bg-[#BC87F6] px-6 py-2 rounded-full hover:scale-105 duration-300 hover:shadow-xl">
                                                    <span className="font-medium">Generate</span>      
                                                    <span>
                                                        <HiOutlineArrowSmRight className='text-xl ms-1 font-medium' />
                                                    </span>
                                                </button>
                                                </div>
                                            </div>
                                        </Tabs.Item> 
                                    </Tabs>
                                </Tabs.Item>
                            </Tabs> */}
                        </div>
                    </div>
                </Card>
                <div className="mt-5 p-5">
                    <h3 className="text-2xl font-semibold mb-5">Result</h3>
                    <div className="md:grid grid-cols-4 gap-4">
                        <div className='rounded-lg mb-2 overflow-hidden max-w-60 relative hover:shadow-lg bg-white border'>
                            <div className='cursor-pointer group flex items-center justify-center flex-col '>
                                <div className='invisible group-hover:visible transition-all absolute flex'>
                                    <div className="bg-white h-10 w-10 rounded-full flex items-center justify-center">
                                        <HiOutlineDocumentDownload className='text-xl font-medium' />
                                    </div>
                                    <div className="bg-white h-10 w-10 rounded-full flex items-center justify-center mx-4">
                                        <HiOutlineEye className='text-xl font-medium' />
                                    </div>
                                    <div className="bg-white h-10 w-10 rounded-full flex items-center justify-center">
                                        <HiOutlineTrash className='text-xl font-medium' onClick={() => setNewfolder(true)} />


                                        <Modal show={openNewfolder} size="md" onClose={() => setNewfolder(false)}>
                                            <Modal.Header>Lorem Ipsum is simply.</Modal.Header>
                                            <Modal.Body>
                                                <div className='space-y-6'>
                                                    <p>
                                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                                    </p>
                                                    
                                                </div>  
                                            </Modal.Body>
                                            <Modal.Footer>
                                                <div className="flex justify-end items-center gap-x-4">
                                                    <button  onClick={() => setNewfolder(false)} className="border border-slate-300 text-slate-700 px-10 md:px-16 py-2 rounded-md cursor-pointer">
                                                        Cancel
                                                    </button>
                                                    <button className='bg-red-100/50 border border-red-300 text-red-500 rounded-md py-2 px-10 md:px-16 font-medium'>
                                                        Delete
                                                    </button>
                                                </div>
                                            </Modal.Footer>
                                        </Modal>
                                        
                                    </div>
                                </div>
                                <img src={cat} alt='' className="" />
                            </div>
                            <div className='flex items-start justify-items-start justify-start'>
                                <h3 className="text-lg font-semibold px-2 py-2 ">Cat</h3>
                            </div>
                        </div>
                        <div className='rounded-lg mb-2 overflow-hidden max-w-60 relative hover:shadow-lg bg-white border'>
                            <div className='cursor-pointer group flex items-center justify-center flex-col '>
                                <div className='invisible group-hover:visible transition-all absolute flex'>
                                    <div className="bg-white h-10 w-10 rounded-full flex items-center justify-center">
                                        <HiOutlineDocumentDownload className='text-xl font-medium' />
                                    </div>
                                    <div className="bg-white h-10 w-10 rounded-full flex items-center justify-center mx-4">
                                        <HiOutlineEye className='text-xl font-medium' />
                                    </div>
                                    <div className="bg-white h-10 w-10 rounded-full flex items-center justify-center">
                                        <HiOutlineTrash className='text-xl font-medium' />
                                    </div>
                                </div>
                                <img src={cat} alt='' className="" />
                            </div>
                            <div className='flex items-start justify-items-start justify-start'>
                                <h3 className="text-lg font-semibold px-2 py-2 ">Cat</h3>
                            </div>
                        </div>
                        <div className='rounded-lg mb-2 overflow-hidden max-w-60 relative hover:shadow-lg bg-white border'>
                            <div className='cursor-pointer group flex items-center justify-center flex-col '>
                                <div className='invisible group-hover:visible transition-all absolute flex'>
                                    <div className="bg-white h-10 w-10 rounded-full flex items-center justify-center">
                                        <HiOutlineDocumentDownload className='text-xl font-medium' />
                                    </div>
                                    <div className="bg-white h-10 w-10 rounded-full flex items-center justify-center mx-4">
                                        <HiOutlineEye className='text-xl font-medium' />
                                    </div>
                                    <div className="bg-white h-10 w-10 rounded-full flex items-center justify-center">
                                        <HiOutlineTrash className='text-xl font-medium' />
                                    </div>
                                </div>
                                <img src={cat} alt='' className="" />
                            </div>
                            <div className='flex items-start justify-items-start justify-start'>
                                <h3 className="text-lg font-semibold px-2 py-2 ">Cat</h3>
                            </div>
                        </div>
                        <div className='rounded-lg mb-2 overflow-hidden max-w-60 relative hover:shadow-lg bg-white border'>
                            <div className='cursor-pointer group flex items-center justify-center flex-col '>
                                <div className='invisible group-hover:visible transition-all absolute flex'>
                                    <div className="bg-white h-10 w-10 rounded-full flex items-center justify-center">
                                        <HiOutlineDocumentDownload className='text-xl font-medium' />
                                    </div>
                                    <div className="bg-white h-10 w-10 rounded-full flex items-center justify-center mx-4">
                                        <HiOutlineEye className='text-xl font-medium' />
                                    </div>
                                    <div className="bg-white h-10 w-10 rounded-full flex items-center justify-center">
                                        <HiOutlineTrash className='text-xl font-medium' />
                                    </div>
                                </div>
                                <img src={cat} alt='' className="" />
                            </div>
                            <div className='flex items-start justify-items-start justify-start'>
                                <h3 className="text-lg font-semibold px-2 py-2 ">Cat</h3>
                            </div>
                        </div>
                        <div className='rounded-lg mb-2 overflow-hidden max-w-60 relative hover:shadow-lg bg-white border'>
                            <div className='cursor-pointer group flex items-center justify-center flex-col '>
                                <div className='invisible group-hover:visible transition-all absolute flex'>
                                    <div className="bg-white h-10 w-10 rounded-full flex items-center justify-center">
                                        <HiOutlineDocumentDownload className='text-xl font-medium' />
                                    </div>
                                    <div className="bg-white h-10 w-10 rounded-full flex items-center justify-center mx-4">
                                        <HiOutlineEye className='text-xl font-medium' />
                                    </div>
                                    <div className="bg-white h-10 w-10 rounded-full flex items-center justify-center">
                                        <HiOutlineTrash className='text-xl font-medium' />
                                    </div>
                                </div>
                                <img src={cat} alt='' className="" />
                            </div>
                            <div className='flex items-start justify-items-start justify-start'>
                                <h3 className="text-lg font-semibold px-2 py-2 ">Cat</h3>
                            </div>
                        </div>
                        <div className='rounded-lg mb-2 overflow-hidden max-w-60 relative hover:shadow-lg bg-white border'>
                            <div className='cursor-pointer group flex items-center justify-center flex-col '>
                                <div className='invisible group-hover:visible transition-all absolute flex'>
                                    <div className="bg-white h-10 w-10 rounded-full flex items-center justify-center">
                                        <HiOutlineDocumentDownload className='text-xl font-medium' />
                                    </div>
                                    <div className="bg-white h-10 w-10 rounded-full flex items-center justify-center mx-4">
                                        <HiOutlineEye className='text-xl font-medium' />
                                    </div>
                                    <div className="bg-white h-10 w-10 rounded-full flex items-center justify-center">
                                        <HiOutlineTrash className='text-xl font-medium' />
                                    </div>
                                </div>
                                <img src={cat} alt='' className="" />
                            </div>
                            <div className='flex items-start justify-items-start justify-start'>
                                <h3 className="text-lg font-semibold px-2 py-2 ">Cat</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default AiImage;