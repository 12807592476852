import { 
    HiOutlineChevronLeft,
    HiOutlinePencil,
} from "react-icons/hi";
import { Link } from "react-router-dom";
import { Table } from 'flowbite-react';
const EmailTemplates =()=>{
    return(
        <>
            <div className='p-5 md:flex md:justify-between bg-white rounded-md my-2'>
                <div>
                    <div className='mb-1 text-sm text-slate-400'>
                        <Link to="/admin/dashboard" className="flex items-center">
                                <HiOutlineChevronLeft className="me-1" /> Back to dashboard
                        </Link>
                    </div>
                    <h2 className='text-2xl lg:text-4xl font-bold'>Email Templates</h2>
                    <p className='text-slate-400 mb-3 md:mb-0'>Manage Email Templates</p>
                </div> 
            </div>
            <div className="p-5 mt-4 bg-white rounded-md mb-2">
                <div className="border rounded-md">
                    <div className="">
                        <div className="overflow-x-auto">
                            <Table className="bg-transparent">
                                <Table.Head>
                                    <Table.HeadCell className="bg-transparent border-b text-slate-500 font-medium whitespace-nowrap">
                                        Title
                                    </Table.HeadCell>
                                    <Table.HeadCell className="bg-transparent border-b text-slate-500 font-medium whitespace-nowrap">
                                        Subject
                                    </Table.HeadCell>
                                    <Table.HeadCell className="bg-transparent border-b text-slate-500 font-medium whitespace-nowrap">
                                        Actions
                                    </Table.HeadCell>
                                </Table.Head>
                                <Table.Body className="divide-y">
                                    <Table.Row>
                                        <Table.Cell className="whitespace-nowrap">
                                            Confirmation
                                        </Table.Cell>
                                        <Table.Cell className="whitespace-nowrap">
                                            Confirmation Email
                                        </Table.Cell>
                                        <Table.Cell>
                                            <div className='flex gap-2'>
                                                <div className='h-10 w-10 flex justify-center items-center bg-white  hover:bg-blue-600 rounded-full shadow-md hover:-translate-y-1 duration-300 hover:shadow-xl cursor-pointer hover:text-white' title="Edit">
                                                    <HiOutlinePencil className='text-lg' />
                                                </div>
                                            </div>
                                        </Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell className="whitespace-nowrap">
                                            Confirmation
                                        </Table.Cell>
                                        <Table.Cell className="whitespace-nowrap">
                                            Confirmation Email
                                        </Table.Cell>
                                        <Table.Cell>
                                            <div className='flex gap-2'>
                                                <div className='h-10 w-10 flex justify-center items-center bg-white  hover:bg-blue-600 rounded-full shadow-md hover:-translate-y-1 duration-300 hover:shadow-xl cursor-pointer hover:text-white' title="Edit">
                                                    <HiOutlinePencil className='text-lg' />
                                                </div>
                                            </div>
                                        </Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell className="whitespace-nowrap">
                                            Confirmation
                                        </Table.Cell>
                                        <Table.Cell className="whitespace-nowrap">
                                            Confirmation Email
                                        </Table.Cell>
                                        <Table.Cell>
                                            <div className='flex gap-2'>
                                                <div className='h-10 w-10 flex justify-center items-center bg-white  hover:bg-blue-600 rounded-full shadow-md hover:-translate-y-1 duration-300 hover:shadow-xl cursor-pointer hover:text-white' title="Edit">
                                                    <HiOutlinePencil className='text-lg' />
                                                </div>
                                            </div>
                                        </Table.Cell>
                                    </Table.Row>
                                </Table.Body>
                            </Table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default EmailTemplates;