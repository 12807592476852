import { Link } from "react-router-dom";
import { 
    HiOutlineChevronLeft,
    HiOutlineInformationCircle,
    HiOutlineArrowSmRight,
} from "react-icons/hi";
import { 
    Card,
    Label,
    FileInput,
    TextInput,
    Tooltip
} from 'flowbite-react';
const Aivideo =()=>{
    return(
        <>
            <div className='p-5 md:flex md:justify-between bg-white rounded-md my-2'>
                <div>
                    <div className='mb-1 text-gray-400 font-medium'>
                        <Link to="/" className="flex items-center">
                                <HiOutlineChevronLeft className="me-1" /> Back to dashboard
                        </Link>
                    </div>
                    <h2 className='text-4xl font-bold'>AI Video</h2>
                    <p className='text-slate-400'>
                        Bring your static images to life and create visually compelling videos effortlessly.
                    </p>
                </div>
                {/* <div className='flex items-center gap-3'>
                <button class='bg-blue-600 hover:bg-blue-500 rounded-md py-2 px-4 text-white flex font-medium hover:-translate-y-2 duration-300 hover:drop-shadow-lg'>
                        <HiPlusSm className="mr-2 h-5 w-5" /> New Folder
                    </button>
                </div> */}
            </div>
            <div className='rounded-md my-2'>
                <Card className="bg-[#F3E8FF] shadow-none ">
                    <div>
                        <h3 className="text-2xl font-bold">Source Image</h3>
                        <div className="flex w-full items-center justify-center mt-4">
                            <Label
                                htmlFor="dropzone-file"
                                className="dark:hover:bg-bray-800 flex h-64 w-full cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-dashed border-gray-300 bg-gray-50 hover:bg-gray-100 dark:border-gray-600 dark:bg-gray-700 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                            >
                                <div className="flex flex-col items-center justify-center pb-6 pt-5">
                                <svg
                                    className="mb-4 h-8 w-8 text-gray-500 dark:text-gray-400"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 20 16"
                                >
                                    <path
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLineJoin="round"
                                    strokeWidth="2"
                                    d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                                    />
                                </svg>
                                <p className="mb-2 px-4 text-sm text-gray-500 dark:text-gray-400">
                                    <span className="font-semibold">
                                        Drop your image here or browse. 1024x576, 576x1024, 768x768 images are avaiable.
                                    </span>
                                </p>
                                <p className="text-xs text-gray-500 dark:text-gray-400">
                                    (Only jpg, png will be accepted)
                                </p>
                                </div>
                                <FileInput id="dropzone-file" className="hidden" />
                            </Label>
                        </div>
                        <div className="md:grid grid-cols-3 gap-4 mt-5">
                            <div>
                                <div className="max-w-md mb-3">
                                    <div className="mb-2 flex justify-between items-center">
                                        <Label value="Seed" />
                                        <div>
                                            <Tooltip content="A specific value from 0 to 4294967294 that is used to guide the 'randomness' of the generation." className="w-64">
                                                <HiOutlineInformationCircle />
                                            </Tooltip>
                                        </div>
                                    </div>
                                    <TextInput type="number" required />
                                </div>
                            </div>
                            <div>
                                <div className="max-w-md mb-3">
                                    <div className="mb-2 flex justify-between items-center">
                                        <Label value="Fidelity" />
                                        <div>
                                            <Tooltip content="A specific value from 0 to 10 to express how strongly the video sticks to the original image." className="w-64">
                                                <HiOutlineInformationCircle />
                                            </Tooltip>
                                        </div>
                                    </div>
                                    <TextInput type="number" required />
                                </div>
                            </div>
                            <div>
                                <div className="max-w-md mb-3">
                                    <div className="mb-2 flex justify-between items-center">
                                        <Label value="Motion intensity" />
                                        <div>
                                            <Tooltip content="Lower values generally result in less motion in the output video, while higher values generally result in more motion. The range is 0 ~ 255" className="w-64">
                                                <HiOutlineInformationCircle />
                                            </Tooltip>
                                        </div>
                                    </div>
                                    <TextInput type="number" required />
                                </div>
                            </div>
                        </div>
                        <div className='mt-6'>
                            <button className="flex items-center justify-center text-white bg-[#BC87F6] px-6 py-2 rounded-full hover:scale-105 duration-300 hover:shadow-xl w-full">
                                <span className="font-medium">Generate</span>      
                                <span>
                                    <HiOutlineArrowSmRight className='text-xl ms-1 font-medium' />
                                </span>
                            </button>
                        </div>
                    </div>
                </Card>
                <div className='mt-6'>
                    <h3 className="text-2xl font-semibold mb-5">Result</h3>
                </div>
            </div>
        </>
    )
}
export default Aivideo;