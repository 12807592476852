import { Link } from "react-router-dom";
import { 
    HiOutlineChevronLeft, 
    HiOutlineSearch, 
    HiOutlineChat,
    HiOutlinePencil,
    HiOutlineX,
    HiPlus,
    HiOutlineClipboard,
    HiOutlineDocumentText,
    HiOutlineMicrophone
} from "react-icons/hi";
import { MdAttachFile, MdSend } from "react-icons/md";
import { Textarea, FileInput, Label } from 'flowbite-react';

const Aichatbot =()=>{
    return(
        <>
            <div className='p-5 md:flex md:justify-between bg-white rounded-md my-2'>
                <div>
                    <div className='mb-1 text-slate-400'>
                        <Link to="/" className="flex items-center">
                                <HiOutlineChevronLeft className="me-1" /> Back to dashboard
                        </Link>
                    </div>
                    <h2 className='text-4xl font-bold mb-4'>AI Chat</h2>
                </div>
            </div>
            <div className="p-5 bg-white rounded">   
                <div className="border rounded-lg lg:grid grid-cols-4 lg:grid-cols-4 gap-0">
                    <div className="relative">
                        <div className="py-4 px-3 border-b">
                            <div className="relative">
                                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                    <span className="text-gray-500 sm:text-sm"><HiOutlineSearch /></span>
                                </div>
                                <input
                                type="text"
                                className="w-full rounded-full shadow-sm border-0  pl-8 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                placeholder="Search..."
                                />
                            </div>
                        </div>
                        <div className="grid grid-cols-1 grid-flow-row divide-y divide-solid max-h-80 overflow-y-auto">
                            <div className="py-4 px-1 group flex cursor-pointer">
                                <div className="flex ">
                                    <div><HiOutlineChat className="text-2xl me-2" /></div>
                                    <div className="pe-4">
                                        <div>Default AI Chat Bot Chat</div>
                                        <div className="text-xs text-slate-500">5 days ago</div>
                                    </div>
                                </div>
                                <div className="flex items-center justify-end pe-3 invisible group-hover:visible">
                                    <div className="h-8 w-8 bg-white rounded-full flex justify-center items-center drop-shadow-md me-2 hover:-translate-y-1 duration-300">
                                        <HiOutlinePencil />
                                    </div>
                                    <div className="h-8 w-8 bg-pink-800 text-white rounded-full flex justify-center items-center hover:-translate-y-1 duration-300">
                                        <HiOutlineX />
                                    </div>
                                </div>
                            </div>
                            <div className="py-4 px-1 flex justify-start lg:justify-center">
                                <div className="lg:absolute bottom-4 bg-purple-600 text-white px-5 py-2 flex items-center rounded-full cursor-pointer hover:-translate-y-1 duration-300 hover:shadow-xl">
                                    <HiPlus className="me-2 text-white" />  New Conversation
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    <div className="col-span-3 border-s">
                        <div className="flex items-center py-3 border-t lg:border-t-0 border-b  px-2">
                            <div>
                                <div className="flex justify-center">
                                    <div className="h-12 w-12 bg-slate-200 border-4 border-white rounded-full flex items-center justify-center shadow-md group-hover:shadow-xl duration-300">
                                        <h2 className="text-sm font-medium text-slate-600 uppercase">acb</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="ps-2">Default</div>
                            <div className="ms-auto pe-10">
                                <div className="relative group">
                                    <div className="absolute -top-6 -right-10 min-w-28 bg-blue-900  group-hover:-translate-y-2 duration-300 rounded-md grid grid-cols-3 divide-x divide-solid divide-slate-100 invisible group-hover:visible text-slate-100 cursor-pointer">
                                        <div className="text-xs p-1">PDF</div>
                                        <div className="text-xs p-1"> Word </div>
                                        <div className="text-xs p-1"> Text</div>
                                    </div>
                                    <HiOutlineClipboard className="text-2xl cursor-pointer" />
                                </div>
                            </div>
                        </div>
                        <div className="h-80 overflow-y-auto py-5 px-2">
                            <div className="flex justify-start mb-4">
                                <div>
                                    <div className='h-8 w-8 bg-slate-100 rounded-full'></div>
                                </div>
                                <div className="relative group">
                                    <div className='bg-slate-200 ms-3 p-2 rounded-md'>
                                        Hi! I am Default AI Chat Bot, and I'm here to answer all your questions                                        
                                    </div>
                                    <div className='h-8 w-8 bg-white rounded-full shadow-md absolute -bottom-2 right-1 flex items-center justify-center invisible group-hover:visible'>
                                        <HiOutlineClipboard className="text-xl cursor-pointer" title="Copy to clipboard" />
                                    </div>
                                </div>
                            </div>
                            <div className="flex justify-end mb-4">
                                <div className="relative">
                                    <div className='bg-slate-200 ms-3 p-2 rounded-md'>
                                        flutter                                        
                                    </div>
                                </div>
                                <div>
                                    <div className='h-8 w-8 bg-slate-100 rounded-full mx-2'>
                                        <img src='https://flowbite.com/docs/images/people/profile-picture-5.jpg' alt="" className='rounded-full' />
                                    </div>
                                </div>
                            </div>
                            <div className="flex justify-start mb-4">
                                <div>
                                    <div className='h-8 w-8 bg-slate-100 rounded-full'></div>
                                </div>
                                <div className="relative group">
                                    <div className='bg-slate-200 ms-3 p-2 rounded-md'>
                                        Flutter is an open-source UI software development kit created by Google. It allows developers to build cross-platform applications for Android, iOS, web, and desktop using a single codebase. Flutter uses the Dart programming language and provides a rich set of premade widgets and tools for designing and customizing the user interface. It is known for its fast development cycles, hot reload feature, and beautiful, native-like user interfaces. Flutter has gained popularity among developers for its efficiency and ease of use.                                      
                                    </div>
                                    <div className='h-8 w-8 bg-white rounded-full shadow-md absolute -bottom-2 right-1 flex items-center justify-center invisible group-hover:visible'>
                                        <HiOutlineClipboard className="text-xl cursor-pointer" title="Copy to clipboard" />
                                    </div>
                                </div>
                            </div>
                            <div className="flex justify-end mb-4">
                                <div className="relative">
                                    <div className='bg-slate-200 ms-3 p-2 rounded-md'>
                                        flutter                                        
                                    </div>
                                </div>
                                <div>
                                    <div className='h-8 w-8 bg-slate-100 rounded-full mx-2'>
                                        <img src='https://flowbite.com/docs/images/people/profile-picture-5.jpg' alt="" className='rounded-full' />
                                    </div>
                                </div>
                            </div>
                            <div className="flex justify-start mb-4">
                                <div>
                                    <div className='h-8 w-8 bg-slate-100 rounded-full'></div>
                                </div>
                                <div className="relative group">
                                    <div className='bg-slate-200 ms-3 p-2 rounded-md'>
                                        <p className="mb-4">
                                            Flutter is a framework that allows you to build native-looking applications for multiple platforms from a single codebase. It was developed by Google and released in 2017. Flutter uses the Dart programming language, which was also created by Google, and provides a set of pre-designed widgets that allow you to create beautiful and responsive user interfaces.
                                        </p>
                                        <p className="mb-4">
                                            One of the main advantages of Flutter is its hot reload feature, which allows developers to see changes in real-time without having to restart the app. This makes the development process more efficient and enables faster iteration.
                                        </p>
                                        <p className="mb-4">
                                            With Flutter, you can build applications for Android, iOS, web, and desktop platforms. It provides a rich ecosystem of packages and plugins that extend its functionality and allow you to integrate with various services or add specific features to your app.
                                        </p>
                                        <p className="mb-4">
                                            Overall, Flutter is a powerful and flexible framework that simplifies the process of building cross-platform applications and delivers high-quality user experiences.
                                        </p>                        
                                    </div>
                                    <div className='h-8 w-8 bg-white rounded-full shadow-md absolute -bottom-2 right-1 flex items-center justify-center invisible group-hover:visible'>
                                        <HiOutlineClipboard className="text-xl cursor-pointer" title="Copy to clipboard" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mt-2 py-4 px-2">
                            <div className="lg:flex justify-between">
                                <div className="w-full">
                                    <div className="relative">
                                        <div className="flex items-center justify-center absolute inset-y-0 left-2">
                                            <Label
                                                htmlFor="dropzone-file"
                                                className="dark:hover:bg-bray-800 flex h-7 w-7 lg:h-9 lg:w-9 cursor-pointer flex-col items-center justify-center rounded-full bg-purple-600 text-white"
                                            >
                                                <div className="flex flex-col items-center justify-center">
                                                    <MdAttachFile className="lg:text-2xl text-lg" />
                                                </div>
                                                <FileInput id="dropzone-file" className="hidden" />
                                            </Label>
                                        </div>
                                        <Textarea placeholder="Type a message or upload an image" className="w-full rounded-full shadow-sm border-0 pl-10 lg:pl-16 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm h-14 lg:h-12" />
                                        <div className="flex items-center justify-center absolute inset-y-0 right-2">
                                            <div className="flex h-6 w-6 lg:h-9 lg:w-9 cursor-pointer flex-col items-center justify-center rounded-full hover:bg-slate-300">
                                                <div className="flex flex-col items-center justify-center">
                                                    <HiOutlineMicrophone className="lg:text-2xl text-lg" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex items-center justify-center absolute inset-y-0 right-9 lg:right-12">
                                            <div className="flex h-7 w-7 lg:h-9 lg:w-9 cursor-pointer flex-col items-center justify-center rounded-full hover:bg-slate-300">
                                                <div className="flex flex-col items-center justify-center">
                                                    <HiOutlineDocumentText className="lg:text-2xl text-lg" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="lg:px-5 pt-3 lg:pt-0">
                                    <div className="flex h-9 w-9 lg:h-12 lg:w-12 cursor-pointer flex-col items-center justify-center rounded-full bg-purple-600 text-white hover:-translate-y-1 duration-300 hover:shadow-xl">
                                        <div className="flex flex-col items-center justify-center">
                                            <MdSend className="text-xl lg:text-2xl" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Aichatbot;