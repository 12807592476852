import { Link } from "react-router-dom";
import { 
    HiOutlineChevronLeft,
    HiOutlineMinusCircle,
    HiOutlineDocumentDuplicate,
    HiDownload,
    HiOutlinePlus,
} from "react-icons/hi";
import { BiUndo, BiRedo } from "react-icons/bi";
import { Label, ToggleSwitch } from 'flowbite-react';
import { useState } from "react";
const Aiyoutube =()=>{
    const [switch3, setSwitch3] = useState(false);
    return(
        <>
            <div className='p-5 md:flex md:justify-between bg-white rounded-md my-2'>
                <div>
                    <div className='mb-1 text-slate-400'>
                        <Link to="/" className="flex items-center">
                                <HiOutlineChevronLeft className="me-1" /> Back to dashboard
                        </Link>
                    </div>
                    <h2 className='text-4xl font-bold'>AI YouTube</h2>
                    <p className='text-slate-400'>
                        Simply turn your Youtube videos into Blog post.
                    </p>
                </div>
            </div>
            <div className="mt-5">   
                <div className="lg:grid grid-cols-5 gap-4">
                    <div className="mb-2 rounded-md px-2 col-span-2 ">
                        <div className="rounded-lg px-2 py-2 mb-4 bg-white shadow-md">
                            <h3>Remaining Credits</h3>
                            <div className='w-full mt-1'>
                                <ul className='sm:flex'>
                                    <li className="w-auto md:w-1/2 mb-2 md:mb-0">
                                        <div className="h-2 bg-blue-600 md:rounded-s-full rounded-full md:rounded-none mb-1"></div>
                                        <div className="flex items-center">
                                            <div className="h-2 w-2 rounded-full bg-blue-600 me-2"></div> 
                                            <div className="pe-4">
                                                <span className='text-slate-500'>Words</span> 2,999,119
                                            </div>
                                        </div>
                                    </li>
                                    <li className="w-auto md:w-1/2">
                                        <div className="h-2 bg-blue-200 md:rounded-e-full rounded-full md:rounded-none mb-1"></div>
                                        <div className="flex items-center">
                                            <div className="h-2 w-2 rounded-full bg-blue-200 me-2"></div> 
                                            <div><span className='text-slate-500'>Images</span> 2,999,996</div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="pt-2 mb-3">
                            <ToggleSwitch checked={switch3} label="Include Your Brand" onChange={setSwitch3} sizing="sm" />
                            <div>
                                {
                                    switch3 && <div className="mt-5">
                                    <div className="mb-4">
                                        <div className="mb-2 flex items-center justify-between">
                                            <Label value="Select Company" />
                                            <Link to="/user/brandvoice/add-or-update">
                                                <div className="w-6 h-6 rounded-md bg-slate-300 flex justify-center items-center">
                                                    <HiOutlinePlus className=" text-slate-600" />
                                                </div>
                                            </Link>
                                        </div>
                                        <select className="bg-[#DCFCE7] w-full border-1 border-slate-300 rounded-md">
                                            <option>Select</option>
                                        </select>
                                    </div>
                                    <div className="mb-4">
                                        <div className="mb-2 block">
                                            <Label value="Select Product/Service" /> 
                                        </div>
                                        <select className="bg-[#DCFCE7] w-full border-1 border-slate-300 rounded-md">
                                            <option>Select</option>
                                        </select>
                                    </div>
                                </div>
                                }
                            </div>
                        </div>
                        <div className="mb-2 rounded-md px-2 md:px-0 py-2">
                            <div className="mb-4">
                                <div className="mb-2 block">
                                    <Label value="YouTube Video URL" />
                                </div>
                                <input type="url" className="bg-[#DCFCE7] w-full border-1 border-slate-300 rounded-md" placeholder="YouTube Video URL" />
                            </div>
                            <div className="mb-4">
                                <div className="mb-2 block">
                                    <Label value="Action" /> 
                                </div>
                                <select className="bg-[#DCFCE7] w-full border-1 border-slate-300 rounded-md">
                                    <option>Select</option>
                                </select>
                            </div>
                            <div className="mb-4">
                                <div className="mb-2 block">
                                    <Label value="Language" /> 
                                </div>
                                <select className="bg-[#DCFCE7] w-full border-1 border-slate-300 rounded-md">
                                    <option>Select</option>
                                </select>
                            </div>
                            <div className="px-1 flex justify-start lg:justify-center">
                                <div className="bg-blue-600 text-white px-5 py-2 flex items-center justify-center rounded-full cursor-pointer hover:-translate-y-1 duration-300 hover:shadow-xl w-full mt-2">Generate</div>
                            </div>
                        </div>
                    </div>
                    <div className="mb-2 rounded-md px-2 col-span-3">
                        <div className="py-2 px-2 flex justify-between items-center gap-1 bg-[#DCFCE7] rounded-md">
                            <div className="flex items-center gap-1">
                                <div className='p-1 hover:bg-gray-300 rounded-sm cursor-pointer '>
                                    <BiUndo className='text-xl' title='Undo' />
                                </div>
                                <div className='p-1 hover:bg-gray-300 rounded-sm cursor-pointer '>
                                    <BiRedo className='text-xl' title='Redo' />
                                </div>
                                <div className='p-1 hover:bg-gray-300 rounded-sm cursor-pointer '>
                                    <HiOutlineDocumentDuplicate className='text-xl' title='Copy to clipboard' />
                                </div>
                                <div className='p-1 hover:bg-gray-300 rounded-sm cursor-pointer '>
                                    <HiDownload className='text-xl' title='Download' />
                                </div>
                                <div className='p-1 hover:bg-gray-300 rounded-sm cursor-pointer '>
                                    <HiOutlineMinusCircle className='text-xl text-red-700' title='Delete' />
                                </div>
                            </div>
                        </div>
                        <div className="py-4">
                            <h3 className="text-2xl px-4">Untitled Document</h3>
                        </div>
                        <div className="bg-[#DCFCE7] mb-2 rounded-md px-4 py-2">
                            React Text Editor
                        </div>   
                    </div>
                </div>
            </div>
        </>
    )
}
export default Aiyoutube;