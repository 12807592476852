import { Link } from "react-router-dom";
import { HiOutlineChevronLeft, } from "react-icons/hi";
import { Label } from 'flowbite-react';
const UserAdd =()=>{
    return(
        <>
            <div className='p-5 md:flex md:justify-between bg-white rounded-md my-2'>
                <div>
                    <div className='mb-1 text-gray-400 font-medium'>
                        <Link to="/" className="flex items-center">
                                <HiOutlineChevronLeft className="me-1" /> Back to dashboard
                        </Link>
                    </div>
                    <h2 className='text-4xl font-bold mb-3 md:mb-0'>Create New User</h2>
                </div>
            </div>
            <div className='p-5 bg-white rounded-md mb-2'>
                <div className='grid md:grid-cols-12'>
                    <div className='md:col-span-6 md:col-start-4'>
                        <div class=''>   
                            <div>
                                <form className="flex flex-col">
                                    <div className='grid md:grid-cols-2 gap-x-4'>
                                        <div className='mb-4'>
                                            <div className="mb-2 block">
                                                <Label value="Name" className="text-slate-500" />
                                            </div>
                                            <input type="text" class="bg-white w-full border-1 border-slate-300 rounded-md" />
                                        </div>
                                        <div className='mb-4'>
                                            <div className="mb-2 block">
                                                <Label value="Surname" className="text-slate-500" />
                                            </div>
                                            <input type="text" class="bg-white w-full border-1 border-slate-300 rounded-md" />
                                        </div>
                                        <div className='mb-4'>
                                            <div className="mb-2 block">
                                                <Label value="Phone" className="text-slate-500" />
                                            </div>
                                            <input type="text" class="bg-white w-full border-1 border-slate-300 rounded-md" />
                                        </div>
                                        <div className='mb-4'>
                                            <div className="mb-2 block">
                                                <Label value="Email" className="text-slate-500" />
                                            </div>
                                            <input type="email" class="bg-white w-full border-1 border-slate-300 rounded-md" />
                                        </div>
                                    </div>
                                    <div className='mb-4'>
                                        <div className="mb-2 block">
                                            <Label value="Avatar" className="text-slate-500" />
                                        </div>
                                        <input class="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:outline-none" type="file" />
                                    </div>
                                    <div className='grid md:grid-cols-2 gap-x-4'>
                                        <div className='mb-4'>
                                            <div className="mb-2 block">
                                                <Label value="Password" className="text-slate-500" />
                                            </div>
                                            <input type="password" class="bg-white w-full border-1 border-slate-300 rounded-md" />
                                        </div>
                                        <div className='mb-4'>
                                            <div className="mb-2 block">
                                                <Label value="Re-Password" className="text-slate-500" />
                                            </div>
                                            <input type="password" class="bg-white w-full border-1 border-slate-300 rounded-md" />
                                        </div>
                                    </div>
                                    <div className='mb-4'>
                                        <div className="mb-2 block">
                                            <Label value="Country" className="text-slate-500" />
                                        </div>
                                        <select class="bg-white w-full border-1 border-slate-300 rounded-md">
                                            <option>Select</option>
                                        </select>
                                    </div>
                                    <div className='grid md:grid-cols-2 gap-x-4'>
                                        <div className='mb-4'>
                                            <div className="mb-2 block">
                                                <Label value="Type" className="text-slate-500" />
                                            </div>
                                            <select class="bg-white w-full border-1 border-slate-300 rounded-md">
                                                <option>Select</option>
                                            </select>
                                        </div>
                                        <div className='mb-4'>
                                            <div className="mb-2 block">
                                                <Label value="Status" className="text-slate-500" />
                                            </div>
                                            <select class="bg-white w-full border-1 border-slate-300 rounded-md">
                                                <option>Select</option>
                                            </select>
                                        </div>
                                        <div className='mb-4'>
                                            <div className="mb-2 block">
                                                <Label value="Remaining Words" className="text-slate-500" />
                                            </div>
                                            <input type="number" class="bg-white w-full border-1 border-slate-300 rounded-md" />
                                        </div>
                                        <div className='mb-4'>
                                            <div className="mb-2 block">
                                                <Label value="Remaining Images" className="text-slate-500" />
                                            </div>
                                            <input type="number" class="bg-white w-full border-1 border-slate-300 rounded-md" />
                                        </div>
                                    </div>
                                    <div className="py-4 flex justify-center">
                                        <button className="bg-blue-600 hover:bg-blue-500 rounded-full py-2 px-4 text-white flex justify-center font-medium hover:-translate-y-2 duration-300 hover:drop-shadow-lg w-full">
                                            Save
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default UserAdd;