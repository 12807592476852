import { Link } from "react-router-dom";
import { 
    HiOutlineChevronLeft,
    HiOutlineInformationCircle,
    HiOutlineArrowRight,
} from "react-icons/hi";
import { Label } from 'flowbite-react';

const ChattemplatesAddUpdate =()=>{
    return(
        <>
            <div className='p-5 md:flex md:justify-between bg-white rounded-md my-2'>
                <div>
                    <div className='mb-1 text-slate-400'>
                        <Link to="/admin/dashboard" className="flex items-center">
                                <HiOutlineChevronLeft className="me-1" /> Back to dashboard
                        </Link>
                    </div>
                    <h2 className='text-4xl font-bold mb-1'>Add or Edit Chat Template</h2>
                </div>
            </div>
            <div className='p-5 bg-white rounded-md'>
                <div className='grid md:grid-cols-12'>
                    <div className='md:col-span-6 md:col-start-4'>
                        <div class=''>
                            <div>
                                <div className="flex items-center p-4 py-3 gap-3 rounded-xl bg-gray-100 mt-5 mb-8">
                                    <div className="w-6 h-6 flex justify-center items-center bg-purple-600 text-white  rounded-full font-bold ">
                                        1
                                    </div>
                                    <div className="text-slate-500 font-bold">Template</div>
                                </div>
                                <form className="flex flex-col">
                                    <div className='mb-4'>
                                        <div className="mb-2 relative flex items-center">
                                            <Label value="Template Name" className="text-slate-500" />
                                            <span className='cursor-pointer group'>
                                                <HiOutlineInformationCircle className='ms-1 text-slate-500' />
                                                <span className='absolute bottom-2 left-0 bg-slate-200 text-slate-600 p-2 rounded-md border text-xs invisible group-hover:visible group-hover:-translate-y-6 translate-y-4 duration-300 opacity-0 group-hover:opacity-100'>
                                                    Pick a name for the template.
                                                </span>
                                            </span>
                                        </div>
                                        <input type="text" class="bg-white w-full border-1 border-slate-300 rounded-md" />
                                    </div>
                                    <div className='mb-4'>
                                        <div className="mb-2 relative flex items-center">
                                            <Label value="Category" className="text-slate-500" />
                                            <span className='cursor-pointer group'>
                                                <HiOutlineInformationCircle className='ms-1 text-slate-500' />
                                                <span className='absolute bottom-2 left-0 bg-slate-200 text-slate-600 p-2 rounded-md border text-xs invisible group-hover:visible group-hover:-translate-y-6 translate-y-4 duration-300 opacity-0 group-hover:opacity-100'>
                                                    Pick a category for the template.
                                                </span>
                                            </span>
                                        </div>
                                        <select class="bg-white w-full border-1 border-slate-300 rounded-md">
                                            <option>Select</option>
                                        </select>
                                    </div>
                                    <div className='mb-4'>
                                        <div className="mb-2 relative flex items-center">
                                            <Label value="Template Short Name" className="text-slate-500" />
                                            <span className='cursor-pointer group'>
                                                <HiOutlineInformationCircle className='ms-1 text-slate-500' />
                                                <span className='absolute bottom-2 left-0 bg-slate-200 text-slate-600 p-2 rounded-md border text-xs invisible group-hover:visible group-hover:-translate-y-6 translate-y-4 duration-300 opacity-0 group-hover:opacity-100'>
                                                    Shortened name of the template or human name. Maximum 3 letters is suggested.
                                                </span>
                                            </span>
                                        </div>
                                        <input type="text" class="bg-white w-full border-1 border-slate-300 rounded-md" />
                                    </div>
                                    <div className='mb-4'>
                                        <div className="mb-2 relative flex items-center">
                                            <Label value="Description" className="text-slate-500" />
                                            <span className='cursor-pointer group'>
                                                <HiOutlineInformationCircle className='ms-1 text-slate-500' />
                                                <span className='absolute bottom-2 left-0 bg-slate-200 text-slate-600 p-2 rounded-md border text-xs invisible group-hover:visible group-hover:-translate-y-6 translate-y-4 duration-300 opacity-0 group-hover:opacity-100'>
                                                    A short description of what this chat template can help with.
                                                </span>
                                            </span>
                                        </div>
                                        <textarea rows={4} class="bg-white w-full border-1 border-slate-300 rounded-md" ></textarea>
                                    </div>
                                    <div className='mb-4'>
                                        <div className="mb-2 relative flex items-center">
                                            <Label value="First Message" className="text-slate-500" />
                                        </div>
                                        <input type="text" class="bg-white w-full border-1 border-slate-300 rounded-md" />
                                    </div>
                                    <div className='mb-4'>
                                        <div className="mb-2 relative flex items-center">
                                            <Label value="Instructions" className="text-slate-500" />
                                            <span className='cursor-pointer group'>
                                                <HiOutlineInformationCircle className='ms-1 text-slate-500' />
                                                <span className='absolute bottom-2 left-0 bg-slate-200 text-slate-600 p-2 rounded-md border text-xs invisible group-hover:visible group-hover:-translate-y-6 translate-y-4 duration-300 opacity-0 group-hover:opacity-100'>
                                                    You can provide instructions to your GPT-3 model to ensure it aligns with your brand and tone.
                                                </span>
                                            </span>
                                        </div>
                                        <textarea rows={4} class="bg-white w-full border-1 border-slate-300 rounded-md" ></textarea>
                                    </div>
                                    <div className='mb-4'>
                                        <div className="mb-2 relative flex items-center">
                                            <Label value="Avatar" className="text-slate-500" />
                                            <span className='cursor-pointer group'>
                                                <HiOutlineInformationCircle className='ms-1 text-slate-500' />
                                                <span className='absolute bottom-2 left-0 bg-slate-200 text-slate-600 p-2 rounded-md border text-xs invisible group-hover:visible group-hover:-translate-y-6 translate-y-4 duration-300 opacity-0 group-hover:opacity-100'>
                                                    Avatar will shown in chat page.
                                                </span>
                                            </span>
                                        </div>
                                        <input class="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:outline-none" type="file" />
                                    </div>
                                    <div className='mb-4'>
                                        <div className="mb-2 relative flex items-center">
                                            <Label value="Template Color" className="text-slate-500" />
                                            <span className='cursor-pointer group'>
                                                <HiOutlineInformationCircle className='ms-1 text-slate-500' />
                                                <span className='absolute bottom-2 left-0 bg-slate-200 text-slate-600 p-2 rounded-md border text-xs invisible group-hover:visible group-hover:-translate-y-6 translate-y-4 duration-300 opacity-0 group-hover:opacity-100'>
                                                    Pick a color for for the icon container shape. Color is in HEX format.
                                                </span>
                                            </span>
                                        </div>
                                        <div class="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:outline-none py-1 px-2">
                                            <input  type="color" className="w-8 h-8 cursor-pointer" />
                                        </div>
                                    </div>
                                    <div className="flex items-center p-4 py-3 gap-3 rounded-xl bg-gray-100 mt-5 mb-8">
                                        <div className="w-6 h-6 flex justify-center items-center bg-purple-600 text-white  rounded-full font-bold ">
                                            2
                                        </div>
                                        <div className="text-slate-500 font-bold">Personality</div>
                                    </div>
                                    <div className='mb-4'>
                                        <div className="mb-2 relative flex items-center">
                                            <Label value="Human Name" className="text-slate-500" />
                                            <span className='cursor-pointer group'>
                                                <HiOutlineInformationCircle className='ms-1 text-slate-500' />
                                                <span className='absolute bottom-2 left-0 bg-slate-200 text-slate-600 p-2 rounded-md border text-xs invisible group-hover:visible group-hover:-translate-y-6 translate-y-4 duration-300 opacity-0 group-hover:opacity-100'>
                                                    Define a human name for the chatbot to give it more personality.
                                                </span>
                                            </span>
                                        </div>
                                        <input type="text" class="bg-white w-full border-1 border-slate-300 rounded-md" />
                                    </div>
                                    <div className='mb-4'>
                                        <div className="mb-2 relative flex items-center">
                                            <Label value="Template Role" className="text-slate-500" />
                                            <span className='cursor-pointer group'>
                                                <HiOutlineInformationCircle className='ms-1 text-slate-500' />
                                                <span className='absolute bottom-2 left-0 bg-slate-200 text-slate-600 p-2 rounded-md border text-xs invisible group-hover:visible group-hover:-translate-y-6 translate-y-4 duration-300 opacity-0 group-hover:opacity-100'>
                                                    A role for the chatbot that can define what it can help with. For example Finance Expert.
                                                </span>
                                            </span>
                                        </div>
                                        <input type="text" class="bg-white w-full border-1 border-slate-300 rounded-md" />
                                    </div>
                                    <div className='mb-4'>
                                        <div className="mb-2 relative flex items-center">
                                            <Label value="Helps With" className="text-slate-500" />
                                            <span className='cursor-pointer group'>
                                                <HiOutlineInformationCircle className='ms-1 text-slate-500' />
                                                <span className='absolute bottom-2 left-0 bg-slate-200 text-slate-600 p-2 rounded-md border text-xs invisible group-hover:visible group-hover:-translate-y-6 translate-y-4 duration-300 opacity-0 group-hover:opacity-100'>
                                                    Describe what this chatbot can help with. It shows when starting a conversation and the chatbot introducing itself.
                                                </span>
                                            </span>
                                        </div>
                                        <textarea rows={4} class="bg-white w-full border-1 border-slate-300 rounded-md" ></textarea>
                                    </div>
                                    <div className='mb-4'>
                                        <div className="mb-2 relative flex items-center">
                                            <Label value="Chatbot Training" className="text-slate-500" />
                                            <span className='cursor-pointer group'>
                                                <HiOutlineInformationCircle className='ms-1 text-slate-500' />
                                                <span className='absolute bottom-2 left-0 bg-slate-200 text-slate-600 p-2 rounded-md border text-xs invisible group-hover:visible group-hover:-translate-y-6 translate-y-4 duration-300 opacity-0 group-hover:opacity-100'>
                                                    Choose any trained chatbot. If you need to train a new chatbot, visit the Chatbot Training
                                                </span>
                                            </span>
                                            <span className="ms-3">
                                                <button className="bg-blue-600 hover:bg-blue-500 text-white rounded-full px-8 flex justify-center items-center font-medium hover:-translate-y-2 duration-300 hover:drop-shadow-lg  w-full">
                                                    Create example input
                                                </button>
                                            </span>
                                        </div>
                                        <select class="bg-white w-full border-1 border-slate-300 rounded-md">
                                            <option>Select</option>
                                        </select>
                                    </div>
                                    <div className='mb-4'>
                                        <div className="mb-2 relative flex items-center">
                                            <Label value="Chatbot Training" className="text-slate-500" />
                                            <span className='cursor-pointer group'>
                                                <HiOutlineInformationCircle className='ms-1 text-slate-500' />
                                                <span className='absolute bottom-2 left-0 bg-slate-200 text-slate-600 p-2 rounded-md border text-xs invisible group-hover:visible group-hover:-translate-y-6 translate-y-4 duration-300 opacity-0 group-hover:opacity-100'>
                                                    Chat models take a list of messages as input and return a model-generated message as output. Although the chat format is designed to make multi-turn conversations easy, it's just as useful for single-turn tasks without any conversation. Add your custom JSON data.
                                                </span>
                                            </span>
                                        </div>
                                        <textarea rows={4} class="bg-white w-full border-1 border-slate-300 rounded-md" ></textarea>
                                    </div>
                                    <div className="p-2 mb-6 rounded-md hover:underline">
                                        <Link className="flex items-center">
                                            <span>More Info</span>
                                            <span><HiOutlineArrowRight className='ms-2 -rotate-45' /></span>
                                        </Link>
                                    </div>
                                    <div className="flex justify-center items-center">
                                        <button className="bg-blue-600 hover:bg-blue-500 text-white rounded-full px-8 flex py-2 justify-center items-center font-medium hover:-translate-y-2 duration-300 hover:drop-shadow-lg  w-full">
                                            Save
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default ChattemplatesAddUpdate;