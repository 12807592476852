import { Button, Checkbox, Label, TextInput } from 'flowbite-react';
import { HiOutlineEyeOff, HiOutlineChevronLeft } from "react-icons/hi";
import logo from '../images/hamariAI-logo.svg';
import authBg from '../images/bg-auth.jpg';
import dashMockup from '../images/dash-mockup.jpg';
import { Link } from 'react-router-dom';
const Login =()=>{
    return(
        <>
            <div className='grid lg:grid-cols-2 '>
                <div className='col-span-full fixed w-screen z-10 sm:text-white flex items-center justify-between py-4 sm:px-24 px-4'>
                    <div><img src={logo}  className='h-9' alt="Hamari AI" /></div>
                    {/* <div className='flex items-center'><HiOutlineChevronLeft className='me-2 cursor-pointer' /> Back to Home</div> */}
                </div>
                <div className='h-screen grid content-center md:justify-center px-4'>
                    <div className=''>
                        <h1 className='text-3xl font-bold mb-6'>Sign in</h1>
                        <form className="flex flex-col gap-4 md:w-96">
                            <div>
                                <div className="mb-2 block">
                                    <Label htmlFor="email2" value="Email address" />
                                </div>
                                <TextInput id="email2" type="email" required shadow  />
                            </div>
                            <div>
                                <div className="mb-2 block">
                                    <Label htmlFor="password" value="Password" />
                                </div>
                                <div className='relative'>
                                    <TextInput id="password" type="password" required shadow />
                                    <HiOutlineEyeOff className='absolute right-4 top-3 cursor-pointer' />
                                </div>
                            </div>
                            <div className="flex items-center justify-between gap-2">
                                <div className='flex items-center'>
                                    <Checkbox id="agree" />
                                    <Label htmlFor="agree" className="flex ms-2">Remember me</Label>
                                </div>
                                <div>
                                    <Label className="flex">Forget Password</Label>
                                </div>
                            </div>
                            <div className='mt-5'>
                                <Button type="submit" className='rounded-full w-full bg-indigo-700 hover:-translate-y-1 transition-all duration-300 h-14'>
                                    <span className='text-xl'>Sign in</span>
                                </Button>
                            </div>
                            <div className='text-center mt-5'>
                                <h2>Don't have account yet? <Link to='/signup' className='text-indigo-700 font-medium underline decoration-solid'>Sign up</Link></h2>
                            </div>
                        </form>
                    </div>
                </div>
                <div className='relative overflow-hidden hidden lg:block'>
                    <img src={authBg} alt='' className='h-screen w-screen object-cover' />
                    <img src={dashMockup} alt='' className='h-96 rounded-3xl absolute top-0 right-0 translate-y-28 translate-x-40' />
                </div>
            </div>
        </>
    )
}
export default Login;