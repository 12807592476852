
import { Link } from "react-router-dom";
import { 
    HiOutlineChevronLeft,
    HiOutlinePencil,
    HiOutlineInformationCircle,
} from "react-icons/hi";
import { Table } from 'flowbite-react';
const GoogleAdsense =()=>{
    return(
        <>
            <div className='p-5 md:flex md:justify-between bg-white rounded-md my-2'>
                <div>
                    <div className='mb-1 text-gray-400 font-medium'>
                        <Link to="/" className="flex items-center">
                                <HiOutlineChevronLeft className="me-1" /> Back to dashboard
                        </Link>
                    </div>
                    <h2 className='text-4xl font-bold mb-3 md:mb-0 flex items-center relative'>
                        <span>Google Adsense</span>
                        <span className='cursor-pointer group'>
                            <HiOutlineInformationCircle className='ms-1 text-base' />
                            <span className='absolute bottom-4 right-0 bg-slate-200 text-slate-600 p-2 rounded-md border text-xs invisible group-hover:visible group-hover:-translate-y-6 translate-y-4 duration-300 opacity-0 group-hover:opacity-100'>
                                Activate header section to view ads
                            </span>
                        </span>
                    </h2>
                </div>
            </div>
            <div className="p-5 bg-white rounded-md mb-2">
                <div className="border rounded-md">
                    <div className="">
                        <div className="overflow-x-auto">
                            <Table className="bg-transparent">
                                <Table.Head>
                                    <Table.HeadCell className="bg-transparent border-b text-slate-500 font-medium whitespace-nowrap">
                                        Adsense Type
                                    </Table.HeadCell>
                                    <Table.HeadCell className="bg-transparent border-b text-slate-500 font-medium whitespace-nowrap">
                                        Status
                                    </Table.HeadCell>
                                    <Table.HeadCell className="bg-transparent border-b text-slate-500 font-medium whitespace-nowrap">
                                        Updated On
                                    </Table.HeadCell>
                                    <Table.HeadCell className="bg-transparent border-b text-slate-500 font-medium whitespace-nowrap">
                                        Actions
                                    </Table.HeadCell>
                                </Table.Head>
                                <Table.Body className="divide-y">
                                    <Table.Row>
                                        <Table.Cell className="min-w-64 md:min-w-40 max-w-96">
                                            landing-header-section
                                        </Table.Cell>
                                        <Table.Cell className="">
                                            <span className="bg-red-100 text-red-600 px-4 py-1 rounded-full font-semibold">
                                                Passive
                                            </span>
                                        </Table.Cell>
                                        <Table.Cell className="min-w-32">
                                            <div>06 Sep 2023</div> 
                                            <div className='text-slate-400'>08:04 AM</div>
                                        </Table.Cell>
                                        <Table.Cell>
                                            <div className='flex gap-2'>
                                                <div className='h-10 w-10 flex justify-center items-center bg-white  hover:bg-blue-600 rounded-full shadow-md hover:-translate-y-1 duration-300 hover:shadow-xl cursor-pointer hover:text-white' title="Edit">
                                                    <HiOutlinePencil className='text-lg' />
                                                </div>
                                            </div>
                                        </Table.Cell>
                                    </Table.Row>
                                </Table.Body>
                            </Table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default GoogleAdsense;