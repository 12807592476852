import { Link } from "react-router-dom";
import { 
    HiOutlineChevronLeft, 
    HiOutlineSearch, 
    HiOutlineChat,
    HiOutlinePencil,
    HiOutlineX,
    HiPlus,
    HiOutlineClipboard,
    HiOutlineDocumentText,
    HiOutlineMicrophone
} from "react-icons/hi";
import { MdAttachFile, MdSend } from "react-icons/md";
import { Textarea, FileInput, Label } from 'flowbite-react';
import avatar from '../../../images/avatar.jpg';
const Aipdf =()=>{
    return(
        <>
            <div className='p-5 md:flex md:justify-between bg-white rounded-md my-2'>
                <div>
                    <div className='mb-1 text-slate-400'>
                        <Link to="/" className="flex items-center">
                                <HiOutlineChevronLeft className="me-1" /> Back to dashboard
                        </Link>
                    </div>
                    <h2 className='text-4xl font-bold'>AI File Chat</h2>
                    <p className='text-slate-400'>
                        Simply upload a file (PDF, CSV, .doc or .docx) and extract key insights or summarize the entire document.
                    </p>
                </div>
            </div>
            <div className="">   
                <div className="lg:grid grid-cols-4 gap-4">
                    <div className="">
                        <div className="bg-[#FFDFDF] mb-2 rounded-md px-2 py-4">
                            <div className="relative">
                                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                    <span className="text-gray-500 sm:text-sm"><HiOutlineSearch /></span>
                                </div>
                                <input
                                type="text"
                                className="w-full rounded-full shadow-sm border-0  pl-8 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                placeholder="Search..."
                                />
                            </div>
                        </div>
                        <div className="bg-[#FFDFDF] mb-2 rounded-md px-2 pt-2 pb-5">
                            <div className="h-96 overflow-y-auto bg-white mt-5 rounded-md mb-4">
                                <div className="grid grid-cols-1 grid-flow-row divide-y divide-solid mt-2">
                                    <div className="py-4 px-2 group flex cursor-pointer">
                                        <div className="flex ">
                                            <div><HiOutlineChat className="text-2xl me-2" /></div>
                                            <div className="pe-4">
                                                <div>Chat Image</div>
                                                <div className="text-xs text-slate-500">5 days ago</div>
                                            </div>
                                        </div>
                                        <div className="flex items-center justify-end pe-3 invisible group-hover:visible">
                                            <div className="h-8 w-8 bg-white rounded-full flex justify-center items-center drop-shadow-md me-2 hover:-translate-y-1 duration-300">
                                                <HiOutlinePencil />
                                            </div>
                                            <div className="h-8 w-8 bg-pink-800 text-white rounded-full flex justify-center items-center hover:-translate-y-1 duration-300">
                                                <HiOutlineX />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="py-4 px-2 group flex cursor-pointer">
                                        <div className="flex ">
                                            <div><HiOutlineChat className="text-2xl me-2" /></div>
                                            <div className="pe-4">
                                                <div>Chat Image</div>
                                                <div className="text-xs text-slate-500">5 days ago</div>
                                            </div>
                                        </div>
                                        <div className="flex items-center justify-end pe-3 invisible group-hover:visible">
                                            <div className="h-8 w-8 bg-white rounded-full flex justify-center items-center drop-shadow-md me-2 hover:-translate-y-1 duration-300">
                                                <HiOutlinePencil />
                                            </div>
                                            <div className="h-8 w-8 bg-pink-800 text-white rounded-full flex justify-center items-center hover:-translate-y-1 duration-300">
                                                <HiOutlineX />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="py-4 px-2 group flex cursor-pointer">
                                        <div className="flex ">
                                            <div><HiOutlineChat className="text-2xl me-2" /></div>
                                            <div className="pe-4">
                                                <div>Chat Image</div>
                                                <div className="text-xs text-slate-500">5 days ago</div>
                                            </div>
                                        </div>
                                        <div className="flex items-center justify-end pe-3 invisible group-hover:visible">
                                            <div className="h-8 w-8 bg-white rounded-full flex justify-center items-center drop-shadow-md me-2 hover:-translate-y-1 duration-300">
                                                <HiOutlinePencil />
                                            </div>
                                            <div className="h-8 w-8 bg-pink-800 text-white rounded-full flex justify-center items-center hover:-translate-y-1 duration-300">
                                                <HiOutlineX />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="py-4 px-2 group flex cursor-pointer">
                                        <div className="flex ">
                                            <div><HiOutlineChat className="text-2xl me-2" /></div>
                                            <div className="pe-4">
                                                <div>Chat Image</div>
                                                <div className="text-xs text-slate-500">5 days ago</div>
                                            </div>
                                        </div>
                                        <div className="flex items-center justify-end pe-3 invisible group-hover:visible">
                                            <div className="h-8 w-8 bg-white rounded-full flex justify-center items-center drop-shadow-md me-2 hover:-translate-y-1 duration-300">
                                                <HiOutlinePencil />
                                            </div>
                                            <div className="h-8 w-8 bg-pink-800 text-white rounded-full flex justify-center items-center hover:-translate-y-1 duration-300">
                                                <HiOutlineX />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="py-4 px-2 group flex cursor-pointer">
                                        <div className="flex ">
                                            <div><HiOutlineChat className="text-2xl me-2" /></div>
                                            <div className="pe-4">
                                                <div>Chat Image</div>
                                                <div className="text-xs text-slate-500">5 days ago</div>
                                            </div>
                                        </div>
                                        <div className="flex items-center justify-end pe-3 invisible group-hover:visible">
                                            <div className="h-8 w-8 bg-white rounded-full flex justify-center items-center drop-shadow-md me-2 hover:-translate-y-1 duration-300">
                                                <HiOutlinePencil />
                                            </div>
                                            <div className="h-8 w-8 bg-pink-800 text-white rounded-full flex justify-center items-center hover:-translate-y-1 duration-300">
                                                <HiOutlineX />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="py-4 px-2 group flex cursor-pointer">
                                        <div className="flex ">
                                            <div><HiOutlineChat className="text-2xl me-2" /></div>
                                            <div className="pe-4">
                                                <div>Chat Image</div>
                                                <div className="text-xs text-slate-500">5 days ago</div>
                                            </div>
                                        </div>
                                        <div className="flex items-center justify-end pe-3 invisible group-hover:visible">
                                            <div className="h-8 w-8 bg-white rounded-full flex justify-center items-center drop-shadow-md me-2 hover:-translate-y-1 duration-300">
                                                <HiOutlinePencil />
                                            </div>
                                            <div className="h-8 w-8 bg-pink-800 text-white rounded-full flex justify-center items-center hover:-translate-y-1 duration-300">
                                                <HiOutlineX />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="py-4 px-2 group flex cursor-pointer">
                                        <div className="flex ">
                                            <div><HiOutlineChat className="text-2xl me-2" /></div>
                                            <div className="pe-4">
                                                <div>Chat Image</div>
                                                <div className="text-xs text-slate-500">5 days ago</div>
                                            </div>
                                        </div>
                                        <div className="flex items-center justify-end pe-3 invisible group-hover:visible">
                                            <div className="h-8 w-8 bg-white rounded-full flex justify-center items-center drop-shadow-md me-2 hover:-translate-y-1 duration-300">
                                                <HiOutlinePencil />
                                            </div>
                                            <div className="h-8 w-8 bg-pink-800 text-white rounded-full flex justify-center items-center hover:-translate-y-1 duration-300">
                                                <HiOutlineX />
                                            </div>
                                        </div>
                                    </div>
                                </div> 
                            </div>
                        <div className=" px-1 flex justify-start lg:justify-center">
                            <div className="bg-blue-600 text-white px-5 py-2 flex items-center rounded-full cursor-pointer hover:-translate-y-1 duration-300 hover:shadow-xl w-full">
                                <HiPlus className="me-2 text-white" />  New Conversation
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="bg-[#FFDFDF] mb-2 rounded-md px-2 py-4 col-span-3">
                        <div className="bg-white rounded-md">
                            <div className="md:flex md:items-center py-2 border-t lg:border-t-0 border-b px-2">
                                <div className="mb-2 md:mb-0">
                                    <div className="md:flex justify-center">
                                        <div className="h-12 w-12 bg-slate-200 border-4 border-white rounded-full flex items-center justify-center shadow-md group-hover:shadow-xl duration-300">
                                            <h2 className="text-sm font-medium text-slate-600 uppercase">acb</h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="ps-2 mb-2 md:mb-0">
                                    <h3 className='font-medium'>PDF AI</h3>
                                    <div className='text-slate-500 text-sm'>Image and PDF Expert</div>
                                </div>
                                <div className="ms-auto flex pe-10 gap-4">
                                    <div className="relative group">
                                        <div className="absolute -top-6 -right-10 min-w-28 bg-blue-900  group-hover:-translate-y-2 duration-300 rounded-md grid grid-cols-3 divide-x divide-solid divide-slate-100 invisible group-hover:visible text-slate-100 cursor-pointer">
                                            <div className="text-xs p-1">PDF</div>
                                            <div className="text-xs p-1"> Word </div>
                                            <div className="text-xs p-1"> Text</div>
                                        </div>
                                        <HiOutlineClipboard className="text-2xl cursor-pointer" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col mt-5 h-96 pb-5 overflow-y-auto">
                            <div className="flex justify-start mb-4">
                                <div>
                                    <div className='h-8 w-8'>
                                        <img src={avatar} className="rounded-full"  alt="" />
                                    </div>
                                </div>
                                <div className="">
                                    <div className='bg-slate-50 mb-4 ms-3 w-3/4 p-2 rounded-md relative group '>
                                        Hi! I am PDF AI, and I'm Image and PDF Expert. I can assist you with PDF or Images-related information or questions

                                        <div className='h-8 w-8 bg-white rounded-full shadow-md absolute -bottom-2 right-1 flex items-center justify-center invisible group-hover:visible'>
                                            <HiOutlineClipboard className="text-xl cursor-pointer" title="Copy to clipboard" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="flex justify-end mb-4">
                                <div className="relative">
                                    <div className='bg-slate-50 ms-32 p-2 rounded-md'>
                                        Chat app poliocy pdf                                      
                                    </div>
                                </div>
                                <div>
                                    <div className='h-8 w-8 bg-slate-100 rounded-full mx-2'>
                                        <img src='https://flowbite.com/docs/images/people/profile-picture-5.jpg' alt="" className='rounded-full' />
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="w-full flex items-center">
                            <div className="relative w-full">
                                <div className="flex items-center justify-center absolute inset-y-0 left-2">
                                    <Label
                                        htmlFor="dropzone-file"
                                        className="dark:hover:bg-bray-800 flex h-7 w-7 lg:h-9 lg:w-9 cursor-pointer flex-col items-center justify-center rounded-full bg-blue-600 text-white"
                                    >
                                        <div className="flex flex-col items-center justify-center">
                                            <MdAttachFile className="lg:text-2xl text-lg" />
                                        </div>
                                        <FileInput id="dropzone-file" className="hidden" />
                                    </Label>
                                </div>
                                <Textarea 
                                    placeholder="Type a message or upload an image" className="w-full rounded-full shadow-sm border-0 pl-10 lg:pl-14 lg:pr-28 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm placeholder:pt-2"
                                />
                                <div className="flex items-center justify-center absolute inset-y-0 right-2">
                                    <div className="flex h-6 w-6 lg:h-9 lg:w-9 cursor-pointer flex-col items-center justify-center rounded-full hover:bg-slate-300">
                                        <div className="flex flex-col items-center justify-center">
                                            <HiOutlineMicrophone className="lg:text-2xl text-lg" />
                                        </div>
                                    </div>
                                </div>
                                <div className="flex items-center justify-center absolute inset-y-0 right-9 lg:right-12">
                                    <div className="flex h-7 w-7 lg:h-9 lg:w-9 cursor-pointer flex-col items-center justify-center rounded-full hover:bg-slate-300">
                                        <div className="flex flex-col items-center justify-center">
                                            <HiOutlineDocumentText className="lg:text-2xl text-lg" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="lg:px-2 pt-3 lg:pt-0">
                                <div className="flex h-9 w-9 lg:h-12 lg:w-12 cursor-pointer flex-col items-center justify-center rounded-full bg-blue-600 text-white hover:-translate-y-1 duration-300 hover:shadow-xl">
                                    <div className="flex flex-col items-center justify-center">
                                        <MdSend className="text-xl lg:text-2xl" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Aipdf;