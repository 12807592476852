import { Link } from "react-router-dom";
import { 
    HiOutlineChevronLeft,
} from "react-icons/hi";
import { ToggleSwitch } from 'flowbite-react';
import { useState } from 'react';
const Privacy =()=>{
    const [switch1, setSwitch1] = useState(false);
    return(
        <>
            <div className='p-5 md:flex md:justify-between bg-white rounded-md my-2'>
                <div>
                    <div className='mb-1 text-slate-400'>
                        <Link to="/admin/dashboard" className="flex items-center">
                                <HiOutlineChevronLeft className="me-1" /> Back to dashboard
                        </Link>
                    </div>
                    <h2 className='text-4xl font-bold'>Privacy Policy and Terms Settings</h2>
                </div> 
            </div>
            <div className='p-5 bg-white rounded-md mb-2'>
                <div className='grid md:grid-cols-12'>
                    <div className='md:col-span-6 md:col-start-4'>
                        <div class=''>
                            <div className="mb-3">
                                <ToggleSwitch checked={switch1} label="Enable Privacy Policy and Terms" onChange={setSwitch1} />
                            </div>
                            <div className="mb-4 mt-4 flex justify-center">
                                <button className="bg-blue-600 hover:bg-blue-500 rounded-full py-2 px-4 text-white flex justify-center font-medium hover:-translate-y-2 duration-300 hover:drop-shadow-lg w-full">
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Privacy;