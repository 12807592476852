import { Label, Textarea, Table  } from 'flowbite-react'; 
import { 
    HiOutlineInformationCircle, 
    HiOutlinePlus,
    HiOutlineVolumeUp,
    HiOutlineX,
    HiOutlineChevronLeft
} from "react-icons/hi";
import { Link } from 'react-router-dom';
const Aivoiceover =()=>{
    return(
        <>
            <div className='p-5 md:flex md:justify-between bg-white rounded-md my-2'>
                <div>
                    <div className='mb-1 text-gray-400 font-medium'>
                        <Link to="/" className="flex items-center">
                                <HiOutlineChevronLeft className="me-1" /> Back to dashboard
                        </Link>
                    </div>
                    <h2 className='text-4xl font-bold'>AI Voiceover</h2>
                    <p className='text-slate-400 mb-3 md:mb-0'>
                        The AI app that turns text into audio speech with ease. Get ready to generate custom audios from texts quickly and accurately.
                    </p>
                </div>
                {/* <div className='flex items-center gap-3'>
                    <button class='bg-blue-600 hover:bg-blue-500 rounded-md py-2 px-4 text-white flex font-medium hover:-translate-y-2 duration-300 hover:drop-shadow-lg'>
                        <HiPlusSm className="mr-2 h-5 w-5" /> New Folder
                    </button>
                </div> */}
            </div>
            <div className='mt-4'>
                <div className='p-5 bg-[#F3E8FF] border border-slate-200 rounded-md'>
                    <div className='pb-4 border-b border-slate-300 text-2xl text-slate-700 mb-6'>
                        Untitled Document...
                    </div>
                    <div className='grid md:grid-cols-4 gap-4'>
                        <div>
                            <div>
                                <div className="mb-2 block">
                                    <Label className='flex items-center relative'>Language 
                                        <span className='cursor-pointer group'>
                                            <HiOutlineInformationCircle className='ms-1' />
                                            <span className='absolute bottom-2 left-0 bg-slate-200 text-slate-600 p-2 rounded-md border text-xs invisible group-hover:visible group-hover:-translate-y-4 translate-y-4 duration-300 opacity-0 group-hover:opacity-100'>
                                                Select speech language
                                            </span>
                                        </span>
                                    </Label>
                                </div>
                                <select className="bg-white w-full border-1 border-slate-300 rounded-md">
                                    <option>Select</option>
                                </select>
                            </div>
                        </div>
                        <div>
                            <div>
                                <div className="mb-2 block">
                                    <Label className='flex items-center relative'>Voice
                                        <span className='cursor-pointer group'>
                                            <HiOutlineInformationCircle className='ms-1' />
                                            <span className='absolute bottom-2 left-0 bg-slate-200 text-slate-600 p-2 rounded-md border text-xs invisible group-hover:visible group-hover:-translate-y-4 translate-y-4 duration-300 opacity-0 group-hover:opacity-100'>
                                                You can select speech voice. Female, Male, and type
                                            </span>
                                        </span>
                                    </Label>
                                </div>
                                <select className="bg-white w-full border-1 border-slate-300 rounded-md">
                                    <option>Select</option>
                                </select>
                            </div>
                        </div>
                        <div>
                            <div>
                                <div className="mb-2 block">
                                    <Label className='flex items-center relative'>Pace
                                        <span className='cursor-pointer group'>
                                            <HiOutlineInformationCircle className='ms-1' />
                                            <span className='absolute bottom-2 left-0 bg-slate-200 text-slate-600 p-2 rounded-md border text-xs invisible group-hover:visible group-hover:-translate-y-4 translate-y-4 duration-300 opacity-0 group-hover:opacity-100'>
                                                Speech speed
                                            </span>
                                        </span>
                                    </Label>
                                </div>
                                <select className="bg-white w-full border-1 border-slate-300 rounded-md">
                                    <option>Select</option>
                                </select>
                            </div>
                        </div>
                        <div>
                            <div>
                                <div className="mb-2 block">
                                    <Label className='flex items-center relative'>Pause
                                        <span className='cursor-pointer group'>
                                            <HiOutlineInformationCircle className='ms-1' />
                                            <span className='absolute bottom-2 left-0 bg-slate-200 text-slate-600 p-2 rounded-md border text-xs invisible group-hover:visible group-hover:-translate-y-4 translate-y-4 duration-300 opacity-0 group-hover:opacity-100'>
                                                Wait x seconds after the speech. Represents the time before the next sentence.
                                            </span>
                                        </span>
                                    </Label>
                                </div>
                                <select className="bg-white w-full border-1 border-slate-300 rounded-md">
                                    <option>Select</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className='mt-5'>
                        <div className='flex items-center gap-4'>
                            <div><h3 className='text-lg font-medium text-slate-600'>Speeches</h3></div>
                            <div>
                                <button className='bg-[#BC87F6] text-white px-2 py-1 flex items-center justify-center rounded-md cursor-pointer hover:-translate-y-1 duration-300 hover:shadow-xl'>
                                    <HiOutlinePlus className='me-2' />  Add New
                                </button>
                            </div>
                        </div>
                        <div className='md:flex justify-between mt-5 mb-5'>
                            <div>
                                <div className='flex items-center gap-2 mb-3'>
                                    <div className='text-md font-medium text-slate-600'>US Lily (Female)</div>
                                    <div>
                                        <select className="bg-white w-full border-1 border-slate-300 rounded-md">
                                            <option>Select</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className='flex gap-2'>
                                <div className='h-10 w-10 flex justify-center items-center bg-white rounded-full shadow-md hover:-translate-y-1 duration-300 hover:shadow-xl cursor-pointer'>
                                    <HiOutlineVolumeUp className='text-slate-600' />
                                </div>
                                <div className='h-10 w-10 flex justify-center items-center bg-white  hover:bg-red-700 rounded-full shadow-md hover:-translate-y-1 duration-300 hover:shadow-xl cursor-pointer hover:text-white'>
                                    <HiOutlineX />
                                </div>
                            </div>
                        </div>
                        <div className='mb-5'>
                            <Textarea placeholder="write something..." required rows={4} className="bg-white" />
                        </div>
                        <div>
                            <button className="bg-[#BC87F6] text-white px-5 py-2 flex items-center justify-center rounded-full cursor-pointer hover:-translate-y-1 duration-300 hover:shadow-xl">
                                Generate
                            </button>
                        </div>
                    </div>
                </div>
                <div className="overflow-x-auto mt-5">
                    <Table>
                        <Table.Head >
                            <Table.HeadCell className='bg-transparent border-b text-slate-500'>File</Table.HeadCell>
                            <Table.HeadCell className='bg-transparent border-b text-slate-500'>Language</Table.HeadCell>
                            <Table.HeadCell className='bg-transparent border-b text-slate-500'>Voice</Table.HeadCell>
                            <Table.HeadCell className='bg-transparent border-b text-slate-500'>Date</Table.HeadCell>
                            <Table.HeadCell className='bg-transparent border-b text-slate-500'>Play</Table.HeadCell>
                            <Table.HeadCell className='bg-transparent border-b text-slate-500'>Action</Table.HeadCell>
                        </Table.Head>
                        <Table.Body className="divide-y">
                            <Table.Row className="dark:border-gray-700 dark:bg-gray-800">
                                <Table.Cell className="whitespace-nowrap font-medium text-slate-500 dark:text-white">
                                No entries created yet.
                                </Table.Cell>
                                <Table.Cell></Table.Cell>
                                <Table.Cell></Table.Cell>
                                <Table.Cell></Table.Cell>
                                <Table.Cell></Table.Cell>
                                <Table.Cell></Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                </div>
            </div>
        </>
    )
}
export default Aivoiceover;