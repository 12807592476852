import { HiOutlineChevronLeft, HiOutlinePencil, HiOutlineX } from "react-icons/hi";
import { Table } from 'flowbite-react';
import { Link } from "react-router-dom";
const UserManagement =()=>{
    return(
        <>
            <div className='p-5 md:flex md:justify-between bg-white rounded-md my-2'>
                <div>
                    <div className='mb-1 text-gray-400 font-medium'>
                        <Link to="/" className="flex items-center">
                                <HiOutlineChevronLeft className="me-1" /> Back to dashboard
                        </Link>
                    </div>
                    <h2 className='text-4xl font-bold mb-3 md:mb-0'>User Management</h2>
                </div>
            </div>
            <div className="p-5 bg-white rounded-md mb-2">
                <div className="border rounded-md">
                    <div className="p-2 flex justify-between items-center border-b">
                        <div><h3 className="text-lg font-medium">User Management</h3></div>
                        <div>
                            <div className="flex justify-center"> 
                                <Link to="/admin/users/add">
                                    <button  className="bg-blue-600 hover:bg-blue-500 rounded-full py-2 px-4 text-white flex justify-center font-medium hover:-translate-y-2 duration-300 hover:drop-shadow-lg w-full">
                                        Add New 
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="">
                        <div className="overflow-x-auto">
                            <Table className="bg-transparent">
                                <Table.Head>
                                    <Table.HeadCell className="bg-transparent border-b text-slate-500 font-medium whitespace-nowrap">
                                        Name
                                    </Table.HeadCell>
                                    <Table.HeadCell className="bg-transparent border-b text-slate-500 font-medium whitespace-nowrap">
                                        Group
                                    </Table.HeadCell>
                                    <Table.HeadCell className="bg-transparent border-b text-slate-500 font-medium whitespace-nowrap">
                                        Words Left
                                    </Table.HeadCell>
                                    <Table.HeadCell className="bg-transparent border-b text-slate-500 font-medium whitespace-nowrap">
                                        Images Left
                                    </Table.HeadCell>
                                    <Table.HeadCell className="bg-transparent border-b text-slate-500 font-medium whitespace-nowrap">
                                        Country
                                    </Table.HeadCell>
                                    <Table.HeadCell className="bg-transparent border-b text-slate-500 font-medium whitespace-nowrap">
                                        Status
                                    </Table.HeadCell>
                                    <Table.HeadCell className="bg-transparent border-b text-slate-500 font-medium whitespace-nowrap">
                                        Created At
                                    </Table.HeadCell>
                                    <Table.HeadCell className="bg-transparent border-b text-slate-500 font-medium whitespace-nowrap">
                                        Actions
                                    </Table.HeadCell>
                                </Table.Head>
                                <Table.Body className="divide-y">
                                    <Table.Row>
                                        <Table.Cell className="whitespace-nowrap">
                                            Admin Admin
                                        </Table.Cell>
                                        <Table.Cell>
                                            Admin
                                        </Table.Cell>
                                        <Table.Cell className="max-w-xs">
                                            2993919
                                        </Table.Cell> 
                                        <Table.Cell className="max-w-xs">
                                            2993919
                                        </Table.Cell>
                                        <Table.Cell className="max-w-xs"></Table.Cell>
                                        <Table.Cell className="max-w-xs">
                                            Passive
                                        </Table.Cell>
                                        <Table.Cell className="min-w-32">
                                            <div className='text-slate-800'>2.6.2023</div> 
                                            <div className='text-slate-400'>12:33:09</div>
                                        </Table.Cell>
                                        <Table.Cell>
                                        <div className='flex gap-2'>
                                            <div className='h-10 w-10 flex justify-center items-center bg-white  hover:bg-blue-600 rounded-full shadow-md hover:-translate-y-1 duration-300 hover:shadow-xl cursor-pointer hover:text-white' title="Edit">
                                                <HiOutlinePencil className='text-lg' />
                                            </div>
                                            <div className='h-10 w-10 flex justify-center items-center bg-white  hover:bg-red-700 rounded-full shadow-md hover:-translate-y-1 duration-300 hover:shadow-xl cursor-pointer hover:text-white' title="Delete">
                                                <HiOutlineX className='text-lg' />
                                            </div>
                                        </div>
                                        </Table.Cell>
                                    </Table.Row>
                                </Table.Body>
                            </Table>
                        </div>
                    </div>
                </div>
            </div>  
        </>
    )
}
export default UserManagement;