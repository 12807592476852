import { Table } from 'flowbite-react';
import { Link } from 'react-router-dom';
import { 
    HiOutlineChevronLeft,
} from "react-icons/hi";

const Aispeech =()=>{
    return(
        <>
            <div className='p-5 md:flex md:justify-between bg-white rounded-md my-2'>
                <div>
                    <div className='mb-1 text-gray-400 font-medium'>
                        <Link to="/" className="flex items-center">
                                <HiOutlineChevronLeft className="me-1" /> Back to dashboard
                        </Link>
                    </div>
                    <h2 className='text-4xl font-bold'>AI Speech to Text</h2>
                    <p className='text-slate-400 mb-3 md:mb-0'>
                        The AI app that turns audio speech into text with ease.
                    </p> 
                </div>
            </div>
            <div className="grid md:grid-cols-1 lg:grid-cols-2 gap-0 mt-4">
                <div className="pt-1 pb-10 ps-2 pe-6">
                    <div className="rounded-lg px-2 py-2 mb-4 bg-white shadow-md">
                            <h3>Remaining Credits</h3>
                            <div className='w-full mt-1'>
                                <ul className='sm:flex'>
                                    <li className="w-auto md:w-1/2 mb-2 md:mb-0">
                                        <div className="h-2 bg-blue-600 md:rounded-s-full rounded-full md:rounded-none mb-1"></div>
                                        <div className="flex items-center">
                                            <div className="h-2 w-2 rounded-full bg-blue-600 me-2"></div> 
                                            <div className="pe-4">
                                                <span className='text-slate-500'>Words</span> 2,999,119
                                            </div>
                                        </div>
                                    </li>
                                    <li className="w-auto md:w-1/2">
                                        <div className="h-2 bg-blue-200 md:rounded-e-full rounded-full md:rounded-none mb-1"></div>
                                        <div className="flex items-center">
                                            <div className="h-2 w-2 rounded-full bg-blue-200 me-2"></div> 
                                            <div><span className='text-slate-500'>Images</span> 2,999,996</div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    <div>
                        <div className='mb-3'>
                            <label class="block mb-2 text-sm text-slate-500 font-normal">
                                Upload an Audio File (mp3, mp4, mpeg, mpga, m4a, wav, and webm)(Max: 25Mb)
                            </label>
                        </div>
                        <input class="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-white" type="file" />
                    </div>
                    <div className="py-4 flex justify-center mt-4">
                        <button className="bg-blue-600 text-white px-5 py-2 flex items-center justify-center rounded-full cursor-pointer hover:-translate-y-1 duration-300 hover:shadow-xl w-full">
                        Generate
                        </button>
                    </div>
                </div>
                <div className="lg:border-s pt-1 pb-10 px-6">
                    <div className="overflow-x-auto mt-5">
                        <Table>
                            <Table.Head >
                                <Table.HeadCell className='bg-transparent border-b text-slate-500'>Type</Table.HeadCell>
                                <Table.HeadCell className='bg-transparent border-b text-slate-500'>Result</Table.HeadCell>
                            </Table.Head>
                            <Table.Body className="divide-y">
                                <Table.Row className="dark:border-gray-700 dark:bg-gray-800">
                                    <Table.Cell className="whitespace-nowrap font-medium text-slate-500 dark:text-white">
                                    No entries created yet.
                                    </Table.Cell>
                                    <Table.Cell></Table.Cell>
                                </Table.Row>
                            </Table.Body>
                        </Table>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Aispeech;