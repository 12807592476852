import { Link } from "react-router-dom";
import { 
    HiOutlineChevronLeft,
} from "react-icons/hi";
import { Label, TextInput, Select, Textarea } from 'flowbite-react';
const SectionSettings =()=>{
    return(
        <>
            <div className='p-5 md:flex md:justify-between bg-white rounded-md my-2'>
                <div>
                    <div className='mb-1 text-gray-400 font-medium'>
                        <Link to="/" className="flex items-center">
                                <HiOutlineChevronLeft className="me-1" /> Back to dashboard
                        </Link>
                    </div>
                    <h2 className='text-4xl font-bold'>Frontend Section Settings</h2>
                </div>
            </div>
            <div className='p-5 bg-white rounded-md mb-2'>
                <div className='grid md:grid-cols-12'>
                    <div className='md:col-span-6 md:col-start-4'>
                        <div class=''>
                            <h3 className='text-2xl font-medium text-slate-700 mb-5'>Features Section</h3>
                            <div>
                                <form className="flex flex-col">
                                    <div className='mb-4'>
                                        <div className="mb-2 block">
                                            <Label value="Features Section Active" className="text-slate-500" />
                                        </div>
                                        <Select>
                                            <option>Active</option>
                                            <option>Passive</option>
                                        </Select>
                                    </div>
                                    <div className='mb-4'>
                                        <div className="mb-2 block">
                                            <Label value="Features Title" className="text-slate-500" />
                                        </div>
                                        <TextInput type="text" placeholder="The future of AI." />
                                    </div>
                                    <div className='mb-4'>
                                        <div className="mb-2 block">
                                            <Label value="Features Description" className="text-slate-500" />
                                        </div>
                                        <Textarea rows={3} />
                                    </div>
                                    <h3 className='text-2xl font-medium text-slate-700 my-5'>Generators Section</h3>
                                    <div className='mb-4'>
                                        <div className="mb-2 block">
                                            <Label value="Generators Active" className="text-slate-500" />
                                        </div>
                                        <Select>
                                            <option>Active</option>
                                            <option>Passive</option>
                                        </Select>
                                    </div>
                                    <h3 className='text-2xl font-medium text-slate-700 my-5'>For Who Section</h3>
                                    <div className='mb-4'>
                                        <div className="mb-2 block">
                                            <Label value="For Who Section Active" className="text-slate-500" />
                                        </div>
                                        <Select>
                                            <option>Active</option>
                                            <option>Passive</option>
                                        </Select>
                                    </div>
                                    <h3 className='text-2xl font-medium text-slate-700 my-5'>
                                        Custom Templates Section
                                    </h3>
                                    <div className='mb-4'>
                                        <div className="mb-2 block">
                                            <Label value="Custom Templates Active" className="text-slate-500" />
                                        </div>
                                        <Select>
                                            <option>Active</option>
                                            <option>Passive</option>
                                        </Select>
                                    </div>
                                    <div className='mb-4'>
                                        <div className="mb-2 block">
                                            <Label value="Custom Templates Subtitle One" className="text-slate-500" />
                                        </div>
                                        <TextInput type="text" placeholder="Custom" />
                                    </div>
                                    <div className='mb-4'>
                                        <div className="mb-2 block">
                                            <Label value="Custom Templates Subtitle Two" className="text-slate-500" />
                                        </div>
                                        <TextInput type="text" placeholder="Prompts" />
                                    </div>
                                    <div className='mb-4'>
                                        <div className="mb-2 block">
                                            <Label value="Custom Templates Title" className="text-slate-500" />
                                        </div>
                                        <TextInput type="text" placeholder="Custom Templates." />
                                    </div>
                                    <div className='mb-4'>
                                        <div className="mb-2 block">
                                            <Label value="Custom Templates Description" className="text-slate-500" />
                                        </div>
                                        <Textarea rows={3} />
                                    </div>
                                    <h3 className='text-2xl font-medium text-slate-700 my-5'>
                                        Tools Section
                                    </h3>
                                    <div className='mb-4'>
                                        <div className="mb-2 block">
                                            <Label value="Tools Active" className="text-slate-500" />
                                        </div>
                                        <Select>
                                            <option selected>Active</option>
                                            <option>Passive</option>
                                        </Select>
                                    </div>
                                    <div className='mb-4'>
                                        <div className="mb-2 block">
                                            <Label value="Tools Title" className="text-slate-500" />
                                        </div>
                                        <TextInput type="text" placeholder="Magic Tools." />
                                    </div>
                                    <div className='mb-4'>
                                        <div className="mb-2 block">
                                            <Label value="Tools Description" className="text-slate-500" />
                                        </div>
                                        <Textarea rows={3} />
                                    </div>
                                    <h3 className='text-2xl font-medium text-slate-700 my-5'>
                                        How It Works Section
                                    </h3>
                                    <div className='mb-4'>
                                        <div className="mb-2 block">
                                            <Label value="How It Works Active" className="text-slate-500" />
                                        </div>
                                        <Select>
                                            <option selected>Active</option>
                                            <option>Passive</option>
                                        </Select>
                                    </div>
                                    <div className='mb-4'>
                                        <div className="mb-2 block">
                                            <Label value="How It Works Title" className="text-slate-500" />
                                        </div>
                                        <TextInput type="text" placeholder="So, how does it work?" />
                                    </div>
                                    <h3 className='text-2xl font-medium text-slate-700 my-5'>
                                        Testimonials Section
                                    </h3>
                                    <div className='mb-4'>
                                        <div className="mb-2 block">
                                            <Label value="Testimonials Active" className="text-slate-500" />
                                        </div>
                                        <Select>
                                            <option selected>Active</option>
                                            <option>Passive</option>
                                        </Select>
                                    </div>
                                    <div className='mb-4'>
                                        <div className="mb-2 block">
                                            <Label value="Testimonials Title" className="text-slate-500" />
                                        </div>
                                        <TextInput type="text" placeholder="Trusted by millions." />
                                    </div>
                                    <div className='mb-4'>
                                        <div className="mb-2 block">
                                            <Label value="Testimonials Subtitle One" className="text-slate-500" />
                                        </div>
                                        <TextInput type="text" placeholder="Testimonials" />
                                    </div>
                                    <div className='mb-4'>
                                        <div className="mb-2 block">
                                            <Label value="Testimonials Subtitle Two" className="text-slate-500" />
                                        </div>
                                        <TextInput type="text" placeholder="Trustpilot" />
                                    </div>
                                    <h3 className='text-2xl font-medium text-slate-700 my-5'>
                                        Pricing Section
                                    </h3>
                                    <div className='mb-4'>
                                        <div className="mb-2 block">
                                            <Label value="Pricing Active" className="text-slate-500" />
                                        </div>
                                        <Select>
                                            <option selected>Active</option>
                                            <option>Passive</option>
                                        </Select>
                                    </div>
                                    <div className='mb-4'>
                                        <div className="mb-2 block">
                                            <Label value="Pricing Title" className="text-slate-500" />
                                        </div>
                                        <TextInput type="text" placeholder="Flexible Pricing." />
                                    </div>
                                    <div className='mb-4'>
                                        <div className="mb-2 block">
                                            <Label value="Pricing Description" className="text-slate-500" />
                                        </div>
                                        <Textarea rows={3} />
                                    </div>
                                    <div className='mb-4'>
                                        <div className="mb-2 block">
                                            <Label value="Pricing Save Percent" className="text-slate-500" />
                                        </div>
                                        <TextInput type="text" placeholder="Save 30%" />
                                    </div>

                                    <h3 className='text-2xl font-medium text-slate-700 my-5'>
                                        FAQ Section
                                    </h3>
                                    <div className='mb-4'>
                                        <div className="mb-2 block">
                                            <Label value="FAQ Active" className="text-slate-500" />
                                        </div>
                                        <Select>
                                            <option selected>Active</option>
                                            <option>Passive</option>
                                        </Select>
                                    </div>
                                    <div className='mb-4'>
                                        <div className="mb-2 block">
                                            <Label value="FAQ Title" className="text-slate-500" />
                                        </div>
                                        <TextInput type="text" placeholder="Have a question?" />
                                    </div>
                                    <div className='mb-4'>
                                        <div className="mb-2 block">
                                            <Label value="FAQ Subtitle" className="text-slate-500" />
                                        </div>
                                        <TextInput type="text" placeholder="Our support team will get assistance from AI-powered suggestions, making it quicker than ever to handle support requests." />
                                    </div>
                                    <div className='mb-4'>
                                        <div className="mb-2 block">
                                            <Label value="FAQ Text One" className="text-slate-500" />
                                        </div>
                                        <TextInput type="text" placeholder="FAQ" />
                                    </div>
                                    <div className='mb-4'>
                                        <div className="mb-2 block">
                                            <Label value="FAQ Text Two" className="text-slate-500" />
                                        </div>
                                        <TextInput type="text" placeholder="Help Center" />
                                    </div>

                                    <h3 className='text-2xl font-medium text-slate-700 my-5'>
                                        Blog Section
                                    </h3>
                                    <div className='mb-4'>
                                        <div className="mb-2 block">
                                            <Label value="Blog Active" className="text-slate-500" />
                                        </div>
                                        <Select>
                                            <option>Active</option>
                                            <option selected>Passive</option>
                                        </Select>
                                    </div>
                                    <div className='mb-4'>
                                        <div className="mb-2 block">
                                            <Label value="Blog Title" className="text-slate-500" />
                                        </div>
                                        <TextInput type="text" placeholder="Latest News" />
                                    </div>
                                    <div className='mb-4'>
                                        <div className="mb-2 block">
                                            <Label value="Blog Subtitle" className="text-slate-500" />
                                        </div>
                                        <TextInput type="text" placeholder="Stay up-to-date" />
                                    </div>
                                    <div className='mb-4'>
                                        <div className="mb-2 block">
                                            <Label value="Blog Posts Per Page" className="text-slate-500" />
                                        </div>
                                        <TextInput type="number" min={1} max={6} placeholder="3" />
                                    </div>
                                    <div className='mb-4'>
                                        <div className="mb-2 block">
                                            <Label value="Blog Button Text" className="text-slate-500" />
                                        </div>
                                        <TextInput type="text" placeholder="Show more" />
                                    </div>
                                    <h3 className='text-2xl font-medium text-slate-700 my-5'>
                                        Blog Archive Options
                                    </h3>
                                    <div className='mb-4'>
                                        <div className="mb-2 block">
                                            <Label value="Blog Title" className="text-slate-500" />
                                        </div>
                                        <TextInput type="text" placeholder="Blog Posts" />
                                    </div>
                                    <div className='mb-4'>
                                        <div className="mb-2 block">
                                            <Label value="Blog Subtitle" className="text-slate-500" />
                                        </div>
                                        <TextInput type="text" placeholder="Blog Posts" />
                                    </div>
                                    <div className='mb-4'>
                                        <div className="mb-2 block">
                                            <Label value="Blog Description" className="text-slate-500" />
                                        </div>
                                        <TextInput type="text" placeholder="Welcome to our cozy corner of the internet, where you will find a delightful collection of our heartfelt and thought-provoking blog posts." />
                                    </div>
                                    <div className='mb-4'>
                                        <div className="mb-2 block">
                                            <Label value="Blog Posts Per Page" className="text-slate-500" />
                                        </div>
                                        <TextInput type="number" placeholder="6" min={1} max={12} />
                                    </div>
                                    <div className="py-4 flex justify-center">
                                        <button className="bg-blue-600 hover:bg-blue-500 rounded-full py-2 px-4 text-white flex justify-center font-medium hover:-translate-y-2 duration-300 hover:drop-shadow-lg w-full">
                                            Save
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default SectionSettings;