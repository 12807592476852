import { 
    HiPlusSm,
    HiOutlineChevronLeft,
    HiOutlineViewGrid,
    HiOutlineX,
    HiOutlineStar,
    HiOutlineDotsVertical,
    HiFolder,
    HiOutlinePencil,
    HiOutlineMinusCircle
} from "react-icons/hi";
import { LuListFilter, LuList } from "react-icons/lu";
import { MdTitle } from "react-icons/md";
import { Link } from "react-router-dom";
import { Table, Dropdown, TextInput, Modal, Label } from 'flowbite-react';
import { useState } from 'react';
const Orders =()=>{   
    const [openNewfolder, setNewfolder] = useState(false);
    return(
        <>
            <div className='p-5 md:flex md:justify-between bg-white rounded-md my-2'>
                <div>
                    <div className='mb-1 text-gray-400 font-medium'>
                        <Link to="/" className="flex items-center">
                                <HiOutlineChevronLeft className="me-1" /> Back to dashboard
                        </Link>
                    </div>
                    <h2 className='text-4xl font-bold mb-3 md:mb-0'>My Orders</h2>
                </div>
            </div>
            <div className='bg-white rounded-md px-4 py-4 my-2 overflow-x-auto'>
                <div>
                    <Table>
                        <Table.Head className='bg-white p-0' >
                            <Table.HeadCell className='bg-transparent text-slate-500 p-0'>
                                <div className="border-b px-6 pb-4 mb-4">ORDER ID</div>
                            </Table.HeadCell>
                            <Table.HeadCell className='bg-transparent text-slate-500 p-0'>
                                <div className="border-b px-6 pb-4 mb-4">STATUS</div>
                            </Table.HeadCell>
                            <Table.HeadCell className='bg-transparent text-slate-500 p-0'>
                                <div className="border-b px-6 pb-4 mb-4">PLAN NAME</div> 
                            </Table.HeadCell>
                            <Table.HeadCell className='bg-transparent text-slate-500 p-0'>
                                <div className="border-b px-6 pb-4 mb-4">WORDS</div>
                            </Table.HeadCell>
                            <Table.HeadCell className='bg-transparent text-slate-500 p-0'>
                                <div className="border-b px-6 pb-4 mb-4">IMAGES</div>
                            </Table.HeadCell>
                            <Table.HeadCell className='bg-transparent text-slate-500 p-0'>
                                <div className="border-b px-6 pb-4 mb-4">PRICE</div>
                            </Table.HeadCell>
                            <Table.HeadCell className='bg-transparent text-slate-500 p-0'>
                                <div className="border-b px-6 pb-4 mb-4">TAX</div>
                            </Table.HeadCell>
                            <Table.HeadCell className='bg-transparent text-slate-500 p-0'>
                                <div className="border-b px-6 pb-4 mb-4">TYPE</div>
                            </Table.HeadCell>
                            <Table.HeadCell className='bg-transparent text-slate-500 p-0'>
                                <div className="border-b px-6 pb-4 mb-4">DATE</div>
                            </Table.HeadCell>
                            <Table.HeadCell className='bg-transparent text-slate-500 p-0'>
                                <div className="border-b px-6 pb-4 mb-4">ACTIONS</div>
                            </Table.HeadCell>
                        </Table.Head>
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell></Table.Cell>
                                <Table.Cell></Table.Cell>
                                <Table.Cell></Table.Cell>
                                <Table.Cell></Table.Cell>
                                <Table.Cell></Table.Cell>
                                <Table.Cell></Table.Cell>
                                <Table.Cell></Table.Cell>
                                <Table.Cell></Table.Cell>
                                <Table.Cell></Table.Cell>
                                <Table.Cell></Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                </div>
            </div>
        </>
    )
}
export default Orders;