import { Link } from "react-router-dom";
import { 
    HiOutlineChevronLeft,
} from "react-icons/hi";
import { Label, TextInput } from 'flowbite-react';
const Affiliate =()=>{
    return(
        <>
            <div className='p-5 md:flex md:justify-between bg-white rounded-md my-2'>
                <div>
                    <div className='mb-1 text-slate-400'>
                        <Link to="/admin/dashboard" className="flex items-center">
                                <HiOutlineChevronLeft className="me-1" /> Back to dashboard
                        </Link>
                    </div>
                    <h2 className='text-4xl font-bold mb-4'>Affiliate Settings</h2>
                </div> 
            </div>
            <div className='p-5 bg-white rounded-md mb-2'>
                <div className='grid md:grid-cols-12'>
                    <div className='md:col-span-6 md:col-start-4'>
                        <div class=''>
                            <div>
                                <form className="flex flex-col">
                                    <div className='mb-4'>
                                        <div className="mb-2 block">
                                            <Label value="Affiliate Minimum Withdrawal" className="text-slate-500" />
                                        </div>
                                        <TextInput type="number" placeholder="10" />
                                    </div>
                                    <div className='mb-4'>
                                        <div className="mb-2 block">
                                            <Label value="Affiliate Comission Percentage (%)" className="text-slate-500" />
                                        </div>
                                        <TextInput type="text" placeholder="10" />
                                    </div>
                                    <div className="mb-4 mt-4 flex justify-center">
                                        <button className="bg-blue-600 hover:bg-blue-500 rounded-full py-2 px-4 text-white flex justify-center font-medium hover:-translate-y-2 duration-300 hover:drop-shadow-lg w-full">
                                            Save
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Affiliate;