import { Link } from "react-router-dom";
import { 
    HiOutlineChevronLeft,
    HiOutlineInformationCircle,
    HiOutlinePlus,
    HiMinusSm,
} from "react-icons/hi";
import { Label, TextInput, Textarea,Select } from 'flowbite-react';
const AddUpdate =()=>{
    
    return(
        <>
            <div className='p-5 md:flex md:justify-between bg-white rounded-md my-2'>
                <div>
                    <div className='mb-1 text-slate-400'>
                        <Link to="/admin/dashboard" className="flex items-center">
                                <HiOutlineChevronLeft className="me-1" /> Back to dashboard
                        </Link>
                    </div>
                    <h2 className='text-4xl font-bold mb-1'>Add or Edit Custom Template</h2>
                </div>
            </div>
            <div className='p-5 bg-white rounded-md'>
                <div className='grid md:grid-cols-12'>
                    <div className='md:col-span-6 md:col-start-4'>
                        <div class=''>
                            <div>
                                <div className="flex items-center p-4 py-3 gap-3 rounded-xl bg-gray-100 mt-5 mb-8">
                                    <div className="w-6 h-6 flex justify-center items-center bg-purple-600 text-white  rounded-full font-bold ">
                                        1
                                    </div>
                                    <div className="text-slate-500 font-bold">Template</div>
                                </div>
                                <form className="flex flex-col">
                                    <div className='mb-4'>
                                        <div className="mb-2 relative flex items-center">
                                            <Label value="Template Title" className="text-slate-500" />
                                            <span className='cursor-pointer group'>
                                                <HiOutlineInformationCircle className='ms-1 text-slate-500' />
                                                <span className='absolute bottom-2 left-0 bg-slate-200 text-slate-600 p-2 rounded-md border text-xs invisible group-hover:visible group-hover:-translate-y-6 translate-y-4 duration-300 opacity-0 group-hover:opacity-100'>
                                                    A title for the template that will show in templates list and in search results
                                                </span>
                                            </span>
                                        </div>
                                        <TextInput type="text" />
                                    </div>
                                    <div className='mb-4'>
                                        <div className="mb-2 relative flex items-center">
                                            <Label value="Template Description" className="text-slate-500" />
                                            <span className='cursor-pointer group'>
                                                <HiOutlineInformationCircle className='ms-1 text-slate-500' />
                                                <span className='absolute bottom-2 left-0 bg-slate-200 text-slate-600 p-2 rounded-md border text-xs invisible group-hover:visible group-hover:-translate-y-6 translate-y-4 duration-300 opacity-0 group-hover:opacity-100'>
                                                    A short description about what this template do.
                                                </span>
                                            </span>
                                        </div>
                                        <Textarea rows={4}  />
                                    </div>
                                    <div className='mb-4'>
                                        <div className="mb-2 relative flex items-center">
                                            <Label className="text-slate-500">
                                                <span>
                                                    Template Icon <Link className="hover:underline text-blue-600">TablerIcons as SVG</Link>
                                                </span>
                                            </Label>
                                            <span className='cursor-pointer group'>
                                                <HiOutlineInformationCircle className='ms-1 text-slate-500' />
                                                <span className='absolute bottom-2 left-0 bg-slate-200 text-slate-600 p-2 rounded-md border text-xs invisible group-hover:visible group-hover:-translate-y-6 translate-y-4 duration-300 opacity-0 group-hover:opacity-100'>
                                                    Paste the svg code you get from the Tabler Icons or any other icon sets
                                                </span>
                                            </span>
                                        </div>
                                        <TextInput type="text" />
                                    </div>
                                    <div className='mb-4'>
                                        <div className="mb-2 relative flex items-center">
                                            <Label className="text-slate-500" value="Template Color" />
                                            <span className='cursor-pointer group'>
                                                <HiOutlineInformationCircle className='ms-1 text-slate-500' />
                                                <span className='absolute bottom-2 left-0 bg-slate-200 text-slate-600 p-2 rounded-md border text-xs invisible group-hover:visible group-hover:-translate-y-6 translate-y-4 duration-300 opacity-0 group-hover:opacity-100'>
                                                    Pick a color for for the icon container shape. Color is in HEX format.
                                                </span>
                                            </span>
                                        </div>
                                        <TextInput type="text" />
                                    </div>
                                    <div className='mb-4'>
                                        <div className="mb-2 relative flex items-center">
                                            <Label className="text-slate-500" value="Template Category" />
                                            <span className='cursor-pointer group'>
                                                <HiOutlineInformationCircle className='ms-1 text-slate-500' />
                                                <span className='absolute bottom-2 left-0 bg-slate-200 text-slate-600 p-2 rounded-md border text-xs invisible group-hover:visible group-hover:-translate-y-6 translate-y-4 duration-300 opacity-0 group-hover:opacity-100'>
                                                    Categories of the template. Useful for filtering in the templates list.
                                                </span>
                                            </span>
                                        </div>
                                        <TextInput type="text" />
                                    </div>
                                    <div className="bg-blue-100 text-blue-600 rounded-lg p-2 flex items-center mb-4 mt-2 text-sm">
                                        <span className='me-2'><HiOutlineInformationCircle className='text-2xl' /> 
                                        </span>  You can add more filters, just add a filter and hit enter.
                                    </div>
                                    <div className='mb-4'>
                                        <div className="mb-2 relative flex items-center">
                                            <Label className="text-slate-500" value="Package Type" />
                                            <span className='cursor-pointer group'>
                                                <HiOutlineInformationCircle className='ms-1 text-slate-500' />
                                                <span className='absolute bottom-2 left-0 bg-slate-200 text-slate-600 p-2 rounded-md border text-xs invisible group-hover:visible group-hover:-translate-y-6 translate-y-4 duration-300 opacity-0 group-hover:opacity-100'>
                                                    Choose package type for which plans accessible.
                                                </span>
                                            </span>
                                        </div>
                                        <Select>
                                            <option>Regular</option>
                                            <option>Premium</option>  
                                        </Select>
                                    </div>
                                    <div className="flex items-center justify-between p-4 py-3 gap-3 rounded-xl bg-gray-100 mt-10 mb-10">
                                        <div className="flex">
                                            <div className="w-6 h-6 flex justify-center items-center bg-purple-600 text-white  rounded-full font-bold ">
                                                2
                                            </div>
                                            <div className="text-slate-500 font-bold ms-4">Input Groups</div>
                                        </div>
                                        <div className="w-7 h-7 flex justify-center items-center bg-white hover:bg-black hover:text-white rounded-full font-bold cursor-pointer">
                                            <HiOutlinePlus />
                                        </div>
                                    </div>
                                    <div className="border rounded-xl p-5 mb-4">
                                        <div className='mb-4'>
                                            <div className="mb-2 relative flex items-center justify-between">
                                                <div className="flex items-center">
                                                    <Label className="text-slate-500" value="Select Input Type" />
                                                    <span className='cursor-pointer group'>
                                                        <HiOutlineInformationCircle className='ms-1 text-slate-500' />
                                                        <span className='absolute bottom-2 left-0 bg-slate-200 text-slate-600 p-2 rounded-md border text-xs invisible group-hover:visible group-hover:-translate-y-6 translate-y-4 duration-300 opacity-0 group-hover:opacity-100'>
                                                            Input fields for short texts and Textarea fields are good for long text.
                                                        </span>
                                                    </span>
                                                </div>
                                                <div className="w-8 h-8 flex justify-center items-center rounded-full hover:border-2 hover:border-red-600">
                                                    <div className="w-6 h-6 flex justify-center items-center rounded-full border-2 border-red-600 text-red-700 transition-all hover:text-white hover:bg-red-600"><HiMinusSm /></div>
                                                </div>
                                            </div>
                                            <Select>
                                                <option>Input Field</option>
                                                <option>Textarea Field</option>  
                                            </Select>
                                        </div>
                                        <div className='mb-4'>
                                            <div className="mb-2 relative flex items-center">
                                                <Label className="text-slate-500" value="Input Name" />
                                                <span className='cursor-pointer group'>
                                                    <HiOutlineInformationCircle className='ms-1 text-slate-500' />
                                                    <span className='absolute bottom-2 left-0 bg-slate-200 text-slate-600 p-2 rounded-md border text-xs invisible group-hover:visible group-hover:-translate-y-6 translate-y-4 duration-300 opacity-0 group-hover:opacity-100'>
                                                        Unique input name that you can use in your prompts later.
                                                    </span>
                                                </span>
                                            </div>
                                            <TextInput type="text" />
                                        </div>
                                        <div className='mb-4'>
                                            <div className="mb-2 relative flex items-center">
                                                <Label className="text-slate-500" value="Input Description" />
                                                <span className='cursor-pointer group'>
                                                    <HiOutlineInformationCircle className='ms-1 text-slate-500' />
                                                    <span className='absolute bottom-2 left-0 bg-slate-200 text-slate-600 p-2 rounded-md border text-xs invisible group-hover:visible group-hover:-translate-y-6 translate-y-4 duration-300 opacity-0 group-hover:opacity-100'>
                                                        A description for the input.
                                                    </span>
                                                </span>
                                            </div>
                                            <TextInput type="text" />
                                        </div>
                                    </div>
                                    <div className="flex items-center p-4 py-3 gap-3 rounded-xl bg-gray-100 mt-10 mb-10">
                                        <div className="w-6 h-6 flex justify-center items-center bg-purple-600 text-white  rounded-full font-bold ">
                                            3
                                        </div>
                                        <div className="text-slate-500 font-bold">Prompt</div>
                                    </div>
                                    <div className='mb-4'>
                                        <div className="mb-2 relative flex items-center">
                                            <Label className="text-slate-500" value="Created Inputs" />
                                            <span className='cursor-pointer group'>
                                                <HiOutlineInformationCircle className='ms-1 text-slate-500' />
                                                <span className='absolute bottom-2 left-0 bg-slate-200 text-slate-600 p-2 rounded-md border text-xs invisible group-hover:visible group-hover:-translate-y-6 translate-y-4 duration-300 opacity-0 group-hover:opacity-100'>
                                                    Click on each item to get the dynamic data from users input.
                                                </span>
                                            </span>
                                        </div>
                                        <TextInput type="text" />
                                    </div>
                                    <div className='mb-4'>
                                        <div className="mb-2">
                                            <Label className="text-slate-500" value="Custom Prompt" />
                                        </div>
                                        <Textarea rows={4} />
                                    </div>                                 
                                    <div className="py-4 flex justify-center">
                                        <button className="bg-blue-600 hover:bg-blue-500 rounded-full py-2 px-4 text-white flex justify-center font-medium hover:-translate-y-2 duration-300 hover:drop-shadow-lg w-full">
                                            Save
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default AddUpdate;