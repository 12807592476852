
import { 
    HiPlusSm,
    HiOutlineChevronLeft,
    HiOutlinePencil,
    HiOutlineX,
} from "react-icons/hi";
import { Table } from 'flowbite-react';
import { Link } from "react-router-dom";

const ChatbotTraining =()=>{
    return(
        <>
            <div className='p-5 md:flex md:justify-between bg-white rounded-md my-2'>
                <div>
                    <div className='mb-1 text-gray-400 font-medium'>
                        <Link to="/" className="flex items-center">
                                <HiOutlineChevronLeft className="me-1" /> Back to dashboard
                        </Link>
                    </div>
                    <h2 className='text-4xl font-bold mb-3 md:mb-0'>Chatbot Training</h2>
                    <p className='text-slate-400 mb-3 md:mb-0'>
                        Train MagicAI on your own data (website or PDF) and make your AI content exclusive.
                    </p>
                </div>
                <div className='flex items-center gap-3'>
                    <Link to="/admin/chatbottraining/new-chatbot">
                        <button class='bg-blue-600 hover:bg-blue-500 rounded-md py-2 px-4 text-white flex font-medium hover:-translate-y-2 duration-300 hover:drop-shadow-lg' >
                            <HiPlusSm className="mr-1 h-5 w-5" /> Add New Chatbot
                        </button>
                    </Link>
                </div>
            </div>

            <div className="p-5 mt-4 bg-white rounded-md mb-2">
                <div className="border rounded-md">
                    <div className="">
                        <div className="overflow-x-auto">
                            <Table className="bg-transparent">
                                <Table.Head>
                                    <Table.HeadCell className="bg-transparent border-b text-slate-500 font-medium whitespace-nowrap">
                                        Name
                                    </Table.HeadCell>
                                    <Table.HeadCell className="bg-transparent border-b text-slate-500 font-medium whitespace-nowrap">
                                        Status
                                    </Table.HeadCell>
                                    <Table.HeadCell className="bg-transparent border-b text-slate-500 font-medium whitespace-nowrap">
                                        Created
                                    </Table.HeadCell>
                                    <Table.HeadCell className="bg-transparent border-b text-slate-500 font-medium whitespace-nowrap">
                                        Model
                                    </Table.HeadCell>
                                    <Table.HeadCell className="bg-transparent border-b text-slate-500 font-medium whitespace-nowrap">
                                        Actions
                                    </Table.HeadCell>
                                </Table.Head>
                                <Table.Body className="divide-y">
                                    <Table.Row>
                                        <Table.Cell className="whitespace-nowrap">
                                        <div className='flex items-center'>
                                            <div>
                                                <div className='h-10 w-10 bg-slate-200 rounded-full flex items-center justify-center font-bold text-lg'>
                                                    UN
                                                </div>
                                            </div>
                                            <div className='ps-3'>
                                                <h3 className="truncate md:max-w-64 text-wrap md:text-nowrap">
                                                    Untitled Chatbot
                                                </h3>
                                            </div>
                                        </div>
                                        </Table.Cell>
                                        <Table.Cell className="">
                                            <span className="bg-slate-100 px-4 py-1 rounded-full font-semibold text-slate-400">
                                                Not Trained
                                            </span>
                                        </Table.Cell>
                                        <Table.Cell className="min-w-36">
                                            1 month ago
                                        </Table.Cell>
                                        <Table.Cell>
                                            gpt-3.5-turbo
                                        </Table.Cell>
                                        <Table.Cell>
                                            <div className='flex gap-2'>
                                                <div className='h-10 w-10 flex justify-center items-center bg-white  hover:bg-blue-600 rounded-full shadow-md hover:-translate-y-1 duration-300 hover:shadow-xl cursor-pointer hover:text-white' title="Edit">
                                                    <HiOutlinePencil className='text-lg' />
                                                </div>
                                                <div className='h-10 w-10 flex justify-center items-center bg-white  hover:bg-red-700 rounded-full shadow-md hover:-translate-y-1 duration-300 hover:shadow-xl cursor-pointer hover:text-white' title="Delete">
                                                    <HiOutlineX className='text-lg' />
                                                </div>
                                            </div>
                                        </Table.Cell>
                                    </Table.Row>
                                </Table.Body>
                            </Table>
                        </div>
                    </div>
                </div>
                <div className="flex overflow-x-auto sm:justify-end mt-5">
                    <nav class="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
                        <Link class="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0">
                            <span class="sr-only">Previous</span>
                            <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                <path fill-rule="evenodd" d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z" clip-rule="evenodd" />
                            </svg>
                        </Link>
                        <Link aria-current="page" class="relative z-10 inline-flex items-center bg-indigo-600 px-4 py-2 text-sm font-semibold text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                            1
                        </Link>
                        <Link class="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0">
                            2
                        </Link>
                        <Link class="relative hidden items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 md:inline-flex">
                            3
                        </Link>
                        <Link class="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0">
                            <span class="sr-only">Next</span>
                            <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                <path fill-rule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clip-rule="evenodd" />
                            </svg>
                        </Link>
                    </nav>
                </div>
            </div>
        </>
    )
}
export default ChatbotTraining;