import { Tabs } from 'flowbite-react';
import { 
    HiChevronRight,
    HiStar,
    HiOutlineStar,
    HiOutlineChevronLeft,
} from "react-icons/hi";
import { MdTitle } from "react-icons/md";
import { Link } from 'react-router-dom';

const Aiwriter = () =>{
   
    return(
        <>
            <div className='p-5 md:flex md:justify-between bg-white rounded-md my-2'>
                <div>
                    <div className='mb-1 text-gray-400 font-medium'>
                        <Link to="/" className="flex items-center">
                                <HiOutlineChevronLeft className="me-1" /> Back to dashboard
                        </Link>
                    </div>
                    <h2 className='text-4xl font-bold'>AI Writer</h2>
                    <p className='text-slate-400 mb-3 md:mb-0'>Text Generator & AI Copywriting Assistant</p>
                </div>
            </div>

            <div className='bg-white rounded-md px-4 py-4 my-2'>
                <div className="md:flex justify-between items-center">
                    <div className="md:flex gap-x-4">
                        <div>
                            <ul className="md:flex gap-x-6">
                                <li className="px-4 py-1 bg-gray-200 hover:bg-gray-200 rounded-md cursor-pointer mb-1 md:mb-0">All</li>
                                <li className="px-4 py-1 hover:bg-gray-200 rounded-md cursor-pointer mb-1 md:mb-0">
                                    Blog
                                </li>
                                <li className="px-4 py-1 hover:bg-gray-200 rounded-md cursor-pointer mb-1 md:mb-0">Ecommerce</li>
                                <li className="px-4 py-1 hover:bg-gray-200 rounded-md cursor-pointer mb-1 md:mb-0">
                                    Development
                                </li>
                                <li className="px-4 py-1 hover:bg-gray-200 rounded-md cursor-pointer mb-1 md:mb-0">Advertisment</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className='bg-white rounded-md px-4 py-4 my-2'>
                <div class='grid sm:grid-cols-2 lg:grid-cols-4 gap-2 p-0'>
                    <Link to="/user/post-title-generator">
                        <div className='p-5 relative odd:bg-[#C5EED3] even:bg-[#FFF4DE] rounded-md'>
                            <div className='h-8 w-8 bg-white rounded-full flex items-center justify-center absolute top-4 right-2 cursor-pointer drop-shadow-md hover:drop-shadow-xl ease-in hover:-translate-y-1 duration-300'>
                                <HiOutlineStar className='text-slate-500'  />
                            </div>
                            <div className='mt-14 cursor-pointer group '>
                                <div className='h-12 w-12 bg-[#EDFFF3] rounded-full flex items-center justify-center  cursor-pointer relative mb-4 group-hover:drop-shadow-lg ease-in group-hover:-translate-y-1 duration-300'>
                                    <MdTitle className='text-slate-800 text-2xl h-6' />
                                    <div className='h-4 w-4 bg-[#73A685] rounded-full absolute bottom-0 right-0 border-2 border-white'></div>
                                </div>
                                <h2 className='text-xl font-medium flex items-center justify-between'>
                                    <span>Post Title Generator</span> 
                                    <span className='invisible group-hover:visible transition-all'>
                                        <HiChevronRight className='text-xl font-medium' />
                                    </span>
                                </h2>
                                <p className='mt-3 text-slate-500'>
                                    Get captivating post titles instantly with our title generator. Boost engagement and save time.
                                </p>
                            </div>
                        </div>
                    </Link>
                    <div className='p-5 relative odd:bg-[#C5EED3] even:bg-[#FFF4DE] rounded-md'>
                        <div className='h-8 w-8 bg-white rounded-full flex items-center justify-center absolute top-4 right-2 cursor-pointer drop-shadow-md hover:drop-shadow-xl ease-in hover:-translate-y-1 duration-300'>
                            <HiOutlineStar className='text-slate-500'  />
                        </div>
                        <div className='mt-14 cursor-pointer group '>
                            <div className='h-12 w-12 bg-[#EDFFF3] rounded-full flex items-center justify-center  cursor-pointer relative mb-4 group-hover:drop-shadow-lg ease-in group-hover:-translate-y-1 duration-300'>
                                <MdTitle className='text-slate-800 text-2xl h-6' />
                                <div className='h-4 w-4 bg-[#73A685] rounded-full absolute bottom-0 right-0 border-2 border-white'></div>
                            </div>
                            <h2 className='text-xl font-medium flex items-center justify-between'>
                                <span>Post Title Generator</span> 
                                <span className='invisible group-hover:visible transition-all'>
                                    <HiChevronRight className='text-xl font-medium' />
                                </span>
                            </h2>
                            <p className='mt-3 text-slate-500'>
                                Get captivating post titles instantly with our title generator. Boost engagement and save time.
                            </p>
                        </div>
                    </div>
                    <div className='p-5 relative odd:bg-[#C5EED3] even:bg-[#FFF4DE] rounded-md'>
                        <div className='h-8 w-8 bg-white rounded-full flex items-center justify-center absolute top-4 right-2 cursor-pointer drop-shadow-md hover:drop-shadow-xl ease-in hover:-translate-y-1 duration-300'>
                            <HiOutlineStar className='text-slate-500'  />
                        </div>
                        <div className='mt-14 cursor-pointer group '>
                            <div className='h-12 w-12 bg-[#EDFFF3] rounded-full flex items-center justify-center  cursor-pointer relative mb-4 group-hover:drop-shadow-lg ease-in group-hover:-translate-y-1 duration-300'>
                                <MdTitle className='text-slate-800 text-2xl h-6' />
                                <div className='h-4 w-4 bg-[#73A685] rounded-full absolute bottom-0 right-0 border-2 border-white'></div>
                            </div>
                            <h2 className='text-xl font-medium flex items-center justify-between'>
                                <span>Post Title Generator</span> 
                                <span className='invisible group-hover:visible transition-all'>
                                    <HiChevronRight className='text-xl font-medium' />
                                </span>
                            </h2>
                            <p className='mt-3 text-slate-500'>
                                Get captivating post titles instantly with our title generator. Boost engagement and save time.
                            </p>
                        </div>
                    </div>
                    <div className='p-5 relative odd:bg-[#C5EED3] even:bg-[#FFF4DE] rounded-md'>
                        <div className='h-8 w-8 bg-white rounded-full flex items-center justify-center absolute top-4 right-2 cursor-pointer drop-shadow-md hover:drop-shadow-xl ease-in hover:-translate-y-1 duration-300'>
                            <HiOutlineStar className='text-slate-500'  />
                        </div>
                        <div className='mt-14 cursor-pointer group '>
                            <div className='h-12 w-12 bg-[#EDFFF3] rounded-full flex items-center justify-center  cursor-pointer relative mb-4 group-hover:drop-shadow-lg ease-in group-hover:-translate-y-1 duration-300'>
                                <MdTitle className='text-slate-800 text-2xl h-6' />
                                <div className='h-4 w-4 bg-[#73A685] rounded-full absolute bottom-0 right-0 border-2 border-white'></div>
                            </div>
                            <h2 className='text-xl font-medium flex items-center justify-between'>
                                <span>Post Title Generator</span> 
                                <span className='invisible group-hover:visible transition-all'>
                                    <HiChevronRight className='text-xl font-medium' />
                                </span>
                            </h2>
                            <p className='mt-3 text-slate-500'>
                                Get captivating post titles instantly with our title generator. Boost engagement and save time.
                            </p>
                        </div>
                    </div>
                    <div className='p-5 relative odd:bg-[#C5EED3] even:bg-[#FFF4DE] rounded-md'>
                        <div className='h-8 w-8 bg-white rounded-full flex items-center justify-center absolute top-4 right-2 cursor-pointer drop-shadow-md hover:drop-shadow-xl ease-in hover:-translate-y-1 duration-300'>
                            <HiOutlineStar className='text-slate-500'  />
                        </div>
                        <div className='mt-14 cursor-pointer group '>
                            <div className='h-12 w-12 bg-[#EDFFF3] rounded-full flex items-center justify-center  cursor-pointer relative mb-4 group-hover:drop-shadow-lg ease-in group-hover:-translate-y-1 duration-300'>
                                <MdTitle className='text-slate-800 text-2xl h-6' />
                                <div className='h-4 w-4 bg-[#73A685] rounded-full absolute bottom-0 right-0 border-2 border-white'></div>
                            </div>
                            <h2 className='text-xl font-medium flex items-center justify-between'>
                                <span>Post Title Generator</span> 
                                <span className='invisible group-hover:visible transition-all'>
                                    <HiChevronRight className='text-xl font-medium' />
                                </span>
                            </h2>
                            <p className='mt-3 text-slate-500'>
                                Get captivating post titles instantly with our title generator. Boost engagement and save time.
                            </p>
                        </div>
                    </div>
                    <div className='p-5 relative odd:bg-[#C5EED3] even:bg-[#FFF4DE] rounded-md'>
                        <div className='h-8 w-8 bg-white rounded-full flex items-center justify-center absolute top-4 right-2 cursor-pointer drop-shadow-md hover:drop-shadow-xl ease-in hover:-translate-y-1 duration-300'>
                            <HiOutlineStar className='text-slate-500'  />
                        </div>
                        <div className='mt-14 cursor-pointer group '>
                            <div className='h-12 w-12 bg-[#EDFFF3] rounded-full flex items-center justify-center  cursor-pointer relative mb-4 group-hover:drop-shadow-lg ease-in group-hover:-translate-y-1 duration-300'>
                                <MdTitle className='text-slate-800 text-2xl h-6' />
                                <div className='h-4 w-4 bg-[#73A685] rounded-full absolute bottom-0 right-0 border-2 border-white'></div>
                            </div>
                            <h2 className='text-xl font-medium flex items-center justify-between'>
                                <span>Post Title Generator</span> 
                                <span className='invisible group-hover:visible transition-all'>
                                    <HiChevronRight className='text-xl font-medium' />
                                </span>
                            </h2>
                            <p className='mt-3 text-slate-500'>
                                Get captivating post titles instantly with our title generator. Boost engagement and save time.
                            </p>
                        </div>
                    </div>
                    <div className='p-5 relative odd:bg-[#C5EED3] even:bg-[#FFF4DE] rounded-md'>
                        <div className='h-8 w-8 bg-white rounded-full flex items-center justify-center absolute top-4 right-2 cursor-pointer drop-shadow-md hover:drop-shadow-xl ease-in hover:-translate-y-1 duration-300'>
                            <HiOutlineStar className='text-slate-500'  />
                        </div>
                        <div className='mt-14 cursor-pointer group '>
                            <div className='h-12 w-12 bg-[#EDFFF3] rounded-full flex items-center justify-center  cursor-pointer relative mb-4 group-hover:drop-shadow-lg ease-in group-hover:-translate-y-1 duration-300'>
                                <MdTitle className='text-slate-800 text-2xl h-6' />
                                <div className='h-4 w-4 bg-[#73A685] rounded-full absolute bottom-0 right-0 border-2 border-white'></div>
                            </div>
                            <h2 className='text-xl font-medium flex items-center justify-between'>
                                <span>Post Title Generator</span> 
                                <span className='invisible group-hover:visible transition-all'>
                                    <HiChevronRight className='text-xl font-medium' />
                                </span>
                            </h2>
                            <p className='mt-3 text-slate-500'>
                                Get captivating post titles instantly with our title generator. Boost engagement and save time.
                            </p>
                        </div>
                    </div>
                    <div className='p-5 relative odd:bg-[#C5EED3] even:bg-[#FFF4DE] rounded-md'>
                        <div className='h-8 w-8 bg-white rounded-full flex items-center justify-center absolute top-4 right-2 cursor-pointer drop-shadow-md hover:drop-shadow-xl ease-in hover:-translate-y-1 duration-300'>
                            <HiOutlineStar className='text-slate-500'  />
                        </div>
                        <div className='mt-14 cursor-pointer group '>
                            <div className='h-12 w-12 bg-[#EDFFF3] rounded-full flex items-center justify-center  cursor-pointer relative mb-4 group-hover:drop-shadow-lg ease-in group-hover:-translate-y-1 duration-300'>
                                <MdTitle className='text-slate-800 text-2xl h-6' />
                                <div className='h-4 w-4 bg-[#73A685] rounded-full absolute bottom-0 right-0 border-2 border-white'></div>
                            </div>
                            <h2 className='text-xl font-medium flex items-center justify-between'>
                                <span>Post Title Generator</span> 
                                <span className='invisible group-hover:visible transition-all'>
                                    <HiChevronRight className='text-xl font-medium' />
                                </span>
                            </h2>
                            <p className='mt-3 text-slate-500'>
                                Get captivating post titles instantly with our title generator. Boost engagement and save time.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Aiwriter;