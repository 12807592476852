
import { 
    HiOutlineChevronLeft,
    HiOutlinePencil,
    HiOutlineInformationCircle,
    HiOutlineRefresh,
} from "react-icons/hi";
import { Link } from "react-router-dom";

const NewChatbot =()=>{
    return(
        <>
            <div className='p-5 md:flex md:justify-between bg-white rounded-md my-2'>
                <div>
                    <div className='mb-1 text-gray-400 font-medium'>
                        <Link to="/" className="flex items-center">
                                <HiOutlineChevronLeft className="me-1" /> Back to dashboard
                        </Link>
                    </div>
                    <h2 className='text-4xl font-bold mb-3 md:mb-0'>Chatbot Training</h2>
                </div>
            </div>
            <div className='p-5 bg-white rounded-md mb-2'>
                <div className='grid md:grid-cols-12'>
                    <div className='md:col-span-6 md:col-start-4'>
                        <div class=''>
                            <div className="flex justify-between items-center mb-4 relative">
                                <div className="flex items-center">
                                    <h3 className="text-2xl font-bold">Untitled chatbot</h3> 
                                    <span className="ms-2 w-6 h-6 rounded-full border flex justify-center items-center cursor-pointer">
                                        <HiOutlinePencil className="text-sm" />
                                    </span>
                                </div>
                                <div className="flex items-center ">
                                    <span>Need help?</span>
                                    <span className='cursor-pointer group'>
                                        <HiOutlineInformationCircle className='ms-1 text-base' />
                                        <span className='absolute bottom-4 right-0 bg-slate-200 text-slate-600 p-2 rounded-md border text-xs invisible group-hover:visible group-hover:-translate-y-6 translate-y-4 duration-300 opacity-0 group-hover:opacity-100'>
                                            You can deploy your trained chatbot to an existing AI Chat template. Simply navigate to Chat Templates select Edit Template, and assign your chatbot there.
                                        </span>
                                    </span>
                                </div>
                            </div>
                            <p className="mb-4">
                                Simply select the source and MagicAI will do the rest to train your GPT in seconds.
                            </p>
                            <div className="py-2 px-4 mb-4 rounded-full bg-slate-100">
                                <div>
                                    <ul className="md:flex justify-between">
                                        <li className="px-6 py-1 bg-white hover:bg-white rounded-full cursor-pointer mb-1 md:mb-0 font-medium shadow-md">
                                            Website
                                        </li>
                                        <li className="px-6 py-1 hover:bg-white rounded-full cursor-pointer mb-1 md:mb-0 font-medium hover:shadow-md">
                                            PDF
                                        </li>
                                        <li className="px-6 py-1 hover:bg-white rounded-full cursor-pointer mb-1 md:mb-0 font-medium hover:shadow-md">
                                            Text
                                        </li>
                                        <li className="px-6 py-1 hover:bg-white rounded-full cursor-pointer mb-1 md:mb-0 font-medium hover:shadow-md">
                                            Q&A
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="flex items-center p-4 py-3 gap-3 rounded-xl bg-gray-100 mt-8 mb-8">
                                <div className="w-6 h-6 flex justify-center items-center bg-purple-600 text-white rounded-full font-bold ">
                                    1
                                </div>
                                <div className="text-slate-500 font-bold">Add URL</div>
                            </div>
                            <div>
                                <form className="flex flex-col">
                                    <div className='mb-4'>
                                        <div className="mb-2 ">
                                            <ul className="flex gap-x-4">
                                                <li>
                                                    <span className="underline cursor-pointer">Website</span>
                                                </li>
                                                <li><span className="text-slate-400">Single URL</span></li>
                                            </ul>
                                        </div>
                                        <div className="relative">
                                            <input type="text" class="bg-white w-full border-1 border-slate-300 rounded-md" placeholder="https://example.com" />
                                            <div className="absolute top-3 right-3 cursor-pointer">
                                                <HiOutlineRefresh />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex items-center justify-between p-4 py-3 gap-3 rounded-xl bg-gray-100 mt-8 mb-8">
                                        <div className="flex items-center gap-x-3">
                                            <div className="w-6 h-6 flex justify-center items-center bg-purple-600 text-white rounded-full font-bold ">
                                                2
                                            </div>
                                            <div className="text-slate-500 font-bold">Select Pages</div>
                                        </div>
                                        <div>
                                            <div className="cursor-pointer text-slate-500 font-semibold">Deselect All</div>
                                        </div>
                                    </div>
                                    <div className="py-4 flex justify-center items-center">
                                        <button className="bg-blue-600 hover:bg-blue-500 rounded-full  px-4 py-2 text-white flex justify-center font-medium hover:-translate-y-2 duration-300 hover:drop-shadow-lg w-full">
                                            Train GPT
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default NewChatbot;