import { Link } from "react-router-dom";
import { 
    HiOutlineChevronLeft,
    HiOutlineInformationCircle
} from "react-icons/hi";
import { FiAlertTriangle } from "react-icons/fi";
import { Label, Select, Textarea } from 'flowbite-react';
const StableDiffusion =()=>{
    return(
        <>
            <div className='p-5 md:flex md:justify-between bg-white rounded-md my-2'>
                <div>
                    <div className='mb-1 text-slate-400'>
                        <Link to="/admin/dashboard" className="flex items-center">
                                <HiOutlineChevronLeft className="me-1" /> Back to dashboard
                        </Link>
                    </div>
                    <h2 className='text-4xl font-bold'>StableDiffusion Settings</h2>
                </div> 
            </div>
            <div className='p-5 bg-white rounded-md mb-2'>
                <div className='grid md:grid-cols-12'>
                    <div className='md:col-span-6 md:col-start-4'>
                        <div class=''>
                            <h3 className='text-2xl font-medium text-slate-700 mb-5'>StableDiffusion Settings</h3>
                            <div>
                                <form className="flex flex-col">
                                    <div className='mb-4'>
                                        <div className="mb-2 block">
                                            <Label value="StableDiffusion API Secret" className="text-slate-500" />
                                        </div>
                                        <Textarea rows={2} />
                                        <div className="bg-blue-100 text-blue-600 rounded-xl p-3 flex mt-2 text-sm">
                                        <span className='me-1'><HiOutlineInformationCircle className='text-2xl' /></span>
                                            You can enter as much API KEY as you want. Click "Enter" after each api key.
                                        </div>
                                        <div className="bg-orange-100 text-orange-600 rounded-xl p-3 flex mt-2 text-sm">
                                        <span className='me-1'><FiAlertTriangle className='text-2xl' /> </span> Please ensure that your stable diffusion API key is fully functional and billing defined on your stable diffusion account.
                                        </div>
                                    </div>
                                    <div className="mb-4 flex justify-center">
                                        <button className="bg-blue-600 hover:bg-blue-500 rounded-full py-2 px-4 text-white flex justify-center font-medium hover:-translate-y-2 duration-300 hover:drop-shadow-lg w-full">
                                            After Saving Setting, Click Here to Test Your Api Keys
                                        </button>
                                    </div>
                                    <div className='mb-4'>
                                        <div className="mb-2 block">
                                            <Label value="Default stablediffusion Language" className="text-slate-500" />
                                        </div>
                                        <Select>
                                            <option>Select</option>
                                        </Select>
                                    </div>
                                    <div className='mb-4'>
                                        <div className="mb-2 block">
                                            <Label value="Stablediffusion default model" className="text-slate-500" />
                                        </div>
                                        <Select>
                                            <option>Select</option>
                                        </Select>
                                    </div>
                                    <div className="py-4 flex justify-center">
                                        <button className="bg-blue-600 hover:bg-blue-500 rounded-full py-2 px-4 text-white flex justify-center font-medium hover:-translate-y-2 duration-300 hover:drop-shadow-lg w-full">
                                            Save
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default StableDiffusion;