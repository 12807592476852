import { Link } from "react-router-dom";
import { 
    HiOutlineChevronLeft,
    HiOutlineInformationCircle
} from "react-icons/hi";
import { FiAlertTriangle } from "react-icons/fi";
import { Label, TextInput, Select, Textarea } from 'flowbite-react';
const Openai =()=>{
    return(
        <>
            <div className='p-5 md:flex md:justify-between bg-white rounded-md my-2'>
                <div>
                    <div className='mb-1 text-slate-400'>
                        <Link to="/admin/dashboard" className="flex items-center">
                                <HiOutlineChevronLeft className="me-1" /> Back to dashboard
                        </Link>
                    </div>
                    <h2 className='text-4xl font-bold'>OpenAI Settings</h2>
                </div> 
            </div>
            <div className='p-5 bg-white rounded-md mb-2'>
                <div className='grid md:grid-cols-12'>
                    <div className='md:col-span-6 md:col-start-4'>
                        <div class=''>
                            <h3 className='text-2xl font-medium text-slate-700 mb-5'>OpenAI Settings</h3>
                            <div>
                                <form className="flex flex-col">
                                    <div className='mb-4'>
                                        <div className="mb-2 block">
                                            <Label value="OpenAi API Secret" className="text-slate-500" />
                                        </div>
                                        <Textarea rows={4} />
                                        <div className="bg-blue-100 text-blue-600 rounded-xl p-3 flex mt-2 text-sm">
                                        <span className='me-1'><HiOutlineInformationCircle className='text-2xl' /></span>
                                          You can enter as much API KEY as you want. Click "Enter" after each api key.
                                        </div>
                                        <div className="bg-orange-100 text-orange-600 rounded-xl p-3 flex mt-2 text-sm">
                                        <span className='me-1'><FiAlertTriangle className='text-2xl' /> </span> Please ensure that your OpenAI API key is fully functional and billing defined on your OpenAI account.
                                        </div>
                                    </div>
                                    <div className="mb-4 flex justify-center">
                                        <button className="bg-blue-600 hover:bg-blue-500 rounded-full py-2 px-4 text-white flex justify-center font-medium hover:-translate-y-2 duration-300 hover:drop-shadow-lg w-full">
                                            After Saving Setting, Click Here to Test Your Api Keys
                                        </button>
                                    </div>
                                    <div className='mb-4'>
                                        <div className="mb-2 block">
                                            <Label value="Default Dall-E Model" className="text-slate-500" />
                                        </div>
                                        <Select>
                                            <option>Dall-E-2</option>
                                            <option>Dall-E-3</option>
                                        </Select>
                                    </div>
                                    <div className='mb-4'>
                                        <div className="mb-2 block">
                                            <Label value="Default Openai Model" className="text-slate-500" />
                                        </div>
                                        <Select>
                                            <option>Select</option>
                                        </Select>
                                        <div className="bg-blue-100 text-blue-600 rounded-xl p-2 flex mt-2 text-sm">
                                            <span className='me-1'><HiOutlineInformationCircle className='text-2xl' /> </span>  Please note GPT-4 is not working with every api_key. You have to have an api key which can work with GPT-4. Also please note that Chat models works with ChatGPT and GPT-4 models. So if you choose below it will automatically use ChatGPT.
                                        </div>
                                    </div>
                                    <div className='mb-4'>
                                        <div className="mb-2 block">
                                            <Label value="Default Openai Language" className="text-slate-500" />
                                        </div>
                                        <Select>
                                            <option>Select</option>
                                        </Select>
                                    </div>
                                    <div className='mb-4'>
                                        <div className="mb-2 block">
                                            <Label value="Default Tone of Voice" className="text-slate-500" />
                                        </div>
                                        <Select>
                                            <option>Select</option>
                                        </Select>
                                    </div>
                                    <div className='mb-4'>
                                        <div className="mb-2 block">
                                            <Label value="Default Creativity" className="text-slate-500" />
                                        </div>
                                        <Select>
                                            <option>Select</option>
                                        </Select>
                                    </div>
                                    <div className='mb-4'>
                                        <div className="mb-2 block">
                                            <Label value="Default Stream Server" className="text-slate-500" />
                                        </div>
                                        <Select>
                                            <option>Select</option>
                                        </Select>
                                    </div>
                                    <div className='grid md:grid-cols-2 gap-4'>
                                        <div className='mb-4'>
                                            <div className="mb-2 block">
                                                <Label value="Maximum Input Length" className="text-slate-500" />
                                            </div>
                                            <TextInput type="number" min="10" max="2000"  />
                                            <div className="bg-blue-100 text-blue-600 rounded-xl p-2 flex mt-2 text-sm">
                                                <span className='me-1'>
                                                    <HiOutlineInformationCircle className='text-2xl' /> 
                                                </span> In Characters
                                            </div>
                                        </div>
                                        <div className='mb-4'>
                                            <div className="mb-2 block">
                                                <Label value="Maximum Output Length" className="text-slate-500" />
                                            </div>
                                            <TextInput type="number" min="0"  />
                                            <div className="bg-blue-100 text-blue-600 rounded-xl p-2 flex mt-2 text-sm">
                                                <span className='me-1'>
                                                    <HiOutlineInformationCircle className='text-2xl' /> 
                                                </span>  In Words. OpenAI has a hard limit based on Token limits for each model. Refer to OpenAI documentation to learn more. As a recommended by OpenAI, max result length is capped at 2000 tokens The maximum output length refers to the point at which the AI-generated response will stop. It can occur when the response reaches 4096 bytes or when the generated content is considered sufficient for the given context.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="py-4 flex justify-center">
                                        <button className="bg-blue-600 hover:bg-blue-500 rounded-full py-2 px-4 text-white flex justify-center font-medium hover:-translate-y-2 duration-300 hover:drop-shadow-lg w-full">
                                            Save
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Openai;