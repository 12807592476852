import { 
    HiPlusSm,
    HiOutlineChevronUp,
    HiOutlineCamera,
    HiOutlinePencil,
    HiOutlineUpload
} from "react-icons/hi";
import { LuUserPlus } from "react-icons/lu";
import { FaDollarSign } from "react-icons/fa6";
import { Button } from 'flowbite-react';
const DashboardHome = () =>{
    return(
        <>
            <div className='p-5 md:flex md:justify-between bg-white rounded-md my-2'>
                <div>
                    <div className='mb-1 text-gray-400 font-medium'>Admin Dashboard</div>
                    <h2 className='text-4xl font-bold mb-3 md:mb-0'>Overview</h2>
                </div>
                <div className='flex items-center gap-4'>
                    <button class='bg-white hover:bg-gray-50/50 rounded-md py-2 px-4 flex font-medium hover:-translate-y-2 duration-300 border hover:shadow-lg'>
                        <HiOutlineUpload className="mr-2 h-5 w-5" /> My Documents
                    </button>
                    <button class='bg-blue-600 hover:bg-blue-500 rounded-md py-2 px-4 text-white flex font-medium hover:-translate-y-2 duration-300 hover:drop-shadow-lg'>
                        <HiPlusSm className="mr-2 h-5 w-5" /> New
                    </button>
                </div>
            </div>
            <div className='p-5 bg-white rounded-md'>
                <div class="grid sm:grid-cols-2 lg:grid-cols-4 gap-2 xl:gap-6">
                    <div className='min-h-24 border rounded-md flex items-center'>
                        <div>
                            <div className='h-12 w-12 rounded-full flex items-center justify-center shadow-md mx-4'>
                            <FaDollarSign className='text-2xl' />
                            </div>
                        </div>
                        <div className='ml-2'>
                            <div className='text-gray-500 mb-1'>Total sales</div>
                            <div className='text-xl font-bold flex items-center'>
                                <span><FaDollarSign /></span>
                                <span className='ml-1'>0</span>
                                <span className='flex items-center ml-2 text-xs bg-green-100 text-green-600 px-1 rounded-sm font-semibold'>
                                    <HiOutlineChevronUp className='mr-1' /> 0%
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className='min-h-24 border rounded-md flex items-center'>
                        <div>
                            <div className='h-12 w-12 rounded-full flex items-center justify-center shadow-md mx-4'>
                            <LuUserPlus className='text-2xl' />
                            </div>
                        </div>
                        <div className='ml-2'>
                            <div className='text-gray-500 mb-1'>New users</div>
                            <div className='text-xl font-bold flex items-center'>
                                <span><FaDollarSign /></span>
                                <span className='ml-1'>0</span>
                                <span className='flex items-center ml-2 text-xs bg-green-100 text-green-600 px-1 rounded-sm font-semibold'>
                                    <HiOutlineChevronUp className='mr-1' /> 100%
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className='min-h-24 border rounded-md flex items-center'>
                        <div>
                            <div className='h-12 w-12 rounded-full flex items-center justify-center shadow-md mx-4'>
                                <HiOutlinePencil className='text-2xl' />
                            </div>
                        </div>
                        <div className='ml-2'>
                            <div className='text-gray-500 mb-1'>Words Generated</div>
                            <div className='text-xl font-bold flex items-center'>
                                <span><FaDollarSign /></span>
                                <span className='ml-1'>0</span>
                                <span className='flex items-center ml-2 text-xs bg-green-100 text-green-600 px-1 rounded-sm font-semibold'>
                                    <HiOutlineChevronUp className='mr-1' /> 0%
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className='min-h-24 border rounded-md flex items-center'>
                        <div>
                            <div className='h-12 w-12 rounded-full flex items-center justify-center shadow-md mx-4'>
                                <HiOutlineCamera className='text-2xl' /> 
                            </div>
                        </div>
                        <div className='ml-2'>
                            <div className='text-gray-500 mb-1'>Images Generated</div>
                            <div className='text-xl font-bold flex items-center'>
                                <span><FaDollarSign /></span>
                                <span className='ml-1'>0</span>
                                <span className='flex items-center ml-2 text-xs bg-green-100 text-green-600 px-1 rounded-sm font-semibold'>
                                    <HiOutlineChevronUp className='mr-1' /> 0%
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='mt-8 grid sm:grid-cols-2 gap-6'> 
                    <div className='sm:min-h-64 border rounded-md'>
                        <div className='p-4 border-b font-medium text-slate-600'>Revenue</div>
                    </div>
                    <div className='sm:min-h-64 border rounded-md'>
                        <div className='p-4 border-b font-medium text-slate-600'>Generated Content</div>
                    </div>
                    <div className='sm:min-h-64 border rounded-md'>
                        <div className='p-4 border-b font-medium text-slate-600'>Top Countries</div>
                    </div>
                    <div className='sm:min-h-64 border rounded-md'>
                        <div className='p-4 border-b font-medium text-slate-600'>Activity</div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default DashboardHome;