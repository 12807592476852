import { Link } from "react-router-dom";
import { 
    HiOutlineChevronLeft,
    HiOutlineDocumentText,
    HiOutlinePlus,
    HiOutlineDotsVertical,
} from "react-icons/hi";
import { FiRotateCw } from "react-icons/fi";
import { Select, ToggleSwitch } from 'flowbite-react';
import { useState } from 'react';
const Languages =()=>{

    const [switch1, setSwitch1] = useState(true);
    const [switch2, setSwitch2] = useState(false);
    const [switch3, setSwitch3] = useState(false);
    const [switch4, setSwitch4] = useState(false);
    const [switch5, setSwitch5] = useState(false);

    return(
        <>
            <div className='p-5 md:flex md:justify-between bg-white rounded-md my-2'>
                <div>
                    <div className='mb-1 text-gray-400 font-medium'>
                        <Link to="/" className="flex items-center">
                                <HiOutlineChevronLeft className="me-1" /> Back to dashboard
                        </Link>
                    </div>
                    <h2 className='text-4xl font-bold mb-3 md:mb-0'>Manage Languages</h2>
                </div>
                <div className='md:flex items-center gap-3'>
                    <div>
                        <button class='bg-white hover:bg-gray-50 rounded-full py-2 px-4 flex justify-center font-medium items-center hover:-translate-y-2 duration-300 drop-shadow-md hover:drop-shadow-lg w-full mb-4 md:mb-0'>
                            <FiRotateCw className="mr-2" /> Reinstall Language Files
                        </button>
                    </div>
                    <div>
                        <button class='bg-blue-600 hover:bg-blue-500 rounded-full py-2 px-4 text-white flex justify-center font-medium hover:-translate-y-2 duration-300 hover:drop-shadow-lg w-full'>
                            <HiOutlineDocumentText className="mr-1 h-5 w-5" /> Publish All JSON Files
                        </button>
                    </div>
                </div>
            </div>
            <div className='p-5 bg-white rounded-md mb-2'>
                <div className='grid md:grid-cols-12 mt-5'>
                    <div className='md:col-span-6 md:col-start-4'>
                        <div class='mb-8'>
                            <div>
                                <div className="p-4 rounded-md shadow-sm border border-l-8 border-l-red-700 text-red-900/80">
                                    <h3 className='font-semibold'>Take a backup before process!</h3>
                                    <p className="mt-1">
                                        If you have previously created or edited a language file (JSON), the Generate process will overwrite those files.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='my-4'>
                            <div className="grid md:grid-cols-2 gap-4">
                                <div>
                                    <div className="relative">
                                        <Select className="rounded-full">
                                            <option selected>Add new language</option>
                                        </Select>
                                        <div className="absolute top-2 right-2 w-7 h-7 bg-white shadow-md rounded-full flex items-center justify-center hover:-translate-y-1 duration-300 hover:shadow-xl cursor-pointer" onClick={()=>alert('Are you sure you want to create a new language?')} >
                                            <HiOutlinePlus className='text-slate-600' />
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="relative">
                                        <Select className="rounded-full">
                                            <option selected >Select Default Language</option>
                                        </Select>
                                        <div className="absolute top-2 right-2 w-7 h-7 bg-white shadow-md rounded-full flex items-center justify-center hover:-translate-y-1 duration-300 hover:shadow-xl cursor-pointer" onClick={()=>alert('Are you sure you want to change default language?')} >
                                            <HiOutlinePlus className='text-slate-600' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='border-t my-10 h-1'></div>
                        <div>
                            <h3 className='text-2xl font-medium text-slate-700 mb-5'>Available Languages</h3>
                            <div className='border p-3 rounded-md md:flex justify-between items-center'>
                                <div className="font-bold text-xl">
                                    US English <span className="text-sm text-slate-500 font-normal">en</span>
                                </div>
                                <div className="mt-4 md:mt-0">
                                    <ToggleSwitch checked={switch1} label="Edit default strings" onChange={setSwitch1} />
                                </div>
                            </div>
                            <div className='border-t my-10 h-1'></div>
                            <div className='border p-3 rounded-md flex justify-between items-center mb-4'>
                                <div className="font-bold text-xl">US English <span className="text-sm text-slate-500 font-normal">en</span></div>
                                <div className="flex items-center gap-x-2">
                                    <ToggleSwitch checked={switch2} onChange={setSwitch2} />
                                    <div className='h-10 w-10 flex justify-center items-center bg-white rounded-full hover:-translate-y-1 duration-300 cursor-pointer'>
                                        <HiOutlineDotsVertical className='text-lg' />
                                    </div>
                                </div>
                            </div>
                            <div className='border p-3 rounded-md flex justify-between items-center mb-4'>
                                <div className="font-bold text-xl">US English <span className="text-sm text-slate-500 font-normal">en</span></div>
                                <div className="flex items-center gap-x-2">
                                    <ToggleSwitch checked={switch3} onChange={setSwitch3} />
                                    <div className='h-10 w-10 flex justify-center items-center bg-white rounded-full hover:-translate-y-1 duration-300 cursor-pointer'>
                                        <HiOutlineDotsVertical className='text-lg' />
                                    </div>
                                </div>
                            </div>
                            <div className='border p-3 rounded-md flex justify-between items-center mb-4'>
                                <div className="font-bold text-xl">US English <span className="text-sm text-slate-500 font-normal">en</span></div>
                                <div className="flex items-center gap-x-2">
                                    <ToggleSwitch checked={switch4} onChange={setSwitch4} />
                                    <div className='h-10 w-10 flex justify-center items-center bg-white rounded-full hover:-translate-y-1 duration-300 cursor-pointer'>
                                        <HiOutlineDotsVertical className='text-lg' />
                                    </div>
                                </div>
                            </div>
                            <div className='border p-3 rounded-md flex justify-between items-center mb-4'>
                                <div className="font-bold text-xl">US English <span className="text-sm text-slate-500 font-normal">en</span></div>
                                <div className="flex items-center gap-x-2">
                                    <ToggleSwitch checked={switch5} onChange={setSwitch5} />
                                    <div className='h-10 w-10 flex justify-center items-center bg-white rounded-full hover:-translate-y-1 duration-300 cursor-pointer'>
                                        <HiOutlineDotsVertical className='text-lg' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Languages;