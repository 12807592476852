import { Link } from "react-router-dom";
import { 
    HiOutlineChevronLeft,
    HiOutlineInformationCircle,
    HiOutlineArrowRight 
} from "react-icons/hi";
import { Label, TextInput, Select, Textarea, FileInput, ToggleSwitch } from 'flowbite-react';
import { useState } from 'react';

const General =()=>{
    const [switch1, setSwitch1] = useState(false);
    const [switch2, setSwitch2] = useState(true);
    const [switch3, setSwitch3] = useState(false);
    const [switch4, setSwitch4] = useState(false);
    const [switch5, setSwitch5] = useState(false);
    const [switch6, setSwitch6] = useState(false);
    const [switch7, setSwitch7] = useState(false);
    const [switch8, setSwitch8] = useState(true);
    const [switch9, setSwitch9] = useState(true);
    const [switch10, setSwitch10] = useState(true);
    const [switch11, setSwitch11] = useState(true);
    const [switch12, setSwitch12] = useState(true);
    const [switch13, setSwitch13] = useState(true);
    const [switch14, setSwitch14] = useState(true);
    const [switch15, setSwitch15] = useState(true);
    const [switch16, setSwitch16] = useState(true);
    const [switch17, setSwitch17] = useState(true);
    const [switch18, setSwitch18] = useState(true);
    const [switch19, setSwitch19] = useState(false);


    return(
        <>
            <div className='p-5 md:flex md:justify-between bg-white rounded-md my-2'>
                <div>
                    <div className='mb-1 text-slate-400'>
                        <Link to="/admin/dashboard" className="flex items-center">
                                <HiOutlineChevronLeft className="me-1" /> Back to dashboard
                        </Link>
                    </div>
                    <h2 className='text-4xl font-bold'>General Settings</h2>
                </div> 
            </div>
            <div className='p-5 bg-white rounded-md mb-2'>
                <div class="flex justify-center">
                    <div class="">
                        <h3 className='text-2xl font-medium text-slate-700 mb-5'>Global Settings</h3>
                        <div className="flex items-center relative mb-5">
                            <ToggleSwitch checked={switch1} label="Disable Login Without Confirmation" onChange={setSwitch1} />
                            <span className='cursor-pointer group'>
                                <HiOutlineInformationCircle className='ms-1' />
                                <span className='absolute bottom-2 left-0 bg-slate-200 text-slate-600 p-2 rounded-md border text-xs invisible group-hover:visible group-hover:-translate-y-6 translate-y-4 duration-300 opacity-0 group-hover:opacity-100'>
                                    If this is enabled users cannot login unless they confirm their emails.
                                </span>
                            </span>
                        </div>
                        <div className="flex items-center relative mb-5">
                            <ToggleSwitch checked={switch2} label="Turbo Writer" onChange={setSwitch2} />
                            <span className='cursor-pointer group'>
                                <HiOutlineInformationCircle className='ms-1' />
                                <span className='absolute bottom-2 left-0 bg-slate-200 text-slate-600 p-2 rounded-md border text-xs invisible group-hover:visible group-hover:-translate-y-6 translate-y-4 duration-300 opacity-0 group-hover:opacity-100'>
                                    Please enable this to activate turbo writer
                                </span>
                            </span>
                        </div>
                        <div>
                            <form className="flex max-w-md flex-col gap-4">
                                <div>
                                    <div className="mb-2 block">
                                        <Label value="Site Name" className="text-slate-500" />
                                    </div>
                                    <TextInput type="text" placeholder="Hamari AI" />
                                </div>
                                <div>
                                    <div className="mb-2 block">
                                        <Label value="Site URL" className="text-slate-500" />
                                    </div>
                                    <TextInput type="url" placeholder="https://hamariai.com" />
                                </div>
                                <div>
                                    <div className="mb-2 block">
                                        <Label value="Site Email" className="text-slate-500" />
                                    </div>
                                    <TextInput type="email" placeholder="email@gmail.com" />
                                </div>
                                <div>
                                    <div className="mb-2 block">
                                        <Label value="Default Country" className="text-slate-500" />
                                    </div>
                                    <Select>
                                        <option selected>India</option>
                                        <option>United States</option>
                                        <option>Canada</option>
                                        <option>France</option>
                                        <option>Germany</option>
                                    </Select>
                                </div>
                                <div>
                                    <div className="mb-2 block">
                                        <Label value="Default Currency" className="text-slate-500" />
                                    </div>
                                    <Select>
                                        <option selected>India-INR-Rp</option>
                                        <option>Indonesia-IDR-Rp</option>
                                        <option>America-USD-$</option>
                                        <option>Afghanistan-AFN-؋</option>
                                        <option>Argentina-ARS-$</option>
                                    </Select>
                                </div>
                                <div>
                                    <div className="mb-2 block">
                                        <Label value="Registration Active" className="text-slate-500" />
                                    </div>
                                    <Select>
                                        <option selected>Active</option>
								        <option>Passive</option>
                                    </Select>
                                </div>
                                <div>
                                    <div className="mb-2 block">
                                        <Label value="Free Usage Upon Registration (words,images)" className="text-slate-500" />
                                    </div>
                                    <TextInput type="text" placeholder="0,0" />
                                </div>
                                <div className="mb-2">
                                    <ToggleSwitch checked={switch3} label="Apply daily limit on image generation" onChange={setSwitch3} />
                                    <div>
                                        {
                                            switch3 && <div>
                                            <div className="mb-2 mt-4 block">
                                                <Label value="Daily Image Limit Count" className="text-slate-500" />
                                            </div>
                                            <TextInput type="text" placeholder="2" />
                                        </div>
                                        }
                                    </div>
                                </div>
                                <div className="mb-2">
                                    <ToggleSwitch checked={switch4} label="Apply daily limit on voice generation" onChange={setSwitch4} />
                                    <div>
                                        {
                                            switch4 && <div>
                                            <div className="mb-2 mt-4 block">
                                                <Label value="Daily Voice Limit Count" className="text-slate-500" />
                                            </div>
                                            <TextInput type="text" placeholder="1" />
                                        </div>
                                        }
                                    </div>
                                </div>
                                <div>
                                    <h3 className='text-2xl font-medium text-slate-700 mb-5'>
                                        Social Login
                                    </h3>
                                    <div className="p-2 mb-6 rounded-md bg-blue-100 text-blue-500 hover:underline">
                                        <Link className="flex items-center">
                                            <span>Check the documentation.</span>
                                            <span><HiOutlineArrowRight className='ms-1 -rotate-45' /></span>
                                        </Link>
                                    </div>
                                    <div className="mb-2">
                                        <ToggleSwitch checked={switch5} label="Facebook" onChange={setSwitch5} />
                                    </div>
                                    <div className="mb-2">
                                        <ToggleSwitch checked={switch6} label="Google" onChange={setSwitch6} />
                                    </div>
                                    <div className="mb-2">
                                        <ToggleSwitch checked={switch7} label="Github" onChange={setSwitch7} />
                                    </div>
                                </div>
                                <div>
                                    <h3 className='text-2xl font-medium text-slate-700 mb-5'>
                                        Logo Settings
                                    </h3>
                                    <div className="max-w-md mb-6">
                                        <div className="mb-2 block">
                                            <Label value="Site Favicon" className="text-slate-500" />
                                        </div>
                                        <FileInput />
                                    </div>
                                    <div className="p-2 mb-6 rounded-md bg-blue-100 text-blue-500 hover:underline">
                                        <Link className="flex items-center">
                                            <span>If you will use SVG, you do not need the Retina (2x) option.</span>
                                        </Link>
                                    </div>
                                </div>
                                <div className="grid sm:grid-cols-2 gap-3">
                                    <div>
                                        <h3 className='text-md font-medium tex text-slate-700 mb-4'>Default Logos</h3>
                                        <div className="mb-4">
                                            <div className="mb-2 block">
                                                <Label value="Site Favicon" className="text-slate-500" />
                                            </div>
                                            <FileInput />
                                        </div>
                                        <div className="mb-4">
                                            <div className="mb-2 block">
                                                <Label value="Site Logo (Dark)" className="text-slate-500" />
                                            </div>
                                            <FileInput />
                                        </div>
                                        <div className="mb-4">
                                            <div className="mb-2 block">
                                                <Label value="Site Logo Sticky" className="text-slate-500" />
                                            </div>
                                            <FileInput />
                                        </div>
                                        <div className="mb-4">
                                            <div className="mb-2 block">
                                                <Label value="Dashboard Logo" className="text-slate-500" />
                                            </div>
                                            <FileInput />
                                        </div>
                                        <div className="mb-4">
                                            <div className="mb-2 block">
                                                <Label value="Dashboard Logo (Dark)" className="text-slate-500" />
                                            </div>
                                            <FileInput />
                                        </div>
                                        <div className="mb-4">
                                            <div className="mb-2 block">
                                                <Label value="Dashboard Logo Collapsed" className="text-slate-500" />
                                            </div>
                                            <FileInput />
                                        </div>
                                        <div className="mb-4">
                                            <div className="mb-2 block">
                                                <Label value="Dashboard Logo Collapsed (Dark)" className="text-slate-500" />
                                            </div>
                                            <FileInput />
                                        </div>
                                    </div>
                                    <div>
                                        <h3 className='text-md font-medium tex text-slate-700 mb-4'>
                                            Retina Logos (2x) - Optional
                                        </h3>
                                        <div className="mb-4">
                                            <div className="mb-2 block">
                                                <Label value="Site Favicon" className="text-slate-500" />
                                            </div>
                                            <FileInput />
                                        </div>
                                        <div className="mb-4">
                                            <div className="mb-2 block">
                                                <Label value="Site Logo (Dark)" className="text-slate-500" />
                                            </div>
                                            <FileInput />
                                        </div>
                                        <div className="mb-4">
                                            <div className="mb-2 block">
                                                <Label value="Site Logo Sticky" className="text-slate-500" />
                                            </div>
                                            <FileInput />
                                        </div>
                                        <div className="mb-4">
                                            <div className="mb-2 block">
                                                <Label value="Dashboard Logo" className="text-slate-500" />
                                            </div>
                                            <FileInput />
                                        </div>
                                        <div className="mb-4">
                                            <div className="mb-2 block">
                                                <Label value="Dashboard Logo (Dark)" className="text-slate-500" />
                                            </div>
                                            <FileInput />
                                        </div>
                                        <div className="mb-4">
                                            <div className="mb-2 block">
                                                <Label value="Dashboard Logo Collapsed" className="text-slate-500" />
                                            </div>
                                            <FileInput />
                                        </div>
                                        <div className="mb-4">
                                            <div className="mb-2 block">
                                                <Label value="Dashboard Logo Collapsed (Dark)" className="text-slate-500" />
                                            </div>
                                            <FileInput />
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <h3 className='text-2xl font-medium text-slate-700 mb-5'>
                                        Seo Settings
                                    </h3>
                                    <div className='mb-4'>
                                        <div className="mb-2 block">
                                            <Label value="Google Analytics Tracking ID (UA-1xxxxx) or (G-xxxxxx)" className='text-slate-500' />
                                        </div>
                                        <TextInput type="text" />
                                    </div>
                                    <div className='mb-4'>
                                        <div className="mb-2 block">
                                            <Label value="Meta Title" className='text-slate-500' />
                                        </div>
                                        <TextInput type="text" />
                                    </div>
                                    <div className='mb-4'>
                                        <div className="mb-2 block">
                                            <Label value="Meta Description" className='text-slate-500' />
                                        </div>
                                        <Textarea rows={4} />
                                    </div>
                                    <div className='mb-4'>
                                        <div className="mb-2 block">
                                            <Label value="Meta Keywords" className='text-slate-500' />
                                        </div>
                                        <Textarea placeholder="ChatGPT, AI Writer, AI Image Generator, AI Chat" rows={4} />
                                    </div>
                                </div>
                                <div>
                                    <h3 className='text-2xl font-medium text-slate-700 mb-5'>
                                        Advanced Settings
                                    </h3>
                                    <div className='mb-4'>
                                        <div className="mb-2 flex items-center relative">
                                            <Label value="Code before </head> (Dashboard)" className='text-slate-500' />
                                            <span className='cursor-pointer group'>
                                                <HiOutlineInformationCircle className='ms-1 text-slate-500' />
                                                <span className='absolute bottom-2 left-0 bg-slate-200 text-slate-600 p-2 rounded-md border text-xs invisible group-hover:visible group-hover:-translate-y-6 translate-y-4 duration-300 opacity-0 group-hover:opacity-100'>
                                                    Only accepts javascript code wrapped with &lt;script&gt; tags and HTML markup that is valid inside the &lt;/head&gt; tag.
                                                </span>
                                            </span>
                                        </div>
                                        <Textarea rows={4} />
                                    </div>
                                    <div className='mb-4'>
                                        <div className="mb-2 flex items-center relative">
                                            <Label value="Code before </body> (Dashboard)" className='text-slate-500' />
                                            <span className='cursor-pointer group'>
                                                <HiOutlineInformationCircle className='ms-1 text-slate-500' />
                                                <span className='absolute bottom-2 left-0 bg-slate-200 text-slate-600 p-2 rounded-md border text-xs invisible group-hover:visible group-hover:-translate-y-6 translate-y-4 duration-300 opacity-0 group-hover:opacity-100'>
                                                    Only accepts javascript code wrapped with &lt;script&gt; tags and HTML markup that is valid inside the &lt;/body&gt; tag. 
                                                </span>
                                            </span>
                                        </div>
                                        <Textarea rows={4} />
                                    </div>
                                </div>
                                <div>
                                    <h3 className='text-2xl font-medium text-slate-700 mb-5'>
                                        Manage the Features
                                    </h3>
                                    <p className='text-slate-500 mb-3'>
                                        Manage the features you want to activate for users.
                                    </p>
                                    <div>
                                        <div className="mb-2">
                                            <ToggleSwitch checked={switch8} label="AI Writer" onChange={setSwitch8} />
                                        </div>
                                        <div className="mb-2">
                                            <ToggleSwitch checked={switch9} label="AI Image" onChange={setSwitch9} />
                                        </div>
                                        <div className="mb-2">
                                            <ToggleSwitch checked={switch10} label="AI Chat" onChange={setSwitch10} />
                                        </div>
                                        <div className="mb-2">
                                            <ToggleSwitch checked={switch11} label="AI Code" onChange={setSwitch11} />
                                        </div>
                                        <div className="mb-2">
                                            <ToggleSwitch checked={switch12} label="AI Speech to Text" onChange={setSwitch12} />
                                        </div>
                                        <div className="mb-2">
                                            <ToggleSwitch checked={switch13} label="AI Voiceover" onChange={setSwitch13} />
                                        </div>
                                        <div className="mb-2">
                                            <ToggleSwitch checked={switch14} label="Affilates" onChange={setSwitch14} />
                                        </div>
                                        <div className="mb-2">
                                            <ToggleSwitch checked={switch15} label="Article Wizard" onChange={setSwitch15} />
                                        </div>
                                        <div className="mb-2">
                                            <ToggleSwitch checked={switch16} label="AI Vision" onChange={setSwitch16} />
                                        </div>
                                        <div className="mb-2">
                                            <ToggleSwitch checked={switch17} label="Chat Image" onChange={setSwitch17} />
                                        </div>
                                        <div className="mb-2">
                                            <ToggleSwitch checked={switch18} label="AI PDF" onChange={setSwitch18} />
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <h3 className='text-2xl font-medium text-slate-700 mb-5'>
                                        Mobile Settings
                                    </h3>
                                    <p className='text-slate-500 mb-3'>
                                        This setting is in early alpha stage. Please do not activate until offically released.
                                    </p>
                                    <div className="mb-2">
                                        <ToggleSwitch checked={switch19} label="Mobile Payment" onChange={setSwitch19} />
                                    </div>
                                </div>
                                <div className="py-4 flex justify-center">
                                    <button className="bg-blue-600 hover:bg-blue-500 rounded-full py-2 px-4 text-white flex justify-center font-medium hover:-translate-y-2 duration-300 hover:drop-shadow-lg w-full">
                                        Save
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
           </div>
        </>
    )
}

export default General;