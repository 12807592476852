

import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from './auth/Login';
import SignUp from './auth/SignUp';
import Privateroutes from './routes/Privateroutes';

import Dashboard from './component/userPages/dashboard/Dashboard';
import Documents from './component/userPages/documents/Documents';
import Aiwriter from './component/userPages/aiwriter/Aiwriter';
import PostGenerator from './component/userPages/aiwriter/PostGenerator';
import AiImage from './component/userPages/aiimage/AiImage';
import Aiarticlewizard from './component/userPages/aiarticlewizard/Aiarticlewizard';
import Aichat from './component/userPages/aichat/Aichat';
import Aichatbot from './component/userPages/aichat/Aichatbot';
import Aipdf from './component/userPages/aipdf/Aipdf';
import Aivision from './component/userPages/aivision/Aivision';
import Aicode from './component/userPages/aicode/Aicode';
import Aispeech from './component/userPages/aispeech/Aispeech';
import Aivoiceover from './component/userPages/aivoiceover/Aivoiceover';

import AdminDashboard from './component/adminPages/dashboard/AdminDashboard';
import UserManagement from './component/adminPages/userManagement/UserManagement';
import General from './component/adminPages/settings/general/General';
import Invoice from './component/adminPages/settings/invoice/Invoice';
import Openai from "./component/adminPages/settings/openai/Openai";
import StableDiffusion from "./component/adminPages/settings/stablediffusion/StableDiffusion";
import Unsplashapi from "./component/adminPages/settings/unsplashapi/Unsplashapi";
import Serperapi from "./component/adminPages/settings/serperapi/Serperapi";
import Tts from "./component/adminPages/settings/tts/Tts";
import Affiliate from "./component/adminPages/settings/affiliate/Affiliate";
import Smtp from "./component/adminPages/settings/smtp/Smtp";
import Gdpr from "./component/adminPages/settings/gdpr/Gdpr";
import Privacy from "./component/adminPages/settings/privacy/Privacy";
import Storage from "./component/adminPages/settings/storage/Storage";
import Languages from "./component/adminPages/settings/languages/Languages";
import FrontendSettings from "./component/adminPages/frontend/FrontendSettings/FrontendSettings";
import SectionSettings from "./component/adminPages/frontend/sectionSettings/SectionSettings";
import Menu from "./component/adminPages/frontend/menu/Menu";
import Faq from "./component/adminPages/frontend/faq/Faq";
import Tools from "./component/adminPages/frontend/toolsSection/Tools";
import Features from "./component/adminPages/frontend/featuresSection/Features";
import Testimonials from "./component/adminPages/frontend/testimonialsSection/Testimonials";
import Clients from "./component/adminPages/frontend/clientsSection/Clients";
import HowitWorks from "./component/adminPages/frontend/howitWorks/HowitWorks";
import WhoCan from "./component/adminPages/frontend/whoCan/WhoCan";
import GeneratorList from "./component/adminPages/frontend/generatorlist/GeneratorList";
import CreateUpdate from "./component/adminPages/frontend/generatorlist/CreateUpdate";
import BuiltTemplates from "./component/adminPages/templates/builtTemplates/BuiltTemplates";
import CustomTemplates from "./component/adminPages/templates/customTemplates/CustomTemplates";
import AddUpdate from "./component/adminPages/templates/customTemplates/AddUpdate";
import AIcategories from "./component/adminPages/templates/aiCategories/AIcategories";
import AddOrUpdate from "./component/adminPages/templates/aiCategories/AddOrUpdate";
import ChatCategories from "./component/adminPages/templates/chatCategories/ChatCategories";
import ChatAddUpdate from "./component/adminPages/templates/chatCategories/ChatAddUpdate";
import ChatTemplates from "./component/adminPages/templates/chatTemplates/ChatTemplates";
import ChattemplatesAddUpdate from "./component/adminPages/templates/chatTemplates/ChattemplatesAddUpdate";
import BlogPosts from "./component/adminPages/blog/BlogPosts";
import Pages from "./component/adminPages/pages/Pages";
import EmailTemplates from "./component/adminPages/emailTemplates/EmailTemplates";
import Aivideo from "./component/userPages/aivideo/Aivideo";
import Settings from "./component/usersetting/Settings";
import Payment from "./component/usersetting/Payment";
import Orders from "./component/usersetting/Orders";
import AIReWriter from "./component/userPages/airewriter/AIReWriter";
import ChatImage from "./component/userPages/chatimage/ChatImage";
import Aiyoutube from "./component/userPages/aiyoutube/Aiyoutube";
import Airss from "./component/userPages/airss/Airss";
import BrandVoice from "./component/userPages/brandvoice/BrandVoice";
import AddupdateCompany from "./component/userPages/brandvoice/AddupdateCompany";
import Marketplace from "./component/adminPages/marketplace/Marketplace";
import UserAdd from "./component/adminPages/userManagement/UserAdd";
import GoogleAdsense from "./component/adminPages/googleAdsense/GoogleAdsense";
import PageAddUpdate from "./component/adminPages/pages/PageAddUpdate";
import BlogAddUpdate from "./component/adminPages/blog/BlogAddUpdate";
import ChatbotTraining from "./component/adminPages/templates/chatbottraining/ChatbotTraining";
import NewChatbot from "./component/adminPages/templates/chatbottraining/NewChatbot";
import FloatingChat from "./component/adminPages/templates/floatingchatsettings/FloatingChat";


function App() {
  return (
    <>
      <div className='bg-white'>
        <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Login/>} />
          <Route path="signup" element={<SignUp/>} />

          <Route element={<Privateroutes/>} >
            <Route path="/" element={<Dashboard/>} /> 
            <Route path="user/post-title-generator" element={<PostGenerator/>} />
            <Route path="user/ai-writer" element={<Aiwriter/>} />
            <Route path="user/ai-image" element={<AiImage/>} />
            <Route path="user/ai-video" element={<Aivideo/>} />
            <Route path="user/ai-chat" element={<Aichat/>} />
            <Route path="user/ai-chat-bot" element={<Aichatbot/>} />
            <Route path="user/ai-pdf" element={<Aipdf/>} />
            <Route path="user/ai-code" element={<Aicode/>} />
            <Route path="user/ai-youtube" element={<Aiyoutube/>} />
            <Route path="user/ai-rss" element={<Airss/>} />
            <Route path="user/ai-speech-to-text" element={<Aispeech/>} />
            <Route path="user/ai-vision" element={<Aivision/>} />
            <Route path="user/brand-voice" element={<BrandVoice/>} />
            <Route path="user/brandvoice/add-or-update" element={<AddupdateCompany/>} />
            <Route path="user/ai-rewriter" element={<AIReWriter/>} />
            <Route path="user/chat-image" element={<ChatImage/>} />
            <Route path="user/ai-voiceover" element={<Aivoiceover/>} />
            <Route path="user/documents" element={<Documents/>} />
            <Route path="user/ai-article-wizard" element={<Aiarticlewizard/>} />

            <Route path="admin/dashboard" element={<AdminDashboard/>} />
            <Route path="admin/marketplace" element={<Marketplace/>} />
            <Route path="admin/user-management" element={<UserManagement/>} />
            <Route path="/admin/users/add" element={<UserAdd/>} />
            <Route path="/admin/googleAdsense/google-adsense" element={<GoogleAdsense/>} />
            <Route path="admin/general" element={<General/>} />
            <Route path="admin/invoice" element={<Invoice/>} />
            <Route path="admin/openai" element={<Openai/>} />
            <Route path="admin/stablediffusion" element={<StableDiffusion/>} />
            <Route path="admin/unsplashapi" element={<Unsplashapi/>} />
            <Route path="admin/serperapi" element={<Serperapi/>} />
            <Route path="admin/tts" element={<Tts/>} />
            <Route path="admin/affiliate" element={<Affiliate/>} /> 
            <Route path="admin/smtp" element={<Smtp/>} />
            <Route path="admin/gdpr" element={<Gdpr/>} /> 
            <Route path="admin/privacy" element={<Privacy/>} />
            <Route path="admin/languages" element={<Languages/>} />
            <Route path="admin/storage" element={<Storage/>} /> 

            <Route path="admin/frontend-settings" element={<FrontendSettings/>} />
            <Route path="admin/frontend-section-settings" element={<SectionSettings/>} />
            <Route path="admin/menu" element={<Menu/>} />
            <Route path="admin/faq" element={<Faq/>} />
            <Route path="admin/tools" element={<Tools/>} />
            <Route path="admin/features" element={<Features/>} />
            <Route path="admin/testimonials" element={<Testimonials/>} />
            <Route path="admin/clients" element={<Clients/>} />
            <Route path="admin/how-it-works" element={<HowitWorks/>} />
            <Route path="admin/who-can-use-section" element={<WhoCan/>} />
            <Route path="admin/generators-list" element={<GeneratorList/>} />
            <Route path="admin/generators-list/create-or-update" element={<CreateUpdate/>} />

            <Route path="admin/built-in-templates" element={<BuiltTemplates/>} /> 
            <Route path="admin/custom-templates" element={<CustomTemplates/>} />
            <Route path="admin/custom-templates/add-or-update" element={<AddUpdate/>} /> 

            <Route path="admin/ai-writer-categories" element={<AIcategories/>} />
            <Route path="admin/ai-writer-categories/add-or-update" element={<AddOrUpdate/>} />
            <Route path="admin/chat-categories" element={<ChatCategories/>} />
            <Route path="admin/chat-categories/add-or-update" element={<ChatAddUpdate/>} />
            <Route path="admin/chat-templates" element={<ChatTemplates/>} />
            <Route path="admin/chat-templates/add-or-update" element={<ChattemplatesAddUpdate/>} />
            <Route path="admin/chatbot" element={<ChatbotTraining/>} />
            <Route path="admin/chatbottraining/new-chatbot" element={<NewChatbot/>} />
            <Route path="admin/floatingchatsettings/floating-chat-settings" element={<FloatingChat/>} />
            <Route path="admin/blog-posts" element={<BlogPosts/>} />
            <Route path="admin/blog-posts/add-or-update" element={<BlogAddUpdate/>} />
            <Route path="admin/pages" element={<Pages/>} />
            <Route path="admin/pages/add-or-update" element={<PageAddUpdate/>} />
            <Route path="admin/email-templates" element={<EmailTemplates/>} />

            <Route path="user/orders" element={<Orders/>} />
            <Route path="user/payment" element={<Payment/>} />
            <Route path="user/settings" element={<Settings/>} />
            
          </Route>
        </Routes>
      </BrowserRouter>
      </div>
    </>
  );
}

export default App;
