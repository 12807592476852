import { 
    HiPlusSm,
    HiOutlineChevronLeft,
    HiCheck,
    HiOutlineInformationCircle,
} from "react-icons/hi";
import { Link } from "react-router-dom";

const Payment = () =>{
    return(
        <>
            <div className='p-5 md:flex md:justify-between bg-white rounded-md my-2'>
                <div>
                    <div className='mb-1 text-slate-400'>
                        <Link to="/admin/dashboard" className="flex items-center">
                                <HiOutlineChevronLeft className="me-1" /> Back to dashboard
                        </Link>
                    </div>
                    <h2 className='text-4xl font-bold mb-3 md:mb-0'>Plans</h2>
                </div>
                <div>
                    <div className="rounded-lg px-2 py-2 bg-white shadow-md  ">
                        <div className='w-full mt-1'>
                            <ul className='md:flex'>
                                <li className="w-auto mb-2 md:mb-0">
                                    <div className="flex items-center">
                                        <div className="h-2 w-2 rounded-full bg-blue-600 me-2"></div> 
                                        <div className="pe-3">
                                            <span className='text-slate-500'>Words</span> 2,999,119
                                        </div>
                                    </div>
                                    <div className="h-1 bg-blue-600 mb-1"></div>
                                </li>
                                <li className="w-auto">
                                    <div className="flex items-center">
                                        <div className="h-2 w-2 rounded-full bg-blue-200 me-2"></div> 
                                        <div><span className='text-slate-500'>Images</span> 2,999,996</div>
                                    </div>
                                    <div className="h-1 bg-blue-200 mb-1"></div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className='p-5 bg-white rounded-md my-2'>
                <div class="grid sm:grid-cols-2 lg:grid-cols-2 gap-2 xl:gap-6">
                    <div className='text-slate-500'>
                        <h3 className='text-2xl font-medium mb-4 text-slate-700'>Upgrade</h3>
                        <p className='mb-4'>
                            You have no subscription at the moment. Please select a subscription plan or a token pack.
                        </p>
                        <p className='mb-4'>
                            Total <span className='font-medium text-slate-700'>Unlimited</span> word and <span className='font-medium text-slate-700'>2,999,992</span> image tokens left.
                        </p>
                        <div className='mt-5 mb-2'>
                            <Link to="/user/payment">
                                <button className='flex items-center bg-gray-100 hover:bg-green-500 hover:text-white rounded-full py-2 px-4 shadow-md hover:-translate-y-1 duration-300 font-semibold'>
                                    <HiPlusSm className=" text-2xl" /> Select a Plan
                                </button>
                            </Link>
                        </div>
                    </div>
                    <div className='text-slate-500 flex items-center justify-center flex-col'>
                        <h3 className='text-2xl font-medium mb-4 text-slate-700'> 2,999,992</h3>
                        <h3 className='text-2xl font-medium mb-4 text-slate-700'> Chart</h3> 
                    </div>
                </div>
            </div>
            <div className='p-5 bg-white rounded-md my-2 pb-10'>
                <div className="mb-6">
                    <h3 className='text-3xl font-bold text-slate-700'>Subscription Plans:</h3>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-4">
                    <div className="min-h-80 border border-slate-100 shadow-lg shadow-slate-200 rounded-xl p-4 xl:p-10 relative">
                        <div class="mb-2 flex items-start font-bold leading-none ">
                            <small class="inline-flex text-[24px] font-medium -mt-1">$</small>
                            <span className="text-[50px]">29</span>
                            <div class="ms-2 inline-flex flex-col items-start text-xl">
                                Monthly
                                <div class="inline-flex rounded-full bg-gradient-to-r from-[#ece7f7] via-[#e7c5e6] to-[#e7ebf9] px-3 text-black text-sm mt-1">
                                    Popular plan
                                </div>
                            </div>
                        </div>
                        <h3 className="text-lg font-semibold text-slate-400 mb-4">Premium</h3>
                        <ul className="mb-5 md:mb-20">
                            <li class="mb-3 flex items-start">
                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                    <HiCheck />
                                </div>
                                2 Days of free trial.
                            </li>
                            <li class="mb-3 flex items-start relative">
                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                    <HiCheck />
                                </div>
                                Access <span className="font-semibold mx-1"> 36</span> Templates 

                                <span className='cursor-pointer group peer relative inline-flex items-center justify-center'>
                                    <HiOutlineInformationCircle className='ms-1 mt-1' />
                                    <span className='min-w-60 max-w-64 pointer-events-none invisible absolute top-1/2 z-10 ms-2 max-h-96 -translate-y-1/2 translate-x-2 overflow-y-auto rounded-lg border bg-white p-4 opacity-0 shadow-xl transition-all group-hover:pointer-events-auto group-hover:visible group-hover:translate-x-0 group-hover:opacity-100 left-[-244px]'>
                                        <h3 className="text-lg font-semibold mb-3">Ecommerce</h3>
                                        <ul className="text-sm mb-3">
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div> 
                                                Product Description
                                            </li>
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div>
                                                Testimonial Review
                                            </li>
                                        </ul>
                                        <h3 className="text-lg font-semibold mb-3">Blog</h3>
                                        <ul className="text-sm mb-3">
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div> 
                                                Article Generator
                                            </li>
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div>
                                                Blog Conclusion
                                            </li>
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div>
                                                Paragraph Generator
                                            </li>
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div>
                                                Grammar Correction
                                            </li>
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div>
                                                TL;DR Summarization
                                            </li>
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div>
                                                AI Speech to Text
                                            </li>
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div>
                                                AI Article Wizard Generator
                                            </li>
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div>
                                                AI Vision
                                            </li>
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div>
                                                File Analyzer
                                            </li>
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div>
                                                Chat Image
                                            </li>
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div>
                                                AI ReWriter
                                            </li>
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div>
                                                AI Plagiarism Checker
                                            </li>
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div>
                                                AI Content Detector
                                            </li>
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div>
                                                AI Web Chat
                                            </li>
                                        </ul>
                                        <h3 className="text-lg font-semibold mb-3">Social media</h3>
                                        <ul className="text-sm mb-3">
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div>
                                                Youtube Video Description
                                            </li>
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div>
                                                Instagram Captions
                                            </li>
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div>
                                                Instagram Hashtags
                                            </li>
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div>
                                                Social Media Post Tweet
                                            </li>
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div>
                                                Social Media Post Business
                                            </li>
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div>
                                                Facebook Headlines
                                            </li>
                                        </ul>
                                        <h3 className="text-lg font-semibold mb-3">Advertisement</h3>
                                        <ul className="text-sm mb-3">
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div> 
                                                Google Ads Headlines
                                            </li>
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div>
                                                Google Ads Description
                                            </li>
                                        </ul>
                                        <h3 className="text-lg font-semibold mb-3">Development</h3>
                                        <ul className="text-sm mb-3">
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div> 
                                                Pros & Cons
                                            </li>
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div>
                                                Meta Description
                                            </li>
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div>
                                                FAQ Generator (All Datas)
                                            </li>
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div>
                                                AI Image Generator
                                            </li>
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div>
                                                AI Code Generator
                                            </li>
                                        </ul>
                                        <h3 className="text-lg font-semibold mb-3">Email</h3>
                                        <ul className="text-sm mb-3">
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div> 
                                                Email Generator
                                            </li>
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div>
                                                Email Answer Generator 
                                            </li>
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div> 
                                                Newsletter Generator
                                            </li>
                                        </ul>
                                        <h3 className="text-lg font-semibold mb-3">Voiceover</h3>
                                        <ul className="text-sm mb-3">
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div> 
                                                AI Voiceover
                                            </li>
                                        </ul>
                                        <h3 className="text-lg font-semibold mb-3">Youtube</h3>
                                        <ul className="text-sm mb-3">
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div> 
                                                AI YouTube
                                            </li>
                                        </ul>
                                        <h3 className="text-lg font-semibold mb-3">Rss</h3>
                                        <ul className="text-sm mb-3">
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div> 
                                                AI RSS
                                            </li>
                                        </ul>
                                        <h3 className="text-lg font-semibold mb-3">Video</h3>
                                        <ul className="text-sm mb-3">
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div> 
                                                AI Video
                                            </li>
                                        </ul>
                                    </span>
                                </span>
                            </li>
                            <li class="mb-3 flex items-start">
                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                    <HiCheck />
                                </div> 
                                All features in previous plans
                            </li>
                            <li class="mb-3 flex items-start">
                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                    <HiCheck />
                                </div> 
                                VIP Support
                            </li>
                            <li class="mb-3 flex items-start">
                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                    <HiCheck />
                                </div>
                                <span className="font-semibold me-1"> Unlimited</span> Word Tokens
                            </li>
                            <li class="mb-3 flex items-start">
                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                    <HiCheck />
                                </div> 
                                <span className="font-semibold me-1"> Unlimited</span> Image Tokens
                            </li>
                        </ul>
                        <div className="md:absolute left-0 bottom-5 w-full px-8 pt-10">
                            <div className="shadow-md rounded-full py-2 hover:bg-blue-500 hover:text-white text-lg cursor-pointer hover:-translate-y-2 duration-300 hover:shadow-lg text-center">
                                Choose plan
                            </div>
                        </div>
                    </div>

                    <div className="min-h-80 border border-slate-100 shadow-lg shadow-slate-200 rounded-xl p-4 xl:p-10 relative">
                        <div class="mb-2 flex items-start font-bold leading-none ">
                            <small class="inline-flex text-[24px] font-medium -mt-1">$</small>
                            <span className="text-[50px]">0</span>
                            <div class="ms-2 inline-flex flex-col items-start text-xl">
                                Monthly
                            </div>
                        </div>
                        <h3 className="text-lg font-semibold text-slate-400 mb-4">Free</h3>
                        <ul className="mb-5 md:mb-20">
                            <li class="mb-3 flex items-start relative">
                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                    <HiCheck />
                                </div>
                                Access <span className="font-semibold mx-1"> 36</span> Templates 

                                <span className='cursor-pointer group peer relative inline-flex items-center justify-center'>
                                    <HiOutlineInformationCircle className='ms-1 mt-1' />
                                    <span className='min-w-60 max-w-64 pointer-events-none invisible absolute top-1/2 z-10 ms-2 max-h-96 -translate-y-1/2 translate-x-2 overflow-y-auto rounded-lg border bg-white p-4 opacity-0 shadow-xl transition-all group-hover:pointer-events-auto group-hover:visible group-hover:translate-x-0 group-hover:opacity-100 left-[-244px]'>
                                        <h3 className="text-lg font-semibold mb-3">Ecommerce</h3>
                                        <ul className="text-sm mb-3">
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div> 
                                                Product Description
                                            </li>
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div>
                                                Testimonial Review
                                            </li>
                                        </ul>
                                        <h3 className="text-lg font-semibold mb-3">Blog</h3>
                                        <ul className="text-sm mb-3">
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div> 
                                                Article Generator
                                            </li>
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div>
                                                Blog Conclusion
                                            </li>
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div>
                                                Paragraph Generator
                                            </li>
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div>
                                                Grammar Correction
                                            </li>
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div>
                                                TL;DR Summarization
                                            </li>
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div>
                                                AI Speech to Text
                                            </li>
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div>
                                                AI Article Wizard Generator
                                            </li>
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div>
                                                AI Vision
                                            </li>
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div>
                                                File Analyzer
                                            </li>
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div>
                                                Chat Image
                                            </li>
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div>
                                                AI ReWriter
                                            </li>
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div>
                                                AI Plagiarism Checker
                                            </li>
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div>
                                                AI Content Detector
                                            </li>
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div>
                                                AI Web Chat
                                            </li>
                                        </ul>
                                        <h3 className="text-lg font-semibold mb-3">Social media</h3>
                                        <ul className="text-sm mb-3">
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div>
                                                Youtube Video Description
                                            </li>
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div>
                                                Instagram Captions
                                            </li>
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div>
                                                Instagram Hashtags
                                            </li>
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div>
                                                Social Media Post Tweet
                                            </li>
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div>
                                                Social Media Post Business
                                            </li>
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div>
                                                Facebook Headlines
                                            </li>
                                        </ul>
                                        <h3 className="text-lg font-semibold mb-3">Advertisement</h3>
                                        <ul className="text-sm mb-3">
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div> 
                                                Google Ads Headlines
                                            </li>
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div>
                                                Google Ads Description
                                            </li>
                                        </ul>
                                        <h3 className="text-lg font-semibold mb-3">Development</h3>
                                        <ul className="text-sm mb-3">
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div> 
                                                Pros & Cons
                                            </li>
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div>
                                                Meta Description
                                            </li>
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div>
                                                FAQ Generator (All Datas)
                                            </li>
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div>
                                                AI Image Generator
                                            </li>
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div>
                                                AI Code Generator
                                            </li>
                                        </ul>
                                        <h3 className="text-lg font-semibold mb-3">Email</h3>
                                        <ul className="text-sm mb-3">
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div> 
                                                Email Generator
                                            </li>
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div>
                                                Email Answer Generator 
                                            </li>
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div> 
                                                Newsletter Generator
                                            </li>
                                        </ul>
                                        <h3 className="text-lg font-semibold mb-3">Voiceover</h3>
                                        <ul className="text-sm mb-3">
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div> 
                                                AI Voiceover
                                            </li>
                                        </ul>
                                        <h3 className="text-lg font-semibold mb-3">Youtube</h3>
                                        <ul className="text-sm mb-3">
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div> 
                                                AI YouTube
                                            </li>
                                        </ul>
                                        <h3 className="text-lg font-semibold mb-3">Rss</h3>
                                        <ul className="text-sm mb-3">
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div> 
                                                AI RSS
                                            </li>
                                        </ul>
                                        <h3 className="text-lg font-semibold mb-3">Video</h3>
                                        <ul className="text-sm mb-3">
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div> 
                                                AI Video
                                            </li>
                                        </ul>
                                    </span>
                                </span>
                            </li>
                            <li class="mb-3 flex items-start">
                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                    <HiCheck />
                                </div> 
                                ChatGPT 3.5
                            </li>
                            <li class="mb-3 flex items-start">
                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                    <HiCheck />
                                </div> 
                                Basic Support
                            </li>
                            <li class="mb-3 flex items-start">
                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                    <HiCheck />
                                </div>  
                                <span className="font-semibold me-1"> 10,000</span> Word Tokens
                            </li>
                            <li class="mb-3 flex items-start">
                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                    <HiCheck />
                                </div> 
                                <span className="font-semibold me-1"> 5,000</span> Image Tokens
                            </li>
                        </ul>
                        <div className="md:absolute left-0 bottom-5 w-full px-8 pt-10">
                            <div className="shadow-md rounded-full py-2 hover:bg-blue-500 hover:text-white text-lg cursor-pointer hover:-translate-y-2 duration-300 hover:shadow-lg text-center">
                                Choose plan
                            </div>
                        </div>
                    </div>

                    <div className="min-h-80 border border-slate-100 shadow-lg shadow-slate-200 rounded-xl p-4 xl:p-10 relative">
                        <div class="mb-2 flex items-start font-bold leading-none ">
                            <small class="inline-flex text-[24px] font-medium -mt-1">$</small>
                            <span className="text-[50px]">19</span>
                            <div class="ms-2 inline-flex flex-col items-start text-xl">
                                Monthly
                            </div>
                        </div>
                        <h3 className="text-lg font-semibold text-slate-400 mb-4">Teams</h3>
                        <ul className="mb-5 md:mb-20">
                            <li class="mb-3 flex items-start relative">
                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                    <HiCheck />
                                </div>
                                Access <span className="font-semibold mx-1"> 36</span> Templates 

                                <span className='cursor-pointer group peer relative inline-flex items-center justify-center'>
                                    <HiOutlineInformationCircle className='ms-1 mt-1' />
                                    <span className='min-w-60 max-w-64 pointer-events-none invisible absolute top-1/2 z-10 ms-2 max-h-96 -translate-y-1/2 translate-x-2 overflow-y-auto rounded-lg border bg-white p-4 opacity-0 shadow-xl transition-all group-hover:pointer-events-auto group-hover:visible group-hover:translate-x-0 group-hover:opacity-100 left-[-244px]'>
                                        <h3 className="text-lg font-semibold mb-3">Ecommerce</h3>
                                        <ul className="text-sm mb-3">
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div> 
                                                Product Description
                                            </li>
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div>
                                                Testimonial Review
                                            </li>
                                        </ul>
                                        <h3 className="text-lg font-semibold mb-3">Blog</h3>
                                        <ul className="text-sm mb-3">
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div> 
                                                Article Generator
                                            </li>
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div>
                                                Blog Conclusion
                                            </li>
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div>
                                                Paragraph Generator
                                            </li>
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div>
                                                Grammar Correction
                                            </li>
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div>
                                                TL;DR Summarization
                                            </li>
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div>
                                                AI Speech to Text
                                            </li>
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div>
                                                AI Article Wizard Generator
                                            </li>
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div>
                                                AI Vision
                                            </li>
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div>
                                                File Analyzer
                                            </li>
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div>
                                                Chat Image
                                            </li>
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div>
                                                AI ReWriter
                                            </li>
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div>
                                                AI Plagiarism Checker
                                            </li>
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div>
                                                AI Content Detector
                                            </li>
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div>
                                                AI Web Chat
                                            </li>
                                        </ul>
                                        <h3 className="text-lg font-semibold mb-3">Social media</h3>
                                        <ul className="text-sm mb-3">
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div>
                                                Youtube Video Description
                                            </li>
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div>
                                                Instagram Captions
                                            </li>
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div>
                                                Instagram Hashtags
                                            </li>
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div>
                                                Social Media Post Tweet
                                            </li>
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div>
                                                Social Media Post Business
                                            </li>
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div>
                                                Facebook Headlines
                                            </li>
                                        </ul>
                                        <h3 className="text-lg font-semibold mb-3">Advertisement</h3>
                                        <ul className="text-sm mb-3">
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div> 
                                                Google Ads Headlines
                                            </li>
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div>
                                                Google Ads Description
                                            </li>
                                        </ul>
                                        <h3 className="text-lg font-semibold mb-3">Development</h3>
                                        <ul className="text-sm mb-3">
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div> 
                                                Pros & Cons
                                            </li>
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div>
                                                Meta Description
                                            </li>
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div>
                                                FAQ Generator (All Datas)
                                            </li>
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div>
                                                AI Image Generator
                                            </li>
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div>
                                                AI Code Generator
                                            </li>
                                        </ul>
                                        <h3 className="text-lg font-semibold mb-3">Email</h3>
                                        <ul className="text-sm mb-3">
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div> 
                                                Email Generator
                                            </li>
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div>
                                                Email Answer Generator 
                                            </li>
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div> 
                                                Newsletter Generator
                                            </li>
                                        </ul>
                                        <h3 className="text-lg font-semibold mb-3">Voiceover</h3>
                                        <ul className="text-sm mb-3">
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div> 
                                                AI Voiceover
                                            </li>
                                        </ul>
                                        <h3 className="text-lg font-semibold mb-3">Youtube</h3>
                                        <ul className="text-sm mb-3">
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div> 
                                                AI YouTube
                                            </li>
                                        </ul>
                                        <h3 className="text-lg font-semibold mb-3">Rss</h3>
                                        <ul className="text-sm mb-3">
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div> 
                                                AI RSS
                                            </li>
                                        </ul>
                                        <h3 className="text-lg font-semibold mb-3">Video</h3>
                                        <ul className="text-sm mb-3">
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div> 
                                                AI Video
                                            </li>
                                        </ul>
                                    </span>
                                </span>
                            </li>
                            <li class="mb-3 flex items-start">
                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                    <HiCheck />
                                </div> 
                                ChatGPT 4
                            </li>
                            <li class="mb-3 flex items-start">
                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                    <HiCheck />
                                </div> 
                                5 Seats Available
                            </li>
                            <li class="mb-3 flex items-start">
                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                    <HiCheck />
                                </div> 
                                VIP Support
                            </li>
                            <li class="mb-3 flex items-start">
                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                    <HiCheck />
                                </div> 
                                Access to premium templates
                            </li>
                            <li class="mb-3 flex items-start">
                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                    <HiCheck />
                                </div> 
                                <span className="font-semibold me-1"> 5</span> Team allow seats
                            </li>
                            <li class="mb-3 flex items-start">
                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                    <HiCheck />
                                </div>  
                                <span className="font-semibold me-1"> 30,000</span> Word Tokens
                            </li>
                            <li class="mb-3 flex items-start">
                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                    <HiCheck />
                                </div> 
                                <span className="font-semibold me-1"> 10,000</span> Image Tokens
                            </li>
                        </ul>
                        <div className="md:absolute left-0 bottom-5 w-full px-8 pt-10">
                            <div className="shadow-md rounded-full py-2 hover:bg-blue-500 hover:text-white text-lg cursor-pointer hover:-translate-y-2 duration-300 hover:shadow-lg text-center">
                                Choose plan
                            </div>
                        </div>
                    </div>

                    <div className="min-h-80 border border-slate-100 shadow-lg shadow-slate-200 rounded-xl p-4 xl:p-10 relative">
                        <div class="mb-2 flex items-start font-bold leading-none ">
                            <small class="inline-flex text-[24px] font-medium -mt-1">$</small>
                            <span className="text-[50px]">399</span>
                            <div class="ms-2 inline-flex flex-col items-start text-xl">
                                Yearly
                                <div class="inline-flex rounded-full bg-gradient-to-r from-[#ece7f7] via-[#e7c5e6] to-[#e7ebf9] px-3 text-black text-sm mt-1">
                                    Popular plan
                                </div>
                            </div>
                        </div>
                        <h3 className="text-lg font-semibold text-slate-400 mb-4">Basic</h3>
                        <ul className="mb-5 md:mb-20">
                            <li class="mb-3 flex items-start relative">
                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                    <HiCheck />
                                </div>
                                Access <span className="font-semibold mx-1"> 36</span> Templates 

                                <span className='cursor-pointer group relative inline-flex items-center justify-center'>
                                    <HiOutlineInformationCircle className='ms-1 mt-1' />
                                    <span className='min-w-60 max-w-64 pointer-events-none invisible absolute top-1/2 z-10 ms-2 max-h-96 -translate-y-1/2 translate-x-2 overflow-y-auto rounded-lg border bg-white p-4 opacity-0 shadow-xl transition-all group-hover:pointer-events-auto group-hover:visible group-hover:translate-x-0 group-hover:opacity-100 left-[-244px]'>
                                        <h3 className="text-lg font-semibold mb-3">Ecommerce</h3>
                                        <ul className="text-sm mb-3">
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div> 
                                                Product Description
                                            </li>
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div>
                                                Testimonial Review
                                            </li>
                                        </ul>
                                        <h3 className="text-lg font-semibold mb-3">Blog</h3>
                                        <ul className="text-sm mb-3">
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div> 
                                                Article Generator
                                            </li>
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div>
                                                Blog Conclusion
                                            </li>
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div>
                                                Paragraph Generator
                                            </li>
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div>
                                                Grammar Correction
                                            </li>
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div>
                                                TL;DR Summarization
                                            </li>
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div>
                                                AI Speech to Text
                                            </li>
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div>
                                                AI Article Wizard Generator
                                            </li>
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div>
                                                AI Vision
                                            </li>
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div>
                                                File Analyzer
                                            </li>
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div>
                                                Chat Image
                                            </li>
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div>
                                                AI ReWriter
                                            </li>
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div>
                                                AI Plagiarism Checker
                                            </li>
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div>
                                                AI Content Detector
                                            </li>
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div>
                                                AI Web Chat
                                            </li>
                                        </ul>
                                        <h3 className="text-lg font-semibold mb-3">Social media</h3>
                                        <ul className="text-sm mb-3">
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div>
                                                Youtube Video Description
                                            </li>
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div>
                                                Instagram Captions
                                            </li>
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div>
                                                Instagram Hashtags
                                            </li>
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div>
                                                Social Media Post Tweet
                                            </li>
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div>
                                                Social Media Post Business
                                            </li>
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div>
                                                Facebook Headlines
                                            </li>
                                        </ul>
                                        <h3 className="text-lg font-semibold mb-3">Advertisement</h3>
                                        <ul className="text-sm mb-3">
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div> 
                                                Google Ads Headlines
                                            </li>
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div>
                                                Google Ads Description
                                            </li>
                                        </ul>
                                        <h3 className="text-lg font-semibold mb-3">Development</h3>
                                        <ul className="text-sm mb-3">
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div> 
                                                Pros & Cons
                                            </li>
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div>
                                                Meta Description
                                            </li>
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div>
                                                FAQ Generator (All Datas)
                                            </li>
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div>
                                                AI Image Generator
                                            </li>
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div>
                                                AI Code Generator
                                            </li>
                                        </ul>
                                        <h3 className="text-lg font-semibold mb-3">Email</h3>
                                        <ul className="text-sm mb-3">
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div> 
                                                Email Generator
                                            </li>
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div>
                                                Email Answer Generator 
                                            </li>
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div> 
                                                Newsletter Generator
                                            </li>
                                        </ul>
                                        <h3 className="text-lg font-semibold mb-3">Voiceover</h3>
                                        <ul className="text-sm mb-3">
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div> 
                                                AI Voiceover
                                            </li>
                                        </ul>
                                        <h3 className="text-lg font-semibold mb-3">Youtube</h3>
                                        <ul className="text-sm mb-3">
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div> 
                                                AI YouTube
                                            </li>
                                        </ul>
                                        <h3 className="text-lg font-semibold mb-3">Rss</h3>
                                        <ul className="text-sm mb-3">
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div> 
                                                AI RSS
                                            </li>
                                        </ul>
                                        <h3 className="text-lg font-semibold mb-3">Video</h3>
                                        <ul className="text-sm mb-3">
                                            <li class="mb-1 flex lg:items-center">
                                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                                    <HiCheck />
                                                </div> 
                                                AI Video
                                            </li>
                                        </ul>
                                    </span>
                                </span>
                            </li>
                            <li class="mb-3 flex items-start">
                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                    <HiCheck />
                                </div> 
                                ChatGPT 3.5
                            </li>
                            <li class="mb-3 flex items-start">
                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                    <HiCheck />
                                </div> 
                                Basic Support
                            </li>
                            <li class="mb-3 flex items-start">
                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                    <HiCheck />
                                </div>  
                                <span className="font-semibold me-1"> 100,000</span> Word Tokens
                            </li>
                            <li class="mb-3 flex items-start">
                                <div class="p-[2px] me-2 inline-flex items-center justify-center rounded-full bg-primary/10 align-middle text-primary">
                                    <HiCheck />
                                </div> 
                                <span className="font-semibold me-1"> 50,000</span> Image Tokens
                            </li>
                        </ul>
                        <div className="md:absolute left-0 bottom-5 w-full px-8 pt-10">
                            <div className="shadow-md rounded-full py-2 hover:bg-blue-500 hover:text-white text-lg cursor-pointer hover:-translate-y-2 duration-300 hover:shadow-lg text-center">
                                Choose plan
                            </div>
                        </div>
                    </div>                    
                </div>
            </div>
        </>
    )
}
export default Payment;