import { Link } from "react-router-dom";
import { 
    HiOutlineChevronLeft,
    HiPlusSm,
    HiOutlineTrash,
    HiOutlineDotsVertical,
} from "react-icons/hi";
import { Label, TextInput, ToggleSwitch } from 'flowbite-react';
import { useState } from "react";
const Menu =()=>{
    const [visible, setVisible] = useState(false);
    const [switch1, setSwitch1] = useState(false);
    return(
        <>
            <div className='p-5 md:flex md:justify-between bg-white rounded-md my-2'>
                <div>
                    <div className='mb-1 text-slate-400'>
                        <Link to="/admin/dashboard" className="flex items-center">
                                <HiOutlineChevronLeft className="me-1" /> Back to dashboard
                        </Link>
                    </div>
                    <h2 className='text-4xl font-bold'>Menu Settings</h2>
                </div> 
            </div>
            <div className='p-5 bg-white rounded-md'>
                <div className='grid md:grid-cols-12'>
                    <div className='md:col-span-6 md:col-start-4'>
                        <div>
                            <div className="py-4 flex justify-center">
                                <button className="bg-white px-5 py-2 flex items-center justify-center rounded-full cursor-pointer hover:-translate-y-1 duration-300 shadow-md hover:shadow-xl" >
                                    <HiPlusSm className="mr-1 h-5 w-5" /> Add Menu
                                </button>
                            </div>
                            <div className="mt-6">
                                <div className="border p-2 rounded-md mb-4">
                                    <div className='md:flex justify-between items-center cursor-pointer' onClick={() => setVisible(!visible)}>
                                        <div className="font-bold text-lg">
                                            Home <span className="text-sm text-slate-500 font-normal">#banner</span>
                                        </div>
                                        <div className="mt-4 md:mt-0 flex gap-1">
                                            <div className="w-10 h-10 hover:bg-red-100 hover:text-red-500 cursor-pointer rounded-md flex justify-center items-center">
                                                <HiOutlineTrash className="text-2xl" />
                                            </div>
                                            <div className="w-10 h-10 rounded-md cursor-move hover:bg-black hover:bg-opacity-10 flex justify-center items-center">
                                                <HiOutlineDotsVertical className="text-2xl" />
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        {
                                            visible && 
                                            <div className="mt-4">
                                                <div className="mb-4">
                                                    <div className="mb-2 block">
                                                    <Label value="Title" />
                                                    </div>
                                                    <TextInput type="text" placeholder="Home" />
                                                </div>
                                                <div className="mb-4">
                                                    <div className="mb-2 block">
                                                    <Label value="URL" />
                                                    </div>
                                                    <TextInput type="text" placeholder="#banner" />
                                                </div>
                                                <div className="my-4">
                                                    <ToggleSwitch checked={switch1} label="Open In New Tab" onChange={setSwitch1} />
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Menu;