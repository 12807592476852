import { HiOutlineChevronLeft, HiOutlinePencil, HiOutlineX, HiPlusSm } from "react-icons/hi";
import { Link } from "react-router-dom";
import { Table } from 'flowbite-react';
const BlogPosts =()=>{
    return(
        <>
            <div className='p-5 md:flex md:justify-between bg-white rounded-md my-2'>
                <div>
                    <div className='mb-1 text-sm text-slate-400'>
                        <Link to="/admin/dashboard" className="flex items-center">
                                <HiOutlineChevronLeft className="me-1" /> Back to dashboard
                        </Link>
                    </div>
                    <h2 className='text-2xl lg:text-4xl font-bold'>Blog Posts</h2>
                    <p className='text-slate-400 mb-3 md:mb-0'>Manage Blog Posts</p>
                </div> 
            </div>
            <div className="p-5 mt-4 bg-white rounded-md mb-2">
                <div className="p-2 flex items-center mb-5">
                    <div>
                        <div className="flex justify-center">
                            <Link to="/admin/blog-posts/add-or-update">
                                <button  className="bg-blue-600 hover:bg-blue-500 rounded-full py-2 px-4 text-white flex justify-center font-medium hover:-translate-y-2 duration-300 hover:drop-shadow-lg w-full">
                                    <HiPlusSm className="mr-2 h-5 w-5" /> Add Post
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="border rounded-md">
                    <div className="">
                        <div className="overflow-x-auto">
                            <Table className="bg-transparent">
                                <Table.Head>
                                    <Table.HeadCell className="bg-transparent border-b text-slate-500 font-medium whitespace-nowrap">
                                        Title
                                    </Table.HeadCell>
                                    <Table.HeadCell className="bg-transparent border-b text-slate-500 font-medium whitespace-nowrap">
                                        Category
                                    </Table.HeadCell>
                                    <Table.HeadCell className="bg-transparent border-b text-slate-500 font-medium whitespace-nowrap">
                                        Tag
                                    </Table.HeadCell>
                                    <Table.HeadCell className="bg-transparent border-b text-slate-500 font-medium whitespace-nowrap">
                                        Status
                                    </Table.HeadCell>
                                    <Table.HeadCell className="bg-transparent border-b text-slate-500 font-medium whitespace-nowrap">
                                        Author
                                    </Table.HeadCell>
                                    <Table.HeadCell className="bg-transparent border-b text-slate-500 font-medium whitespace-nowrap">
                                        Updated At
                                    </Table.HeadCell>
                                    <Table.HeadCell className="bg-transparent border-b text-slate-500 font-medium whitespace-nowrap">
                                        Actions
                                    </Table.HeadCell>
                                </Table.Head>
                                <Table.Body className="divide-y">
                                    <Table.Row>
                                        <Table.Cell className="whitespace-nowrap">
                                            The Benefits of ChatGPT
                                        </Table.Cell>
                                        <Table.Cell>
                                            ChatGPT
                                        </Table.Cell>
                                        <Table.Cell>
                                            ChatGPT	
                                        </Table.Cell>
                                        <Table.Cell className="">
                                            <span className="bg-green-100 px-4 py-1 rounded-full font-semibold text-green-600">
                                                Published
                                            </span>
                                        </Table.Cell>
                                        <Table.Cell className="min-w-32">
                                            Admin
                                        </Table.Cell>
                                        <Table.Cell className="min-w-32">
                                            <div>20.9.2023</div> 
                                            <div className='text-slate-400'>05:26:49</div>
                                        </Table.Cell>
                                        <Table.Cell>
                                            <div className='flex gap-2'>
                                                <div className='h-10 w-10 flex justify-center items-center bg-white  hover:bg-blue-600 rounded-full shadow-md hover:-translate-y-1 duration-300 hover:shadow-xl cursor-pointer hover:text-white' title="Edit">
                                                    <HiOutlinePencil className='text-lg' />
                                                </div>
                                                <div className='h-10 w-10 flex justify-center items-center bg-white  hover:bg-red-700 rounded-full shadow-md hover:-translate-y-1 duration-300 hover:shadow-xl cursor-pointer hover:text-white' title="Delete">
                                                    <HiOutlineX className='text-lg' />
                                                </div>
                                            </div>
                                        </Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell className="whitespace-nowrap">
                                            Creating Articles with ChatGPT
                                        </Table.Cell>
                                        <Table.Cell>
                                            ChatGPT Article
                                        </Table.Cell>
                                        <Table.Cell>
                                            Article
                                        </Table.Cell>
                                        <Table.Cell className="">
                                            <span className="bg-blue-600 text-white px-4 py-1 rounded-full font-semibold">
                                                Draft
                                            </span>
                                        </Table.Cell>
                                        <Table.Cell className="min-w-32">
                                            Admin
                                        </Table.Cell>
                                        <Table.Cell className="min-w-32">
                                            <div>20.9.2023</div> 
                                            <div className='text-slate-400'>05:26:49</div>
                                        </Table.Cell>
                                        <Table.Cell>
                                            <div className='flex gap-2'>
                                                <div className='h-10 w-10 flex justify-center items-center bg-white  hover:bg-blue-600 rounded-full shadow-md hover:-translate-y-1 duration-300 hover:shadow-xl cursor-pointer hover:text-white' title="Edit">
                                                    <HiOutlinePencil className='text-lg' />
                                                </div>
                                                <div className='h-10 w-10 flex justify-center items-center bg-white  hover:bg-red-700 rounded-full shadow-md hover:-translate-y-1 duration-300 hover:shadow-xl cursor-pointer hover:text-white' title="Delete">
                                                    <HiOutlineX className='text-lg' />
                                                </div>
                                            </div>
                                        </Table.Cell>
                                    </Table.Row>
                                </Table.Body>
                            </Table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default BlogPosts;