import { Link } from "react-router-dom";
import { 
    HiPlusSm,
    HiOutlineChevronLeft,
    HiOutlineSearch,
    HiStar,
} from "react-icons/hi";
import newsletter from '../../../images/newsletter.png'

const Marketplace =()=>{
    return(
        <>
            <div className='p-5 md:flex md:justify-between bg-white rounded-md my-2'>
                <div>
                    <div className='mb-1 text-gray-400 font-medium'>
                        <Link to="/" className="flex items-center">
                                <HiOutlineChevronLeft className="me-1" /> Back to dashboard
                        </Link>
                    </div>
                    <h2 className='text-4xl font-bold'>Marketplace</h2>
                    {/* <p className='text-slate-400 mb-3 md:mb-0'>
                        Generate AI content exclusive to your brand and eliminate the need for repetitive introductions of your company.
                    </p>  */}
                </div>
                <div className='flex items-center gap-3'>
                    <Link to="/user/brand-voice">
                        <button class='bg-white hover:bg-gray-50/50 rounded-md py-2 px-4 flex font-medium hover:-translate-y-2 duration-300 border hover:shadow-lg'>
                            Manage Addons
                        </button>
                    </Link>
                    <Link to="/user/brandvoice/add-or-update">
                        <button class='bg-blue-600 hover:bg-blue-500 rounded-md py-2 px-4 text-white flex font-medium hover:-translate-y-2 duration-300 hover:drop-shadow-lg'>
                            <HiPlusSm className="mr-1 h-5 w-5" /> Browse Add-ons
                        </button>
                    </Link>
                </div>
            </div>
            <div className='bg-white rounded-md px-2 py-2 my-2'>
                <div className="relative my-2 md:my-auto lg:mb-0 rounded-md shadow-sm bg-[#F4F4F4] w-full">
                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                        <span className="text-gray-500 sm:text-sm"><HiOutlineSearch /></span>
                    </div>
                    <input
                    type="text"
                    className="block w-full rounded-md border-0 py-1.5 pl-8 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 h-12 bg-transparent"
                    placeholder="Search for templates and documents..."
                    />
                </div>
            </div>
            <div className='bg-white rounded-md px-2 py-2 my-2'>
                <div className="md:flex justify-between items-center">
                    <div className="md:flex gap-x-4">
                        <div>
                            <ul className="flex gap-x-2">
                                <li className="px-4 py-1 text-white bg-[#0502AD] hover:bg-[#0502AD] rounded-md cursor-pointer mb-1 md:mb-0">All</li>
                                <li className="px-4 py-1 bg-gray-200 hover:bg-[#0502AD] hover:text-white rounded-md cursor-pointer mb-1 md:mb-0">
                                    Installed
                                </li>
                                <li className="px-4 py-1 bg-gray-200 hover:bg-[#0502AD] hover:text-white rounded-md cursor-pointer mb-1 md:mb-0">
                                    Free
                                </li>
                                <li className="px-4 py-1 bg-gray-200 hover:bg-[#0502AD] hover:text-white rounded-md cursor-pointer mb-1 md:mb-0">
                                    Paid
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className='rounded-md py-2 my-2'>
                <div className="md:grid md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-x-6">
                    <div className="mb-6">
                        <Link>
                            <div className='p-4 h-auto md:min-h-72 lg:min-h-64 relative rounded-md bg-white shadow-md'>
                                <div className='cursor-pointer group '>
                                    <div className='h-12 w-12 flex items-center justify-center  cursor-pointer relative mb-4'>
                                        <img src={newsletter} alt="" />
                                    </div>
                                    <h2 className='text-lg font-medium flex items-center'>
                                        <span>Newsletter</span> <span className="ms-2 me-1">
                                            <HiStar className="text-amber-500" /> 
                                        </span> <span className="text-base">5.0</span>
                                    </h2>
                                    <p className='mt-3 text-slate-500'>
                                        Create own email template and send to users.
                                    </p>
                                </div>
                                <div className="mt-4 md:absolute bottom-2 xl:bottom-4">
                                    <ul className="flex gap-2">
                                        <li className=" relative pe-3 text-sm">Bulk Email 
                                            <div class="break-after-column w-1 h-1 bg-slate-300 rounded-full top-2 right-0 absolute"></div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="mb-6">
                        <Link>
                            <div className='p-4 h-auto md:min-h-72 lg:min-h-64 relative rounded-md bg-white shadow-md'>
                                <div className='cursor-pointer group'>
                                    <div className='h-12 w-12 flex items-center justify-center  cursor-pointer relative mb-4'>
                                        <img src={newsletter} alt="" />
                                    </div>
                                    <h2 className='text-lg font-medium flex items-center justify-between'>
                                        <span>AI Plagiarism and Content Detector</span>
                                        <span className="ms-2 me-1">
                                            <HiStar className="text-amber-500" /> 
                                        </span> <span className="text-base">5.0</span>
                                    </h2>
                                    <p className='mt-3 text-slate-500'>
                                        Analyze text, comparing it against a vast database online content to identify potential plagiarism
                                    </p>
                                </div>
                                <div className="mt-5 md:absolute bottom-2 xl:bottom-4">
                                    <ul className="flex gap-2">
                                        <li className=" relative pe-3 text-sm">Plagiarism 
                                            <div class="break-after-column w-1 h-1 bg-slate-300 rounded-full top-2 right-0 absolute"></div>
                                        </li>
                                        <li className=" relative pe-3 text-sm">Blog 
                                            <div class="break-after-column w-1 h-1 bg-slate-300 rounded-full top-2 right-0 absolute"></div>
                                        </li>
                                        <li className=" relative pe-3 text-sm">AI Content 
                                            <div class="break-after-column w-1 h-1 bg-slate-300 rounded-full top-2 right-0 absolute"></div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="mb-6">
                        <Link>
                            <div className='p-4 h-auto md:min-h-72 lg:min-h-64 relative rounded-md bg-white shadow-md'>
                                <div className='cursor-pointer group '>
                                    <div className='h-12 w-12 flex items-center justify-center  cursor-pointer relative mb-4'>
                                        <img src={newsletter} alt="" />
                                    </div>
                                    <h2 className='text-lg font-medium flex items-center'>
                                        <span>Newsletter</span>
                                        <span className="ms-2 me-1">
                                            <HiStar className="text-amber-500" /> 
                                        </span> <span className="text-base">5.0</span>
                                    </h2>
                                    <p className='mt-3 text-slate-500'>
                                        Create own email template and send to users.
                                    </p>
                                </div>
                                <div className="mt-4 md:absolute bottom-2 xl:bottom-4">
                                    <ul className="flex gap-2">
                                        <li className=" relative pe-3 text-sm">Bulk Email 
                                            <div class="break-after-column w-1 h-1 bg-slate-300 rounded-full top-2 right-0 absolute"></div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Marketplace;