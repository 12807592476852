import { 
    HiPlusSm,
    HiOutlineChevronLeft,
    HiOutlineInformationCircle,
    HiOutlinePlus,
    HiMinusSm
} from "react-icons/hi";
import { Link } from "react-router-dom";
import { Label, TextInput, Textarea,Select, FileInput } from 'flowbite-react';
const AddupdateCompany =()=>{   
    return(
        <>
            <div className='p-5 md:flex md:justify-between bg-white rounded-md my-2'>
                <div>
                    <div className='mb-1 text-gray-400 font-medium'>
                        <Link to="/" className="flex items-center">
                                <HiOutlineChevronLeft className="me-1" /> Back to dashboard
                        </Link>
                    </div>
                    <h2 className='text-4xl font-bold'>Brand Voice</h2>
                    <p className='text-slate-400 mb-3 md:mb-0'>
                        Generate AI content exclusive to your brand and eliminate the need for repetitive introductions of your company.
                    </p> 
                </div>
                <div className='flex items-center gap-3'>
                    <Link to="/user/brand-voice">
                        <button class='bg-white hover:bg-gray-50/50 rounded-md py-2 px-4 flex font-medium hover:-translate-y-2 duration-300 border hover:shadow-lg'>
                            Manage Voices
                        </button>
                    </Link>
                    <Link to="/user/brandvoice/add-or-update">
                        <button class='bg-blue-600 hover:bg-blue-500 rounded-md py-2 px-4 text-white flex font-medium hover:-translate-y-2 duration-300 hover:drop-shadow-lg'>
                            <HiPlusSm className="mr-1 h-5 w-5" /> New Company
                        </button>
                    </Link>
                </div>
            </div>
            <div className='p-5 bg-white rounded-md'>
                <div className='grid md:grid-cols-12'>
                    <div className='md:col-span-6 md:col-start-4'>
                        <div class=''>
                            <div>
                                <div className="flex items-center p-4 py-3 gap-3 rounded-xl bg-gray-100 mt-5 mb-8">
                                    <div className="w-6 h-6 flex justify-center items-center bg-purple-600 text-white  rounded-full font-bold ">
                                        1
                                    </div>
                                    <div className="text-slate-500 font-bold">Company</div>
                                </div>
                                <form className="flex flex-col">
                                    <div className='mb-4'>
                                        <div className="mb-2 relative flex items-center">
                                            <Label value="Company Name" className="text-slate-500" />
                                            <span className='cursor-pointer group'>
                                                <HiOutlineInformationCircle className='ms-1 text-slate-500' />
                                                <span className='absolute bottom-2 left-0 bg-slate-200 text-slate-600 p-2 rounded-md border text-xs invisible group-hover:visible group-hover:-translate-y-6 translate-y-4 duration-300 opacity-0 group-hover:opacity-100'>
                                                    Enter the name of your company or organization.
                                                </span>
                                            </span>
                                        </div>
                                        <TextInput type="text" />
                                    </div>
                                    <div className='mb-4'>
                                        <div className="mb-2 relative flex items-center">
                                            <Label value="Industry" className="text-slate-500" />
                                            <span className='cursor-pointer group'>
                                                <HiOutlineInformationCircle className='ms-1 text-slate-500' />
                                                <span className='absolute bottom-2 left-0 bg-slate-200 text-slate-600 p-2 rounded-md border text-xs invisible group-hover:visible group-hover:-translate-y-6 translate-y-4 duration-300 opacity-0 group-hover:opacity-100'>
                                                    The field or sector of business activity your company primarily belongs to.
                                                </span>
                                            </span>
                                        </div>
                                        <TextInput type="text" />
                                    </div>
                                    <div className="bg-blue-100 text-blue-600 rounded-lg p-2 flex items-center mb-4 mt-2 text-sm">
                                        <span className='me-2'><HiOutlineInformationCircle className='text-2xl' /> 
                                        </span>  You can enter as much Industry as you want. Click "Enter" after each Industry.
                                    </div>
                                    <div className='mb-4'>
                                        <div className="mb-2 relative flex items-center">
                                            <Label value="Description" className="text-slate-500" />
                                            <span className='cursor-pointer group'>
                                                <HiOutlineInformationCircle className='ms-1 text-slate-500' />
                                                <span className='absolute bottom-2 left-0 bg-slate-200 text-slate-600 p-2 rounded-md border text-xs invisible group-hover:visible group-hover:-translate-y-6 translate-y-4 duration-300 opacity-0 group-hover:opacity-100'>
                                                A concise summary describing your company, its mission, and what sets it apart.
                                                </span>
                                            </span>
                                        </div>
                                        <Textarea rows={4} />
                                    </div>
                                    <div className='mb-4'>
                                        <div className="mb-2 relative flex items-center">
                                            <Label value="Website " className="text-slate-500" />
                                            <span className='cursor-pointer group'>
                                                <HiOutlineInformationCircle className='ms-1 text-slate-500' />
                                                <span className='absolute bottom-2 left-0 bg-slate-200 text-slate-600 p-2 rounded-md border text-xs invisible group-hover:visible group-hover:-translate-y-6 translate-y-4 duration-300 opacity-0 group-hover:opacity-100'>
                                                Please provide the full web address (URL) of your company’s official website.
                                                </span>
                                            </span>
                                        </div>
                                        <TextInput type="text" />
                                    </div>
                                    <div className='mb-4'>
                                        <div className="mb-2 relative flex items-center">
                                            <Label value="Tagline" className="text-slate-500" />
                                            <span className='cursor-pointer group'>
                                                <HiOutlineInformationCircle className='ms-1 text-slate-500' />
                                                <span className='absolute bottom-2 left-0 bg-slate-200 text-slate-600 p-2 rounded-md border text-xs invisible group-hover:visible group-hover:-translate-y-6 translate-y-4 duration-300 opacity-0 group-hover:opacity-100'>
                                                A memorable and succinct phrase encapsulating your company’s mission or value proposition.
                                                </span>
                                            </span>
                                        </div>
                                        <TextInput type="text" />
                                    </div>
                                    <div className='mb-4'>
                                        <div className="mb-2 relative flex items-center">
                                            <Label value="Tone of Voice" className="text-slate-500" />
                                            <span className='cursor-pointer group'>
                                                <HiOutlineInformationCircle className='ms-1 text-slate-500' />
                                                <span className='absolute bottom-2 left-0 bg-slate-200 text-slate-600 p-2 rounded-md border text-xs invisible group-hover:visible group-hover:-translate-y-6 translate-y-4 duration-300 opacity-0 group-hover:opacity-100'>
                                                Select the tone of voice that best represents your company’s brand.
                                                </span>
                                            </span>
                                        </div>
                                        <Select>
                                            <option>Select</option>
                                        </Select>
                                    </div>
                                    <div className='mb-4'>
                                        <div className="mb-2 relative flex items-center">
                                            <Label value="Target Audience" className="text-slate-500" />
                                            <span className='cursor-pointer group'>
                                                <HiOutlineInformationCircle className='ms-1 text-slate-500' />
                                                <span className='absolute bottom-2 left-0 bg-slate-200 text-slate-600 p-2 rounded-md border text-xs invisible group-hover:visible group-hover:-translate-y-6 translate-y-4 duration-300 opacity-0 group-hover:opacity-100'>
                                                Describe the primary demographic or audience your company is targeting.
                                                </span>
                                            </span>
                                        </div>
                                        <Textarea rows={4} />
                                    </div>

                                    <div className='mb-4'>
                                        <div className="mb-2 relative flex items-center">
                                            <Label value="Logo" className="text-slate-500" />
                                            <span className='cursor-pointer group'>
                                                <HiOutlineInformationCircle className='ms-1 text-slate-500' />
                                                <span className='absolute bottom-2 left-0 bg-slate-200 text-slate-600 p-2 rounded-md border text-xs invisible group-hover:visible group-hover:-translate-y-6 translate-y-4 duration-300 opacity-0 group-hover:opacity-100'>
                                                Please upload a high-resolution image file (JPEG, PNG, or SVG) of your company logo.
                                                </span>
                                            </span>
                                        </div>
                                        <FileInput />
                                    </div>
                                    <div className='mb-4'>
                                        <div className="mb-2 relative flex items-center">
                                            <Label value="Brand Color" className="text-slate-500" />
                                            <span className='cursor-pointer group'>
                                                <HiOutlineInformationCircle className='ms-1 text-slate-500' />
                                                <span className='absolute bottom-2 left-0 bg-slate-200 text-slate-600 p-2 rounded-md border text-xs invisible group-hover:visible group-hover:-translate-y-6 translate-y-4 duration-300 opacity-0 group-hover:opacity-100'>
                                                Pick a color for for the icon container shape. Color is in HEX format.
                                                </span>
                                            </span>
                                        </div>
                                        <TextInput />
                                    </div>
                                    <div className="flex items-center justify-between p-4 py-3 gap-3 rounded-xl bg-gray-100 mt-10 mb-10">
                                        <div className="flex">
                                            <div className="w-6 h-6 flex justify-center items-center bg-purple-600 text-white  rounded-full font-bold ">
                                                2
                                            </div>
                                            <div className="text-slate-500 font-bold ms-4">
                                                Products or Services
                                            </div>
                                        </div>
                                        <div className="w-7 h-7 flex justify-center items-center bg-white hover:bg-black hover:text-white rounded-full font-bold cursor-pointer">
                                            <HiOutlinePlus />
                                        </div>
                                    </div>
                                    <div className="border rounded-xl p-5 mb-4">
                                        <div className='mb-4'>
                                            <div className="mb-2 relative flex items-center justify-between">
                                                <div className="flex items-center">
                                                    <Label className="text-slate-500" value="Name" />
                                                    <span className='cursor-pointer group'>
                                                        <HiOutlineInformationCircle className='ms-1 text-slate-500' />
                                                        <span className='absolute bottom-2 left-0 bg-slate-200 text-slate-600 p-2 rounded-md border text-xs invisible group-hover:visible group-hover:-translate-y-6 translate-y-4 duration-300 opacity-0 group-hover:opacity-100'>
                                                            The primary item or service your company provides to its customers.
                                                        </span>
                                                    </span>
                                                </div>
                                                <div className="w-7 h-7 flex justify-center items-center rounded-full hover:border-2 hover:border-red-600">
                                                    <div className="w-5 h-5 flex justify-center items-center rounded-full border-2 border-red-600 text-red-700 transition-all hover:text-white hover:bg-red-600"><HiMinusSm /></div>
                                                </div>
                                            </div>
                                            <TextInput type="text" />
                                        </div>
                                        <div className='mb-4'>
                                            <div className="mb-2 relative flex items-center">
                                                <Label className="text-slate-500" value="Type" />
                                            </div>
                                            <Select>
                                                <option>Select</option> 
                                            </Select>
                                        </div>
                                        <div className='mb-4'>
                                            <div className="mb-2 relative flex items-center">
                                                <Label className="text-slate-500" value="Key Features" />
                                                <span className='cursor-pointer group'>
                                                    <HiOutlineInformationCircle className='ms-1 text-slate-500' />
                                                    <span className='absolute bottom-2 left-0 bg-slate-200 text-slate-600 p-2 rounded-md border text-xs invisible group-hover:visible group-hover:-translate-y-6 translate-y-4 duration-300 opacity-0 group-hover:opacity-100'>
                                                        
                                                    </span>
                                                </span>
                                            </div>
                                            <Textarea rows={4} />
                                        </div>
                                    </div>                               
                                    <div className="py-4 flex justify-center">
                                        <button className="bg-blue-600 hover:bg-blue-500 rounded-full py-2 px-4 text-white flex justify-center font-medium hover:-translate-y-2 duration-300 hover:drop-shadow-lg w-full">
                                            Save
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default AddupdateCompany;