import { 
    HiPlusSm,
    HiOutlineChevronLeft,
    HiOutlineViewGrid,
    HiOutlineX,
    HiOutlineStar,
    HiOutlineDotsVertical,
    HiFolder,
    HiOutlinePencil,
    HiOutlineMinusCircle
} from "react-icons/hi";
import { LuListFilter, LuList } from "react-icons/lu";
import { MdTitle } from "react-icons/md";
import { Link } from "react-router-dom";
import { Table, Dropdown, TextInput, Modal, Label } from 'flowbite-react';
import { useState } from 'react';
const Documents =()=>{   
    const [openNewfolder, setNewfolder] = useState(false);
    return(
        <>
            <div className='p-5 md:flex md:justify-between bg-white rounded-md my-2'>
                <div>
                    <div className='mb-1 text-gray-400 font-medium'>
                        <Link to="/" className="flex items-center">
                                <HiOutlineChevronLeft className="me-1" /> Back to dashboard
                        </Link>
                    </div>
                    <h2 className='text-4xl font-bold mb-3 md:mb-0'>My Documents</h2>
                </div>
                <div className='flex items-center gap-3'>
                    <button class='bg-blue-600 hover:bg-blue-500 rounded-md py-2 px-4 text-white flex font-medium hover:-translate-y-2 duration-300 hover:drop-shadow-lg' onClick={() => setNewfolder(true)}>
                        <HiPlusSm className="mr-1 h-5 w-5" /> New Folder
                    </button>
                    <Modal show={openNewfolder} size="md" onClose={() => setNewfolder(false)}>
                        <Modal.Header>New Folder</Modal.Header>
                        <Modal.Body>
                            <div className='space-y-6'>
                                <div className="mb-4">
                                    <div className='mb-2 block'>
                                    <Label value="New Folder Name:" />
                                    </div>
                                    <TextInput type='text' />
                                </div>
                                <div className="py-4 flex justify-end gap-4">
                                    <button  onClick={() => setNewfolder(false)} className="border border-purple-300 text-purple-700 px-5 py-2 flex items-center justify-center rounded-md cursor-pointer hover:-translate-y-1 duration-300 hover:shadow-lg">
                                        Cancel
                                    </button>
                                    <button class='bg-blue-600 hover:bg-blue-500 rounded-md py-2 px-4 text-white flex font-medium hover:-translate-y-2 duration-300 hover:drop-shadow-lg'>
                                        Add
                                    </button>
                                </div>
                            </div>  
                        </Modal.Body>
                    </Modal>
                </div>
            </div>
            <div className='bg-white rounded-md px-5 py-2 my-2'>
                <div className="md:flex justify-between items-center">
                    <div className="md:flex gap-x-4">
                        <div className="flex items-center gap-x-2">
                            <span>Sort By :</span> 
                            <Dropdown label="" dismissOnClick={false} renderTrigger={() => 
                                <span className='flex items-center gap-2 hover:bg-gray-200 cursor-pointer rounded-md py-1 px-2'> 
                                    <LuListFilter />
                                </span>
                                }>
                                <Dropdown.Item>Chat GPT</Dropdown.Item>
                                <Dropdown.Item>Gemini GPT</Dropdown.Item>
                            </Dropdown>
                        </div>
                        <div>
                            <ul className="md:flex gap-x-6">
                                <li className="px-4 py-1 hover:bg-gray-200 rounded-md cursor-pointer mb-1 md:mb-0">All</li>
                                <li className="px-4 py-1 hover:bg-gray-200 rounded-md cursor-pointer mb-1 md:mb-0">
                                    Favorites
                                </li>
                                <li className="px-4 py-1 hover:bg-gray-200 rounded-md cursor-pointer mb-1 md:mb-0">Text</li>
                                <li className="px-4 py-1 hover:bg-gray-200 rounded-md cursor-pointer mb-1 md:mb-0">
                                    Image
                                </li>
                                <li className="px-4 py-1 hover:bg-gray-200 rounded-md cursor-pointer mb-1 md:mb-0">Code</li>
                            </ul>
                        </div>
                    </div>
                    <div className="flex gap-x-4">
                        <div className="p-1 hover:bg-gray-200 rounded-sm cursor-pointer"><LuList /></div>
                        <div className="p-1 hover:bg-gray-200 rounded-sm cursor-pointer"><HiOutlineViewGrid/></div>
                    </div>
                </div>
            </div>
            <div className='bg-white rounded-md p-5 my-2'>
                <div className="md:grid grid-cols-4 gap-2">
                    <div className="bg-gray-100 hover:bg-gray-300 rounded-md px-2 py-1 mb-2 flex justify-between items-center cursor-pointer">
                        <div className="flex gap-2 items-center">
                            <div><HiFolder  className="text-6xl text-gray-400" /></div>
                            <div>
                                <div>
                                    <h3 className=" font-semibold text-sm text-slate-900">test</h3>
                                </div>
                                <h3 className="font-medium text-sm text-slate-600">test</h3>
                            </div>
                        </div>
                        <div>
                            <Dropdown label="" dismissOnClick={false} renderTrigger={() => 
                                <span className='cursor-pointer'>
                                    <HiOutlineDotsVertical className='text-slate-800 text-2xl' />
                                </span>}>
                                <Dropdown.Item className="px-4 py-1 mb-2">
                                    <HiOutlinePencil className="me-2 text-lg" /> Move
                                </Dropdown.Item>
                                <Dropdown.Item className="px-4 py-1">
                                    <HiOutlineMinusCircle className='me-2 text-lg text-red-700' /> Move
                                </Dropdown.Item>
                            </Dropdown>
                        </div>
                    </div>
                    <div className="bg-gray-100 hover:bg-gray-300 rounded-md px-2 py-1 mb-2 flex justify-between items-center cursor-pointer">
                        <div className="flex gap-2 items-center">
                            <div><HiFolder  className="text-6xl text-gray-400" /></div>
                            <div>
                                <div>
                                    <h3 className=" font-semibold text-sm text-slate-900">test</h3>
                                </div>
                                <h3 className="font-medium text-sm text-slate-600">test</h3>
                            </div>
                        </div>
                        <div>
                            <Dropdown label="" dismissOnClick={false} renderTrigger={() => 
                                <span className='cursor-pointer'>
                                    <HiOutlineDotsVertical className='text-slate-800 text-2xl' />
                                </span>}>
                                <Dropdown.Item className="px-4 py-1 mb-2">
                                    <HiOutlinePencil className="me-2 text-lg" /> Move
                                </Dropdown.Item>
                                <Dropdown.Item className="px-4 py-1">
                                    <HiOutlineMinusCircle className='me-2 text-lg text-red-700' /> Move
                                </Dropdown.Item>
                            </Dropdown>
                        </div>
                    </div>
                    <div className="bg-gray-100 hover:bg-gray-300 rounded-md px-2 py-1 mb-2 flex justify-between items-center cursor-pointer">
                        <div className="flex gap-2 items-center">
                            <div><HiFolder  className="text-6xl text-gray-400" /></div>
                            <div>
                                <div>
                                    <h3 className=" font-semibold text-sm text-slate-900">test</h3>
                                </div>
                                <h3 className="font-medium text-sm text-slate-600">test</h3>
                            </div>
                        </div>
                        <div>
                            <Dropdown label="" dismissOnClick={false} renderTrigger={() => 
                                <span className='cursor-pointer'>
                                    <HiOutlineDotsVertical className='text-slate-800 text-2xl' />
                                </span>}>
                                <Dropdown.Item className="px-4 py-1 mb-2">
                                    <HiOutlinePencil className="me-2 text-lg" /> Move
                                </Dropdown.Item>
                                <Dropdown.Item className="px-4 py-1">
                                    <HiOutlineMinusCircle className='me-2 text-lg text-red-700' /> Move
                                </Dropdown.Item>
                            </Dropdown>
                        </div>
                    </div>
                    <div className="bg-gray-100 hover:bg-gray-300 rounded-md px-2 py-1 mb-2 flex justify-between items-center cursor-pointer">
                        <div className="flex gap-2 items-center">
                            <div><HiFolder  className="text-6xl text-gray-400" /></div>
                            <div>
                                <div>
                                    <h3 className=" font-semibold text-sm text-slate-900">test</h3>
                                </div>
                                <h3 className="font-medium text-sm text-slate-600">test</h3>
                            </div>
                        </div>
                        <div>
                            <Dropdown label="" dismissOnClick={false} renderTrigger={() => 
                                <span className='cursor-pointer'>
                                    <HiOutlineDotsVertical className='text-slate-800 text-2xl' />
                                </span>}>
                                <Dropdown.Item className="px-4 py-1 mb-2">
                                    <HiOutlinePencil className="me-2 text-lg" /> Move
                                </Dropdown.Item>
                                <Dropdown.Item className="px-4 py-1">
                                    <HiOutlineMinusCircle className='me-2 text-lg text-red-700' /> Move
                                </Dropdown.Item>
                            </Dropdown>
                        </div>
                    </div>
                    <div className="bg-gray-100 hover:bg-gray-300 rounded-md px-2 py-1 mb-2 flex justify-between items-center cursor-pointer">
                        <div className="flex gap-2 items-center">
                            <div><HiFolder  className="text-6xl text-gray-400" /></div>
                            <div>
                                <div>
                                    <h3 className=" font-semibold text-sm text-slate-900">test</h3>
                                </div>
                                <h3 className="font-medium text-sm text-slate-600">test</h3>
                            </div>
                        </div>
                        <div>
                            <Dropdown label="" dismissOnClick={false} renderTrigger={() => 
                                <span className='cursor-pointer'>
                                    <HiOutlineDotsVertical className='text-slate-800 text-2xl' />
                                </span>}>
                                <Dropdown.Item className="px-2 py-1">
                                    <HiOutlinePencil className="me-2 text-lg" /> Move
                                </Dropdown.Item>
                                <Dropdown.Item className="px-2 py-1">
                                    <HiOutlineMinusCircle className='me-2 text-lg text-red-700' /> Move
                                </Dropdown.Item>
                            </Dropdown>
                        </div>
                    </div>
                    <div className="bg-gray-100 hover:bg-gray-300 rounded-md px-2 py-1 mb-2 flex justify-between items-center cursor-pointer">
                        <div className="flex gap-2 items-center">
                            <div><HiFolder  className="text-6xl text-gray-400" /></div>
                            <div>
                                <div>
                                    <h3 className=" font-semibold text-sm text-slate-900">test</h3>
                                </div>
                                <h3 className="font-medium text-sm text-slate-600">test</h3>
                            </div>
                        </div>
                        <div>
                            <Dropdown label="" dismissOnClick={false} renderTrigger={() => 
                                <span className='cursor-pointer'>
                                    <HiOutlineDotsVertical className='text-slate-800 text-2xl' />
                                </span>}>
                                <Dropdown.Item className="px-2 py-1">
                                    <HiOutlinePencil className="me-2 text-lg" /> Move
                                </Dropdown.Item>
                                <Dropdown.Item className="px-2 py-1">
                                    <HiOutlineMinusCircle className='me-2 text-lg text-red-700' /> Move
                                </Dropdown.Item>
                            </Dropdown>
                        </div>
                    </div>
                </div>
            </div>
            <div className='bg-white rounded-md p-5 my-2 overflow-x-auto'>
                <div>
                    <Table>
                        <Table.Head className='bg-white p-0' >
                            <Table.HeadCell className='bg-transparent text-slate-500 p-0'>
                                <div className="border-b px-6 pb-4 mb-4">Name</div>
                            </Table.HeadCell>
                            <Table.HeadCell className='bg-transparent text-slate-500 p-0'>
                                <div className="border-b px-6 pb-4 mb-4">Type</div>
                            </Table.HeadCell>
                            <Table.HeadCell className='bg-transparent text-slate-500 p-0'>
                                <div className="border-b px-6 pb-4 mb-4">Date</div> 
                            </Table.HeadCell>
                            <Table.HeadCell className='bg-transparent text-slate-500 p-0'>
                                <div className="border-b px-6 pb-4 mb-4">Cost</div>
                            </Table.HeadCell>
                            <Table.HeadCell className='bg-transparent text-slate-500 p-0'>
                                <div className="border-b px-6 pb-4 mb-4">Actions</div>
                            </Table.HeadCell>
                        </Table.Head>
                        <Table.Body className="divide-y-4 divide-white text-slate-900">
                            <Table.Row className="even:bg-[#FFEBEB] odd:bg-[#EBFAFF]">
                                <Table.Cell className="whitespace-nowrap rounded-tl-xl rounded-bl-xl">
                                    <div className='flex items-center'>
                                        <div>
                                            <div className='h-8 w-8 bg-green-200 rounded-full flex items-center justify-center'>
                                                <MdTitle className='text-slate-800 text-xl' />
                                            </div>
                                        </div>
                                        <div className='ps-3'>
                                            <h3 className="truncate md:max-w-64 text-wrap md:text-nowrap">
                                                Understanding HTML HTML, or HyperText Markup Lang...
                                            </h3>
                                        </div>
                                    </div>
                                </Table.Cell>
                                <Table.Cell>
                                    <h3 className="truncate md:max-w-64 w-60 text-wrap ">
                                        AI Article Wizard Generator
                                    </h3>
                                </Table.Cell>
                                <Table.Cell>
                                    <h3 class="m-0 text-nowrap">Jan 15 2024, <span class="opacity-50">05:55</span>
                                    </h3>
                                </Table.Cell>
                                <Table.Cell>
                                    <h3>520</h3>
                                </Table.Cell>
                                <Table.Cell className='rounded-tr-xl rounded-br-xl'>
                                    <div className='flex gap-2'>
                                        <div className='h-10 w-10 flex justify-center items-center bg-white hover:bg-blue-600 rounded-full shadow-md hover:-translate-y-1 duration-300 hover:shadow-xl cursor-pointer hover:text-white'>
                                            <HiOutlineStar className='text-lg' />
                                        </div>
                                        <div className='h-10 w-10 flex justify-center items-center bg-white  hover:bg-red-700 rounded-full shadow-md hover:-translate-y-1 duration-300 hover:shadow-xl cursor-pointer hover:text-white'>
                                            <HiOutlineX className='text-lg' />
                                        </div>
                                        <div>
                                            <Dropdown label="" dismissOnClick={false} renderTrigger={() => 
                                            <span className='h-10 w-10 flex justify-center items-center hover:bg-white hover:rounded-full duration-300 hover:shadow-md cursor-pointer'>
                                                <HiOutlineDotsVertical className='text-slate-600 text-xl' />
                                            </span>}>
                                                <Dropdown.Item>Move</Dropdown.Item>
                                            </Dropdown>
                                        </div>
                                    </div>
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row className="even:bg-[#FFEBEB] odd:bg-[#EBFAFF]">
                                <Table.Cell className="whitespace-nowrap rounded-tl-xl rounded-bl-xl">
                                    <div className='flex items-center'>
                                        <div>
                                            <div className='h-8 w-8 bg-green-200 rounded-full flex items-center justify-center'>
                                                <MdTitle className='text-slate-800 text-xl' />
                                            </div>
                                        </div>
                                        <div className='ps-3'>
                                            <h3 className="truncate md:max-w-64 text-wrap md:text-nowrap">
                                                Understanding HTML HTML, or HyperText Markup Lang...
                                            </h3>
                                        </div>
                                    </div>
                                </Table.Cell>
                                <Table.Cell>
                                    <h3 className="truncate md:max-w-64 w-60 text-wrap ">
                                        AI Article Wizard Generator
                                    </h3>
                                </Table.Cell>
                                <Table.Cell>
                                    <h3 class="m-0 text-nowrap">Jan 15 2024, <span class="opacity-50">05:55</span>
                                    </h3>
                                </Table.Cell>
                                <Table.Cell>
                                    <h3>520</h3>
                                </Table.Cell>
                                <Table.Cell className='rounded-tr-xl rounded-br-xl'>
                                    <div className='flex gap-2'>
                                        <div className='h-10 w-10 flex justify-center items-center bg-white hover:bg-blue-600 rounded-full shadow-md hover:-translate-y-1 duration-300 hover:shadow-xl cursor-pointer hover:text-white'>
                                            <HiOutlineStar className='text-lg' />
                                        </div>
                                        <div className='h-10 w-10 flex justify-center items-center bg-white  hover:bg-red-700 rounded-full shadow-md hover:-translate-y-1 duration-300 hover:shadow-xl cursor-pointer hover:text-white'>
                                            <HiOutlineX className='text-lg' />
                                        </div>
                                        <div>
                                            <Dropdown label="" dismissOnClick={false} renderTrigger={() => 
                                            <span className='h-10 w-10 flex justify-center items-center hover:bg-white hover:rounded-full duration-300 hover:shadow-md cursor-pointer'>
                                                <HiOutlineDotsVertical className='text-slate-600 text-xl' />
                                            </span>}>
                                                <Dropdown.Item>Move</Dropdown.Item>
                                            </Dropdown>
                                        </div>
                                    </div>
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row className="even:bg-[#FFEBEB] odd:bg-[#EBFAFF]">
                                <Table.Cell className="whitespace-nowrap rounded-tl-xl rounded-bl-xl">
                                    <div className='flex items-center'>
                                        <div>
                                            <div className='h-8 w-8 bg-green-200 rounded-full flex items-center justify-center'>
                                                <MdTitle className='text-slate-800 text-xl' />
                                            </div>
                                        </div>
                                        <div className='ps-3'>
                                            <h3 className="truncate md:max-w-64 text-wrap md:text-nowrap">
                                                Understanding HTML HTML, or HyperText Markup Lang...
                                            </h3>
                                        </div>
                                    </div>
                                </Table.Cell>
                                <Table.Cell>
                                    <h3 className="truncate md:max-w-64 w-60 text-wrap ">
                                        AI Article Wizard Generator
                                    </h3>
                                </Table.Cell>
                                <Table.Cell>
                                    <h3 class="m-0 text-nowrap">Jan 15 2024, <span class="opacity-50">05:55</span>
                                    </h3>
                                </Table.Cell>
                                <Table.Cell>
                                    <h3>520</h3>
                                </Table.Cell>
                                <Table.Cell className='rounded-tr-xl rounded-br-xl'>
                                    <div className='flex gap-2'>
                                        <div className='h-10 w-10 flex justify-center items-center bg-white hover:bg-blue-600 rounded-full shadow-md hover:-translate-y-1 duration-300 hover:shadow-xl cursor-pointer hover:text-white'>
                                            <HiOutlineStar className='text-lg' />
                                        </div>
                                        <div className='h-10 w-10 flex justify-center items-center bg-white  hover:bg-red-700 rounded-full shadow-md hover:-translate-y-1 duration-300 hover:shadow-xl cursor-pointer hover:text-white'>
                                            <HiOutlineX className='text-lg' />
                                        </div>
                                        <div>
                                            <Dropdown label="" dismissOnClick={false} renderTrigger={() => 
                                            <span className='h-10 w-10 flex justify-center items-center hover:bg-white hover:rounded-full duration-300 hover:shadow-md cursor-pointer'>
                                                <HiOutlineDotsVertical className='text-slate-600 text-xl' />
                                            </span>}>
                                                <Dropdown.Item>Move</Dropdown.Item>
                                            </Dropdown>
                                        </div>
                                    </div>
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row className="even:bg-[#FFEBEB] odd:bg-[#EBFAFF]">
                                <Table.Cell className="whitespace-nowrap rounded-tl-xl rounded-bl-xl">
                                    <div className='flex items-center'>
                                        <div>
                                            <div className='h-8 w-8 bg-green-200 rounded-full flex items-center justify-center'>
                                                <MdTitle className='text-slate-800 text-xl' />
                                            </div>
                                        </div>
                                        <div className='ps-3'>
                                            <h3 className="truncate md:max-w-64 text-wrap md:text-nowrap">
                                                Understanding HTML HTML, or HyperText Markup Lang...
                                            </h3>
                                        </div>
                                    </div>
                                </Table.Cell>
                                <Table.Cell>
                                    <h3 className="truncate md:max-w-64 w-60 text-wrap ">
                                        AI Article Wizard Generator
                                    </h3>
                                </Table.Cell>
                                <Table.Cell>
                                    <h3 class="m-0 text-nowrap">Jan 15 2024, <span class="opacity-50">05:55</span>
                                    </h3>
                                </Table.Cell>
                                <Table.Cell>
                                    <h3>520</h3>
                                </Table.Cell>
                                <Table.Cell className='rounded-tr-xl rounded-br-xl'>
                                    <div className='flex gap-2'>
                                        <div className='h-10 w-10 flex justify-center items-center bg-white hover:bg-blue-600 rounded-full shadow-md hover:-translate-y-1 duration-300 hover:shadow-xl cursor-pointer hover:text-white'>
                                            <HiOutlineStar className='text-lg' />
                                        </div>
                                        <div className='h-10 w-10 flex justify-center items-center bg-white  hover:bg-red-700 rounded-full shadow-md hover:-translate-y-1 duration-300 hover:shadow-xl cursor-pointer hover:text-white'>
                                            <HiOutlineX className='text-lg' />
                                        </div>
                                        <div>
                                            <Dropdown label="" dismissOnClick={false} renderTrigger={() => 
                                            <span className='h-10 w-10 flex justify-center items-center hover:bg-white hover:rounded-full duration-300 hover:shadow-md cursor-pointer'>
                                                <HiOutlineDotsVertical className='text-slate-600 text-xl' />
                                            </span>}>
                                                <Dropdown.Item>Move</Dropdown.Item>
                                            </Dropdown>
                                        </div>
                                    </div>
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row className="even:bg-[#FFEBEB] odd:bg-[#EBFAFF]">
                                <Table.Cell className="whitespace-nowrap rounded-tl-xl rounded-bl-xl">
                                    <div className='flex items-center'>
                                        <div>
                                            <div className='h-8 w-8 bg-green-200 rounded-full flex items-center justify-center'>
                                                <MdTitle className='text-slate-800 text-xl' />
                                            </div>
                                        </div>
                                        <div className='ps-3'>
                                            <h3 className="truncate md:max-w-64 text-wrap md:text-nowrap">
                                                Understanding HTML HTML, or HyperText Markup Lang...
                                            </h3>
                                        </div>
                                    </div>
                                </Table.Cell>
                                <Table.Cell>
                                    <h3 className="truncate md:max-w-64 w-60 text-wrap ">
                                        AI Article Wizard Generator
                                    </h3>
                                </Table.Cell>
                                <Table.Cell>
                                    <h3 class="m-0 text-nowrap">Jan 15 2024, <span class="opacity-50">05:55</span>
                                    </h3>
                                </Table.Cell>
                                <Table.Cell>
                                    <h3>520</h3>
                                </Table.Cell>
                                <Table.Cell className='rounded-tr-xl rounded-br-xl'>
                                    <div className='flex gap-2'>
                                        <div className='h-10 w-10 flex justify-center items-center bg-white hover:bg-blue-600 rounded-full shadow-md hover:-translate-y-1 duration-300 hover:shadow-xl cursor-pointer hover:text-white'>
                                            <HiOutlineStar className='text-lg' />
                                        </div>
                                        <div className='h-10 w-10 flex justify-center items-center bg-white  hover:bg-red-700 rounded-full shadow-md hover:-translate-y-1 duration-300 hover:shadow-xl cursor-pointer hover:text-white'>
                                            <HiOutlineX className='text-lg' />
                                        </div>
                                        <div>
                                            <Dropdown label="" dismissOnClick={false} renderTrigger={() => 
                                            <span className='h-10 w-10 flex justify-center items-center hover:bg-white hover:rounded-full duration-300 hover:shadow-md cursor-pointer'>
                                                <HiOutlineDotsVertical className='text-slate-600 text-xl' />
                                            </span>}>
                                                <Dropdown.Item>Move</Dropdown.Item>
                                            </Dropdown>
                                        </div>
                                    </div>
                                </Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                </div>
            </div>
        </>
    )
}
export default Documents;